import React from "react"
import { Modal } from "components/common"
import { TypographyV2, StepsV2 } from "components/v2"
import { CloseIcon } from "components/icons"
import { ExerciseAddVariableList, QuestionsAddVariableList } from "./components"
import { useQuestionAddVariableModal } from "./useQuestionAddVariableModal"

const QuestionAddVariableModal = () => {
    const { t, isHeLanguage, steps, isOpened, selectedExercise, onClose } = useQuestionAddVariableModal()

    return (
        <Modal show={isOpened} onClose={onClose} centered cardClassName="w-3/4 max-w-screen-lg">
            <div className="p-4 flex justify-between border-b border-border-blue">
                <TypographyV2 width="bold" type="base" className="text-med-black">
                    {t("selectExercise")}
                </TypographyV2>
                <div className="flex items-center">
                    <div className={isHeLanguage ? "ml-3.5" : "mr-3.5"}>
                        <StepsV2 viewType="row" steps={steps} currentStep={selectedExercise ? 2 : 1} />
                    </div>
                    <CloseIcon onClick={onClose} className="cursor-pointer text-[#9CA3AF]" height={20} width={20} />
                </div>
            </div>
            <div className="p-6 h-120 overflow-y-auto bg-gray-50">
                <TypographyV2 width="bold" type="xs" className="mb-2">
                    {t("selectFromLibrary")}
                </TypographyV2>
                {selectedExercise ? <QuestionsAddVariableList /> : <ExerciseAddVariableList isFiltering />}
            </div>
        </Modal>
    )
}

export default QuestionAddVariableModal
