import Slider from "rc-slider"
import "rc-slider/assets/index.css"

export interface IScaleSliderV2Props {
    min: number
    max: number
    step?: number
    value: number
    minLabel?: string
    maxLabel?: string
    onChange: (value: number) => void
    isDisabled?: boolean
    name?: string
}

const ScaleSliderV2 = ({
    min = 0,
    max = 1,
    step,
    value,
    onChange,
    isDisabled = false,
    minLabel,
    maxLabel,
    name
}: IScaleSliderV2Props) => {
    return (
        <div className="w-full">
            <div className="px-[16px] py-[18px]">
                <Slider {...{ min, max, step, value, onChange, name }} disabled={isDisabled} dots />
            </div>
            <div className="w-full flex items-center justify-between">
                {minLabel && <span className="text-sm font-medium text-[#6B7280]">{minLabel}</span>}
                {maxLabel && <span className="text-sm font-medium text-[#6B7280]">{maxLabel}</span>}
            </div>
        </div>
    )
}

export default ScaleSliderV2
