import { useMemo, useRef, useState, useEffect } from "react"
import { classNames } from "utils"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { useClickOutside } from "hooks/useClickOutside"
import {
    SelectMenuV2,
    MultiSelectTriggerV2,
    SingleSelectTriggerV2,
    ISelectV2Props,
    SearchInputV2,
    IGroupOptionV2,
    IOptionV2
} from "components/v2"
import { useFloating, useClick, useInteractions, flip, shift, offset, autoUpdate } from "@floating-ui/react"
import { ModalCompatiblePortal } from "components/common"
import { CloseIcon } from "components/icons"
import { errorInputClassNames } from "components/v2/constants"

const SelectV2 = ({
    error,
    selectedKeys,
    options,
    onChange,
    placeholder,
    mode = "single",
    isAutocompleteEnabled,
    isClearIconVisible = false,
    isDisabled = false,
    classList,
    modalRef
}: ISelectV2Props) => {
    const selectRef = useRef<HTMLDivElement>(null)
    const portalRef = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [search, setSearch] = useState("")

    const filterOptionsByGroup = (option: IGroupOptionV2) =>
        option.options.some(({ title }) => title.toLowerCase().includes(search.toLowerCase())) ||
        option.groupName.toLowerCase().includes(search.toLowerCase())

    const filteredOptions = useMemo(
        () =>
            options.filter((option) =>
                "groupName" in option ? filterOptionsByGroup(option) : option.title.toLowerCase()
            ),
        [options]
    )

    const filteredListBySearch = useMemo(
        () =>
            options
                .filter((option) =>
                    "groupName" in option
                        ? filterOptionsByGroup(option)
                        : !!search.length
                        ? option.title.toLowerCase().includes(search.toLowerCase())
                        : true
                )
                .sort((a: IOptionV2, b: IOptionV2) => {
                    if (!Number.isNaN(Number(a?.title)) && !Number.isNaN(Number(b?.title))) {
                        return Number(a?.title) - Number(b?.title)
                    }

                    const nameA = `${a?.title?.trim()}`.toLowerCase()
                    const nameB = `${b?.title?.trim()}`.toLowerCase()
                    return nameA.localeCompare(nameB)
                }),
        [options, search]
    )

    const { refs, floatingStyles, context, update } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [flip(), shift(), offset(4)],
        whileElementsMounted: autoUpdate
    })

    useEffect(() => {
        if (!modalRef) return

        const modalElement = modalRef.current
        if (!modalElement) return

        const handleScroll = () => {
            update()
        }

        modalElement.addEventListener("scroll", handleScroll)
        return () => {
            modalElement.removeEventListener("scroll", handleScroll)
        }
    }, [update])

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry.isIntersecting) {
                    setIsOpen(false) // Close dropdown when it's out of view
                }
            },
            { threshold: 0.1 }
        )

        if (selectRef.current) {
            observer.observe(selectRef.current)
        }

        return () => {
            if (selectRef.current) {
                observer.unobserve(selectRef.current)
            }
        }
    }, [])

    const onClick = useClick(context, {
        enabled: !isDisabled
    })

    const { getReferenceProps, getFloatingProps } = useInteractions([onClick])
    useClickOutside([selectRef, portalRef], () => setIsOpen(false))

    const getOption = (value: string) => {
        for (const option of filteredOptions) {
            if ("groupName" in option) {
                for (const opt of option.options) {
                    if (opt.id == value) {
                        return opt
                    }
                }
            } else {
                if (option.id == value) {
                    return option
                }
            }
        }
    }

    const selectOption = (option: string | number) => {
        if (mode === "single") {
            onChange([option])
            setIsOpen(false)
        } else {
            const sk = selectedKeys.includes(option)
                ? selectedKeys.filter((key) => key !== option)
                : [...selectedKeys, option]
            onChange(sk)
        }
    }

    return (
        <div ref={selectRef} className="w-full">
            <div
                ref={refs.setReference}
                {...getReferenceProps()}
                className={classNames(
                    `flex gap-x-2.5  items-center w-full border focus:outline-none rounded-lg h-fit px-3 cursor-pointer`,
                    error && !isDisabled
                        ? errorInputClassNames
                        : isOpen
                        ? "border-med-blue shadow-[0_0_8px_0_#3C7FF980]"
                        : "border-base-light",
                    isDisabled ? "bg-[#F9FAFB] text-[#9CA3AF]" : "bg-white",
                    classList?.input
                )}
            >
                {mode === "multiple" ? (
                    <MultiSelectTriggerV2
                        isDisabled={isDisabled}
                        placeholder={placeholder}
                        handleRemoveOption={selectOption}
                        keys={selectedKeys}
                        getOption={getOption}
                    />
                ) : (
                    <SingleSelectTriggerV2
                        placeholder={placeholder}
                        optionKey={selectedKeys[0]}
                        getOption={getOption}
                    />
                )}
                {isClearIconVisible && !!selectedKeys?.[0] ? (
                    <CloseIcon
                        width={20}
                        height={20}
                        className="flex-shrink-0"
                        onClick={(e) => {
                            e.stopPropagation()
                            onChange([])
                        }}
                    />
                ) : (
                    <ChevronDownIcon
                        width={20}
                        height={20}
                        className={classNames(
                            isOpen ? "transition-transform" : "rotate-180 transition-transform",
                            "flex-shrink-0"
                        )}
                    />
                )}
            </div>
            {isOpen && (
                <ModalCompatiblePortal>
                    <div
                        ref={refs.setFloating}
                        style={{ ...floatingStyles, width: selectRef?.current?.clientWidth }}
                        {...getFloatingProps()}
                        className="flex flex-col h-fit max-h-372 border rounded-lg border-base-light bg-white shadow-[0_0_8px_0_#3C7FF980] overflow-hidden z-9999 cursor-pointer"
                    >
                        <div ref={portalRef} className="overflow-y-scroll no-scrollbar">
                            {isAutocompleteEnabled && (
                                <div className="px-1 py-2">
                                    <SearchInputV2
                                        wrapperClassname="border-none border-b mb-0"
                                        iconClassName="text-[#F9FAFB]0"
                                        isClearEnabled
                                        placeholder="Search"
                                        searchText={search}
                                        onChange={(value) => setSearch(value)}
                                    />
                                </div>
                            )}
                            <SelectMenuV2
                                options={filteredListBySearch}
                                mode={mode}
                                selectedKeys={selectedKeys}
                                handleSelectOption={selectOption}
                                isSearching={search.length > 1}
                            />
                        </div>
                    </div>
                </ModalCompatiblePortal>
            )}
        </div>
    )
}

export default SelectV2
