import { ChevronLeftIcon } from "@heroicons/react/24/outline"
import { EventComment, FlatEvent } from "@sequel-care/types/Event"
import { Button } from "components/common"
import Spinner from "components/common/Spinner"
import { Tag } from "components/common/Tag"
import { ArrowUpRightIcon } from "components/icons"
import EventTitle from "components/PatientTimeline/EventTitle"
import { ETypographyTypes, Typography } from "components/Typography"
import { addDays, differenceInSeconds } from "date-fns"
import { MutableRefObject, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { pushToLibraryStack } from "store/patient/actions"
import { goToThePrevSidebar, pushEventSidebarToStack } from "store/sidebars/actions"
import { EventSidebar as EventSidebarData } from "types/AppSidebar"
import { eventTypeIconMap } from "utils"
import { sendMixpanelReport } from "utils/mixpanel"
import {
    CollaboratorsField,
    CompletedAtField,
    CreatorField,
    EndsAtField,
    FileField,
    InviteesField,
    LinkField,
    LocationField,
    RecurrenceField,
    StartsAtField
} from "./EventDetailsFields"
import EventMenu from "./EventMenu"
import { EventWithResponseSection, responseSectionMap } from "./ResponseSections"
import SidebarActivity from "./SidebarActivity"
import StatusField from "./StatusField"
import { useSidebarEvent } from "./utils"

type EventSidebarProps = {
    sidebar: EventSidebarData
    containerRef: MutableRefObject<HTMLElement>
}

const EventSidebar = ({ sidebar, containerRef }: EventSidebarProps) => {
    const { t } = useTranslation("common")

    const dispatch = useDispatch()
    const date = sidebar?.date_on_timeline
    const { event, comments, loading, wasFetched, setState } = useSidebarEvent(sidebar)

    const updateLocalEvent = wasFetched
        ? (updates: Partial<FlatEvent<string>>) => setState((state) => ({ ...state, event: { ...event, ...updates } }))
        : undefined

    const setFetchedComments = (newComments: EventComment<string>[]) =>
        setState({ event, comments: newComments, loading, wasFetched })

    useEffect(() => {
        if (!sidebar.isOpen && event?.type === "questionnaire") {
            const time_spent = differenceInSeconds(new Date(), sidebar.context.time)

            sendMixpanelReport("Questionnaire sidebar", {
                time_spent,
                context: sidebar.context.context,
                score: event.score,
                questionnaire_id: event.questionnaire_id,
                questionnaire_title: event.title
            })
        }
    }, [sidebar])

    if (loading && sidebar.isOpen)
        return (
            <div className="absolute w-10 top-1/2 right-1/2 -translate-y-1/2">
                <Spinner size={12} />
            </div>
        )

    if (!event) return null

    const ResponseSection = responseSectionMap[event.type as EventWithResponseSection] ?? null

    const isEventMenuDisabled = (event as any)?.target_role === "therapist"
    return (
        <div data-testid="event-sidebar" className="divide-y">
            {!isEventMenuDisabled && <EventMenu {...{ date, event }} />}
            <EventTitle
                large
                loading={loading}
                withScore
                {...event}
                title={event?.title}
                date={date}
                className="sticky top-0 pt-9 pb-4 bg-white z-10 px-12 flex flex-col items-start"
                style={{ boxShadow: "0 .4rem 1rem .5rem #fff" }}
            >
                <ChevronLeftIcon
                    onClick={() => dispatch(goToThePrevSidebar())}
                    className="cursor-pointer hover:scale-110 hover:text-dark-blue w-6 absolute ltr:left-5 rtl:right-5 top-10"
                />

                {event.type === "task" && (
                    <Typography type={ETypographyTypes.BODY_SMALL} className="w-full">
                        <div dangerouslySetInnerHTML={{ __html: event.description }} />
                    </Typography>
                )}
                {event.type === "questionnaire" && (
                    <Button
                        theme="secondary_borderless"
                        className="px-0"
                        onClick={() =>
                            dispatch(
                                pushToLibraryStack({
                                    patientId: event.patient_id,
                                    view: "learn_more",
                                    eventTemplates: [event]
                                })
                            )
                        }
                    >
                        {t("learnMore")}
                    </Button>
                )}
            </EventTitle>
            <div className="pb-9 px-12 border-b border-border-blue">
                <div className="grid grid-cols-2 mt-4 gap-4">
                    <CreatorField creatorId={event.created_by} />
                    <StatusField {...event} date={date} />
                    {event.end_time && (
                        <>
                            <StartsAtField event={event} />
                            <EndsAtField event={event} />
                        </>
                    )}
                    <RecurrenceField event={event} />
                    {event.completed_at && <CompletedAtField completed_at={event.completed_at} />}
                    {event.location && <LocationField location={event.location} />}
                    {event.link && <LinkField link={event.link} />}
                    {event.invitees ? (
                        <InviteesField invitees={event.invitees} />
                    ) : (
                        <CollaboratorsField collaborators={event.collaborators as number[]} />
                    )}
                    {event.attachment && <FileField event={event} />}
                </div>
            </div>
            {event.linked_events?.length ? (
                <div className="flex flex-col px-12 py-9 gap-6">
                    <span className="text-dark-blue text-xl font-medium">{t("patient:eventManager.target")}</span>

                    <div className="flex flex-col gap-4"></div>
                    {event.linked_events.map((event) => {
                        const {
                            event: { title, type, date_on_timeline, expires_within, id, patient_id }
                        } = event
                        const Icon = eventTypeIconMap[type]

                        const deadline = expires_within && addDays(date_on_timeline, expires_within)
                        const status =
                            type === "task"
                                ? event.event.task_status
                                : event.event.completed_at
                                ? "complete"
                                : deadline > new Date()
                                ? "skipped"
                                : undefined
                        return (
                            <span key={event.id} className="flex justify-between items-center">
                                <div className="flex gap-4 text-text-blue">
                                    <Icon className="w-5 h-5" /> {title}
                                </div>
                                <div className="flex gap-4">
                                    {status && (
                                        <Tag color={["skipped", "declined"].includes(status) ? "red" : "green"}>
                                            {t(`statuses.${status}`)}
                                        </Tag>
                                    )}
                                    <div>
                                        <ArrowUpRightIcon
                                            onClick={() =>
                                                dispatch(
                                                    pushEventSidebarToStack({
                                                        id,
                                                        patient_id,
                                                        context: { time: new Date(), context: "linked-events-sidebar" }
                                                    })
                                                )
                                            }
                                            className="text-xl hover:scale-110 transition-transform cursor-pointer text-secondary-2 rtl:rotate-y-180"
                                        />
                                    </div>
                                </div>
                            </span>
                        )
                    })}
                </div>
            ) : null}
            {ResponseSection && <ResponseSection event={event} updateLocalEvent={updateLocalEvent} />}
            <SidebarActivity
                initialScrollTo={sidebar.initialScrollTo}
                fetchedComments={comments}
                isCommentFetched={wasFetched}
                setFetchedComments={setFetchedComments}
                {...{ date, event, containerRef }}
            />
        </div>
    )
}

export default EventSidebar
