import { SVGProps } from "react"

const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.99935 18.7677C14.6017 18.7677 18.3327 15.0368 18.3327 10.4344C18.3327 5.83203 14.6017 2.10107 9.99935 2.10107C5.39698 2.10107 1.66602 5.83203 1.66602 10.4344C1.66602 15.0368 5.39698 18.7677 9.99935 18.7677Z"
            fill="#DC2626"
        />
        <path d="M10 7.10107V10.4344" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10 13.7681H10.0083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default ErrorIcon
