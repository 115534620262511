import { PatientWithMetrics } from "@sequel-care/types/Patient"
import DashboardCard from "components/DashboardCard"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { useCurrentUser } from "store/hooks"
import { CircleChart } from "../ChartComponents"
import { ReactNode } from "react"

export const Caseload = ({
    patients,
    emptyState,
    loading
}: {
    patients: PatientWithMetrics<string>[]
    emptyState?: ReactNode
    loading: boolean
}) => {
    const { t } = useTranslation("therapist")
    const currentUser = useCurrentUser()

    const isOverloaded = currentUser?.team_member.case_load && patients.length > currentUser.team_member.case_load

    return (
        <DashboardCard
            title={loading ? <Skeleton className="w-32" /> : t("dashboard.caseLoad.title")}
            subtitle={loading ? <Skeleton className="w-64" /> : t("dashboard.caseLoad.subtitle")}
            contentClassName="flex flex-col items-center mt-10 mb-7"
        >
            {emptyState ? (
                emptyState
            ) : (
                <CircleChart
                    loading={loading}
                    label={t("dashboard.caseLoad.label")}
                    size="large"
                    color={isOverloaded ? "text-negative" : "text-med-blue"}
                    value={patients.length}
                    value_out_of={currentUser?.team_member.case_load}
                />
            )}
        </DashboardCard>
    )
}
