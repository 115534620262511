import { useEffect, useState } from "react"
import { useQuestionsSelector } from "store/hooks"
import { getExerciseQuestionsByExerciseId } from "api"

export const useQuestionsAddVariableList = () => {
    const [questions, setQuestions] = useState([])
    const {
        addVariableModal: { selectedExercise, selectedQuestion }
    } = useQuestionsSelector()

    useEffect(() => {
        const fetchQuestions = async () => {
            const response = await getExerciseQuestionsByExerciseId(selectedExercise.id)
            const filteredQuestions = response.filter(({ id }) => id !== selectedQuestion.id)
            setQuestions(filteredQuestions)
        }

        fetchQuestions()
    }, [])

    return { questions }
}
