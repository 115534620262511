import i18n, { use } from "i18next"
import { initReactI18next } from "react-i18next"

import localeEn from "locales/en"
import localeHe from "locales/he"

use(initReactI18next).init({
    lng: "en",
    ns: Object.keys(localeEn),
    resources: {
        en: localeEn,
        he: localeHe
    }
})

export default i18n
