import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2 } from "components/v2"

 const MinutesQuestionResponse = ({
    question,
    screeningAnswer
}: {
    question: any
    screeningAnswer: ScreeningAnswer
}) => {
    return (
        <div className="p-4">
            <div className="bg-white border border-slate-300 rounded-lg py-4 pl-3">
                <TypographyV2 type="sm" className="font-bold">
                    {question.question}
                </TypographyV2>
                <TypographyV2 className="text-[#6B7280] mt-2" type="base">
                    {screeningAnswer?.value} Minutes
                </TypographyV2>
            </div>
        </div>
    )
}

export default MinutesQuestionResponse