import * as yup from "yup"
import { emailRegex } from "../../IntakeScreening/CandidateContactInformation/candidateContactInformationValidationShema"

const personalDetailsValidationSchema = (questions: any, t: any) => {
    const personalValidationSchemaConst = {
        date_of_birth: yup.string().required(t("intake:intake-screening.date_of_birth_is_required")),
        gender: yup.string().required(t("intake:intake-screening.gender_is_required")),
        phone: yup.string().required(t("intake:intake-screening.phone_is_required")),
        first_name: yup
            .string()
            .min(2, t("intake:intake-screening.first_name_is_required"))
            .required(t("intake:intake-screening.first_name_is_required")),
        last_name: yup
            .string()
            .min(2, t("intake:intake-screening.last_name_is_required"))
            .required(t("intake:intake-screening.last_name_is_required")),
        address: yup
            .string()
            .min(2, t("intake:intake-screening.address_is_required"))
            .required(t("intake:intake-screening.address_is_required")),
        email: yup
            .string()
            .matches(emailRegex, t("intake:intake-screening.invalid_email_format"))
            .required(t("intake:intake-screening.email_is_required"))
    }

    for (const key in personalValidationSchemaConst) {
        const personalDetailQuestion = questions.find((question: any) => question.column_name === key)
        if (!personalDetailQuestion)
            delete personalValidationSchemaConst[key as keyof typeof personalValidationSchemaConst]
    }

    return yup.object({
        personal_details: yup.object().shape(personalValidationSchemaConst)
    })
}

export default personalDetailsValidationSchema
