import React, { ReactNode } from "react"
import { classNames } from "utils"

interface ICardV2Props {
    children: ReactNode
    className?: string
    onClick?: () => void
}

const CardV2: React.FC<ICardV2Props> = ({ children, className, onClick }) => (
    <div
        className={classNames(
            "p-[16px] pr-[12px] bg-white border border-gray-200 rounded-lg w-full h-full cursor-pointer",
            className
        )}
        onClick={onClick}
    >
        {children}
    </div>
)

export default CardV2
