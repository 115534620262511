import { Transition } from "@headlessui/react"
import { ReactNode } from "react"
import { classNames } from "utils"

type FadeWrapperProps = {
    transition?: string
    start?: string
    end?: string
    children: ReactNode
} & Omit<
    Parameters<typeof Transition>[0],
    "leave" | "leaveFrom" | "leaveTo" | "enter" | "enterFrom" | "enterTo" | "transition"
>

const FadeWrapper = ({
    transition = "ease-in duration-100",
    start = "opacity-0",
    end = "opacity-100",
    children,
    ...props
}: FadeWrapperProps) => {
    return (
        <Transition
            leave={classNames("transition", transition)}
            leaveFrom={end}
            leaveTo={start}
            enter={classNames("transition", transition)}
            enterFrom={start}
            enterTo={end}
            {...props}
        >
            <div className={transition}>{children}</div>
        </Transition>
    )
}

export default FadeWrapper
