import React from "react"
import { ControlledTextInputV2, FormControlPropsV2 } from "components/v2"
import { useMask } from "@react-input/mask"
import { phoneInputMask } from "./controlPhoneInputConstants"
import { useIsHeLanguage } from "hooks"
import { classNames } from "utils"
interface IControlPhoneInputV2Prop<T> extends FormControlPropsV2<T> {}

const ControlledPhoneInputV2 = <T,>(props: IControlPhoneInputV2Prop<T>) => {
    const inputRef = useMask({ mask: phoneInputMask, replacement: { _: /\d/ } })
    const { isHeLanguage } = useIsHeLanguage()
    return (
        <ControlledTextInputV2
            ref={inputRef}
            {...props}
            {...(isHeLanguage ? { dir: "ltr" } : {})}
            classList={{
                ...props.classList,
                input: classNames(props.classList?.input, isHeLanguage ? "!text-right" : "")
            }}
        />
    )
}

export default ControlledPhoneInputV2
