import { FlatEvent } from "@sequel-care/types"
import { Tag } from "components/common/Tag"
import { ETypographyTypes, Typography } from "components/Typography"
import { CSSProperties, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { useAllQuestionnaires } from "store/hooks"
import { classNames, eventTypeIconMap } from "utils"
import { localeFormat } from "utils/dates"
import { getAttrsByCutoff } from "utils/graphs"

interface EventTitleProps extends FlatEvent<string> {
    date: Date
    large?: boolean
    children?: ReactNode
    className?: string
    style?: CSSProperties
    withScore?: boolean
    loading?: boolean
}

const EventTitle = ({
    date,
    title,
    type,
    large,
    children,
    className,
    style,
    score,
    questionnaire_id,
    withScore,
    loading
}: EventTitleProps) => {
    const { t, i18n } = useTranslation("patient")

    const Icon = eventTypeIconMap[type]
    const isSkeleton = loading ?? !Boolean(date)

    if (type === "reflection") title = t("eventManager.reflectionTitle")

    const questionnaires = useAllQuestionnaires()
    const scale = questionnaires.find(({ id }) => id === questionnaire_id)?.questionnaire_scale
    const cutoffColor = score && scale ? getAttrsByCutoff(score, scale) : undefined

    return (
        <div
            data-testid="sidebar-event_title"
            className={classNames("flex flex-col gap-1 relative", className)}
            style={style}
        >
            <Typography type={ETypographyTypes.SUBTITLE} className={large ? "text-2xl" : undefined}>
                {!isSkeleton ? title || "Untitled" : <Skeleton width="6rem" />}
            </Typography>
            <div className="flex w-full justify-between" data-testid="sidebar-event_subtitle">
                <div className="flex gap-3">
                    <Typography type={ETypographyTypes.MED_BLUE} className="flex items-center gap-1">
                        {!isSkeleton ? (
                            <>
                                {Icon && <Icon className="w-6 h-6" />}
                                {t(`eventTypes.${type}`)}
                            </>
                        ) : (
                            <Skeleton width="8rem" />
                        )}
                    </Typography>
                    <Typography type={ETypographyTypes.BODY_SMALL} className="flex items-center text-text-blue">
                        {!isSkeleton ? (
                            Boolean(date) ? (
                                localeFormat(date, i18n.language === "he" ? "d בMMMM" : "MMMM d", i18n)
                            ) : null
                        ) : (
                            <Skeleton width="5rem" />
                        )}
                    </Typography>
                </div>

                {withScore && score ? (
                    <Tag className="flex gap-2 items-center" color={cutoffColor}>
                        <span>{t("score")}</span>
                        {score}
                    </Tag>
                ) : undefined}
            </div>
            {children}
        </div>
    )
}

export default EventTitle
