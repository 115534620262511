import { useState } from "react"
import { UploadFileV2, TypographyV2 } from "components/v2"
import { Control } from "react-hook-form"
import { ScreeningTemplateQuestion } from "@prisma/client"

interface ImageQuestionCardProps {
    question: ScreeningTemplateQuestion
    control: Control<Record<string, string | (string | number)[]>>
    error: string | undefined
}

const ImageQuestionCard = ({ question, control, error }: ImageQuestionCardProps) => {
    const { question: question_text } = question
    const [file, setFile] = useState(null)
    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <div className="h-[136px] w-full">
                <UploadFileV2 value={file} setValue={(newFile) => setFile(newFile)} />
            </div>
        </div>
    )
}

export default ImageQuestionCard
