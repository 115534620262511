import React, { FunctionComponent, memo, useMemo } from "react"
import { classNames } from "utils"
import { ListItemV2 } from "components/v2"

interface ListV2<T> {
    filterQuery?: string
    isVerticalView?: boolean
    renderItem: FunctionComponent<T>
    list: T[]
    filterFunction?: (item: T) => boolean
}

function ListV2<T>({ list, renderItem: RenderItem, isVerticalView = false, filterFunction, ...otherProps }: ListV2<T>) {
    const filteredList = useMemo(
        () => (filterFunction ? list.filter((item) => filterFunction(item)) : list),
        [list, filterFunction]
    )

    return (
        <div className={classNames(isVerticalView ? "flex-col" : "flex-row", "flex flex-wrap justify-start gap-2")}>
            {filteredList.map((item, idx) => (
                <ListItemV2 key={idx} isVerticalView={isVerticalView}>
                    <RenderItem {...{ ...item, ...otherProps, isVerticalView }} />
                </ListItemV2>
            ))}
        </div>
    )
}

export default memo(ListV2)
