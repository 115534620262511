import { FormControlProps } from "./types"
import { TimeValue } from "@react-types/datepicker"
import { useLocale } from "@react-aria/i18n"
import { useTimeFieldState } from "@react-stately/datepicker"
import { useRef } from "react"
import { useTimeField } from "react-aria"
import { classNames } from "../utils"
import { RenderLabel } from "./RenderLabel"
import { defaultInputClassNames, errorClassNames } from "./utils"
import { DateSegment } from "./DateField"

export function TimeField({ value, error, ...props }: FormControlProps<TimeValue>) {
    const { locale } = useLocale()
    const state = useTimeFieldState({
        locale,
        value,
        onChange: props.setValue
    })
    const ref = useRef(null)
    const { labelProps, fieldProps } = useTimeField(props, state, ref)

    return (
        <div className={classNames("relative", props.classList?.wrapper)}>
            <RenderLabel {...props} elProps={labelProps} />
            <div
                {...fieldProps}
                dir="ltr"
                ref={ref}
                className={classNames("flex items-center", props.classList?.input ?? defaultInputClassNames)}
            >
                {state.segments.map((segment, i) => (
                    <DateSegment key={i} {...{ segment, state }} color="text-dark-blue" />
                ))}
            </div>
            {error && <div className={errorClassNames}>{error}</div>}
        </div>
    )
}
