import React from "react"
import { FormInputWrapper, SwitchV2 } from "components/v2"
import { FormControlPropsV2 } from "components/v2/components/common/form-controls"
import { classNames } from "utils"
import { useController } from "react-hook-form"

interface IControlSwitchInputV2Prop<T> extends FormControlPropsV2<T> {
    isLarge?: boolean
}

const ControlledSwitchInputV2 = <T,>(props: IControlSwitchInputV2Prop<T>) => {
    const { isRequired, label, placeholder, classList, control, name, ...inputProps } = props
    const { error, isDisabled } = inputProps

    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    return (
        <FormInputWrapper {...{ label, error, isRequired, isDisabled, classList: classList?.wrapper }}>
            <SwitchV2
                isChecked={value as boolean}
                onChange={() => onChange(!value)}
                className={classNames(label ? "mt-1" : "mt-0", classList?.input)}
                {...inputProps}
            />
        </FormInputWrapper>
    )
}

export default ControlledSwitchInputV2
