import { ChangeEvent, useState } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { CameraIcon } from "components/icons"
import Spinner from "components/common/Spinner"
import { defaultLimitMb, convertBytesToMb } from "./uploadFileConstantsV2"
import { TypographyV2 } from "components/v2"

interface IUploadFileProps {
    value: File | null
    accept?: string
    limitSize?: number
    placeholder?: string
    setValue: (file: File) => void
}

const UploadFileV2 = ({
    value,
    accept = "application/pdf, image/*",
    placeholder = "Upload Image",
    limitSize = defaultLimitMb,
    setValue
}: IUploadFileProps) => {
    const { t } = useTranslation("common")
    const [isLoading, setLoading] = useState<boolean>(false)

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        try {
            setLoading(true)
            if (event.target.files) {
                const file = event.target.files[0]
                setValue(file)
                if (convertBytesToMb(file.size) > limitSize) {
                    toast(t("fileUpload.fileSizeError", { mb: 10 }), { type: "error" })
                    return
                }

                toast(t("common:fileUpload.success"), { type: "success" })
            }
        } catch (error) {
            toast(t("common:wentWrong"), { type: "error" })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div
            className="w-full h-full bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(/images/file-upload.png)`, backgroundSize: "cover" }}
        >
            {isLoading ? (
                <Spinner size={10} className="upload-file-loader" />
            ) : (
                <label
                    htmlFor="file-upload"
                    className="w-full h-full flex justify-center items-center flex-col cursor-pointer"
                >
                    <input
                        onChange={handleFileChange}
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        accept={accept}
                    />
                    <CameraIcon />
                    <TypographyV2 type="sm" className="text-med-blue">
                        {placeholder}
                    </TypographyV2>
                </label>
            )}
        </div>
    )
}

export default UploadFileV2
