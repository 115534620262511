import { BasicUser, TeamUser } from "@sequel-care/types"
import { AvatarWithName } from "components/UserAvatar"
import { defaultInputClassNames, defineField } from "forms"
import { DateSelectorWithIcons } from "forms/DateSelector"
import SuperSelect from "forms/SuperSelect"
import { BasicItem, RenderItemComponent } from "forms/SuperSelect/types"
import { TagSelect } from "forms/SuperSelect/wrappers"
import { FormControlProps } from "forms/types"
import { DependencyList, useCallback, useMemo } from "react"
import { useUsersInCurrentOrg } from "store/hooks"
import { CompleteUserInOrg } from "types/SchemaExtensions"
import { classNames, getUserName, noopTFunction } from "utils"
import { usePatientFormContext } from ".."

export type TreatmentInfo = {
    treatment_start: Date
    case_manager: number
    collaborators: number[]
    tags: (string | number)[]
}

const labelClassNames = "text-base text-dark-blue whitespace-nowrap"

const useRenderCollaborator = () =>
    useCallback<RenderItemComponent<BasicItem & { user: BasicUser }>>(
        ({ item }) => <AvatarWithName user={item.user} size={6} />,
        []
    )

const useFilteredUsers = (
    filter: (users: CompleteUserInOrg<string>[]) => CompleteUserInOrg<string>[],
    deps: DependencyList
) => {
    const users = useUsersInCurrentOrg()
    return useMemo(
        () => filter(users).map(({ user }) => ({ id: user.id, label: getUserName(user), user })),
        [users, ...deps]
    )
}

const CaseManagerSelect = (props: FormControlProps<number | null> & { disabled: boolean }) => {
    const { state: allState } = usePatientFormContext()

    const items = useFilteredUsers(
        (users) =>
            users.filter(
                ({ user }) => !allState.treatment.collaborators?.find((collaboratorId) => collaboratorId === user.id)
            ),
        [allState.treatment.collaborators]
    )

    const RenderCollaborator = useRenderCollaborator()
    return <SuperSelect {...props} items={items} RenderItem={RenderCollaborator} />
}

const CollaboratorSelect = (props: FormControlProps<number[]>) => {
    const { state } = usePatientFormContext()

    const items = useFilteredUsers(
        (users) =>
            !state.treatment?.case_manager
                ? users
                : users.filter(({ user_id }) => state.treatment.case_manager !== user_id),
        [state.treatment?.case_manager]
    )

    const RenderCollaborator = useRenderCollaborator()
    return <SuperSelect {...props} items={items} style="tags" RenderItem={RenderCollaborator} />
}

export const getTreatmentFields = (currentUser: TeamUser, t = noopTFunction, params?: { isEdit?: boolean }) => {
    const treatmentFields = [
        defineField({
            id: "treatment_start",
            label: t("patient:patientForm.treatmentStartDate") as string,
            Component: DateSelectorWithIcons,
            allowClear: false,
            initialValue: new Date(),
            classList: {
                wrapper: "flex gap-4 items-center justify-between",
                label: labelClassNames,
                input: classNames(defaultInputClassNames, "flex gap-3 justify-between w-48")
            }
        }),
        defineField({
            id: "tags",
            label: t("patient:patientForm.tags") as string,
            initialValue: [],
            Component: TagSelect<true>,
            withAddNew: true,
            placeholder: t("patient:patientForm.tagsPlaceholder") as string
        })
    ] as const

    if (!params?.isEdit)
        return [
            ...treatmentFields,
            defineField({
                id: "case_manager",
                label: t("patient:patientForm.caseManager") as string,
                initialValue: currentUser.id,
                placeholder: t("patient:patientForm.chooseTherapist"),
                Component: CaseManagerSelect,
                validations: { required: { value: true, message: "Case manager is required" } },
                classList: { label: labelClassNames, wrapper: "flex items-center gap-4", inputWrapper: "w-full" },
                disabled: currentUser.role === "therapist"
            }),
            defineField({
                id: "collaborators",
                label: t("common:collaborators") as string,
                initialValue: [],
                Component: CollaboratorSelect
            })
        ] as const
    else return treatmentFields
}
