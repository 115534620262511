import { BasicUser } from "@sequel-care/types"
import { IntakeCandidate } from "components/v2/constants/enums/intake"
import { SequelConversation } from "utils/conversations"

export interface CommonSidebarProps<T extends string> {
    type: T
    isOpen: boolean
}

export interface EventSidebar extends CommonSidebarProps<"event"> {
    id: number
    date_on_timeline?: Date
    initialScrollTo?: "activity"
    patient_id?: number
    context: { time: Date; context: string }
}

export type ChatPage = "list" | "create" | "create-team" | "create-group" | "chat" | "create-group-team"

export interface ConversationSidebar extends CommonSidebarProps<"conversation"> {
    mode: ChatPage
    conversation?: SequelConversation | null
    newChatWith?: BasicUser[] | null
}

export interface PatientSidebar extends CommonSidebarProps<"patient"> {
    patientId: number
    candidate?: IntakeCandidate
}

export interface TherapistSidebar extends CommonSidebarProps<"therapist"> {
    userId: number
}

export type AppSidebar =
    | EventSidebar
    | ConversationSidebar
    | PatientSidebar
    | TherapistSidebar
    | CommonSidebarProps<"notifications">
    | CommonSidebarProps<"activity">
    
function makeSidebarValidator<Sidebar extends AppSidebar>(type: AppSidebar["type"]) {
    return (sidebar: AppSidebar | { type: "placeholder" }): sidebar is Sidebar => {
        return sidebar?.type === type
    }
}

export const isEventSidebar = makeSidebarValidator<EventSidebar>("event")
export const isConversationSidebar = makeSidebarValidator<ConversationSidebar>("conversation")
export const isPatientSidebar = makeSidebarValidator<PatientSidebar>("patient")
export const isTherapistSidebar = makeSidebarValidator<TherapistSidebar>("therapist")
export const isNotificationsSidebar = makeSidebarValidator<CommonSidebarProps<"notifications">>("notifications")
export const isActivitySidebar = makeSidebarValidator<CommonSidebarProps<"activity">>("activity")
