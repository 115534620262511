import { SVGProps } from "react"

const BoldPlusIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M5.90117 1.4188C5.90117 0.921743 5.49823 0.518799 5.00117 0.518799C4.50412 0.518799 4.10117 0.921743 4.10117 1.4188V4.4188H1.10117C0.604116 4.4188 0.201172 4.82174 0.201172 5.3188C0.201172 5.81586 0.604116 6.2188 1.10117 6.2188L4.10117 6.2188V9.2188C4.10117 9.71586 4.50412 10.1188 5.00117 10.1188C5.49823 10.1188 5.90117 9.71586 5.90117 9.2188V6.2188L8.90117 6.2188C9.39823 6.2188 9.80117 5.81586 9.80117 5.3188C9.80117 4.82174 9.39823 4.4188 8.90117 4.4188H5.90117V1.4188Z"
            fill="#3C7FF9"
        />
    </svg>
)

export default BoldPlusIcon
