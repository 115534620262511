import {
    DetailedHTMLProps,
    ForwardRefRenderFunction,
    HTMLAttributes,
    ReactNode,
    forwardRef,
    useEffect,
    useRef
} from "react"
import { useDispatch } from "store"
import { toggleCurrentSidebar } from "store/sidebars/actions"
import { classNames } from "utils"

interface SlidingSidebarProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    show: boolean
    transitionDuration?: number
    axis?: "x" | "y"
    children: ReactNode
    size: string | number
    zIndex?: number
    hasTopBar?: boolean
}

type SlidingSidebarComponent = ForwardRefRenderFunction<HTMLElement, SlidingSidebarProps>

const SlidingSidebar: SlidingSidebarComponent = (
    { show, transitionDuration = 300, axis = "x", size, children, className, zIndex = 10, hasTopBar = false, ...props },
    forwardedRef
) => {
    const dispatch = useDispatch()
    const ref = useRef<HTMLElement>(null)

    const currentSize = show ? size : 0

    useEffect(() => {
        if (show) {
            const closeSidebar = (e: MouseEvent) => {
                const target = e.target as Element
                if (
                    !ref.current?.contains(target) &&
                    !target.closest(".remirror-floating-popover") &&
                    !target.closest("#menu-panel") &&
                    !target.closest("[id^=headlessui-dialog]")
                ) {
                    dispatch(toggleCurrentSidebar())
                }
            }
            window.addEventListener("mouseup", closeSidebar)
            return () => window.removeEventListener("mouseup", closeSidebar)
        }
    }, [show])

    return (
        <aside
            {...props}
            className={classNames(
                "transition-all overflow-hidden absolute bottom-0 ltr:right-0 rtl:left-0 h-full grow shrink-0"
            )}
            style={{
                transitionDuration: `${transitionDuration}ms`,
                width: axis === "x" ? currentSize : undefined,
                height: axis === "y" ? currentSize : undefined,
                zIndex
            }}
            ref={(el) => {
                ref.current = el
                if (typeof forwardedRef === "function") forwardedRef(el)
                else if (forwardedRef) forwardedRef.current = el
            }}
            role="complementary"
        >
            <div
                className={classNames("h-full", className)}
                style={{
                    width: axis === "x" ? size : undefined,
                    height: axis === "y" ? size : undefined
                }}
            >
                {children}
            </div>
        </aside>
    )
}

export default forwardRef(SlidingSidebar)
