import { ControlledTextAreaV2, TypographyV2 } from "components/v2"
import { useTranslation } from "react-i18next"
import { Control, useController } from "react-hook-form"

interface FreeTextQuestionCardProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: any
}

const FreeTextQuestionCard = ({ control, error, question }: FreeTextQuestionCardProps) => {
    const { is_required, question: question_text } = question
    const { t } = useTranslation()

    const {
        field: { value }
    } = useController({
        name: `screeningAnswers.${question.id}`,
        control,
        rules: { required: true },
        defaultValue: ""
    })

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <ControlledTextAreaV2
                error={error}
                isRequired={is_required}
                rowsCount={10}
                classList={{ wrapper: "w-full" }}
                name={`screeningAnswers.${question.id}`}
                control={control}
                placeholder={t("intake:common.tell_us")}
            />
        </div>
    )
}

export default FreeTextQuestionCard
