import { SVGProps } from "react"

const EmptyListIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="135" height="52" viewBox="0 0 135 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="16" y="0.5" width="118.419" height="22.4186" rx="5.2093" fill="#F5F7FC" />
        <rect x="21.2093" y="5.70947" width="12" height="12" rx="6" fill="#E0E7F7" />
        <g clip-path="url(#clip0_2467_38212)">
            <path
                d="M24.8951 9.00035C25.0006 8.87261 24.9826 8.68352 24.8548 8.57799C24.7271 8.47247 24.538 8.49049 24.4325 8.61822C24.0728 9.05367 23.8132 9.57559 23.6899 10.1484C23.655 10.3104 23.758 10.47 23.92 10.5049C24.082 10.5397 24.2416 10.4367 24.2764 10.2747C24.3791 9.79826 24.595 9.3636 24.8951 9.00035Z"
                fill="#97B0E3"
            />
            <path
                d="M29.9859 8.61822C29.8804 8.49049 29.6913 8.47247 29.5636 8.57799C29.4359 8.68352 29.4178 8.87261 29.5234 9.00035C29.8234 9.3636 30.0394 9.79826 30.142 10.2747C30.1768 10.4367 30.3364 10.5397 30.4984 10.5049C30.6604 10.47 30.7634 10.3104 30.7285 10.1484C30.6052 9.57559 30.3457 9.05367 29.9859 8.61822Z"
                fill="#97B0E3"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.2093 8.50962C25.8838 8.50962 24.8093 9.58414 24.8093 10.9096C24.8093 11.6643 24.6278 12.3757 24.3063 13.0033C24.2636 13.0867 24.2623 13.1852 24.3027 13.2696C24.3432 13.3541 24.4207 13.4148 24.5124 13.4338C24.9394 13.5225 25.374 13.5905 25.815 13.6368C25.8793 14.3504 26.479 14.9096 27.2093 14.9096C27.9396 14.9096 28.5393 14.3504 28.6036 13.6368C29.0445 13.5905 29.4791 13.5225 29.9062 13.4338C29.9978 13.4148 30.0754 13.3541 30.1158 13.2696C30.1562 13.1852 30.1549 13.0867 30.1122 13.0033C29.7908 12.3757 29.6093 11.6643 29.6093 10.9096C29.6093 9.58414 28.5347 8.50962 27.2093 8.50962ZM27.2093 14.3096C26.8284 14.3096 26.5097 14.0434 26.429 13.6869C26.6872 13.702 26.9473 13.7096 27.2093 13.7096C27.4712 13.7096 27.7314 13.702 27.9896 13.6869C27.9089 14.0434 27.5902 14.3096 27.2093 14.3096Z"
                fill="#97B0E3"
            />
        </g>
        <rect x="37.2093" y="8.70947" width="79" height="6" rx="2" fill="#E0E7F7" />
        <rect x="118.209" y="8.70947" width="11" height="6" rx="2" fill="#E0E7F7" />
        <rect y="29.5" width="118.419" height="22.4186" rx="5.2093" fill="#F5F7FC" />
        <rect x="5.20929" y="34.7095" width="12" height="12" rx="6" fill="#E0E7F7" />
        <g clip-path="url(#clip1_2467_38212)">
            <path
                d="M8.89505 38.0003C9.00057 37.8726 8.98256 37.6835 8.85482 37.578C8.72708 37.4725 8.53799 37.4905 8.43247 37.6182C8.07276 38.0537 7.81325 38.5756 7.68989 39.1484C7.65501 39.3104 7.75804 39.47 7.92001 39.5049C8.08199 39.5397 8.24157 39.4367 8.27644 39.2747C8.37905 38.7983 8.59498 38.3636 8.89505 38.0003Z"
                fill="#97B0E3"
            />
            <path
                d="M13.9859 37.6182C13.8804 37.4905 13.6913 37.4725 13.5636 37.578C13.4359 37.6835 13.4178 37.8726 13.5234 38.0003C13.8234 38.3636 14.0394 38.7983 14.142 39.2747C14.1768 39.4367 14.3364 39.5397 14.4984 39.5049C14.6604 39.47 14.7634 39.3104 14.7285 39.1484C14.6052 38.5756 14.3457 38.0537 13.9859 37.6182Z"
                fill="#97B0E3"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.2093 37.5096C9.88378 37.5096 8.80926 38.5841 8.80926 39.9096C8.80926 40.6643 8.62777 41.3757 8.30632 42.0033C8.26364 42.0867 8.26232 42.1852 8.30274 42.2696C8.34316 42.3541 8.42068 42.4148 8.51235 42.4338C8.93943 42.5225 9.374 42.5905 9.81498 42.6368C9.87926 43.3504 10.479 43.9096 11.2093 43.9096C11.9396 43.9096 12.5393 43.3504 12.6036 42.6368C13.0445 42.5905 13.4791 42.5225 13.9062 42.4338C13.9978 42.4148 14.0754 42.3541 14.1158 42.2696C14.1562 42.1852 14.1549 42.0867 14.1122 42.0033C13.7908 41.3757 13.6093 40.6643 13.6093 39.9096C13.6093 38.5841 12.5347 37.5096 11.2093 37.5096ZM11.2093 43.3096C10.8284 43.3096 10.5097 43.0434 10.429 42.6869C10.6872 42.702 10.9473 42.7096 11.2093 42.7096C11.4712 42.7096 11.7314 42.702 11.9896 42.6869C11.9089 43.0434 11.5902 43.3096 11.2093 43.3096Z"
                fill="#97B0E3"
            />
        </g>
        <rect x="21.2093" y="37.7095" width="79" height="6" rx="2" fill="#E0E7F7" />
        <rect x="102.209" y="37.7095" width="11" height="6" rx="2" fill="#E0E7F7" />
        <defs>
            <clipPath id="clip0_2467_38212">
                <rect width="8" height="8" fill="white" transform="translate(23.2093 7.70947)" />
            </clipPath>
            <clipPath id="clip1_2467_38212">
                <rect width="8" height="8" fill="white" transform="translate(7.20929 36.7095)" />
            </clipPath>
        </defs>
    </svg>
)

export default EmptyListIcon
