import { CompleteExercise } from "@sequel-care/types"
import { useMemo, useState } from "react"
import { useDispatch } from "store"
import { useAllExercises } from "store/hooks"
import { pushToLibraryStack } from "store/patient/actions"
import { ContentLibraryData } from "types/Redux"
import { RenderLibrary } from "./CardsView"
import { eventToTemplate } from "./utils"

const ExerciseLibrary = ({ searchInitialValue }: { searchInitialValue?: string }) => {
    const dispatch = useDispatch()
    const allExercises = useAllExercises()
    const [search, setSearch] = useState(searchInitialValue)

    const { onAdd, onLearnMore } = useMemo(() => {
        const goToView = (view: ContentLibraryData["view"]) => (exercise: CompleteExercise) =>
            dispatch(
                pushToLibraryStack({
                    eventTemplates: eventToTemplate("exercise", exercise),
                    view: view,
                    patientId: "preserve"
                })
            )

        return { onAdd: goToView("config"), onLearnMore: goToView("learn_more") }
    }, [search])

    return (
        <RenderLibrary
            type="exercise"
            allItems={allExercises}
            cardItems={allExercises}
            gridColumns="grid-cols-1"
            {...{ onAdd, onLearnMore, search, setSearch }}
        />
    )
}

export default ExerciseLibrary
