import * as yup from "yup"

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i

const candidateContactInformationValidationSchema = (t: any) =>
    yup.object().shape({
        phone: yup.string().required(t("intake:intake-screening.phone_is_required")),
        email: yup
            .string()
            .matches(emailRegex, t("intake:intake-screening.invalid_email_format"))
            .required(t("intake:intake-screening.email_is_required"))
    })

export default candidateContactInformationValidationSchema
