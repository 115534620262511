import { useEffect } from "react"
import { DragEndEvent, MouseSensor, useSensor, useSensors } from "@dnd-kit/core"
import { useDispatch } from "store"
import { getQuestionByExerciseIdAction, updateQuestionsPositionsAction } from "store/questions/actions"
import { useQuestionsSelector } from "store/hooks"
import { useTranslation } from "react-i18next"

export const useExerciseQuestionsList = (exerciseId: number) => {
    const { t } = useTranslation("question")
    const dispatch = useDispatch()
    const { list: questions, isLoading } = useQuestionsSelector()

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 5 }
        })
    )

    useEffect(() => {
        dispatch(getQuestionByExerciseIdAction(exerciseId))
    }, [exerciseId])

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (!over) return

        const activeId = active.id as number
        const overId = over.id as number

        if (activeId !== overId) {
            dispatch(updateQuestionsPositionsAction({ activeId, overId, exerciseId }))
        }
    }

    return { t, isLoading, sensors, questions, onDragEnd }
}
