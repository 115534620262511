import { ScreeningTemplateQuestion } from "@prisma/client"
import { TypographyV2, ControlledRadioCircleGroupV2, ControlledSelectV2 } from "components/v2"
import { Control } from "react-hook-form"

interface ISingleSelectQuestionProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: ScreeningTemplateQuestion
    onChange?: () => void
}

const SelectQuestionCard = ({ control, error, question, onChange }: ISingleSelectQuestionProps) => {
    const { is_required, question: question_text, definition } = question
    const definitionOptions = (definition as any)?.options
    const isSelect = (definition as any).type === "select"
    const options = definitionOptions?.map((option: any, i: number) => {
        const formattedOption = typeof option === "string" ? option.trim() : option
        return typeof formattedOption === "string"
            ? {
                  label: formattedOption == "" ? (i + 1).toString() : formattedOption,
                  value: formattedOption == "" ? (i + 1).toString() : formattedOption
              }
            : formattedOption
    })

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            {isSelect ? (
                <ControlledSelectV2
                    name={`screeningAnswers.${question.id}`}
                    classList={{ wrapper: "w-full" }}
                    isRequired={is_required}
                    options={definitionOptions.map((option: any) => ({
                        id: option.value,
                        title: option.label
                    }))}
                    label={""}
                    placeholder={question_text}
                    control={control}
                    error={error}
                />
            ) : (
                <ControlledRadioCircleGroupV2
                    name={`screeningAnswers.${question.id}`}
                    classList={{ wrapper: "w-full" }}
                    isBordered
                    isRequired={is_required}
                    options={options.map((option: any) => ({
                        value: option.value,
                        labelComponent: <TypographyV2 type="base">{option.label}</TypographyV2>
                    }))}
                    label={""}
                    control={control}
                    error={error}
                    onChange={onChange}
                />
            )}
        </div>
    )
}

export default SelectQuestionCard
