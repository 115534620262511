import React from "react"
import { QuestionsAddVariableItem } from "../index"
import { useQuestionsAddVariableList } from "./useQuestionsAddVariableList"

const QuestionsAddVariableList = () => {
    const { questions } = useQuestionsAddVariableList()

    return (
        <div className="flex flex-col gap-1">
            {questions.map((question) => (
                <QuestionsAddVariableItem {...question} key={question.id} />
            ))}
        </div>
    )
}

export default QuestionsAddVariableList
