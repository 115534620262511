import { ScreeningAnswer, ScreeningQuestionType } from "@prisma/client"
import {
    FreeTextQuestionResponse,
    MinutesQuestionResponse,
    PercentageQuestionResponse,
    ScreeningQuestionResponse,
    ScaleSliderQuestionResponse
} from "."
import ScaleQuestionResponse from "./ScaleQuestionResponse"
import YesNoQuestionResponse from "./YesNoQuestionResponse"
import { TypographyV2 } from "components/v2"

const buildIntakeFormResponse = (questions: any, screeningAnswers: ScreeningAnswer[]) => {
    return questions.map((question: any, index: number) => {
        const screeningAnswer = screeningAnswers.find((answer) => answer.screening_template_question_id === question.id)
        switch (question.type) {
            case ScreeningQuestionType.free_text:
            case ScreeningQuestionType.number:
            case ScreeningQuestionType.time_of_day:
                return (
                    <FreeTextQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.select:
                return (
                    <ScreeningQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.minutes:
                return (
                    <MinutesQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.percent:
                return (
                    <PercentageQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.scale:
                return (
                    <ScaleQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.yes_no:
                return (
                    <YesNoQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.scale_slider:
                return (
                    <ScaleSliderQuestionResponse
                        key={`buildIntakeFormResponse_${index}`}
                        question={question}
                        screeningAnswer={screeningAnswer}
                    />
                )
            case ScreeningQuestionType.text_only:
                return (
                    <div key={`buildIntakeFormResponse_${index}`} className="mt-6">
                        <TypographyV2 type="sm" width="bold" className="font-bold">
                            {question?.question}
                        </TypographyV2>
                    </div>
                )
        }
    })
}
export default buildIntakeFormResponse
