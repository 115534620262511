import { createCalendar } from "@internationalized/date"
import { useDateField, useDateSegment } from "@react-aria/datepicker"
import { useLocale } from "@react-aria/i18n"
import { DateFieldStateOptions, useDateFieldState } from "@react-stately/datepicker"
import { defaultInputClassNames, FormControlProps } from "forms"
import { RenderLabel } from "forms/RenderLabel"
import { errorClassNames } from "forms/utils"
import { Fragment, useRef } from "react"
import { DateValue } from "react-aria"
import { classNames } from "utils"
import { useGetDateFieldSegments } from "utils/date/useGetDateFieldSegments"

export function DateInputV2({
    value,
    minValue,
    maxValue,
    error,
    noWrapper = false,
    dateFormat,
    ...props
}: FormControlProps<DateValue> &
    Pick<DateFieldStateOptions<DateValue>, "minValue" | "maxValue"> & { dateFormat?: string; noWrapper?: boolean }) {
    const { locale } = useLocale()
    const state = useDateFieldState({
        locale,
        createCalendar,
        value,
        minValue,
        maxValue,
        onChange: props.setValue
    })
    const ref = useRef(null)
    const { labelProps, fieldProps } = useDateField(props, state, ref)

    const Component = noWrapper ? Fragment : "div"
    const segments = useGetDateFieldSegments(state.segments, dateFormat)
    return (
        <Component className={classNames("relative", props.classList?.wrapper)}>
            {!noWrapper && <RenderLabel {...props} elProps={labelProps} />}
            <div
                {...fieldProps}
                ref={ref}
                className={classNames("flex items-center", props.classList?.input ?? defaultInputClassNames, "h-11 rtl:flex-row-reverse rtl:justify-end")}
                data-testid={fieldProps.id}
                data-value={value}
            >
                {segments.map((segment, i) => {
                    return <DateSegment key={i} {...{ segment, state }} dataTestId={`${fieldProps.id}-${i}`} />
                })}
            </div>
            {!noWrapper && error && <div className={errorClassNames}>{error}</div>}
        </Component>
    )
}

type DateSegmentParams = Parameters<typeof useDateSegment>

export function DateSegment({
    segment,
    state,
    color = "text-black",
    dataTestId
}: {
    segment: DateSegmentParams[0]
    state: DateSegmentParams[1]
    color?: string
    dataTestId?: string
}) {
    let ref = useRef(null)
    let { segmentProps } = useDateSegment(segment, state, ref)

    return (
        <div
            {...segmentProps}
            ref={ref}
            data-testid={dataTestId}
            style={{
                ...segmentProps.style,
                minWidth: segment.maxValue != null && String(segment.maxValue).length + "ch"
            }}
            className={classNames(
                "px-0.5 ltr:text-right rtl:text-left outline-none rounded-sm focus:bg-text-blue focus:text-white group",
                !segment.isEditable ? `${color}/50` : color
            )}
        >
            {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
            {segment.isPlaceholder && (
                <span
                    aria-hidden="true"
                    className="block w-full text-center italic text-gray-400 text-sm font-medium group-focus:text-white pointer-events-none"
                >
                    {segment.placeholder}
                </span>
            )}
            {segment.isPlaceholder ? "" : segment.text}
        </div>
    )
}
