import type { ReactNode } from "react"
import { THtmlTag, typographyClasses, widthClasses } from "./typographyConstantsV2"
import { classNames } from "utils"

interface ITypographyV2Props {
    children: ReactNode
    // this could be an HTML tag that we add to the typography (h1, h2...
    tag?: THtmlTag
    type?: keyof typeof typographyClasses
    width?: keyof typeof widthClasses
    className?: string
    //TODO: Add tooltipText
    tooltipText?: string
}

const TypographyV2 = function ({
    type = "xs",
    children,
    className,
    tag = "div",
    width = "normal"
}: ITypographyV2Props) {
    const typographyClass = typographyClasses[type]
    const widthClass = widthClasses[width]

    const TypographyTag = tag

    return <TypographyTag className={classNames(typographyClass, widthClass, className)}>{children}</TypographyTag>
}

export default TypographyV2
