import { Menu, Transition } from "@headlessui/react"
import React, { Fragment } from "react"
import { classNames } from "utils"

import type { IDropdownMenuV2Props } from "./dropDownMenuTypesV2"
import { TypographyV2 } from "components/v2"
import { useIsHeLanguage } from "hooks"

const DropdownMenuV2: React.FC<IDropdownMenuV2Props> = ({
    clickComponent,
    menuItems,
    menuClassName,
    itemClassName,
    listClassName
}) => {
    const { isHeLanguage } = useIsHeLanguage()

    return (
        <Menu as="div" className={classNames("relative inline-block text-left", menuClassName)}>
            <Menu.Button
                className="inline-flex w-full justify-center rounded-md p-2 text-sm font-medium text-white hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 shadow-none"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                }}
            >
                {clickComponent}
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={classNames(
                        "border border-gray-300 z-50 absolute mt-2 w-[210px] origin-top-right divide-y divide-gray-300 rounded-md bg-white ring-1 ring-black/5 focus:outline-none",
                        listClassName,
                        isHeLanguage ? "right-[-210px]" : "right-0"
                    )}
                >
                    {menuItems.map(({ border, title, icon: Icon, onClick }, idx) => (
                        <Menu.Item key={idx}>
                            {({ active }) => {
                                const isFirst = idx === 0
                                const isLast = idx === menuItems.length - 1
                                return (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onClick()
                                        }}
                                        className={classNames(
                                            active ? "bg-gray-300 text-white" : "text-gray-900",
                                            isFirst ? "rounded-t-md" : isLast ? "rounded-b-md" : "",
                                            border ? "border-t-4 border-gray-300" : "border-none",
                                            "group flex w-full items-center py-3 px-[14px] text-sm",
                                            itemClassName
                                        )}
                                    >
                                        <Icon
                                            className={classNames(active ? "text-white" : "text-gray-400", "h-4 w-4")}
                                        />
                                        <TypographyV2
                                            type="sm"
                                            width="medium"
                                            className={isHeLanguage ? "mr-[10px]" : "ml-[10px]"}
                                        >
                                            {title}
                                        </TypographyV2>
                                    </button>
                                )
                            }}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default DropdownMenuV2
