import { useRef, useState } from "react"
import {
    offset,
    shift,
    flip,
    useFloating,
    autoUpdate,
    useInteractions,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    arrow,
    Placement
} from "@floating-ui/react"

interface IHoverablePopoverV2 {
    content?: React.ReactElement
    children?: React.ReactElement
    placement?: Placement
}

const HoverablePopoverV2 = ({ content, children, placement }: IHoverablePopoverV2) => {
    const [isOpen, setIsOpen] = useState(false)
    const arrowRef = useRef(null)
    const {
        refs: { setReference, setFloating },
        floatingStyles,
        context
    } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement,
        middleware: [
            offset(12),
            flip(),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate
    })

    const hover = useHover(context, { move: false })
    const focus = useFocus(context)
    const dismiss = useDismiss(context)
    const role = useRole(context, { role: "tooltip" })

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

    return (
        <>
            <div ref={setReference} {...getReferenceProps()}>
                {children}
            </div>
            {isOpen && content && (
                <div ref={setFloating} style={{ ...floatingStyles, zIndex: 1 }} {...getFloatingProps()}>
                    {content}
                </div>
            )}
        </>
    )
}

export default HoverablePopoverV2
