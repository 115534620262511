import {
    changesQuestionVariableLinkage,
    createExerciseQuestion,
    deleteExerciseQuestion,
    getExerciseQuestionsByExerciseId,
    updateExerciseQuestion,
    updateExerciseQuestionPositions
} from "api"

import * as types from "./types"
import { ExerciseQuestion } from "@prisma/client"
import { arrayMove } from "@dnd-kit/sortable"
import { AppAction, ExerciseDefResponse } from "types/Redux"
import { IQuestionRequest, IUpdateQuestionsPositionsAction } from "types"
import { toast } from "react-toastify"
import i18n from "locales"

export const setNewQuestionsListAction = (payload: ExerciseQuestion[]) => {
    return { type: types.SET_QUESTIONS_LIST, payload }
}

export const addNewQuestionToList = (payload: ExerciseQuestion & { isCreated: boolean }) => {
    return { type: types.ADD_NEW_QUESTION_TO_LIST, payload }
}

export const clearQuestionsList = () => {
    return { type: types.CLEAR_QUESTIONS_LIST }
}

export const openAddVariableModalAction = (payload: ExerciseQuestion) => {
    return { type: types.OPEN_ADD_VARIABLE_MODAL, payload }
}

export const closeAddVariableModalAction = () => {
    return { type: types.CLOSE_ADD_VARIABLE_MODAL }
}

export const selectExerciseForAddVariableModalAction = (payload: ExerciseDefResponse) => {
    return { type: types.SELECT_EXERCISE_FOR_ADD_VARIABLE_MODAL, payload }
}

export const getQuestionByExerciseIdAction =
    (id: number): AppAction<boolean> =>
    async (dispatch) => {
        const questions = await getExerciseQuestionsByExerciseId(id)
        dispatch(setNewQuestionsListAction(questions))
        return true
    }

export const deleteQuestionByIdAction =
    (id: number): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            await deleteExerciseQuestion(id)
            toast.success(i18n.t("notifications.successfullyDeleted", { ns: "question" }))
            let questions = getState().questions.list
            questions = questions.filter((question) => question.id !== id)
            dispatch(setNewQuestionsListAction(questions))

            return true
        } catch (err) {}
    }

export const updateQuestionsPositionsAction =
    ({ activeId, overId, exerciseId }: IUpdateQuestionsPositionsAction): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            const questions = getState().questions.list

            const oldIdx = questions.findIndex(({ id }) => id === activeId)
            const newIdx = questions.findIndex(({ id }) => id === overId)

            const newList = arrayMove(questions, oldIdx, newIdx)

            const requestNewList = newList.map(({ id }, idx) => ({ id, pos: idx + 1 }))
            dispatch(setNewQuestionsListAction(newList))
            await updateExerciseQuestionPositions(requestNewList, exerciseId)
            toast.success(i18n.t("notifications.positionsWereChangedSuccessfully", { ns: "question" }))
            return true
        } catch (err) {}
    }

export const addNewQuestionAction =
    (body: IQuestionRequest): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const newQuestion = await createExerciseQuestion(body)
            toast.success(i18n.t("notifications.successfullyCreated", { ns: "question" }))
            dispatch(addNewQuestionToList({ ...newQuestion, isCreated: true }))
            return true
        } catch (err) {}
    }

export const updateQuestionAction =
    (id: number, body: IQuestionRequest): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const response = await updateExerciseQuestion(id, body)
            toast.success(i18n.t("notifications.successfullyUpdated", { ns: "question" }))
            dispatch(getQuestionByExerciseIdAction(response.exercise_id))
            return true
        } catch (err) {}
    }

export const addVariableLinkageAction =
    (id: number): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            const {
                addVariableModal: { selectedQuestion }
            } = getState().questions
            const { params } = getState().modal
            const questionId = selectedQuestion.id

            await changesQuestionVariableLinkage(questionId, id)
            toast.success(i18n.t("notifications.successfullyAddedVariable", { ns: "question" }))
            dispatch(closeAddVariableModalAction())
            dispatch(getQuestionByExerciseIdAction(params.id))
            return true
        } catch (err) {}
    }

export const deleteVariableLinkageAction =
    (id: number): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            const { params } = getState().modal
            await changesQuestionVariableLinkage(id, null)
            toast.success(i18n.t("notifications.successfullyAddedVariable", { ns: "question" }))
            dispatch(getQuestionByExerciseIdAction(params.id))
            return true
        } catch (err) {}
    }
