import globalReducer from "./global/reducer"
import patientReducer from "./patient/reducer"
import notificationsReducer from "./notifications/reducer"
import sidebarsReducer from "./sidebars/reducer"
import modalReducer from "./modal/reducer"
import questionsReducer from "./questions/reducer"
import exercisesReducer from "./exercises/reducer"

const allReducers = {
    global: globalReducer,
    patient: patientReducer,
    notifications: notificationsReducer,
    sidebars: sidebarsReducer,
    modal: modalReducer,
    questions: questionsReducer,
    exercises: exercisesReducer
}

export default allReducers
