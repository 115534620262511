import { PatientWithDiagnoses } from "@sequel-care/types"
import { getAllPatients } from "api"
import { RangeSelector } from "components/ScoreGraph"
import { GenderSelect, TagSelect } from "forms/SuperSelect/wrappers"
import { Filters } from "pages/administration/export"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { createSetter } from "utils"
import { AgeFilter } from "./AgeFilter"
import { PatientFilter } from "./PatientFilter"
import QuestionnairesFilter from "./QuestionnairesFilter"
import StatusFilter from "./StatusFilter"
import TherapistFilter from "./TherapistFilter"
import TreatmentStart from "./TreatmentStart"

export const defaultFilterClasses = {
    input: "flex border border-border-blue px-4 py-3 w-64 h-[3.125rem] bg-white !text-base"
}

const FilterInput = ({ filters, setFilters }: { filters: Filters; setFilters: Dispatch<SetStateAction<Filters>> }) => {
    const { t } = useTranslation("patient")

    function onFilterChange<K extends keyof Filters>(key: K) {
        return createSetter(setFilters, key)
    }

    const [patients, setPatients] = useState<PatientWithDiagnoses<string, string>[]>([])

    useMemo(() => {
        const fetchPatients = async () => {
            const patients = await getAllPatients()
            setPatients(patients)
        }
        fetchPatients()
    }, [])

    return (
        <div className="flex flex-col gap-9 text-text-blue">
            <div className="flex gap-8 ">
                <label className="flex flex-col gap-4">
                    {t(`patients`)}
                    <PatientFilter
                        value={filters.patient_ids}
                        setFilter={onFilterChange("patient_ids")}
                        patients={patients}
                    />
                </label>
                <label className="flex flex-col gap-4">
                    {t("export.patientStatus")}
                    <StatusFilter contentWidth="w-64" status={filters.status} setStatus={onFilterChange("status")} />
                </label>
                <label className="flex flex-col gap-4">
                    {t(`export.age`)}
                    <AgeFilter ages={filters.age_range} setFilter={onFilterChange("age_range")} />
                </label>
                <label className="flex flex-col gap-4">
                    {t(`export.gender`)}
                    <GenderSelect
                        id="genderFilter"
                        value={filters.gender}
                        setValue={onFilterChange("gender")}
                        placeholder={t("patient:export.allGenders")}
                        usePlaceholderAsAll
                        contentWidth="w-52"
                        classList={defaultFilterClasses}
                    />
                </label>
            </div>
            <div className="flex items-center gap-8">
                <label className="flex flex-col gap-4">
                    {t("export.therapist")}
                    <TherapistFilter value={filters.therapist_ids} setTherapists={onFilterChange("therapist_ids")} />
                </label>
                <label className="flex flex-col gap-4">
                    {t(`export.dateRange`)}
                    <RangeSelector
                        range={filters.date_start}
                        noRangeKey="noFilter"
                        setRange={onFilterChange("date_start")}
                        className="flex border text-dark-blue border-border-blue !border-solid w-full cursor-pointer bg-white whitespace-nowrap px-4 py-3"
                        width="w-64"
                    />
                </label>
                <div className="flex flex-col gap-4">
                    {t("export.treatmentStart")}
                    <TreatmentStart value={filters.treatment_start} setTreatment={onFilterChange("treatment_start")} />
                </div>
            </div>
            <div>
                <label className="flex flex-col gap-4">
                    {t(`export.tags`)}
                    <TagSelect
                        id="patientTags"
                        value={filters.tags}
                        setValue={onFilterChange("tags")}
                        style={"tags"}
                        classList={{ wrapper: "flex text-center border border-blue bg-white px-3 py-2 w-100" }}
                    />
                </label>
            </div>
            <div>
                <label className="flex flex-col gap-4">{t("patient:questionnaires")}</label>
                <QuestionnairesFilter value={filters.questionnaires} setFilter={onFilterChange("questionnaires")} />
            </div>
        </div>
    )
}

export default FilterInput
