import { ChevronLeftIcon } from "@heroicons/react/24/outline"
import { classNames } from "utils"
import {
    DetailedHTMLProps,
    Dispatch,
    HTMLAttributes,
    MutableRefObject,
    ReactNode,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from "react"
import { useDispatch } from "store"
import ConversationSearchInput from "./SearchInput"
import { toggleCurrentSidebar, updateConversationSidebar } from "store/sidebars/actions"
import { ConversationSidebar } from "types/AppSidebar"
import { SearchProps } from "./ConversationDisplay"

type NodeWithProps = (props: { height: number; className?: string }) => ReactNode
export interface WrapperProps
    extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children"> {
    width: "w-full" | "w-150"
    children?: ReactNode | NodeWithProps
    header?: ReactNode
    titleEndNode?: ReactNode
    headerEndNode?: ReactNode
    footer?: ReactNode
    backButton?: ConversationSidebar["mode"] | "close"
    search?: SearchProps
    setSearch?: Dispatch<SetStateAction<SearchProps>>
    setQuery?: Dispatch<SetStateAction<string>>
    containerRef?: MutableRefObject<HTMLDivElement>
    banner?: ReactNode
}

const getHeight = (el: Element | ChildNode) => (el as HTMLElement).offsetHeight + 16 //Gap

function useGuaranteedRef<T>(externalRef: MutableRefObject<T>) {
    const ref = useRef<T>(null)
    return externalRef ?? ref
}

const ChatContentWrapper = ({
    header,
    titleEndNode,
    headerEndNode,
    footer,
    children: Children,
    backButton,
    search,
    setSearch,
    setQuery,
    containerRef,
    banner,
    width,
    ...props
}: WrapperProps) => {
    const ref = useGuaranteedRef(containerRef)
    const dispatch = useDispatch()

    const resizeObserver = useRef<ResizeObserver>(null)
    const [height, setHeight] = useState<number>()

    useEffect(() => {
        if (ref.current) {
            resizeObserver.current =
                resizeObserver.current ??
                new ResizeObserver(([{ target }]) => {
                    let newHeight = getHeight(target) /* sidebar height */

                    // Subtract the height of all children except the scrollable container itself and elements with absolute position. This will give us the correct height for it.
                    for (const child of Array.from(target.children))
                        if (
                            !child.classList.contains("conversationsSidebarContent") &&
                            !child.classList.contains("absolute")
                        )
                            newHeight -= getHeight(child)

                    newHeight -= 16 //Gap
                    if (newHeight !== height) setHeight(newHeight)
                })

            const el = ref.current
            resizeObserver.current.observe(el)
            return () => resizeObserver.current.unobserve(el)
        }
    }, [ref.current])

    return (
        <div className={classNames("flex flex-col h-full max-h-full bg-white gap-4 relative grow", width)} ref={ref}>
            <div className="flex justify-between items-center px-6 pt-4 pb-4 text-dark-blue border-b border-border-blue">
                <div className="flex items-center gap-3 text-lg font-semibold w-full">
                    {backButton && (
                        <ChevronLeftIcon
                            onClick={() => {
                                dispatch(
                                    backButton === "close"
                                        ? toggleCurrentSidebar()
                                        : updateConversationSidebar({ mode: backButton as ConversationSidebar["mode"] })
                                )
                            }}
                            className="w-6 cursor-pointer hover:scale-110 rtl:rotate-180"
                        />
                    )}
                    {header}
                    {titleEndNode}
                </div>
                {headerEndNode}
            </div>
            {banner}
            {typeof search?.query !== "undefined" && (
                <ConversationSearchInput {...{ setSearch, ...search, setQuery }} />
            )}
            {typeof Children === "function" ? (
                <Children height={height} className="conversationsSidebarContent" />
            ) : (
                <div
                    {...props}
                    className={classNames(
                        "conversationsSidebarContent grow overflow-auto flex flex-col gap-2",
                        props.className
                    )}
                    style={{ height }}
                >
                    {Children}
                </div>
            )}

            {footer}
        </div>
    )
}

export default ChatContentWrapper
