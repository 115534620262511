import { SVGProps } from "react"

const AudienceIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.5 16.2585C13.1941 16.4605 13.9281 16.5688 14.6875 16.5688C15.9198 16.5688 17.0854 16.2835 18.122 15.7754C18.124 15.7276 18.125 15.6796 18.125 15.6313C18.125 13.7329 16.5859 12.1938 14.6875 12.1938C13.5058 12.1938 12.4633 12.7901 11.8446 13.6982M12.5 16.2585V16.2563C12.5 15.3289 12.2624 14.457 11.8446 13.6982M12.5 16.2585C12.5 16.2882 12.4997 16.3179 12.4992 16.3474C10.9478 17.2816 9.1304 17.8188 7.1875 17.8188C5.2446 17.8188 3.4272 17.2816 1.87577 16.3474C1.87526 16.3171 1.875 16.2868 1.875 16.2563C1.875 13.3223 4.25349 10.9438 7.1875 10.9438C9.1941 10.9438 10.9409 12.0563 11.8446 13.6982M10 5.63135C10 7.18465 8.7408 8.44385 7.1875 8.44385C5.6342 8.44385 4.375 7.18465 4.375 5.63135C4.375 4.07805 5.6342 2.81885 7.1875 2.81885C8.7408 2.81885 10 4.07805 10 5.63135ZM16.875 7.50635C16.875 8.71447 15.8956 9.69385 14.6875 9.69385C13.4794 9.69385 12.5 8.71447 12.5 7.50635C12.5 6.29822 13.4794 5.31885 14.6875 5.31885C15.8956 5.31885 16.875 6.29822 16.875 7.50635Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default AudienceIcon
