import i18next from "i18next"

export const formatDate = (dateString: Date): string => {
    const date = new Date(dateString)

    const currentLocale = i18next.language

    const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "long",
        year: "numeric"
    }

    return date.toLocaleDateString(currentLocale, options)
}
