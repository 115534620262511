import { SVGProps } from "react"

const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.99935 16.7677C13.6017 16.7677 17.3327 13.0368 17.3327 8.43441C17.3327 3.83203 13.6017 0.101074 8.99935 0.101074C4.39698 0.101074 0.666016 3.83203 0.666016 8.43441C0.666016 13.0368 4.39698 16.7677 8.99935 16.7677Z"
            fill="#059669"
        />
        <path
            d="M12.2727 6.43457L7.27273 11.4346L5 9.16184"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default ErrorIcon
