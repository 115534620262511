import InfoCircle from "components/icons/InfoCircle"
import { TooltipV2 } from "components/v2"

interface IInfoIconV2Props {
    tooltip?: string
}

const InfoIconV2 = ({ tooltip }: IInfoIconV2Props) => {
    return (
        <TooltipV2 content={tooltip}>
            <InfoCircle />
        </TooltipV2>
    )
}

export default InfoIconV2