import { ExerciseType, Gender, Language, PreviousInstance } from "@prisma/client"

export type CreateExerciseDefPayload = {
    title: string
    description: string
    patient_title: string
    patient_description: string
    public_name: string
    recommended_recurrence: number
    min_age?: number
    max_age?: number
    brief_summary?: string
    gender: Gender
    exercise_type: ExerciseType
    language: Language
    previous_instance: PreviousInstance
    diagnose_ids: number[]
    role_ids: number[]
}

export enum RadioGroupMode {
    CUSTOM = "CUSTOM",
    ALL = "ALL"
}

export type CreateExerciseFormSubmitPayload = CreateExerciseDefPayload & {
    gender_custom: Gender
    gender_mode: RadioGroupMode
    age_mode: RadioGroupMode
}
