import { useMemo, useRef } from "react"
import { SlidingSidebar } from "components/common"
import { useSelector } from "react-redux"
import { RootState } from "types/Redux"
import {
    AppSidebar,
    isActivitySidebar,
    isConversationSidebar,
    isEventSidebar,
    isNotificationsSidebar,
    isPatientSidebar,
    isTherapistSidebar
} from "types/AppSidebar"
import EventSidebar from "./Event"
import ConversationSidebar from "./Conversation"
import PatientProfile from "./PatientProfile"
import TherapistProfile from "./TherapistProfile"
import { NotificationsSidebar } from "components/PatientOverview/NotificationsCard"
import { ActivitySidebar } from "components/PatientOverview/ActivityCard"

type SidebarSetup = {
    [key in AppSidebar["type"]]: {
        size: string
        transitionDuration: number
    }
}
const setupByType: SidebarSetup = {
    conversation: { size: "38rem", transitionDuration: 300 },
    patient: { size: "20.5rem", transitionDuration: 300 },
    event: { size: "38rem", transitionDuration: 350 },
    therapist: { size: "20.5rem", transitionDuration: 300 },
    notifications: { size: "38rem", transitionDuration: 300 },
    activity: { size: "38rem", transitionDuration: 300 }
}

const SidebarStack = () => {
    const sidebars = useSelector<RootState, AppSidebar[]>((state) => state.sidebars.stack)
    const sidebarsWithPlaceholder = useMemo(() => {
        // We add this placeholder to make sure that siebars will slide in instead
        // of just appearing. This works by always rendering an extra SlidingSidebar
        // that is not displayed at the end of the stack.
        return [...sidebars, { type: "placeholder" as const }]
    }, [sidebars])

    const lastSidebarIdx = sidebars.length - 1

    return (
        <>
            {sidebarsWithPlaceholder.map((sidebar, index) => (
                <RenderSidebar key={index} {...{ sidebar, index, lastSidebarIdx }} />
            ))}
        </>
    )
}

type RenderSidebarProps = {
    sidebar: AppSidebar | { type: "placeholder" }
    index: number
    lastSidebarIdx: number
}
const RenderSidebar = ({ sidebar, index, lastSidebarIdx }: RenderSidebarProps) => {
    const ref = useRef<HTMLElement>(null)
    if (index < lastSidebarIdx - 1) return null

    return (
        <SlidingSidebar
            show={index === lastSidebarIdx && (sidebar as AppSidebar).isOpen}
            className="relative bg-white overflow-auto ltr:border-l rtl:border-r border-border-blue"
            zIndex={12 - (lastSidebarIdx - index)}
            ref={ref}
            {...(sidebar.type !== "placeholder" && setupByType[sidebar.type])}
        >
            {isEventSidebar(sidebar) && sidebar.isOpen && <EventSidebar sidebar={sidebar} containerRef={ref} />}
            {isConversationSidebar(sidebar) && sidebar.isOpen && <ConversationSidebar sidebar={sidebar} />}
            {isPatientSidebar(sidebar) && sidebar.isOpen && <PatientProfile sidebar={sidebar} />}
            {isTherapistSidebar(sidebar) && sidebar.isOpen && <TherapistProfile sidebar={sidebar} />}
            {isNotificationsSidebar(sidebar) && sidebar.isOpen && <NotificationsSidebar />}
            {isActivitySidebar(sidebar) && sidebar.isOpen && <ActivitySidebar />}
        </SlidingSidebar>
    )
}

export default SidebarStack
