import React, { forwardRef } from "react"
import { FormInputWrapper, TextInputV2 } from "components/v2"
import { FormControlPropsV2 } from "components/v2/components/common/form-controls"
import { classNames } from "utils"
import { useController } from "react-hook-form"

interface IControlTextInputV2Prop extends FormControlPropsV2<any> {}

const ControlledTextInputV2 = forwardRef<HTMLInputElement, IControlTextInputV2Prop>((props, ref) => {
    const { isRequired, label, classList, name, control, rightLabelComponent, isErrorTextShowing, ...inputProps } = props

    const { error } = inputProps

    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    return (
        <FormInputWrapper {...{ label, error, isRequired, classList: classList?.wrapper, rightLabelComponent, isErrorTextShowing }}>
            <TextInputV2
                ref={ref}
                value={value as string}
                onChange={(value: string) => onChange(value)}
                className={classNames(label ? "mt-1" : "mt-0", classList?.input)}
                {...inputProps}
            />
        </FormInputWrapper>
    )
})

export default ControlledTextInputV2
