import { ListIcon, OrganizationIcon, UsersIcon } from "components/icons"
import { ReactNode, SVGProps, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRole } from "store/hooks"
import OrganizationSettings from "./Organization"
import OrganizationList from "./OrganizationList"
import TeamSettings from "./TeamSettings"
import Settings from "../icons/Settings"
import UserSettings from "./User"
import QuestionnairesSettings from "./QuestionnairesSettings"

export type SettingsSection = {
    name: string
    href: string
    Icon: (props: SVGProps<SVGSVGElement>) => ReactNode
    content: () => ReactNode
    testId?: string
}

export const useSettingsSections = (allDefinitions = false) => {
    const currentRole = useCurrentRole()
    const { t } = useTranslation()

    return useMemo(() => {
        const sections: SettingsSection[] = [
            {
                name: t("teamMembers"),
                href: "/settings/team",
                Icon: UsersIcon,
                content: TeamSettings,
                testId: "users-list-link"
            }
        ]

        if (allDefinitions) {
            sections.push({
                name: t("userSettings.title"),
                href: "/settings/user",
                Icon: Settings,
                content: UserSettings
            })
        }

        if (currentRole.role === "admin") {
            sections.push({
                name: t("organizationSettings"),
                href: "/settings/organization",
                Icon: OrganizationIcon,
                content: OrganizationSettings
            })
            sections.push({
                name: t("library"),
                href: "/settings/library/questionnaires",
                Icon: ListIcon,
                content: QuestionnairesSettings
            })
        }

        // === SequelCare
        if (currentRole.organization_id === 3)
            sections.push({
                name: t("allOrganizations"),
                href: "/settings/organizations",
                Icon: OrganizationIcon,
                content: OrganizationList
            })

        return sections
    }, [currentRole])
}
