import { FlatEvent, QuestionnaireListItem } from "@sequel-care/types"
import { ChartDataset, FontSpec, TooltipItem } from "chart.js"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { GRAPH_STYLES } from "utils/graphs"

const getStepSize = (maxScore: number) => {
    let denominator = 2
    if (maxScore < denominator) return maxScore

    while (maxScore % denominator !== 0) denominator++
    return maxScore / denominator
}

export const useResponsesByQuestionId = (event: FlatEvent<string>) => {
    return useMemo(() => {
        if (!event?.responses) return {}
        return Object.fromEntries(event.responses.map(({ question_id, value }) => [question_id, value]))
    }, [event?.responses])
}

export const useLastScoresOptions = ({ scales, questions }: QuestionnaireListItem) => {
    const [scale] = scales // TODO: Replace with solution for multiple scale groups
    const { t } = useTranslation("patient")
    const maxScore = scale.calculation === "avg" ? scale.max : scale.max * questions.length

    return useMemo(() => {
        return {
            animation: {
                duration: 0
            },
            datasets: {
                bar: { borderRadius: 6, barThickness: 6 }
            },
            scales: {
                y: {
                    min: 0,
                    suggestedMax: maxScore,
                    max: maxScore,
                    ticks: {
                        color: GRAPH_STYLES.COLORS.dark_blue,
                        stepSize: getStepSize(maxScore),
                        font: {
                            family: GRAPH_STYLES.FONTS.axes,
                            size: 12
                        }
                    },
                    grid: {
                        color: "#F0F0F0",
                        drawBorder: false
                    }
                },
                x: {
                    grid: {
                        display: false,
                        drawBorder: false
                    },
                    ticks: {
                        color: GRAPH_STYLES.COLORS.secondary,
                        font: {
                            family: GRAPH_STYLES.FONTS.axes,
                            size: 12
                        }
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    backgroundColor: GRAPH_STYLES.COLORS.tooltip,
                    yAlign: "bottom" as const,
                    xAlign: "center" as const,
                    titleColor: GRAPH_STYLES.COLORS.tooltipText,
                    titleMarginBottom: 0,
                    titleFont: {
                        family: GRAPH_STYLES.FONTS.axes,
                        size: 12,
                        weight: "bold" as const
                    },
                    footerColor: GRAPH_STYLES.COLORS.tooltipText,
                    footerFont: {
                        family: GRAPH_STYLES.FONTS.axes,
                        size: 12,
                        weight: "bold" as const
                    },
                    callbacks: {
                        title: (tooltipItem: TooltipItem<"bar">[]) => tooltipItem[0].label,
                        label: () => "",
                        footer: (tooltipItem: TooltipItem<"bar">[]) => {
                            const { dataset, dataIndex } = tooltipItem[0]
                            return `${t("score")} ${(dataset as ChartDataset).data[dataIndex]}`
                        }
                    },
                    padding: {
                        top: 8,
                        bottom: 8,
                        left: 8,
                        right: 24
                    },
                    borderColor: GRAPH_STYLES.COLORS.border_blue,
                    borderWidth: 1,
                    caretPadding: 25,
                    footerMarginTop: 0,
                    bodySpacing: 0
                }
            }
        }
    }, [maxScore, t])
}
