import React, { ReactNode } from "react"
import { CSS } from "@dnd-kit/utilities"
import { useSortable } from "@dnd-kit/sortable"

interface IQuestionsListProps {
    children: ReactNode
    id: number
}

const DraggableItemV2: React.FC<IQuestionsListProps> = ({ id, children }) => {
    const { setNodeRef, attributes, listeners, transform, transition } = useSortable({ id })

    return (
        <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={{
                transform: CSS.Translate.toString(transform),
                transition: transition
            }}
        >
            {children}
        </div>
    )
}

export default DraggableItemV2
