import { ApiDelete, ApiGet, ApiPatch, ApiPost, ApiPut } from "../ApiRequest"
import { IQuestionRequest } from "types"
import { ExerciseQuestionResponse } from "../../types/Redux"

export const getExerciseQuestionsByExerciseId = async (exerciseId: number): Promise<ExerciseQuestionResponse[]> => {
    const instance = new ApiGet<ExerciseQuestionResponse[]>(`/exercise-question/exercise/${exerciseId}`)
    instance.callerName = getExerciseQuestionsByExerciseId.name
    return instance.run()
}

export const updateExerciseQuestionPositions = async (newList: { id: number; pos: number }[], exerciseId: number) => {
    const instance = new ApiPatch("/exercise-question/update-positions", { newList, exerciseId })
    instance.callerName = updateExerciseQuestionPositions.name
    return instance.run()
}

export const deleteExerciseQuestion = async (id: number) => {
    const instance = new ApiDelete(`/exercise-question/${id}`)
    instance.callerName = deleteExerciseQuestion.name
    return instance.run()
}

export const createExerciseQuestion = async (body: IQuestionRequest): Promise<ExerciseQuestionResponse> => {
    const instance = new ApiPost<ExerciseQuestionResponse>("/exercise-question", body)
    instance.callerName = createExerciseQuestion.name
    return instance.run()
}

export const updateExerciseQuestion = async (id: number, body: IQuestionRequest): Promise<ExerciseQuestionResponse> => {
    const instance = new ApiPut<ExerciseQuestionResponse>(`/exercise-question/${id}`, body)
    instance.callerName = updateExerciseQuestion.name
    return instance.run()
}

export const changesQuestionVariableLinkage = async (
    id: number,
    linkage_id: number | null
): Promise<ExerciseQuestionResponse> => {
    const instance = new ApiPatch<ExerciseQuestionResponse>(`/exercise-question/variable/${id}`, { linkage_id })
    instance.callerName = changesQuestionVariableLinkage.name
    return instance.run()
}
