import React from "react"
import { useCreateFirstExerciseQuestionList } from "./useCreateFirstExerciseQuestionList"
import { CardV2, TypographyV2 } from "components/v2"

interface IQuestionsListProps {
    exerciseId: number
}

const CreateFirstExerciseQuestionList: React.FC<IQuestionsListProps> = ({ exerciseId }) => {
    const { t, isHeLanguage, questionTypesWithLocale, onAddEventClick } = useCreateFirstExerciseQuestionList(exerciseId)

    return (
        <div className="bg-slate-50 flex-col items-center py-10">
            <TypographyV2 className="text-center" width="bold">
                {t("startByInset")}
            </TypographyV2>
            <div className="grid grid-cols-2 px-52 mt-6 gap-4">
                {questionTypesWithLocale.map(({ title, id, icon: Icon }) => (
                    <div key={id} className="grow flex items-center cursor-pointer">
                        <CardV2 className="flex items-center" onClick={onAddEventClick(id)}>
                            <Icon className="text-gray-500" />
                            <TypographyV2 className={isHeLanguage ? "mr-2.5" : "ml-2.5"} type="sm">
                                {title}
                            </TypographyV2>
                        </CardV2>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CreateFirstExerciseQuestionList
