import { ExerciseQuestionType } from "@prisma/client"
import {
    FreeTextIcon,
    ScaleIcon,
    ImageIcon,
    TimeOfDayIcon,
    MinutesIcon,
    PercentIcon,
    NumberIcon,
    YesOrNoIcon,
    MultiSelectIcon,
    SingleSelectIcon
} from "components/icons"
import { FC, SVGProps } from "react"

export const questionTypeIcons: {
    [key in keyof typeof ExerciseQuestionType]: FC<SVGProps<SVGSVGElement>>
} = {
    [ExerciseQuestionType.free_text]: FreeTextIcon,
    [ExerciseQuestionType.scale]: ScaleIcon,
    [ExerciseQuestionType.image]: ImageIcon,
    [ExerciseQuestionType.minutes]: MinutesIcon,
    [ExerciseQuestionType.multiple_choice]: MultiSelectIcon,
    [ExerciseQuestionType.number]: NumberIcon,
    [ExerciseQuestionType.percent]: PercentIcon,
    [ExerciseQuestionType.time_of_day]: TimeOfDayIcon,
    [ExerciseQuestionType.yes_no]: YesOrNoIcon,
    [ExerciseQuestionType.select]: SingleSelectIcon,
    [ExerciseQuestionType.text]: FreeTextIcon
}
