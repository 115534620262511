import React, { forwardRef } from "react"
import { FormInputWrapper } from "components/v2"
import { FormControlPropsV2 } from "components/v2/components/common/form-controls"
import { useController } from "react-hook-form"
import { DateInputV2 } from "../../DateInputV2"
import { defaultInputClassNames } from "forms/utils"
import { classNames } from "utils"
interface IControlDateInputV2Prop extends FormControlPropsV2<any> {
    dateFormat: string
}

const ControlledDateInputV2 = forwardRef<HTMLInputElement, IControlDateInputV2Prop>((props, ref) => {
    const { isRequired, label, classList, name, control, isErrorTextShowing, dateFormat, ...inputProps } = props

    const { error } = inputProps

    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    return (
        <FormInputWrapper {...{ label, error, isRequired, isErrorTextShowing, classList: classList?.wrapper }}>
            <DateInputV2
                id={name}
                value={value}
                setValue={onChange}
                dateFormat={dateFormat}
                classList={{
                    input: classNames(defaultInputClassNames, error ? "border-red-500" : "", classList?.input)
                }}
            />
        </FormInputWrapper>
    )
})

export default ControlledDateInputV2
