import { SVGProps } from "react"

const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" {...props}>
        <path
            d="M7.5 4.26367V8.01367H10.3125M13.125 8.01367C13.125 11.1203 10.6066 13.6387 7.5 13.6387C4.3934 13.6387 1.875 11.1203 1.875 8.01367C1.875 4.90707 4.3934 2.38867 7.5 2.38867C10.6066 2.38867 13.125 4.90707 13.125 8.01367Z"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default ClockIcon
