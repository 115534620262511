import { SVGProps } from "react"

const UnitIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.85603 4.74665C4.85603 3.48429 5.87938 2.46094 7.14174 2.46094H12.856C14.1184 2.46094 15.1417 3.48429 15.1417 4.74665V5.05261C15.8245 5.44755 16.2846 6.18569 16.2846 7.03237V7.33833C16.9673 7.73326 17.4275 8.47141 17.4275 9.31808V13.8895C17.4275 15.1519 16.4041 16.1752 15.1417 16.1752H4.85603C3.59366 16.1752 2.57031 15.1519 2.57031 13.8895V9.31808C2.57031 8.47141 3.03043 7.73326 3.71317 7.33833V7.03237C3.71317 6.18569 4.17328 5.44755 4.85603 5.05261V4.74665ZM5.99888 4.74665H13.9989C13.9989 4.11547 13.4872 3.60379 12.856 3.60379H7.14174C6.51056 3.60379 5.99888 4.11547 5.99888 4.74665ZM4.85603 7.03237H15.1417C15.1417 6.53565 14.8245 6.11156 14.3799 5.95439C14.2615 5.91254 14.1335 5.88951 13.9989 5.88951H5.99888C5.86429 5.88951 5.7363 5.91254 5.61788 5.95439C5.17323 6.11156 4.85603 6.53565 4.85603 7.03237ZM4.85603 8.17522C4.72143 8.17522 4.59345 8.19825 4.47502 8.24011C4.03037 8.39727 3.71317 8.82137 3.71317 9.31808V13.8895C3.71317 14.5207 4.22484 15.0324 4.85603 15.0324H15.1417C15.7729 15.0324 16.2846 14.5207 16.2846 13.8895V9.31808C16.2846 8.82137 15.9674 8.39727 15.5227 8.24011C15.4043 8.19825 15.2763 8.17522 15.1417 8.17522H4.85603Z"
            fill="#14B8A6"
        />
    </svg>
)

export default UnitIcon
