import Image from "next/image"

interface IWelcomeCardProps {
    title: string
    subtitle?: string
    classNames?: string
}

const WelcomeCard = ({ title, subtitle, classNames = "" }: IWelcomeCardProps) => {
    return (
        <div className={`flex flex-col items-center justify-center gap-[16px] ${classNames}`}>
            <Image src="/images/logo.png" alt="Intake" width={47} height={70} />
            <h2 className="text-4xl font-normal text-center">{title}</h2>
            <p className="text-sm font-normal text-center">{subtitle}</p>
        </div>
    )
}

export default WelcomeCard
