import { DialogV2, TypographyV2 } from "components/v2"
import { ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import { getQuestionnairByEventId, submitTherapistQuestionnaire } from "api/Therapist"
import { useForm } from "react-hook-form"
import therapistQuestionnaireValidationSchemaBuilder from "./therapistQuestionnaireValidationSchemaBuilder"
import { yupResolver } from "@hookform/resolvers/yup"
import { toast } from "react-toastify"
import { cardBuilder } from "components/v2/components/IntakeScreeningCandidate"
import { getDateAtHour0, DateString } from "utils/dates"
import { getPatient } from "api"

interface ITherapistQuestionnaireModalProps {
    isOpen: boolean
    onClose: () => void
    updateEventsList: (eventId: number) => void
}

const TherapistQuestionnaireModal = ({ isOpen, onClose, updateEventsList }: ITherapistQuestionnaireModalProps) => {
    const { t } = useTranslation("common")
    const [questionnaireResponse, setQuestionnaireResponse] = useState<any>({})
    const [questionnaireQuestions, setQuestionnaireQuestions] = useState<any>([])
    const [apiReqInProcess, setApiReqInProcess] = useState(true)
    const [patient, setPatient] = useState<any>({})
    const {
        control,
        formState: { errors },
        getValues,
        trigger,
        reset
    } = useForm<any>({
        resolver: yupResolver(therapistQuestionnaireValidationSchemaBuilder(questionnaireQuestions)),
        defaultValues: {
            screeningAnswers: {}
        }
    })

    const fillStartedAt = new Date()

    const router = useRouter()
    const eventId = router.query.index?.[1]
    useEffect(() => {
        const fetchTherapistQuestionnaire = async () => {
            setApiReqInProcess(true)
            if (!eventId) {
                return
            }
            const response = (await getQuestionnairByEventId(Number(eventId))) as any
            const patient = await getPatient(response.patient_id)
            setQuestionnaireResponse(response)
            setPatient(patient)
            
            setQuestionnaireQuestions(
                response.questionnaire.questions.map((question: any) => {
                    const scaleId = question.scale_id
                    const scale = response.questionnaire.scales.find((scale: any) => scale.id === scaleId)
                    return { ...question, scale }
                })
            )
            setApiReqInProcess(false)
        }
        fetchTherapistQuestionnaire()
    }, [eventId])

    const onSubmit = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) {
                return
            }

            const questionValues = getValues().screeningAnswers
            const responses = Object.entries(questionValues).reduce((acc: any, [key, value]) => {
                acc[key] = value
                return acc
            }, {})

            await submitTherapistQuestionnaire({
                completed_at: new Date(),
                date_on_timeline: `${DateString.from(getDateAtHour0(new Date()))}T00:00:00.000Z`,
                patient: questionnaireResponse?.patient_id,
                responses,
                event_id: Number(eventId),
                fill_started_at: fillStartedAt
            })
            reset()
            updateEventsList(Number(eventId))
            toast.success(t("questionnaire_submitted"))
            onClose()
        } catch (error) {
            toast.error(t("error_occurred"))
            console.error(error)
        }
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    if (apiReqInProcess) {
        return null
    }

    return (
        <DialogV2
            isOpen={isOpen}
            onClose={handleClose}
            title={t("eventType.questionnaire")}
            type="wide"
            classList={{ content: "w-full p-0 flex flex-col" }}
            onProceed={onSubmit}
            proceedText={t("submit")}
            proceedType="primary"
            dismissType="light-gray"
        >
            <div className="border-t border-b border-gray-300 bg-gray-100 w-full flex flex-row h-[72px] items-center px-[24px]">
                <div className="w-[192px] text-left">
                    <TypographyV2 type="xs" width="semibold" className="text-base-dark">
                        {t("roles.patient")}
                    </TypographyV2>
                    <TypographyV2 type="sm" width="normal" className="text-base-dark">
                        {patient?.user?.first_name} {patient?.user?.last_name}
                    </TypographyV2>
                </div>
                <div className="w-[340px] text-left">
                    <TypographyV2 type="xs" width="semibold" className="text-base-dark">
                        {t("questionnaire_name")}
                    </TypographyV2>
                    <TypographyV2 type="sm" width="normal" className="text-base-dark">
                        {questionnaireResponse?.questionnaire?.title}
                    </TypographyV2>
                </div>
            </div>
            <div className="p-6 h-[600px] overflow-y-auto">
                <div className="rounded-lg bg-white p-[60px] border border-[#DCE2E8] flex flex-col gap-4">
                    <TypographyV2 type="xl" width="bold" className="text-gray-900">
                        {questionnaireResponse?.questionnaire?.title}
                    </TypographyV2>
                    {cardBuilder(questionnaireQuestions || [], control, errors).map((card: ReactNode) => (
                        <div key={card.toString()} className="rounded-lg border border-gray-200 py-4 px-3">
                            {card}
                        </div>
                    ))}
                </div>
            </div>
        </DialogV2>
    )
}

export default TherapistQuestionnaireModal
