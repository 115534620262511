import { QuestionScaleWithCutoffs, QuestionnaireListItem } from "@sequel-care/types"
import { useMemo } from "react"
import { BasicQuestion, ScaleGroup } from "types/Misc"

const getLabelSet = (question?: BasicQuestion) => question?.definition?.label_set
export const useQuestionsGroupedByScale = (questionnaire: QuestionnaireListItem, groupByGuideline = false) => {
    const { questions = [] } = questionnaire ?? {}

    return useMemo(() => {
        // TODO: Remove type guards here after merging exercise and questionnaire
        const scales = questionnaire?.scales?.filter(({ id }) => questions.some(({ scale_id }) => scale_id === id))
        const scalesById: Record<number, QuestionScaleWithCutoffs> = {}
        scales?.forEach((scale) => (scalesById[scale.id] = scale))

        const list: (BasicQuestion | ScaleGroup)[] = []
        const sortedQuestions = questions.filter(({ type }) => type !== "guideline").sort((q1, q2) => q1.pos - q2.pos)
        for (let i = 0; i < sortedQuestions.length; i++) {
            const question = sortedQuestions[i]
            const scale = scalesById[question.scale_id]

            let groupEndIndex = i + 1
            // This is the condition for grouping questions within a scale group
            while (sortedQuestions[groupEndIndex]?.type.includes("scale")) {
                const groupEndQuestion = sortedQuestions[groupEndIndex]

                if (!groupByGuideline) {
                    if (
                        groupEndQuestion.scale_id === question.scale_id &&
                        getLabelSet(groupEndQuestion) === getLabelSet(question)
                    ) {
                        groupEndIndex++
                        continue
                    }
                } else {
                    const groupEndQuestion = sortedQuestions[groupEndIndex]
                    const groupEndScale = scalesById[groupEndQuestion.scale_id]

                    if (groupEndScale?.guideline === scale?.guideline) {
                        groupEndIndex++
                        continue
                    }
                }

                break
            }

            if (!question.type.includes("scale") || !sortedQuestions[i + 1] || groupEndIndex - i === 1) {
                list.push(question)
                continue
            }

            list.push({ questions: sortedQuestions.slice(i, groupEndIndex) })
            i += groupEndIndex - i - 1
        }
        return list
    }, [questions])
}
