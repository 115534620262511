import { useDispatch } from "store"
import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useMemo, useState } from "react"
import { clearQuestionsList } from "store/questions/actions"
import { ExerciseDefResponse } from "types/Redux"
import { ExerciseDefStatuses } from "@prisma/client"
import { closeModal, openModal } from "store/modal/actions"
import { ArchiveIcon, CopyIcon } from "components/icons"
import { makeExerciseArchivedAction, makeExerciseCopyAction } from "store/exercises/actions"
import { useIsHeLanguage } from "hooks"

export const useExerciseBuilderComponent = (exercise: ExerciseDefResponse) => {
    const { id, diagnoses, status, target_roles } = exercise
    const dispatch = useDispatch()
    const { t } = useTranslation("exercise")
    const [activeTab, setActiveTab] = useState("1")
    const visibleRoles = useMemo(() => target_roles.slice(0, 2), [target_roles])
    const notVisibleRolesCount = useMemo(() => target_roles.slice(2).length, [target_roles])

    const isPublished = status === ExerciseDefStatuses.PUBLISHED

    const { isHeLanguage } = useIsHeLanguage()

    const mappedDiagnosesForBagesList = useMemo(
        () => diagnoses.map(({ diagnose: { name_en, id } }) => ({ id, title: name_en })),
        [diagnoses]
    )

    const onMakeACopyClick = () => {
        dispatch(makeExerciseCopyAction(id))
    }

    const dropDownActionsList = [
        {
            title: t("makeACopy"),
            icon: CopyIcon,
            onClick: onMakeACopyClick
        },
        {
            border: true,
            title: t("archive"),
            icon: ArchiveIcon,
            onClick: () => {
                dispatch(makeExerciseArchivedAction(id))
                dispatch(closeModal())
            }
        }
    ]

    useEffect(() => {
        return () => {
            dispatch(clearQuestionsList())
        }
    }, [])

    const onActiveTabClick = useCallback(
        (index: string) => () => {
            setActiveTab(index)
        },
        []
    )

    const onPublishClick = () => {
        dispatch(openModal("publish-exercise", exercise))
    }

    return {
        t,
        diagnoses,
        isPublished,
        isHeLanguage,
        dispatch,
        activeTab,
        visibleRoles,
        dropDownActionsList,
        mappedDiagnosesForBagesList,
        notVisibleRolesCount,
        onActiveTabClick,
        onPublishClick
    }
}
