import { IOptionV2, TypographyV2 } from "components/v2"
import { useCurrentLanguage } from "store/hooks"
import { Language } from "@prisma/client"
import { classNames } from "utils"

interface ISingleSelectTriggerV2Props {
    optionKey: string | number
    getOption: (option: string | number) => IOptionV2
    placeholder?: string
}

const SingleSelectTriggerV2 = ({ optionKey, getOption, placeholder }: ISingleSelectTriggerV2Props) => {
    const language = useCurrentLanguage()
    const isHeLanguage = language === Language.he
    const option = getOption(optionKey)
    const Icon = option?.icon
    return (
        <span className="flex items-center truncate flex-grow h-11 leading-5">
            {optionKey ? (
                <div className="flex items-center gap-x-2.5">
                    {option &&
                        option.media &&
                        (typeof option.media === "string" ? (
                            <img src={option.media} alt={option.title} className="w-6 h-6 rounded-full" />
                        ) : (
                            option.media
                        ))}

                    <div className="flex items-center">
                        {Icon && <Icon className={classNames("text-gray-500", isHeLanguage ? "ml-3" : "mr-3")} />}
                        <TypographyV2 type="sm" width="medium">
                            {option && option.title}
                        </TypographyV2>
                    </div>
                </div>
            ) : (
                <span className="text-[#9CA3AF] font-normal text-xs leading-5">{placeholder}</span>
            )}
        </span>
    )
}

export default SingleSelectTriggerV2
