import { useRouterPatientId } from "hooks/useRouterPatientId"
import Link from "next/link"
import { useTranslation } from "react-i18next"
import { useCurrentUser, usePatientList } from "store/hooks"
import { classNames, getUserName } from "utils"
import ToggleSidebarList, { ToggleMenuProps } from "./ToggleSidebarList"
import { FeatureFlags } from "../../utils/FeatureFlags"

const id = "patient-profile-list"
const PatientList = ({ openSection, setOpen, menuHeightById }: ToggleMenuProps) => {
    const { t } = useTranslation("patient")
    const patientId = useRouterPatientId()
    const patients = usePatientList()
    const currentUser = useCurrentUser()
    const isOverviewEnabled = FeatureFlags.isEnabled("overview")
    return (
        <ToggleSidebarList
            id={id}
            title={t("patients")}
            openSection={openSection}
            setOpen={setOpen}
            menuHeightById={menuHeightById}
        >
            {patients?.map(({ id, user, collaborators }) => {
                const [currentPermission] = collaborators.find(({ user }) => user.id === currentUser.id).permissions

                const page = currentPermission.permission !== "limited" && isOverviewEnabled ? "overview" : "timeline"
                return (
                    <Link key={id} href={`/patient/${id}/${page}`} passHref legacyBehavior>
                        <a
                            className={classNames(
                                "hover:text-white cursor-pointer px-6",
                                patientId === id ? "text-white" : ""
                            )}
                        >
                            {getUserName(user)}
                        </a>
                    </Link>
                )
            })}
        </ToggleSidebarList>
    )
}

export default PatientList
