import React, { ReactNode, SVGProps } from "react"
import { TypographyV2 } from "components/v2"
import { classNames } from "utils"
import { useIsHeLanguage } from "hooks"

interface INavigationItemProps {
    onClick?: () => void
    title: string
    index: number
    isActive: boolean
    isDisabled?: boolean
    icon: (props: SVGProps<SVGSVGElement>) => ReactNode
}

const NavigationItem: React.FC<INavigationItemProps> = ({
    isActive,
    title,
    index,
    icon: Icon,
    onClick,
    isDisabled
}) => {
    const { isHeLanguage } = useIsHeLanguage()
    return (
        <div
            className={classNames(
                "flex items-center py-[10px] px-4 rounded-lg cursor-pointer",
                index === 0 ? "" : "mt-4",
                isActive ? "text-med-blue bg-white" : "text-gray-500 hover:bg-slate-200",
                isDisabled ? "cursor-not-allowed text-base-light hover:bg-gray-100" : ""
            )}
            onClick={isDisabled ? () => {} : onClick}
        >
            <Icon className="min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]" />
            <TypographyV2 type="sm" width="semibold" className={classNames(isHeLanguage ? "mr-2" : "ml-2")}>
                {title}
            </TypographyV2>
        </div>
    )
}

export default NavigationItem
