import { IOptionV2 } from "components/v2"

import { classNames } from "utils"
import { useIsHeLanguage } from "hooks"

interface ISelectOptionV2Props {
    option: IOptionV2
}

const SelectOptionV2 = ({ option: { title, media, subtitle, icon: Icon } }: ISelectOptionV2Props) => {
    const { isHeLanguage } = useIsHeLanguage()
    return (
        <>
            {media &&
                (typeof media === "string" ? <img src={media} alt={title} className="w-6 h-6 rounded-full" /> : media)}
            <div className="flex flex-col w-full">
                <div className="flex items-center">
                    {Icon && <Icon className={classNames("text-gray-500", isHeLanguage ? "ml-3" : "mr-3")} />}
                    <span className="overflow-hidden text-ellipsis">{title}</span>
                </div>
                <span
                    className={classNames(
                        "text-[#6B7280] text-sm font-normal",
                        isHeLanguage ? "text-right" : "text-left"
                    )}
                >
                    {subtitle}
                </span>
            </div>
        </>
    )
}

export default SelectOptionV2
