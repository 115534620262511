import React, { memo } from "react"
import { classNames } from "utils"
import { TypographyV2 } from "components/v2"
import { tabItemClassNamesV2, tabsClassNamesV2 } from "components/v2/constants"

interface ITabsV2Props {
    activeTab: string
    onActiveTabClick: (key: string) => void
    tabItems: { key: string; title: string }[]
}

const TabsHeaderV2: React.FC<ITabsV2Props> = memo(({ tabItems, activeTab, onActiveTabClick }) => (
    <div className={tabsClassNamesV2}>
        {tabItems.map(({ title, key }) => (
            <div
                key={key}
                className={classNames(
                    tabItemClassNamesV2,
                    activeTab === key ? "text-black border-med-blue" : "text-gray-500"
                )}
                onClick={() => onActiveTabClick(key)}
            >
                <TypographyV2 width="semibold">{title}</TypographyV2>
            </div>
        ))}
    </div>
))

export default TabsHeaderV2
