import { SVGProps } from "react"

const DragIndicatorIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.75 6.55664C8.33579 6.55664 8 6.22085 8 5.80664C8 5.39243 8.33579 5.05664 8.75 5.05664C9.16421 5.05664 9.5 5.39243 9.5 5.80664C9.5 6.22085 9.16421 6.55664 8.75 6.55664Z"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.75 12.5566C8.33579 12.5566 8 12.2209 8 11.8066C8 11.3924 8.33579 11.0566 8.75 11.0566C9.16421 11.0566 9.5 11.3924 9.5 11.8066C9.5 12.2209 9.16421 12.5566 8.75 12.5566Z"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.75 18.5566C8.33579 18.5566 8 18.2209 8 17.8066C8 17.3924 8.33579 17.0566 8.75 17.0566C9.16421 17.0566 9.5 17.3924 9.5 17.8066C9.5 18.2209 9.16421 18.5566 8.75 18.5566Z"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.75 6.55664C16.3358 6.55664 16 6.22085 16 5.80664C16 5.39243 16.3358 5.05664 16.75 5.05664C17.1642 5.05664 17.5 5.39243 17.5 5.80664C17.5 6.22085 17.1642 6.55664 16.75 6.55664Z"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.75 12.5566C16.3358 12.5566 16 12.2209 16 11.8066C16 11.3924 16.3358 11.0566 16.75 11.0566C17.1642 11.0566 17.5 11.3924 17.5 11.8066C17.5 12.2209 17.1642 12.5566 16.75 12.5566Z"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.75 18.5566C16.3358 18.5566 16 18.2209 16 17.8066C16 17.3924 16.3358 17.0566 16.75 17.0566C17.1642 17.0566 17.5 17.3924 17.5 17.8066C17.5 18.2209 17.1642 18.5566 16.75 18.5566Z"
            stroke="#9CA3AF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default DragIndicatorIcon
