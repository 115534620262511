import { useMemo } from "react"
import { TFunction } from "i18next"
import { defineStep } from "../../../forms/FormWithSteps"
import { classNames, noopTFunction } from "../../../utils"
import { defaultInputClassNames, defineField } from "../../../forms"
import SuperSelect from "../../../forms/SuperSelect"
import { Language, WeekStart } from "@prisma/client"
import { getItemsFromEnum } from "../../../forms/SuperSelect/utils"
import ActiveHoursField from "./ActiveHoursField"
import UrgentMessagingField from "./UrgentMessagingField"

const dateFormats = ["d/m/y", "m/d/y"]

export const useUserSettingsSteps = (t: TFunction) => {
    return useMemo(
        () => [
            defineStep({
                id: "user_preferences",
                name: t("user:userSettings.tabPreferences"),
                fields: getUserPreferencesFields(t)
            }),
            defineStep({
                id: "chat_preferences",
                name: t("user:userSettings.tabChat"),
                fields: getChatPreferencesFields(t)
            })
        ],
        [t]
    )
}

const getUserPreferencesFields = (t = noopTFunction) => [
    defineField({
        id: "language",
        label: t("user:userSettings.language") as string,
        Component: SuperSelect<Language>,
        withSearch: false,
        items: getItemsFromEnum(Language, (l) => t(`common:languages.${l}`)),
        classList
    }),
    defineField({
        id: "date_format",
        label: t("user:userSettings.dateFormat") as string,
        Component: SuperSelect<string>,
        withSearch: false,
        items: dateFormats.map((f) => ({
            id: f,
            label: t(`user:userSettings.${f === dateFormats[0] ? "dayFirst" : "monthFirst"}`)
        })),
        classList
    }),
    defineField({
        id: "week_start",
        label: t("user:userSettings.weekStart") as string,
        Component: SuperSelect<WeekStart>,
        withSearch: false,
        items: getItemsFromEnum(WeekStart, (k) => t(`user:userSettings:${k}`)),
        classList
    })
]

export const getChatPreferencesFields = (t = noopTFunction) => [
    defineField({
        id: "active_hours",
        Component: ActiveHoursField,
        validations: {
            custom: {
                isValid: (value) => {
                    return Object.values(value.days).some(Boolean) ? true : false
                },
                message: t("user.userSettings.validationMessages.active_hours")
            }
        }
    }),
    defineField({
        id: "chat_automated_reply",
        label: t("user:userSettings.autoReply") as string,
        placeholder: t("user:userSettings.autoReplyPlaceholder"),
        classList: {
            wrapper: "col-span-12 flex flex-col gap-3 w-3/4",
            label: "font-medium text-dark-blue",
            input: classNames(defaultInputClassNames, "!bg-white w-full")
        },
        validations: {
            required: {
                value: true,
                message: t("user:userSettings.validationMessages.chat_automated_reply")
            }
        }
    }),
    defineField({
        id: "urgent_enable",
        Component: UrgentMessagingField
    })
]

const classList = {
    wrapper: "flex h-12 justify-between w-3/4",
    input: classNames(defaultInputClassNames, "!text-dark-blue !bg-white"),
    inputWrapper: "w-2/3",
    label: "flex flex-row items-center gap-2 my-auto text-dark-blue"
}
