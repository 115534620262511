import { Dictionary, omit } from "lodash"
import { Dispatch, ReactNode, SetStateAction, useCallback } from "react"
import { createSetter } from "utils"
import Input from "./Input"
import { ClassList, Field, FieldValues, FormControlProps, FormError, ValidationMap, Validations } from "./types"

export { DateField } from "./DateField"
export { ListField } from "./ListField"
export { PasswordField } from "./PasswordField"
export { PhoneField } from "./PhoneField"
export type { FieldIds, FieldValues, FormControlProps } from "./types"
export { defaultInputClassNames, defaultLabelClassNames } from "./utils"
export type { Field, Validations }

export function initialValuesFromFields<Fields extends readonly Field[]>(fields: Fields) {
    return Object.fromEntries(fields.map((field) => [field.id, field.initialValue])) as FieldValues<Fields>
}

export function defineField<ID extends string, P extends {}, V = string, T = never>(
    field: {
        Component?: (props: FormControlProps<V> & P & { value: V }) => ReactNode
        customDataTestId?: string
    } & Omit<Field<V, P, ID>, "Component"> & { type?: T }
) {
    return field
}

export const getValidationsFromFields = (fields: readonly Field[]) => {
    const validations: ValidationMap = {}
    fields.forEach((field) => {
        if (field.validations) validations[field.id] = field.validations
    })
    return validations
}

const classListKeys = ["input", "wrapper", "label", "inputWrapper", "button", "error"] as const
export const mergeClasses = (...classLists: ClassList[]): ClassList => {
    return Object.fromEntries(
        classListKeys.map((key) => {
            let merged: string | undefined = undefined
            classLists.forEach((classList) => {
                if (typeof classList?.[key] !== "string") return

                if (!merged) merged = ""
                else merged += " "
                merged += classList[key]
            })
            return [key, merged]
        })
    )
}

type RenderFieldsProps<
    Fields extends readonly Field[] = Field[],
    S extends FieldValues<Fields> = FieldValues<Fields>
> = {
    fields: Fields
    state: S
    setState: Dispatch<SetStateAction<S>>
    errors: Dictionary<FormError>
    setErrors?: Dispatch<SetStateAction<Dictionary<FormError>>>
    classList?: ClassList
    isEdit?: Boolean
    onBlur?: Dictionary<() => void>
    loading?: Dictionary<boolean>
}

export function RenderFields<Fields extends readonly Field[], S extends FieldValues<Fields>>({
    fields,
    onBlur,
    loading,
    ...props
}: RenderFieldsProps<Fields, S>) {
    return (
        <>
            {fields.map((field) => (
                <RenderField
                    key={field.id}
                    field={field}
                    onBlur={onBlur?.[field.id]}
                    isLoading={loading?.[field.id]}
                    {...props}
                />
            ))}
        </>
    )
}

const RenderField = ({
    state,
    errors,
    setState,
    setErrors,
    classList,
    field,
    isEdit = true,
    onBlur,
    isLoading
}: Omit<RenderFieldsProps, "fields" | "onBlur" | "loading"> & {
    field: Field
    onBlur?: () => void
    isLoading?: boolean
}) => {
    const { validations } = field
    const id = field.id
    const Component: (props: FormControlProps<unknown, unknown, unknown>) => ReactNode = field.Component ?? Input

    const setValue = useCallback((value: unknown) => {
        createSetter(setState, id)(value)
        if (setErrors) setErrors((errors) => ({ ...errors, [id]: undefined }))
    }, [])

    return (
        <Component
            key={id as string}
            value={state[id]}
            setValue={setValue}
            getAdjacentFieldValue={(key: keyof typeof state) => state[key]}
            isRequired={Boolean(validations?.required?.value)}
            error={errors?.[id]}
            setState={field.settingState ? setState : undefined}
            {...omit(field, ["initialValue", "validations", "Component"] as const)}
            readOnly={field.readOnly || !isEdit}
            onBlur={onBlur ? () => onBlur?.() : undefined}
            isLoading={isLoading}
            classList={
                classList && !field.classList
                    ? classList
                    : field.classList && !classList
                    ? field.classList
                    : mergeClasses(classList, field.classList)
            }
        />
    )
}

export function validate<State extends Dictionary<any>, Fields extends readonly Field[]>(
    state: State,
    fields: Fields,
    ...additionalValidations: ValidationMap[]
) {
    const validations = getValidationsFromFields(fields)
    additionalValidations.forEach((v) => Object.assign(validations, v))

    if (!Object.keys(validations).length) return null

    const newErrors: any = {}
    for (const key in validations) {
        const value = state[key] || ""
        const hasValue = Array.isArray(value) ? value.length : value
        const { required, pattern, custom, maxLen, minLen } = validations[key] || {}

        if ((typeof required?.value === "function" ? required?.value(state) : required?.value) && !hasValue)
            newErrors[key] = required?.message ?? "required"
        if (pattern?.value && !RegExp(pattern.value).test(value)) newErrors[key] = pattern.message ?? "pattern"
        if (hasValue && custom?.isValid) {
            const validation = custom.isValid(value, state)

            if (Array.isArray(validation) || ["object", "string"].includes(typeof validation))
                newErrors[key] = validation
            else if (!validation) newErrors[key] = custom.message ?? "custom"
        }
        if (maxLen?.value && value.length > maxLen.value) newErrors[key] = maxLen.message ?? "maxLen"
        if (minLen?.value && value.length < minLen.value) newErrors[key] = minLen.message ?? "minLen"
    }

    return Object.keys(newErrors).length > 0 ? newErrors : null
}
