import { ControlledSelectV2, IOptionV2, TypographyV2 } from "components/v2"
import { TFunction } from "i18next"
import { Control, FieldErrors } from "react-hook-form"

export const REPEATS_OPTIONS = (t: TFunction) => [
    { id: "0", title: t("featuresSettings.consts.never"), subtitle: t("featuresSettings.consts.repeatsNever") },
    { id: "1", title: t("featuresSettings.consts.daily"), subtitle: t("featuresSettings.consts.repeatsDaily") },
    { id: "7", title: t("featuresSettings.consts.weekly"), subtitle: t("featuresSettings.consts.repeatsWeekly") }
]

const getDaysOptions = (count: number) =>
    new Array(count).fill(0).map((el, i) => ({ id: (i + 1).toString(), title: (i + 1).toString() }))

export const DAYS_OPTION = getDaysOptions(7)
export const HOURS_OPTION = getDaysOptions(24)
export const WEEKS_OPTION = getDaysOptions(7)

const ALWAYS_OPTIONS = (t: TFunction) => [
    { id: "day", title: t("featuresSettings.consts.day") },
    { id: "week", title: t("featuresSettings.consts.week") }
    // { id: "hour", title: t("featuresSettings.consts.hour") }
]

export const AVAILABILITY_OPTIONS = (t: TFunction, control: Control, days_offset: IOptionV2[], errors: FieldErrors) => [
    {
        value: "until",
        labelComponent: (
            <div className="flex flex-row items-center gap-x-1 w-[324px]">
                <TypographyV2 type="sm" width="medium" className="text-[#111827] whitespace-nowrap">
                    {t("featuresSettings.consts.availableFor")}
                </TypographyV2>
                <div className="w-[73px]">
                    <ControlledSelectV2
                        options={days_offset}
                        control={control}
                        name="time_offset"
                        placeholder={"10"}
                    />
                </div>
                <div className="w-[179px]">
                    <ControlledSelectV2
                        options={ALWAYS_OPTIONS(t)}
                        control={control}
                        name="time_unit"
                        placeholder={t("featuresSettings.consts.days")}
                    />
                </div>
            </div>
        )
    }
]
