import React from "react"
import { CardV2, ExerciseDiagnosesList, TypographyV2, UserAvatarV2 } from "components/v2"
import { ExerciseDefResponse } from "types/Redux"
import { useDispatch } from "store"
import { selectExerciseForAddVariableModalAction } from "store/questions/actions"
import { classNames } from "utils"
import { useIsHeLanguage } from "hooks"

const ExerciseAddVariableIItem: React.FC<ExerciseDefResponse> = (exercise) => {
    const {
        title,
        description,
        diagnoses,
        creator: {
            user: { first_name: firstName, last_name: lastName }
        }
    } = exercise

    const dispatch = useDispatch()

    const { isHeLanguage } = useIsHeLanguage()

    const onExerciseClick = () => {
        dispatch(selectExerciseForAddVariableModalAction(exercise))
    }

    return (
        <CardV2 className="flex flex-col justify-between border-none" onClick={onExerciseClick}>
            <div>
                <TypographyV2
                    type="sm"
                    width="bold"
                    className={classNames(
                        isHeLanguage ? "text-right" : "text-left",
                        "w-auto overflow-hidden text-ellipsis"
                    )}
                >
                    {title}
                </TypographyV2>
                <TypographyV2
                    type="sm"
                    className={classNames(
                        "text-gray-400 mt-1",
                        isHeLanguage ? "text-right" : "text-left",
                        "w-auto overflow-hidden text-ellipsis"
                    )}
                >
                    {description}
                </TypographyV2>
            </div>
            <div>
                <div className="flex gap-0.5 mt-4">
                    <ExerciseDiagnosesList diagnoses={diagnoses} />
                </div>
                <div className="mt-4  flex items-center">
                    <UserAvatarV2
                        size={19}
                        {...{ firstName, lastName }}
                        className={classNames("p-3", isHeLanguage ? "ml-1" : "mr-1")}
                    />
                    <TypographyV2 width="normal">{firstName + " " + lastName}</TypographyV2>
                </div>
            </div>
        </CardV2>
    )
}

export default ExerciseAddVariableIItem
