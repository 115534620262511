import { PatientFormContact } from "@sequel-care/types/Patient"
import { defineField, ListField, validate } from "forms"
import { ListRow, ListWrapper } from "forms/ListField"
import { Dictionary } from "lodash"
import { getUserFields } from "modals/UserModal"
import { noopTFunction } from "utils"
import { ContactFields, contactIsNotEmpty, getContactFields, getInitialContactValue, useContactFields } from "./contact"
import { RenderContact } from "../ContactRow"

export const getPatientUserFields = (t = noopTFunction) => {
    return [
        ...getUserFields(t, { isForTherapist: false }),
        defineField({
            id: "contacts",
            label: t("patient:patientForm.familyOr") as string,
            initialValue: [getInitialContactValue()],
            Component: ListField<ContactFields, PatientFormContact>,
            ListWrapper: ListWrapper,
            ItemWrapper: ListRow,
            RenderItem: RenderContact,
            useFields: useContactFields,
            gridTemplateColumns: "4fr repeat(3, 2fr) 3fr",
            getInitialItemValue: getInitialContactValue,
            validations: {
                custom: {
                    isValid: (value, data: { email: string }) => {
                        const fields = getContactFields(t)
                        const contactErrors = value.map((contact) => {
                            if (contactIsNotEmpty(contact)) {
                                const errors = validate(contact, fields) as Dictionary<string>
                                if (
                                    contact.email === data.email ||
                                    value.filter(({ email }) => email === contact.email).length > 1
                                ) {
                                    const message = t("patientForm.duplicated_email")

                                    if (errors) errors.email = message
                                    else return { email: message }
                                }

                                return errors
                            }
                        })

                        return contactErrors.some(Boolean) ? contactErrors : true
                    }
                }
            }
        })
    ] as const
}
