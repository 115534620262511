import { SVGProps } from "react"

const IntegrationsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12.8127 16.7935L17.0637 18.0685M5.93066 6.93555L7.20566 11.1845L5.93066 6.93555Z" />
        <path d="M11.6831 12.3176L17.4421 6.55859" />
        <path d="M5.5 7C6.32843 7 7 6.32843 7 5.5C7 4.67157 6.32843 4 5.5 4C4.67157 4 4 4.67157 4 5.5C4 6.32843 4.67157 7 5.5 7Z" />
        <path d="M18.5 7C19.3284 7 20 6.32843 20 5.5C20 4.67157 19.3284 4 18.5 4C17.6716 4 17 4.67157 17 5.5C17 6.32843 17.6716 7 18.5 7Z" />
        <path d="M18.5 20C19.3284 20 20 19.3284 20 18.5C20 17.6716 19.3284 17 18.5 17C17.6716 17 17 17.6716 17 18.5C17 19.3284 17.6716 20 18.5 20Z" />
        <path d="M8.5 20C10.9853 20 13 17.9853 13 15.5C13 13.0147 10.9853 11 8.5 11C6.01472 11 4 13.0147 4 15.5C4 17.9853 6.01472 20 8.5 20Z" />
    </svg>
)

export default IntegrationsIcon
