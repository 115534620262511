import { CompleteExercise, DiagnosisListItem, QuestionnaireListItem } from "@sequel-care/types"
import { CheckIcon } from "components/icons"
import Package from "components/icons/Package"
import { ComponentProps, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useAllCarePlans, useAllExercises, useAllQuestionnaires, useCurrentPatient, useDiagnoses } from "store/hooks"
import { pushToLibraryStack, setLibrarySearch } from "store/patient/actions"
import { ContentLibraryData } from "types/Redux"
import { FeatureFlags } from "utils/FeatureFlags"
import { RenderLibrary } from "./CardsView"
import { CardData, RenderLibraryProps } from "./CardsView/types"
import { diagnosesMatchPatient, eventToTemplate } from "./utils"
import { CarePlanEvent } from "@sequel-care/types/CarePlan"
import { CarePlanEventTypes } from "components/Settings/utils"

type QuestionnaireLibraryItem = CardData & {
    questionnaires: QuestionnaireListItem | QuestionnaireListItem[]
    exercises: CompleteExercise[]
    tasks: Omit<CompleteExercise, "question_groups">[]
    related_diagnoses: DiagnosisListItem[]
}

const QuestionnaireLibrary = ({ searchInitialValue }: { searchInitialValue?: string }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("patient")
    const patient = useCurrentPatient()
    const allCarePlans = useAllCarePlans()
    const allExercises = useAllExercises()
    const [search, setSearch] = useState(searchInitialValue)
    const preset = useMemo(
        () => [
            {
                id: "recommended",
                name: t("contentLib.recommendedForPatient", { patient_name: patient?.user.first_name }),
                Icon: CheckIcon
            },
            { id: "carePlans", name: t("contentLib.carePlans"), Icon: Package }
        ],
        [t, patient]
    )

    const diagnoses = useDiagnoses()

    const [currentDiagnosis, setCurrentDiagnosis] = useState<Omit<DiagnosisListItem, "id"> & { id: string | number }>(
        preset[0]
    )

    const mapCareplanEvents = (events: CarePlanEvent[], type: CarePlanEventTypes) =>
        events.map(({ id, title, recommended_recurrence, week_day, available_for }) => {
            const def =
                type === "questionnaire"
                    ? allQuestionnaires.find((questionnaire) => questionnaire.id === id)
                    : type === "exercise"
                    ? allExercises.find((exercise) => exercise.id === id)
                    : undefined
            return {
                ...(def ?? {}),
                title,
                type,
                recommended_recurrence: recommended_recurrence ?? def.recommended_recurrence,
                week_day,
                exprires_within: available_for
            }
        })

    const isCarePlanEnabled = FeatureFlags.isEnabled("care-plan")
    const allQuestionnaires = useAllQuestionnaires()
    const { allItems, itemsInCategory } = useMemo(() => {
        const allItems = (
            isCarePlanEnabled
                ? allCarePlans.map<QuestionnaireLibraryItem>((plan) => {
                      const questionnaires = mapCareplanEvents(
                          plan.questionnaires,
                          "questionnaire"
                      ) as unknown as QuestionnaireListItem[]
                      return {
                          ...plan,
                          type: "care_plan",
                          language: questionnaires[0]?.language,
                          related_diagnoses: diagnoses.filter(({ id }) => plan.diagnoses.includes(id)),
                          questionnaires,
                          exercises: mapCareplanEvents(plan.exercises, "exercise") as CompleteExercise[],
                          tasks: mapCareplanEvents(plan.tasks, "task") as QuestionnaireLibraryItem["tasks"]
                      }
                  })
                : ([] as QuestionnaireLibraryItem[])
        ).concat(
            allQuestionnaires
                .filter(({ is_published }) => is_published)
                .map((questionnaire) => ({
                    exercises: [] as CompleteExercise[],
                    questionnaires: questionnaire,
                    tasks: [] as CompleteExercise[],
                    type: "questionnaire",
                    ...questionnaire
                }))
        )

        return {
            allItems,
            itemsInCategory: allItems.filter(({ related_diagnoses, type, is_deleted }) => {
                if (is_deleted) return false

                if (typeof currentDiagnosis.id === "string")
                    return (
                        currentDiagnosis.id === "all" ||
                        (currentDiagnosis.id === "recommended" && diagnosesMatchPatient(related_diagnoses, patient)) ||
                        (currentDiagnosis.id === "carePlans" && type === "care_plan")
                    )

                const getSubIds = (diagnosisId: number): number[] => {
                    const subIds = diagnoses
                        .find((diagnosis) => diagnosis.id === diagnosisId)
                        .sub_diagnoses?.flatMap((diagnosisId) => getSubIds(diagnosisId))

                    return [diagnosisId, ...(subIds ?? [])]
                }

                const idList = getSubIds(currentDiagnosis.id)
                return related_diagnoses.find(({ id }) => idList.includes(id as number))
            })
        }
    }, [allQuestionnaires, allExercises, allCarePlans, currentDiagnosis, patient])

    const { onAdd, onLearnMore } = useMemo(() => {
        const goToView = (view: ContentLibraryData["view"]) => (item: QuestionnaireLibraryItem) => {
            const { questionnaires, exercises, tasks, type } = item
            const isCarePlan = type === "care_plan"

            if (Boolean(search)) dispatch(setLibrarySearch(search))
            dispatch(
                pushToLibraryStack({
                    planId: isCarePlan ? item.id : undefined,
                    eventTemplates: Array.isArray(questionnaires)
                        ? questionnaires
                              .map((q) => eventToTemplate("questionnaire", q))
                              .concat(exercises.map((e) => eventToTemplate("exercise", e)))
                              .concat(tasks.map((e) => eventToTemplate("task", e)))
                        : eventToTemplate("questionnaire", questionnaires),
                    view,
                    patientId: "preserve"
                })
            )
        }
        return { onAdd: goToView("config"), onLearnMore: goToView("learn_more") }
    }, [search])

    return (
        <RenderLibrary
            type="questionnaire"
            cardItems={itemsInCategory}
            allItems={allItems}
            gridColumns="grid-cols-1"
            categories={{
                list: diagnoses,
                label: t("diagnoses"),
                currentCategory: currentDiagnosis,
                setCurrentCategory: setCurrentDiagnosis,
                preset
            }}
            RenderBreadCrumb={RenderBreadCrumb}
            {...{ onAdd, onLearnMore, search, setSearch }}
        />
    )
}

const RenderBreadCrumb = ({
    categories,
    category,
    isSuperDiagnosis = false
}: ComponentProps<RenderLibraryProps<any, DiagnosisListItem>["RenderBreadCrumb"]> & { isSuperDiagnosis?: boolean }) => {
    const superDiagnosis =
        category.super_diagnoses?.length && categories.list.find((cat) => cat.id === category.super_diagnoses[0])
    return (
        <>
            {superDiagnosis && <RenderBreadCrumb category={superDiagnosis} categories={categories} isSuperDiagnosis />}
            <div
                className={isSuperDiagnosis ? "text-secondary hover:text-med-blue cursor-pointer" : "text-med-blue"}
                onClick={isSuperDiagnosis ? () => categories.setCurrentCategory(category) : undefined}
            >
                {category.name}
            </div>
            {isSuperDiagnosis && <div>&gt;</div>}
        </>
    )
}

export default QuestionnaireLibrary
