import { Gender, UserRole } from "@prisma/client"
import { TemplatePublishingStatus } from "./enums"

export const userRoleTitles = {
    [UserRole.admin]: "admin",
    [UserRole.patient]: "patient",
    [UserRole.significant_other]: "significant_other",
    [UserRole.therapist]: "therapist"
}

export const genderTitles = {
    [Gender.male]: "male",
    [Gender.all]: "all",
    [Gender.other]: "other",
    [Gender.intersex]: "intersex",
    [Gender.transgender]: "transgender",
    [Gender.non_binary]: "non_binary",
    [Gender.female]: "female"
}

export const exerciseStatusesWithLocales = {
    [TemplatePublishingStatus.ACTIVE]: "active",
    [TemplatePublishingStatus.INIT]: "draft",
    [TemplatePublishingStatus.PUBLISHED]: "published",
    [TemplatePublishingStatus.DELETED]: "deleted",
    [TemplatePublishingStatus.ARCHIVED]: "archived"
}
