import DashboardCard from "components/DashboardCard"
import { ButtonV2, TypographyV2 } from "components/v2"
import { useTranslation } from "react-i18next"
import { addDays } from "date-fns"
import { getDateLocaleFormat } from "utils/date"
import { TherapistEvent } from "api/Therapist"
import { SHORT_DATE_FORMAT_ENGLISH, SHORT_DATE_FORMAT_HEBREW } from "utils/date/dateConsts"

const TherapistQuestionnaire = ({
    openModal,
    events
}: {
    openModal: (eventId: number) => void
    events: TherapistEvent[]
}) => {
    const { t, i18n } = useTranslation("common")
    const dateFormat = i18n.language === "he" ? SHORT_DATE_FORMAT_HEBREW : SHORT_DATE_FORMAT_ENGLISH

    return (
        <DashboardCard title={t("therapist_questionnaire_waiting")} className="py-[18px] px-[20px]">
            <div className="flex flex-row w-full border-b border-[#DCE2E8] pb-[10px]">
                <div className="w-[30%]">
                    <TypographyV2 type="sm" width="medium" className="text-secondary-2">
                        {t("therapist_questionnaire_name")}
                    </TypographyV2>
                </div>
                <div className="w-[25%]">
                    <TypographyV2 type="sm" width="medium" className="text-secondary-2">
                        {t("patient_name")}
                    </TypographyV2>
                </div>
                <div className="w-[25%]">
                    <TypographyV2 type="sm" width="medium" className="text-secondary-2">
                        {t("expires_on")}
                    </TypographyV2>
                </div>
                <div className="w-[20%]"></div>
            </div>
            <div className="overflow-y-auto max-h-[137px]">
                {events.map((event: TherapistEvent) => {
                    const date = getDateLocaleFormat(addDays(new Date(), event.expires_within), dateFormat, i18n)
                    return (
                        <div
                            key={event.id}
                            className="flex flex-row w-full items-center h-[52px] border-b border-[#DCE2E8]"
                        >
                            <div className="w-[30%]">
                                <TypographyV2 type="sm" width="medium" className="text-dark-blue">
                                    {event.title}
                                </TypographyV2>
                            </div>
                            <div className="w-[25%]">
                                <TypographyV2 type="sm" width="medium" className="text-dark-blue">
                                    {event?.patient?.user?.first_name} {event?.patient?.user?.last_name}
                                </TypographyV2>
                            </div>
                            <div className="w-[25%]">
                                <TypographyV2 type="sm" width="medium" className="text-dark-blue">
                                    {event?.expires_within ? date : ""}
                                </TypographyV2>
                            </div>
                            <div className="w-[20%]">
                                <ButtonV2
                                    className="!w-[81px] !h-[26px] !bg-[#dee5f280] !border-[#5b7eb133] flex items-center justify-center !text-[#5B7EB1] !text-xs !font-[400]"
                                    variant="light-gray"
                                    text={t("start")}
                                    onClick={() => openModal(event.id)}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </DashboardCard>
    )
}

export default TherapistQuestionnaire
