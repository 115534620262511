import { Transition } from "@headlessui/react"
import { ChevronLeftIcon } from "components/icons"
import React, { useEffect, useState } from "react"
import { classNames } from "utils"

interface IExpandableV2Props {
    header: string
    children: React.ReactNode
    isSearching: boolean
}

const ExpandableRowV2: React.FC<IExpandableV2Props> = ({ header, children, isSearching }) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        if (isSearching) {
            setIsOpen(true)
        }
    }, [isSearching])

    return (
        <div className="w-full">
            <div className={classNames("w-full px-3 py-5 w-full border-base-light border-t", isOpen && "border-b")}>
                <button
                    onClick={toggleOpen}
                    className="flex flex-row w-full items-center text-black justify-between font-medium text-sm"
                >
                    {header}
                    <ChevronLeftIcon
                        width={20}
                        height={20}
                        className={classNames(
                            isOpen ? "rotate-90 transition-transform" : "-rotate-90 transition-transform",
                            "flex-shrink-0"
                        )}
                    />
                </button>
            </div>
            <Transition show={isOpen}>
                <div className="transition duration-300 ease-in data-[closed]:opacity-0">{children}</div>
            </Transition>
        </div>
    )
}

export default ExpandableRowV2
