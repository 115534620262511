import { Reducer } from "redux"

import * as types from "./types"
import { ExercisesState } from "types/Redux"
import { ExercisesActionTypes } from "./types"

const initialState: ExercisesState = {
    list: [],
    diagnoses: [],
    creators: [],
    searchParams: {
        text: null,
        diagnose: null,
        status: null,
        creator: null
    }
}

const exercisesReducer: Reducer<ExercisesState, ExercisesActionTypes> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_EXERCISES_LIST: {
            return { ...state, list: [...payload] }
        }
        case types.SET_NEW_EXERCISE_TO_LIST: {
            return { ...state, list: [payload, ...state.list] }
        }
        case types.SET_EXERCISES_SEARCH_PARAMS: {
            const { key, value } = payload
            return { ...state, searchParams: { ...state.searchParams, [key]: value } }
        }
        case types.SET_DIAGNOSES_LIST: {
            return { ...state, diagnoses: [...payload] }
        }
        case types.SET_CREATORS_LIST: {
            return { ...state, creators: [...payload] }
        }
        default:
            return state
    }
}

export default exercisesReducer
