import React, { forwardRef } from "react"
import { classNames } from "utils"
import { disabledInputClassNames, errorInputClassNames, inputClassNames } from "components/v2/constants"

interface ITextAreaV2Props extends Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, "onChange" | "rows"> {
    isDisabled?: boolean
    error?: string
    rowsCount?: number
    onChange: (value: string) => void
}

const TextAreaV2 = forwardRef<HTMLTextAreaElement, ITextAreaV2Props>(
    ({ isDisabled = false, rowsCount = 1, maxLength = 300, error, onChange, className, ...inputProps }, ref) => {
        return (
            <textarea
                ref={ref}
                rows={rowsCount}
                disabled={isDisabled}
                maxLength={maxLength}
                onChange={(e) => onChange(e.target.value)}
                className={classNames(
                    inputClassNames,
                    error && errorInputClassNames,
                    "resize-none", // Заборона ручного зміщення
                    isDisabled && disabledInputClassNames,
                    className
                )}
                {...inputProps}
            />
        )
    }
)

TextAreaV2.displayName = "TextAreaV2"

export default TextAreaV2
