import { SVGProps } from "react"

const ImageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.55126 4.1166C4.31054 4.50236 3.90752 4.75598 3.45734 4.81996C3.20437 4.85591 2.95235 4.89482 2.70133 4.93665C1.99941 5.05361 1.5 5.67108 1.5 6.38268V12C1.5 12.8284 2.17157 13.5 3 13.5H13C13.8284 13.5 14.5 12.8284 14.5 12V6.38268C14.5 5.67109 14.0006 5.05362 13.2987 4.93665C13.0476 4.89482 12.7956 4.85591 12.5427 4.81996C12.0925 4.75599 11.6895 4.50236 11.4487 4.1166L10.9013 3.23926C10.6489 2.83491 10.2196 2.57226 9.74367 2.5467C9.16638 2.5157 8.58502 2.5 8 2.5C7.41498 2.5 6.83362 2.5157 6.25633 2.5467C5.7804 2.57226 5.35105 2.83491 5.09873 3.23926L4.55126 4.1166Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11 8.5C11 10.1569 9.65685 11.5 8 11.5C6.34315 11.5 5 10.1569 5 8.5C5 6.84315 6.34315 5.5 8 5.5C9.65685 5.5 11 6.84315 11 8.5Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.5 7H12.505V7.005H12.5V7Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default ImageIcon
