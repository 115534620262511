import { Message } from "@twilio/conversations"
import { ETypographyTypes, Typography } from "components/Typography"
import { Button, TruncatedString } from "components/common"
import CloudImg from "components/common/CloudImg"
import { AddConversationIcon } from "components/icons"
import { isAfter } from "date-fns"
import { last } from "lodash"
import { ReactNode, SVGProps, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useCurrentUser } from "store/hooks"
import { updateConversationSidebar } from "store/sidebars/actions"
import { PatientState } from "types/Redux"
import { classNames, getUserName } from "utils"
import { SequelConversation, useConversations, useMessageCount, useMessages } from "utils/conversations"
import { shortenedDistanceToNow } from "utils/dates"
import ConversationDetails from "./ConversationDetails"
import ChatContentWrapper from "./ChatContentWrapper"
import { useParticipantsWithoutCurrentUser } from "./utils"
import { ChatConfig } from "@sequel-care/types"
import NotificationBellOff from "../../components/icons/NotificationBellOff"
import AttachIcon from "components/icons/Attach"

export const detailsClassname = "px-6 py-3 cursor-pointer hover:bg-border-blue text-dark-blue font-medium"

const ConversationList = ({ currentPatient }: { currentPatient: PatientState["currentPatient"] }) => {
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()
    const [query, setQuery] = useState("")
    const currentUser = useCurrentUser()
    const conversations = useConversations(currentPatient.id)

    const filteredConversations = useMemo(() => {
        const filtered = !query
            ? conversations
            : conversations.filter((conversation) => {
                  return conversation.participants.find((user) => {
                      return user.id !== currentUser.id && getUserName(user).toLowerCase().includes(query.toLowerCase())
                  })
              })

        return filtered.sort((conversationA, conversationB) => {
            return isAfter(conversationA.lastMessageDate, conversationB.lastMessageDate) ? -1 : 1
        })
    }, [conversations, query])

    return (
        <ChatContentWrapper
            width="w-150"
            header={
                <Typography type={ETypographyTypes.DARK_BLUE} className="flex justify-between w-full text-xl">
                    {t("chat.chat", { count: 10 })}
                    <AddConversationIcon
                        className="text-2xl translate-y-0.25 cursor-pointer hover:scale-110"
                        onClick={() => dispatch(updateConversationSidebar({ mode: "create" }))}
                    />
                </Typography>
            }
            backButton="close"
            {...{ query, setQuery }}
        >
            {!filteredConversations.length && (
                <div className="flex flex-col items-center p-8 text-center text-sm gap-6 mt-20">
                    <CloudImg imageId="chat-bubble" variant="medium" width={100} height={100} />
                    {t(!conversations.length ? "chat.noChats" : "chat.chatsNotFound")}
                    <Button theme="primary" onClick={() => dispatch(updateConversationSidebar({ mode: "create" }))}>
                        {t("chat.newChat")}
                    </Button>
                </div>
            )}
            {filteredConversations.map((conversation) => (
                <ConversationListItem key={conversation.sid} conversation={conversation} />
            ))}
        </ChatContentWrapper>
    )
}

const ConversationListItem = ({ conversation }: { conversation: SequelConversation }) => {
    const dispatch = useDispatch()
    const users = useParticipantsWithoutCurrentUser(conversation)

    const RenderDetailsWithConversation = useCallback(
        (props: any) => <RenderDetails {...props} conversation={conversation} />,
        [conversation]
    )

    return (
        <ConversationDetails
            RenderDetails={RenderDetailsWithConversation}
            users={users}
            onClick={() => dispatch(updateConversationSidebar({ mode: "chat", conversation, newChatWith: null }))}
            className={detailsClassname}
        />
    )
}

const iconByMessageType: Record<string, (props: SVGProps<SVGSVGElement>) => ReactNode> = {
    media: AttachIcon
}

const RenderDetails = ({
    participantNames,
    conversation
}: {
    participantNames: string
    conversation: SequelConversation
}) => {
    const currentUser = useCurrentUser()
    const lastMessage = last(useMessages(conversation)) as Message
    const messageCount = useMessageCount({ conversation })
    const distanceToNow = lastMessage ? shortenedDistanceToNow(lastMessage.dateCreated) : null
    const chatConfig = conversation.configuration
        ? (conversation.configuration as ChatConfig)[`${currentUser.id}`]
        : null
    const isMuted = chatConfig?.is_muted ?? false

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="flex flex-row w-full gap-2 items-center">
                    <div className="truncate">{participantNames}</div>
                    {isMuted && <NotificationBellOff className="text-xl w-5 h-5" />}
                </div>
                {messageCount.unread > 0 && (
                    <div
                        className={classNames(
                            "flex items-center justify-center rounded-full px-1 h-4 min-w-[1rem] text-white text-[.6rem] bg-dark-green"
                        )}
                    >
                        {messageCount.unread}
                    </div>
                )}
            </div>
            <div className="flex justify-between text-sm text-gray-400">
                <RenderLastMessage lastMessage={lastMessage} />
                {distanceToNow}
            </div>
        </>
    )
}

const RenderLastMessage = ({ lastMessage }: { lastMessage: Message }) => {
    const { t } = useTranslation("patient")
    if (!lastMessage) return <div></div>
    const Icon = iconByMessageType[lastMessage.type as string]

    return lastMessage.type === "text" ? (
        <TruncatedString string={lastMessage?.body} maxLen={50} />
    ) : (
        <div className="flex items-center">
            {Icon && <Icon className="w-4 h-4" />}
            {t(`chat.messageType.${lastMessage.type}`)}
        </div>
    )
}

export default ConversationList
