import { CreateExerciseDefPayload } from "types/exercises/ExerciseDef"
import { ApiDelete, ApiGet, ApiPatch, ApiPost, ApiPut } from "../ApiRequest"
import { ExerciseDefResponse } from "types/Redux"

export const getAllExercises = async (): Promise<ExerciseDefResponse[]> => {
    const instance = new ApiGet<ExerciseDefResponse[]>(`/exercise-def`)
    instance.callerName = getAllExercises.name
    return instance.run()
}

export const createExercise = async (body: CreateExerciseDefPayload): Promise<ExerciseDefResponse> => {
    const instance = new ApiPost<ExerciseDefResponse>(`/exercise-def`, body)
    instance.callerName = createExercise.name
    return instance.run()
}

export const updateExercise = async (
    id: number,
    { payload }: { payload: CreateExerciseDefPayload }
): Promise<ExerciseDefResponse> => {
    const instance = new ApiPut<ExerciseDefResponse>(`/exercise-def/${id}`, payload)
    instance.callerName = createExercise.name
    return instance.run()
}

export const deleteExerciseById = async (id: number) => {
    const instance = new ApiDelete(`/exercise-def/${id}`)
    instance.callerName = deleteExerciseById.name
    return instance.run()
}

export const getExerciseById = async (id: number): Promise<ExerciseDefResponse> => {
    const instance = new ApiGet<ExerciseDefResponse>(`/exercise-def/${id}`)
    instance.callerName = getExerciseById.name
    return instance.run()
}

export const archiveExercise = async (id: number) => {
    const instance = new ApiPatch(`/exercise-def/archive/${id}`)
    instance.callerName = archiveExercise.name
    return instance.run()
}

export const publishExercise = async (id: number): Promise<ExerciseDefResponse> => {
    const instance = new ApiPatch<ExerciseDefResponse>(`/exercise-def/publish/${id}`)
    instance.callerName = publishExercise.name
    return instance.run()
}

export const copyExercise = async (id: number): Promise<ExerciseDefResponse> => {
    const instance = new ApiPost<ExerciseDefResponse>(`/exercise-def/copy/${id}`)
    instance.callerName = copyExercise.name
    return instance.run()
}
