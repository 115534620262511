import { SVGProps } from "react"

const ScheduleIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 3.91602C8.41128 3.91602 9.3128 3.95714 10.2028 4.03762C10.4436 4.0594 10.6352 4.2455 10.6639 4.48552C10.757 5.26378 10.8199 6.0514 10.8514 6.84719L9.58529 5.58081C9.36564 5.36112 9.00949 5.36108 8.78979 5.58072C8.5701 5.80037 8.57006 6.15653 8.78971 6.37622L11.0391 8.62614C11.2588 8.84583 11.6149 8.84588 11.8346 8.62625L14.0852 6.37632C14.3049 6.15668 14.3049 5.80053 14.0853 5.58082C13.8657 5.36112 13.5095 5.36107 13.2898 5.58071L11.9789 6.89125C11.9474 6.03552 11.881 5.18862 11.7809 4.35194C11.6891 3.58433 11.074 2.98681 10.3041 2.91719C9.38047 2.83367 8.44514 2.79102 7.5 2.79102C6.55486 2.79102 5.61953 2.83367 4.69586 2.91719C3.92599 2.98681 3.31087 3.58433 3.21907 4.35194C3.17123 4.752 3.13107 5.15439 3.09875 5.55897C3.07401 5.86865 3.30499 6.13974 3.61467 6.16449C3.92434 6.18923 4.19544 5.95824 4.22018 5.64857C4.25133 5.25869 4.29002 4.87096 4.33611 4.48552C4.36482 4.2455 4.55635 4.0594 4.79717 4.03762C5.6872 3.95714 6.58873 3.91602 7.5 3.91602ZM3.96085 7.45602C3.74121 7.23634 3.38508 7.2363 3.16539 7.45592L0.914822 9.7057C0.695114 9.92533 0.695051 10.2815 0.914683 10.5012C1.13431 10.7209 1.49047 10.721 1.71018 10.5013L3.02111 9.19086C3.05258 10.0466 3.11902 10.8934 3.21907 11.7301C3.31087 12.4977 3.92599 13.0952 4.69586 13.1648C5.61953 13.2484 6.55486 13.291 7.5 13.291C8.44514 13.291 9.38047 13.2484 10.3041 13.1648C11.074 13.0952 11.6891 12.4977 11.7809 11.7301C11.8288 11.3297 11.869 10.927 11.9013 10.5221C11.9261 10.2124 11.6951 9.94135 11.3854 9.91661C11.0757 9.89188 10.8046 10.1229 10.7799 10.4325C10.7487 10.8227 10.71 11.2108 10.6639 11.5965C10.6352 11.8365 10.4436 12.0226 10.2028 12.0444C9.3128 12.1249 8.41128 12.166 7.5 12.166C6.58873 12.166 5.6872 12.1249 4.79717 12.0444C4.55635 12.0226 4.36482 11.8365 4.33611 11.5965C4.24305 10.8183 4.18014 10.0307 4.14861 9.23492L5.41472 10.5012C5.63437 10.7209 5.99053 10.7209 6.21022 10.5013C6.4299 10.2816 6.42993 9.92549 6.21028 9.7058L3.96085 7.45602Z"
            fill="#9CA3AF"
        />
    </svg>
)

export default ScheduleIcon
