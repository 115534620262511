import { SVGProps } from "react"

const NumberIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3.5 5.5H13.5M2.5 10.5H12.5M11.3 1.5L8.7 14.5M7.3002 1.5L4.7002 14.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default NumberIcon
