import React from "react"
import { FormControlPropsV2, FormInputWrapper, RadioCircleV2 } from "components/v2/index"
import { useController } from "react-hook-form"

interface IControlledRadioCircleV2Props<T> extends FormControlPropsV2<T> {}

const ControlledRadioCircleV2 = <T,>({
    label,
    name,
    control,
    classList,
    isRequired,
    ...inputProps
}: IControlledRadioCircleV2Props<T>) => {
    const { error } = inputProps
    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    return (
        <FormInputWrapper {...{ isRequired, error, classList: classList?.wrapper }}>
            <RadioCircleV2
                value={value as string}
                onChange={(value) => onChange(value)}
                labelComponent={label}
                radioCircleWrapperStyle={classList.input}
                {...inputProps}
            />
        </FormInputWrapper>
    )
}

export default ControlledRadioCircleV2
