import React from "react"
import { BadgeV2, CardV2 } from "components/v2"

interface IBagesListV2Props {
    list: { title: string; id: number }[]
}

const BagesListV2: React.FC<IBagesListV2Props> = ({ list }) => (
    <CardV2 className="flex gap-1 min-w-28 max-w-[400px] flex-wrap border-gray-300 shadow-md">
        {list.map(({ id, title }) => (
            <BadgeV2 key={id} text={title} color="darkBlue" className="px-1.5 py-0.5" />
        ))}
    </CardV2>
)

export default BagesListV2
