import { IOptionV2 } from "components/v2"
import { useEffect, useState, useRef } from "react"

const CONTAINER_PADDING = 20
const useCalculateVisibleOptions = (
    keys: (string | number)[] = [],
    getOption: (option: string | number) => IOptionV2,
    maxLines = 2
) => {
    const containerRef = useRef(null)
    const [visibleOptions, setVisibleOptions] = useState([])
    const [remainingCount, setRemainingCount] = useState(0)
    const [currentLine, setCurrentLine] = useState(1)

    useEffect(() => {
        const calculateVisibleOptions = () => {
            if (!containerRef.current) return

            const containerWidth = containerRef.current.offsetWidth
            const optionWidths = [] as any
            let usedWidth = 0
            let lineCount = 1
            let visible = []

            const tempDiv = document.createElement("div")
            tempDiv.style.position = "absolute"
            tempDiv.style.visibility = "hidden"
            document.body.appendChild(tempDiv)

            keys?.forEach((optionKey: any) => {
                const option = getOption(optionKey)
                tempDiv.innerHTML = `
                <div class="flex items-center bg-[#F3F4F6] w-fit text-[#111827] rounded-lg text-white px-2 gap-2.5 py-1">
                    <div>
                    ${
                        option?.media
                            ? typeof option.media === "string"
                                ? `<img src="${option.media}" class="w-5 h-5 rounded-full" />`
                                : option.media
                            : ""
                    }
                    <span>${option?.title}</span>
                    ${option?.subtitle ? `<div>${option.subtitle}</div>` : ""}
                    </div>
                    <span><svg class="w-4 h-4"></svg></span> <!-- Placeholder for XIcon -->
                </div>
                `
                const optionWidth = tempDiv.offsetWidth + 10
                optionWidths.push(optionWidth)
            })

            document.body.removeChild(tempDiv)

            for (let i = 0; i < optionWidths.length; i++) {
                const optionWidth = optionWidths[i]
                if (usedWidth + optionWidth <= containerWidth - CONTAINER_PADDING) {
                    usedWidth += optionWidth
                } else {
                    lineCount++
                    if (lineCount > maxLines) {
                        visible.pop()
                        break
                    }
                    usedWidth = optionWidth
                }
                visible.push(keys[i])
            }
            setVisibleOptions(visible.length ? [visible[0]] : [])
            setRemainingCount(keys.length - 1)
            setCurrentLine(lineCount)
        }

        calculateVisibleOptions()

        window.addEventListener("resize", calculateVisibleOptions)
        return () => window.removeEventListener("resize", calculateVisibleOptions)
    }, [keys, getOption, maxLines])

    return { containerRef, visibleOptions, remainingCount, currentLine }
}

export default useCalculateVisibleOptions
