import { EventTypes } from "types"
import { ExerciseIcon, QuestionaryIcon, UnitIcon } from "components/icons"

export const eventTypeColors = {
    [EventTypes.Questionnaire]: "bg-lime-50",
    [EventTypes.Exercise]: "bg-violet-100",
    [EventTypes.Unit]: "bg-teal-50"
}

export const eventTypeIcons = {
    [EventTypes.Questionnaire]: QuestionaryIcon,
    [EventTypes.Exercise]: ExerciseIcon,
    [EventTypes.Unit]: UnitIcon
}
