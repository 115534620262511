import { Language, UserRole } from "@prisma/client"
import { Button } from "components/common"
import CloudImg from "components/common/CloudImg"
import { RepeatIcon } from "components/icons"
import Package from "components/icons/Package"
import { useTranslation } from "react-i18next"
import { classNames, eventTypeIconMap } from "utils"
import { CardData, RenderLibraryProps } from "./types"
import DOMPurify from "dompurify"

type RenderCardProps = Pick<RenderLibraryProps, "onAdd" | "onLearnMore" | "type" | "categories"> & {
    item: CardData
    currentCategory: string
}

function RenderCards<ItemType extends CardData>({
    cardItems,
    gridColumns,
    role,
    language,
    currentCategory,
    ...cardProps
}: Omit<RenderLibraryProps<ItemType>, "allItems" | "categories"> & {
    role: UserRole
    language: Language
    currentCategory: string
}) {
    const { t } = useTranslation("patient")
    const predicate = (item: ItemType) =>
        item.language === language && (item.type === "questionnaire" ? item.target_role === role : true)

    return cardItems?.length > 0 && cardItems.some((item) => predicate(item)) ? (
        <div className={classNames("grid gap-6 pb-6 pt-2 h-max overflow-auto", gridColumns)}>
            {cardItems.map((item) =>
                predicate(item) ? (
                    <RenderCard
                        key={item.type ? `${item.type[0]}${item.id}` : item.id}
                        item={item}
                        {...cardProps}
                        currentCategory={currentCategory}
                    />
                ) : null
            )}
        </div>
    ) : (
        <div className="text-gray-400  mx-auto grow py-12 text-center flex flex-col gap-4 items-center justify-center">
            {t("contentLib.notFound")}
            <CloudImg variant={"large"} width="200" height="200" imageId="looking" />
        </div>
    )
}

const RenderCard = ({ item, onAdd, onLearnMore, type, currentCategory }: RenderCardProps) => {
    const { t } = useTranslation("common")

    const { title, professional_description, description, recommended_recurrence, language, type: cardType } = item
    const isCarePlan = cardType === "care_plan"
    const Icon = isCarePlan ? Package : eventTypeIconMap[type]

    const recurrenceUnit = recommended_recurrence ? (recommended_recurrence % 7 ? "day" : "week") : undefined
    const recurrenceInterval = recommended_recurrence
        ? recommended_recurrence % 7
            ? recommended_recurrence
            : recommended_recurrence / 7
        : undefined

    return (
        <div className="h-max rounded border border-border-blue group relative  px-8 flex flex-col justify-between divide-y divide-border-blue">
            <div className="py-6" dir={language === "he" ? "rtl" : "ltr"}>
                <div className="flex gap-4 items-center">
                    <div
                        className={classNames(
                            isCarePlan ? "bg-green-v2/10 text-green-v2" : "bg-med-blue/10 text-med-blue",
                            "rounded-md  p-2.5 w-fit"
                        )}
                    >
                        <Icon className="w-6 h-6 !m-0 text-lg" strokeWidth={1.75} />
                    </div>
                    <div>
                        {isCarePlan && currentCategory !== "carePlans" && (
                            <h4 className="text-green-v2 text-sm">{t("recommended")}</h4>
                        )}
                        <h2 className="text-dark-blue font-medium mb-1.5 text-lg">{title}</h2>
                    </div>
                </div>
                <div
                    className="text-text-blue pt-5 line-clamp-2 overflow-hidden text-start"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(professional_description ?? description) }}
                />
            </div>
            <div className="flex justify-between py-5">
                {recommended_recurrence ? (
                    <div className="text-med-blue flex items-center gap-2">
                        <RepeatIcon className="text-lg" />
                        {`${t("patient:contentLib.recurrence.recommended")}: ${t(
                            "patient:contentLib.recurrence.every",
                            {
                                count: recurrenceInterval,
                                unit: recurrenceUnit
                            }
                        )}`}
                    </div>
                ) : (
                    <div />
                )}
                <div className="flex gap-2">
                    {onLearnMore && (
                        <Button
                            data-testid="questionnaire_card-learn_more_button"
                            theme="secondary"
                            className={isCarePlan ? "text-green-v2 border-green-v2 hover:!bg-green-v2" : ""}
                            onClick={() => onLearnMore(item)}
                        >
                            {t("common:learnMore")}
                        </Button>
                    )}
                    <Button
                        data-testid="questionnaire_card-add_button"
                        theme="primary"
                        onClick={() => onAdd(item)}
                        className={classNames(
                            isCarePlan && "bg-green-v2 border-green-v2 hover:!bg-dark-green hover:!border-dark-green",
                            "w-24"
                        )}
                    >
                        {t("add")}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default RenderCards
