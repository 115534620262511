import React from "react"
import { classNames } from "utils"
import { FormInputWrapper, TextAreaV2 } from "components/v2"
import { FormControlPropsV2 } from "components/v2/components/common/form-controls"
import { useController } from "react-hook-form"

interface ITextAreaV2Props<T> extends FormControlPropsV2<T> {
    isExpandable?: boolean
    rowsCount?: number
}

const ControlledTextAreaV2 = <T,>({
    label,
    classList,
    control,
    name,
    isRequired,
    ...inputProps
}: ITextAreaV2Props<T>) => {
    const { error } = inputProps

    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })
    return (
        <FormInputWrapper {...{ label, isRequired, error, classList: classList?.wrapper }}>
            <TextAreaV2
                value={value as string}
                onChange={(value) => onChange(value)}
                className={classNames(label ? "mt-1" : "mt-0", classList?.input)}
                {...inputProps}
            />
        </FormInputWrapper>
    )
}

export default ControlledTextAreaV2
