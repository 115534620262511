import { Dictionary } from "lodash"

export const colors: Dictionary<string> = {
    green: "bg-emerald-50 text-emerald-500",
    yellow: "bg-amber-50 text-amber-500",
    red: "bg-red-50 text-red-500",
    blue: "bg-blue-100 text-med-blue",
    darkBlue: "bg-med-gray text-dark-gray",
    gray: "bg-med-gray text-dark-gray",
    transparent: "text-gray"
}
