import { validate } from "forms"
import FormWithSteps from "forms/FormWithSteps"
import { last } from "lodash"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { updateOrgSettings } from "store/global/actions"
import { useCurrentRole } from "store/hooks"
import SettingsSection from "../SettingsSection"
import { stateToActiveHours } from "../User"
import { getInitialOrganizationSettingsState, useOrganizationSettingsSteps } from "./fields"
import { toast } from "react-toastify"

const OrganizationSettings = () => {
    const { t } = useTranslation("organization")
    const currentRole = useCurrentRole()
    const steps = useOrganizationSettingsSteps(t)
    const [state, setState] = useState(getInitialOrganizationSettingsState(currentRole?.organization))
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()

    const onSubmit = async () => {
        let newErrors = {}
        Object.values(state).forEach((stepState, index) => {
            const errors = validate(stepState, steps[index].fields)
            if (errors) newErrors = { ...newErrors, ...errors }
        })
        if (Object.keys(newErrors).length) {
            setErrors(newErrors)
            return
        }
        const res = await dispatch(
            updateOrgSettings({
                ...state.details,
                default_preferences: {
                    id: currentRole.organization.default_pref_id,
                    ...state.preferences,
                    chat_automated_reply: state.chat_preferences.chat_automated_reply,
                    chat_mark_as_urgent_enable: state.chat_preferences.urgent_enable,
                    chat_active_hours: stateToActiveHours(state.chat_preferences.active_hours)
                }
            })
        )

        if (res) toast(t("organizationForm.createSuccess"), { type: "success" })
        else toast(t("common:genericError"))
    }

    return (
        <SettingsSection title="patient:organizationSettings" wrapperClassName="!p-0">
            <FormWithSteps
                wrapperClassName="w-full"
                onSubmit={onSubmit}
                stepClassName="text-dark-blue"
                tabView
                unlockAllSteps
                getSubmitProps={(currentStep) => {
                    const isLast = currentStep === last(steps)
                    const key = isLast ? "common:update" : "common:continue"
                    return { children: t(key) as string, className: "w-48", type: isLast ? "submit" : undefined }
                }}
                {...{ steps, setState, state, setErrors, errors }}
            />
        </SettingsSection>
    )
}

export default OrganizationSettings
