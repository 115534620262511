import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2, RadioCircleGroupV2 } from "components/v2"

enum ScaleDirection {
    POSITIVE = "positive",
    NEGATIVE = "negative"
}

const ScaleQuestionResponse = ({
    question,
    screeningAnswer
}: {
    question: any
    screeningAnswer: ScreeningAnswer
}) => {

    const scaleMinValue = question?.scale?.min
    const scaleMaxValue = question?.scale?.max
    const scaleLabels = question?.scale?.labels[0]
    const scaleDirection = question?.scale?.direction === ScaleDirection.POSITIVE
    const options = scaleLabels.map((label: string, index: number) => {
        const step = (scaleMaxValue - scaleMinValue) / (scaleLabels.length - 1)
        const value = scaleDirection
            ? scaleMaxValue - (step * index)
            : scaleMinValue + (step * index)
        
        return {
            value: value.toString(),
            labelComponent: <TypographyV2 type="base">{label}</TypographyV2>
        }
    })

    return (
        <div className="p-4">
            <div className="bg-white border border-slate-300 rounded-lg  pt-4 pl-3">
                <TypographyV2 type="base" className="text-[#6B7280]">
                    {question.question}
                </TypographyV2>

                <div className="pt-2 flex flex-col gap-4">
                    <RadioCircleGroupV2
                        name={question.id}
                        onChange={() => {}}
                        readOnly
                        value={screeningAnswer?.value}
                        options={options}
                    />
                </div>
            </div>
        </div>
    )
}

export default ScaleQuestionResponse