import { Control } from "react-hook-form"
import { ScreeningQuestionType } from "@prisma/client"
import {
    FreeTextQuestionCard,
    SelectQuestionCard,
    MultipleChoiceQuestionCard,
    NumberQuestionCard,
    PercentQuestionCard,
    MinutesQuestionCard,
    TimeOfDayQuestionCard,
    ScaleQuestionCard,
    ImageQuestionCard,
    ScaleSliderQuestionCard,
    YesNoQuestionCard,
    WelcomeCard
} from "../IntakeCardsContent"

const cardBuilder = (questions: any[], control: Control<Record<string, string | (string | number)[]>>, errors: any) => {
    const cards: React.ReactNode[] = []
    questions
        .sort((a, b) => a.position - b.position)
        .forEach((question) => {
            const errorMessage = errors?.screeningAnswers?.[question.id]?.message ? "The question is required" : ""
            switch (question.type) {
                case ScreeningQuestionType.text_only:
                    cards.push(
                        <WelcomeCard
                            key={question.id}
                            title={question.question}
                            subtitle={""}
                            classNames="h-full"
                        />
                    )
                    break
                case ScreeningQuestionType.free_text:
                    cards.push(
                        <FreeTextQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.yes_no:
                    cards.push(
                        <YesNoQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.select:
                    cards.push(
                        <SelectQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.multiple_choice:
                    cards.push(
                        <MultipleChoiceQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.number:
                    cards.push(
                        <NumberQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.percent:
                    cards.push(
                        <PercentQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.minutes:
                    cards.push(
                        <MinutesQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.time_of_day:
                    cards.push(
                        <TimeOfDayQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.scale:
                    cards.push(
                        <ScaleQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.image:
                    cards.push(
                        <ImageQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
                case ScreeningQuestionType.scale_slider:
                    cards.push(
                        <ScaleSliderQuestionCard
                            key={question.id}
                            question={question}
                            control={control}
                            error={errorMessage}
                        />
                    )
                    break
            }
        })

    return cards
}

export default cardBuilder
