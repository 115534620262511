import React, { memo } from "react"
import { ButtonV2, ControlledSelectV2, ControlledTextInputV2, ControlledTextInputWithLabelV2 } from "components/v2"
import { useQuestionForm } from "./useQuestionForm"
import { questionTypeForms } from "./questionFormConstants"
import { BoldPlusIcon } from "components/icons"
import { ExerciseQuestionResponse } from "types/Redux"

const QuestionForm: React.FC<ExerciseQuestionResponse> = memo((props) => {
    const { type, linked_question: linkedQuestion } = props
    const {
        t,
        errors,
        linageElementTitle,
        questionTypesWithLocale,
        control,
        onClick,
        onAddVariableClick,
        onRemoveVariableClick
    } = useQuestionForm(props)

    const TypeFormComponent = questionTypeForms[type]

    const AddVariableButton = () => (
        <ButtonV2 text={t("addVariable")} variant="transparent" icon={<BoldPlusIcon />} onClick={onAddVariableClick} />
    )

    return (
        <div onClick={onClick} className="p-10">
            <div className="w-56">
                <ControlledSelectV2
                    control={control}
                    name="type"
                    label={t("questionType")}
                    options={questionTypesWithLocale}
                />
            </div>
            <div className="mt-4">
                {linkedQuestion ? (
                    <ControlledTextInputWithLabelV2
                        control={control}
                        prevName="question"
                        nextName="post_question"
                        label={t("question")}
                        placeholder={t("question")}
                        error={errors?.question?.message ?? errors?.post_question?.message}
                        pillLabels={[linageElementTitle]}
                        onLabelClick={onRemoveVariableClick}
                        rightLabelComponent={<AddVariableButton />}
                    />
                ) : (
                    <ControlledTextInputV2
                        classList={{ input: !!linkedQuestion ? "!pr-40" : "" }}
                        control={control}
                        name="question"
                        label={t("question")}
                        placeholder={t("question")}
                        error={errors?.question?.message}
                        rightLabelComponent={<AddVariableButton />}
                    />
                )}
            </div>
            <TypeFormComponent control={control} name="definition" />
        </div>
    )
})

export default QuestionForm
