import { SVGProps } from "react"

const Square3Stack = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clip-path="url(#clip0_3554_17459)">
            <path
                d="M4.28571 6.5L1.5 8L4.28571 9.5M4.28571 6.5L8 8.5L11.7143 6.5M4.28571 6.5L1.5 5L8 1.5L14.5 5L11.7143 6.5M11.7143 6.5L14.5 8L11.7143 9.5M11.7143 9.5L14.5 11L8 14.5L1.5 11L4.28571 9.5M11.7143 9.5L8 11.5L4.28571 9.5"
                stroke="#6B7280"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_3554_17459">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default Square3Stack
