import React, { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react"
import { classNames } from "utils"
import { colors } from "./tagV2Constants"

interface ITagV2Props extends PropsWithChildren<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> {
    icon?: React.ReactNode
    text?: string | number
    color?: string
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const TagV2 = ({ color, className, onClick, icon, text, ...props }: ITagV2Props) => {
    return (
        <div
            {...props}
            className={classNames(
                colors[color],
                "flex justify-center items-center rounded-sm w-[30px] h-[30px]",
                className
            )}
            onClick={onClick}
        >
            {icon ?? text}
        </div>
    )
}

export default TagV2
