import { UserPreferences } from "@prisma/client"
import {
    CompleteExercise,
    DiagnosisListItem,
    EventHandlePolicy,
    PatientWithDiagnoses,
    QuestionnaireListItem
} from "@sequel-care/types"
import { EventConditionData } from "@sequel-care/types/Event"
import { addDays, addMonths, addWeeks, getDate, isAfter } from "date-fns"
import { i18n, TFunction } from "i18next"
import { groupBy, last, pick } from "lodash"
import { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { EventTemplate, RecurrenceUnit } from "types/Misc"
import { classNames } from "utils"
import { DateString, getDateAtHour0, localeFormat } from "utils/dates"
import { EventConditionState, EventConfigState } from "./EventConfig/types"
import { DateData, EventForGantt, GanttItem } from "./types"
import { CarePlanEventTypes } from "components/Settings/utils"

export const COLORS = {
    borderBlue: "#DEE5F2",
    textBlue: "#5B7EB1",
    borderBlueDarker: "#B7C6E1",
    sidebar: "#FAFAFA"
}

export const getTimelineForGantt = (startDate: Date, endDate: Date, i18n: i18n) => {
    const dates: DateData[] = []
    const months: { dateIndex: number; name: string }[] = []

    for (let date = startDate; !isAfter(date, endDate); date = addDays(date, 1)) {
        const dateData: DateData = { date: DateString.from(date), formattedDate: localeFormat(date, "E d", i18n) }
        if (months.length === 0 || getDate(date) === 1)
            months.push({ dateIndex: dates.length, name: localeFormat(date, "MMMM yyyy", i18n) })

        dates.push(dateData)
    }

    return { months, dates }
}

export const populateGantt = (dates: DateData[], events: EventForGantt[]) => {
    const rows: GanttItem[][] = []
    const [{ date: firstDate }] = dates

    events = events
        .filter(({ date_on_timeline }) => {
            return date_on_timeline === firstDate || !DateString.isBefore(date_on_timeline, firstDate)
        })
        .sort(({ date_on_timeline: dateA }, { date_on_timeline: dateB }) => {
            return DateString.isBefore(dateA, dateB) ? -1 : 1
        })

    const groupedEvents = Object.values(
        groupBy(events, (event) => {
            if (event.id === 0) return `current${event.questionnaire_id}`
            if (event.questionnaire_id) return `questionnaire${event.questionnaire_id}`
            else return `id${event.id}`
        })
    )

    groupedEvents
        .filter((events) => events.length === 1)
        .forEach((events) => {
            events.forEach((event) => {
                const startIndex = DateString.differenceInDays(event.date_on_timeline, firstDate)
                const newItem = { startIndex, length: event.expires_within ?? 1, event }

                const rowData = { index: null as number, hasSimilarEvent: false }
                rows.forEach((row, index) => {
                    const lastEvent = last(row).event
                    const diffFromLastEvent =
                        DateString.differenceInDays(event.date_on_timeline, lastEvent.date_on_timeline) -
                        lastEvent.expires_within
                    const hasSimilarEvent = row.some(({ event: { id, questionnaire_id } }) => {
                        return id === event.id || (questionnaire_id && questionnaire_id === event.questionnaire_id)
                    })

                    if (
                        diffFromLastEvent >= 0 &&
                        (rowData.index === null || (!rowData.hasSimilarEvent && hasSimilarEvent))
                    ) {
                        rowData.index = index
                        rowData.hasSimilarEvent = hasSimilarEvent
                    }
                })

                if (rowData.index !== null) rows[rowData.index].push(newItem)
                else rows.push([newItem])
            })
        })

    return groupedEvents
        .filter((events) => events.length > 1)
        .map((events) => {
            return events.map<GanttItem>((event) => {
                const startIndex = DateString.differenceInDays(event.date_on_timeline, firstDate)
                return { startIndex, length: event.expires_within ?? 1, event }
            })
        })
        .concat(rows)
}

export const calculateCurrentEvents = (events: EventConfigState[], t: TFunction, rangeEnd: Date) => {
    const currentEvents: EventForGantt[] = []
    const addToCurrentEvents = (date: Date, config: EventConfigState) => {
        currentEvents.push({
            date_on_timeline: DateString.from(date),
            title: config.title || t("untitled", { type: t(`eventType.${config.type}`) }),
            id: config.id,
            expires_within: config.available_for,
            questionnaire_id: config?.questionnaire_id,
            type: config.type,
            isHighlighted: true
        })
    }

    events.forEach((event) => {
        if (event.recurrence?.enabled) {
            let currentDate = event.time.date_on_timeline
            if (!event.recurrence.interval) return
            while (!isAfter(currentDate, rangeEnd)) {
                addToCurrentEvents(currentDate, event)
                currentDate =
                    event.recurrence.unit === "weeks"
                        ? addWeeks(currentDate, event.recurrence.interval)
                        : addDays(currentDate, event.recurrence.interval)
            }
        } else {
            addToCurrentEvents(event.time.date_on_timeline, event)
        }
    })

    return currentEvents
}

export const generateGanttSkeleton = (
    skeletons: { count: number; interval: number; length: number }[]
): GanttItem[][] => {
    return skeletons.map(({ count, interval, length }) =>
        new Array(count).fill(true).map((_, index) => {
            return { startIndex: index * interval, length, skeleton: true }
        })
    )
}

export const getInitialConfigState = (
    i18n: i18n,
    {
        eventTemplate,
        editPolicy,
        date
    }: { eventTemplate?: EventTemplate; editPolicy?: EventHandlePolicy; date?: string }
): EventConfigState => {
    const { type, recurrence, date_on_timeline, end_time, is_absolute, invitees } = eventTemplate
    const dateString =
        editPolicy === "single" || !editPolicy ? date ?? date_on_timeline : recurrence?.start ?? DateString.today()
    const start = is_absolute ? getDateAtHour0(dateString) : new Date(dateString)

    const interval = recurrence?.interval ?? 7
    const isWeeks = !(interval % 7)

    const eventConfig: EventConfigState = {
        ...pick(eventTemplate, [
            "id",
            "questionnaire_id",
            "exercise_id",
            "title",
            "description",
            "collaborators",
            "link",
            "location"
        ]),
        type,
        assignee: eventTemplate.assignee_id,
        time: {
            isTimeless: type === "appointment" ? false : Boolean(is_absolute),
            date_on_timeline: start,
            start: type === "appointment" ? localeFormat(start, "HH:mm", i18n) : undefined,
            end: end_time ? localeFormat(new Date(end_time), "HH:mm", i18n) : undefined
        },
        available_for: eventTemplate?.expires_within,
        recurrence: {
            enabled: Boolean(eventTemplate.recurrence) && editPolicy !== "single",
            end: recurrence?.end ? getDateAtHour0(recurrence.end) : addMonths(start, 3),
            interval: isWeeks ? interval / 7 : interval,
            unit: Boolean(eventTemplate.recurrence) ? ((isWeeks ? "weeks" : "days") as RecurrenceUnit) : undefined
        },
        goal: eventTemplate?.goal_id,
        original_date: eventTemplate?.date_on_timeline,
        invitees: invitees?.map(({ id }) => id),
        duration: eventTemplate?.duration?.toString(),
        linked_events:
            eventTemplate.linked_events?.map<EventConditionState>((event: EventConditionData) => ({
                ...event,
                condition_type: event.type,
                title: event.event.title,
                type: event.event.type
            })) ?? [],
        attachment: eventTemplate?.attachment
            ? {
                  id: eventTemplate?.attachment.id,
                  original_name: eventTemplate.attachment.original_name
              }
            : null
    }

    return eventConfig
}

export const diagnosesMatchPatient = (
    diagnoses: DiagnosisListItem[],
    { primary_diagnosis, comorbidities }: Pick<PatientWithDiagnoses, "primary_diagnosis" | "comorbidities">,
    includeDefaultBattery = true
) => {
    const findDiagnosis = (diagnosisId: number) => diagnoses.find(({ id }) => id === diagnosisId)
    const isInDefaultBattery = includeDefaultBattery && findDiagnosis(286) // TODO: Replace with a better permanent solution
    return isInDefaultBattery || findDiagnosis(primary_diagnosis) || comorbidities.some(findDiagnosis)
}

export const eventToTemplate = (
    type: CarePlanEventTypes,
    template: (QuestionnaireListItem | Partial<CompleteExercise>) & { week_day?: number; expires_within?: number }
) => {
    return {
        type,
        [`${type}_id`]: template.id,
        title: template.title,
        recurrence: {
            interval: template.recommended_recurrence,
            week_day: template.week_day
        },
        description: "professional_description" in template ? template.professional_description : template.description,
        week_day: template.week_day,
        expires_within: template.expires_within,
        is_absolute: true
    }
}

export const formatDate = (date: Date, preferences: UserPreferences, translator: i18n) => {
    return localeFormat(date, preferences?.date_format === "d/m/y" ? "dd/MM/yyy" : "MM/dd/yyy", translator)
}

export const formControlClassNames =
    "py-1.5 px-2 border border-border-blue rounded-md text-dark-blue focus:ring-0 focus:border-text-blue focus:shadow-sm"

interface NumericFieldProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    value: number
    setValue: (n: number) => void
    additionalClassNames?: string
}

export const NumericField = ({ value, setValue, additionalClassNames, ...props }: NumericFieldProps) => {
    return (
        <input
            className={classNames(formControlClassNames, "w-20", additionalClassNames)}
            type="number"
            value={value}
            {...props}
            onChange={(e) => {
                const value = parseInt(e.target.value)
                setValue(value >= 1 ? value : 1)
            }}
        />
    )
}
