import { SVGProps } from "react"

const ArchiveIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11 5.5V4C11 3.17157 10.3284 2.5 9.5 2.5H4C3.17157 2.5 2.5 3.17157 2.5 4V9.5C2.5 10.3284 3.17157 11 4 11H5.5M11 5.5H12C12.8284 5.5 13.5 6.17157 13.5 7V12C13.5 12.8284 12.8284 13.5 12 13.5H7C6.17157 13.5 5.5 12.8284 5.5 12V11M11 5.5H7C6.17157 5.5 5.5 6.17157 5.5 7V11"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)
export default ArchiveIcon
