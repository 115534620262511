import { ClockIcon } from "@heroicons/react/24/outline"
import { Menu } from "components/common"
import { classNames } from "utils"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { useUnread } from "store/hooks"
import { getNotificationsInitial, setNotificationsLoading } from "store/notifications/actions"
import { AdditionalNotifications, CombinedNotificationCategories, NotificationCategories } from "types/Misc"
import { InfiniteList } from "./InfiniteList"
import { useDispatch } from "store"
import { useDir } from "hooks/useDir"
import Badge from "components/common/Badge"
import { useMessageNotifications } from "../../utils/conversations/hooks"
import { MessageNotificationList } from "./MessageNotificationList"

const getBadgeContainerWidth = (count: number) => `${count ? (2.5 + 6) / 4 : 0}rem`

const Tab = ({
    category,
    selectedCategory,
    t,
    setSelectedCategory,
    unreadCount
}: {
    selectedCategory: CombinedNotificationCategories
    category: CombinedNotificationCategories
    t: TFunction
    setSelectedCategory: (category: CombinedNotificationCategories) => void
    unreadCount?: number
}) => {
    const [badgeContainerWidth, setBadgeContainerWidth] = useState(getBadgeContainerWidth(unreadCount))
    useEffect(() => {
        const newWidth = getBadgeContainerWidth(unreadCount)
        if (newWidth !== badgeContainerWidth) setBadgeContainerWidth(newWidth)
    }, [unreadCount])

    return (
        <div
            className={classNames(
                selectedCategory === category ? "text-dark-blue border-b-2 border-dark-blue -mb-0.5" : "text-text-blue",
                "relative flex flex-row align-items-center px-2"
            )}
        >
            <button
                onClick={() => setSelectedCategory(category)}
                className="font-semibold cursor-pointer pt-4 pb-1.5 flex flex-row items-center gap-2"
            >
                {t(`notificationCategories.${category}`)}
            </button>

            {typeof unreadCount !== "undefined" && (
                <div
                    className="flex justify-end transition-all duration-300 overflow-hidden"
                    style={{ width: badgeContainerWidth, opacity: unreadCount ? 1 : 0 }}
                >
                    <Badge size="small" displayNumber={unreadCount} wrapperClassNames="translate-y-4" visible={true} />
                </div>
            )}
        </div>
    )
}

const NotificationsDisplay = () => {
    const dispatch = useDispatch()
    const [selectedCategory, setSelectedCategory] = useState<CombinedNotificationCategories>(NotificationCategories.All)
    const unread = useUnread()
    const dir = useDir()
    const { t } = useTranslation("patient")
    const messageNotifications = useMessageNotifications()

    useEffect(() => {
        const getNotifications = async () => {
            dispatch(setNotificationsLoading(true))
            await dispatch(getNotificationsInitial(50))
            dispatch(setNotificationsLoading(false))
        }
        getNotifications()
        const pollingInterval = setInterval(() => {
            dispatch(getNotificationsInitial(50))
        }, 60000)
        return () => {
            clearInterval(pollingInterval)
        }
    }, [])

    const unreadMessagesNumber = unread["all"].length + messageNotifications.length

    return (
        <Menu placement="right-start">
            <Menu.Button>
                {({ isOpen }) => <PopoverButton open={isOpen} unreadMessagesNumber={unreadMessagesNumber} />}
            </Menu.Button>
            <Menu.Panel className="z-100 w-150 pt-2 text-black bg-white rounded-lg shadow-md border border-blue">
                <div dir={dir}>
                    <div className="flex flex-row align-items-center list-none border-b-2 border-border-blue px-3 h-12 gap-6">
                        {Object.values(NotificationCategories).map((category, index) => (
                            <Tab
                                key={index}
                                unreadCount={category !== "all" ? unread[category].length : undefined}
                                category={category}
                                {...{ selectedCategory, t, setSelectedCategory }}
                            />
                        ))}
                        <Tab
                            category={"conversations" as AdditionalNotifications}
                            unreadCount={messageNotifications.length}
                            {...{ selectedCategory, t, setSelectedCategory }}
                        />
                    </div>
                    {Object.values(NotificationCategories).includes(selectedCategory as NotificationCategories) && (
                        <InfiniteList category={selectedCategory as NotificationCategories} />
                    )}
                    {selectedCategory === "conversations" && (
                        <MessageNotificationList messageNotifications={messageNotifications} />
                    )}
                </div>
            </Menu.Panel>
        </Menu>
    )
}

export default NotificationsDisplay

const PopoverButton = ({ unreadMessagesNumber, open }: { unreadMessagesNumber: number; open: boolean }) => {
    const { t } = useTranslation("user")

    return (
        <div
            className={classNames(
                open && "bg-white/10",
                "flex items-center gap-x-2.5 py-2 px-6 w-full cursor-pointer hover:bg-white/10"
            )}
        >
            <ClockIcon className="ml-0.5 w-5 -translate-x-0.5" />
            {t("notifications.panelButton")}
            <Badge
                visible={unreadMessagesNumber !== 0}
                displayNumber={unreadMessagesNumber}
                textColor="text-dark-blue"
            />
        </div>
    )
}
