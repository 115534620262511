import { Dispatch, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import Search from "components/Search"
import RenderCards from "./RenderCards"
import { CardData, CategoryObj, LibrarySort, RenderLibraryProps } from "./types"

import { Language, UserRole } from "@prisma/client"
import { useCurrentRole } from "store/hooks"
import RenderHeader from "./RenderHeader"
import RenderSearchResults from "./RenderSearchResults"

const stringsMatch = (strA: string, strB: string) => strA.toLocaleLowerCase().includes(strB.toLocaleLowerCase())

export function RenderLibrary<ItemType extends CardData, CategoryType extends CategoryObj>({
    type,
    allItems,
    cardItems,
    categories,
    search,
    setSearch,
    RenderBreadCrumb,
    ...cardProps
}: RenderLibraryProps<ItemType, CategoryType> & { search: string; setSearch: Dispatch<string> }) {
    const { t } = useTranslation("patient")
    const currentRole = useCurrentRole()
    const [isSearching, setIsSearching] = useState(Boolean(search))

    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        ref.current?.scrollTo({ top: 0 })
    }, [categories?.currentCategory, isSearching])

    const searchRef = useRef<HTMLInputElement>(null)

    const [language, setLanguage] = useState<Language>(
        (currentRole.organization.available_languages?.[0] as Language) ?? "en"
    )
    const [role, setRole] = useState<UserRole>("patient")
    const [sort, setSort] = useState<LibrarySort>(LibrarySort.LAST_ADD)

    const sortedCardItem = useMemo(
        () =>
            type === "questionnaire"
                ? cardItems
                      .sort((a, b) => {
                          if (sort === LibrarySort.LAST_ADD) {
                              return b.id - a.id
                          }
                          if (sort === LibrarySort.AZ) return a.title.localeCompare(b.title)
                      })
                      .sort((a, b) => {
                          if (a.type === b.type) return 0

                          return a.type === "care_plan" ? -1 : 1
                      })
                : cardItems,
        [sort, language, role, cardItems, type]
    )

    return (
        <div className="p-16 pt-0 overflow-y-scroll grow h-full flex flex-col" ref={ref}>
            <div
                className="pt-16 pb-4 sticky top-0 z-100"
                style={{ background: "linear-gradient(#fff, #fff 92%, transparent)" }}
            >
                <Search
                    wrapperClassname="border border-border-blue focus-within:border-dark-blue rounded mb-2.5"
                    onFocus={() => setIsSearching(true)}
                    onBlur={() => setIsSearching(false)}
                    ref={searchRef}
                    {...{ search, setSearch }}
                />
                {!isSearching && (
                    <RenderHeader
                        {...{
                            role,
                            setRole,
                            language,
                            setLanguage,
                            RenderBreadCrumb,
                            type,
                            categories,
                            sort,
                            setSort,
                            t
                        }}
                    />
                )}
            </div>
            {!isSearching ? (
                <RenderCards
                    currentCategory={categories?.currentCategory.id.toString()}
                    {...{ type, cardItems: sortedCardItem, role, language }}
                    {...cardProps}
                />
            ) : (
                <RenderSearchResults
                    matchingCategories={
                        search
                            ? categories?.list.filter((category) => stringsMatch(category.name, search))
                            : categories?.list
                    }
                    matchingItems={allItems.filter((item) => {
                        const rolePredicate = item.type === "questionnaire" ? item.target_role !== role : false
                        if (item.language !== language || rolePredicate || item.is_deleted) return false

                        return !search || stringsMatch(item.title, search) || stringsMatch(item.description, search)
                    })}
                    onAdd={cardProps.onAdd}
                    {...{ categories, t, type, searchRef, search, setSearch }}
                />
            )}
        </div>
    )
}
