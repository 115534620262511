import { useDispatch } from "store"
import { ArchiveIcon, CopyIcon, EditUnitDetailsIcon } from "components/icons"
import { makeExerciseArchivedAction, makeExerciseCopyAction } from "store/exercises/actions"
import { useMemo } from "react"
import { ExerciseDefResponse } from "types/Redux"
import { openExerciseBuilderModal } from "store/modal/actions"
import { useTranslation } from "react-i18next"
import { useCurrentLanguage } from "store/hooks"
import { Language } from "@prisma/client"

export const useExerciseItem = (exerciseDef: ExerciseDefResponse) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("exercise")
    const language = useCurrentLanguage()
    const { id, diagnoses } = exerciseDef

    const mappedDiagnosesForBagesList = useMemo(
        () => diagnoses.map(({ diagnose: { name_en, id } }) => ({ id, title: name_en })),
        [diagnoses]
    )

    const isHeLanguage = language === Language.he

    const openExerciseBuilder = () => {
        dispatch(openExerciseBuilderModal(exerciseDef))
    }

    const onMakeACopyClick = () => {
        dispatch(makeExerciseCopyAction(id))
    }

    const dropDownActionsList = [
        {
            title: t("editExerciseDetails"),
            icon: EditUnitDetailsIcon,
            onClick: openExerciseBuilder
        },
        {
            title: t("makeACopy"),
            icon: CopyIcon,
            onClick: onMakeACopyClick
        },
        {
            border: true,
            title: t("archive"),
            icon: ArchiveIcon,
            onClick: () => {
                dispatch(makeExerciseArchivedAction(id))
            }
        }
    ]

    return {
        t,
        diagnoses,
        isHeLanguage,
        mappedDiagnosesForBagesList,
        dropDownActionsList,
        openExerciseBuilder
    }
}
