import { Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { Message } from "@twilio/conversations"
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import { VariableSizeList } from "react-window"
import { useCurrentUser, usePatientFromList } from "store/hooks"
import { getTextHeight } from "utils"
import { ConversationStore, MessageDisplayItem, SequelConversation } from "utils/conversations"
import { SearchProps } from "."
import { isMessageDeleted } from "../../../utils/conversations/types"
import RenderItem from "./RenderItem"
import { getListData, loaderHeight } from "./utils"

type RenderListProps = ReturnType<typeof getListData> & {
    height: number
    className?: string
    containerRef: MutableRefObject<HTMLDivElement>
    conversation: SequelConversation
    messagesRef: MutableRefObject<VariableSizeList>
    isGroupChat: boolean
    search: SearchProps
}

const RenderList = ({
    height,
    className,
    containerRef,
    conversation,
    messagesRef,
    itemCount,
    listOffset,
    isGroupChat,
    search
}: RenderListProps) => {
    const currentUser = useCurrentUser()
    const currentPatient = usePatientFromList(conversation.patientId)
    const [imgMap, setImgMap] = useState<Map<number, string>>(new Map())

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.resetAfterIndex(0)
            messagesRef.current.scrollToItem(itemCount)
        }
    }, [containerRef.current])

    useEffect(() => {
        if (conversation && conversation.messageCount.unread > 0) conversation.updateMessageCount(true)
        else if (showBackDown) setShowBackDown(false)
    }, [conversation])

    const getCurrentHeight = (conversation: SequelConversation) => {
        const currentHeight =
            conversation?.messageDisplayList.reduce((acc, item, index) => {
                return acc + heightFromItem(index, item)
            }, 0) ?? 0

        return currentHeight + (getListData(conversation).listOffset ? loaderHeight : 0)
    }

    const heightFromItem = useCallback((index: number, item: MessageDisplayItem) => {
        if (!item) return loaderHeight
        if ("header" in item) return 38 + 24 + (index > 0 ? 12 : 0)

        const authorUserId = ConversationStore.getUserIdFromIdentity(item.author)
        const currentUserIsAuthor = authorUserId === currentUser?.id

        if (isMessageDeleted(item)) return 0

        return (
            getTextHeight(
                (item as Message).body,
                (containerRef.current?.offsetWidth ?? 0) - 48 /* padding */ - 8 /* scrollbar? */ - 20 /* padding */ - 40
            ) +
            (currentUserIsAuthor || !isGroupChat ? 0 : 26) /* for the name in group chat*/ +
            (item instanceof Message && (item as Message)?.attachedMedia?.length > 0 ? 160 : 0) /* for the image */ +
            20 /*padding*/ +
            12 /* margin */ +
            20 /* date row */ +
            8
        ) /* for gap */
    }, [])

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.resetAfterIndex(0)
            messagesRef.current.scrollToItem(itemCount)
        }
    }, [containerRef.current])

    useEffect(() => {
        if (messagesRef.current) {
            let itemHeight = 0,
                isFound = false
            for (let i = 0; i < conversation.messageDisplayList.length; i++) {
                const index = i - listOffset
                const message = conversation.messageDisplayList[index]
                if (index === search.foundIdxs[search.selected]) {
                    isFound = true
                }

                if (!isFound) itemHeight += heightFromItem(i, message)
            }

            messagesRef.current.scrollTo(itemHeight)
        }
    }, [messagesRef, search])

    const isLoading = useRef(false)
    const [showBackDown, setShowBackDown] = useState(false)
    const updateShowBackDown = (newValue: boolean) => {
        if (newValue === showBackDown) return

        const updateShowBackDownTimeout = setTimeout(() => setShowBackDown(newValue), newValue ? 150 : 0)
        return () => clearTimeout(updateShowBackDownTimeout)
    }

    const itemSize = useCallback(
        (index: number) => {
            if (!containerRef.current) return 0
            const item = conversation.messageDisplayList[index - listOffset]
            return heightFromItem(index, item)
        },
        [listOffset]
    )

    const onItemsRendered: VariableSizeList["props"]["onItemsRendered"] = async ({
        overscanStartIndex,
        visibleStopIndex
    }) => {
        updateShowBackDown(visibleStopIndex < itemCount - 1)
        if (!conversation.hasMoreMessages || !messagesRef.current || isLoading.current || overscanStartIndex > 10)
            return

        isLoading.current = true

        const scrollOffsetFromEnd = getCurrentHeight(conversation) - (messagesRef.current.state as any).scrollOffset
        await conversation.loadMoreMessages()

        messagesRef.current.scrollTo(getCurrentHeight(conversation) - scrollOffsetFromEnd)
        messagesRef.current.resetAfterIndex(0)
        isLoading.current = false
    }

    const onItemShouldBeDeleted = async (item: MessageDisplayItem) => {
        if (!(item instanceof Message)) return
        await conversation.deleteMessage(item)
    }
    return (
        <>
            <Transition
                show={showBackDown}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                enter="transition ease-in duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
            >
                <span
                    className="z-10 absolute bottom-28 right-7 shadow-md text-white bg-dark-blue rounded-full p-2 cursor-pointer hover:brightness-125"
                    onClick={() => messagesRef.current?.scrollTo(200000)}
                >
                    <ChevronDownIcon className="w-4" />
                </span>
            </Transition>
            {/* @ts-ignore */}
            <VariableSizeList
                itemCount={itemCount}
                height={height ?? 600}
                width={"100%"}
                itemSize={itemSize}
                className={className}
                ref={messagesRef}
                overscanCount={10}
                onItemsRendered={onItemsRendered}
            >
                {({ index, style }) => (
                    <RenderItem
                        index={index - listOffset}
                        onDelete={onItemShouldBeDeleted}
                        className={search.foundIdxs.includes(index - listOffset) ? "bg-bg" : undefined}
                        {...{ style, currentUser, conversation, isGroupChat, currentPatient }}
                    />
                )}
            </VariableSizeList>
        </>
    )
}

export default RenderList
