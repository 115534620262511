import { CalendarDate } from "@internationalized/date"
import { Gender, OrgRole, Profession, Specialty } from "@prisma/client"
import { UserCreateInput } from "@sequel-care/types"
import { TeamMemberCreateInput } from "@sequel-care/types/User"
import { differenceInYears, getYear, setYear } from "date-fns"
import { defineField, Field, initialValuesFromFields, PhoneField } from "forms"
import { DateField } from "forms/DateField"
import { defineStep } from "forms/FormWithSteps"
import SuperSelect from "forms/SuperSelect"
import { getItemsFromEnum } from "forms/SuperSelect/utils"
import { GenderSelect } from "forms/SuperSelect/wrappers"
import { ClassList } from "forms/types"
import { useMemo } from "react"
import { DateValue } from "react-aria"
import { useTranslation } from "react-i18next"
import { useCurrentRole } from "store/hooks"
import { noopTFunction } from "utils"
import { dateToCalendarDate } from "utils/dates"
import ProfilePictureUpload, { ProfileImage } from "./ProfilePictureUpload"

type UserFieldParams = { isForTherapist?: boolean; isManager?: boolean }

const getProfessionField = (t = noopTFunction, classList: ClassList = undefined) =>
    defineField({
        id: "profession",
        label: t("common:profession") as string,
        initialValue: "therapist" as Profession,
        placeholder: t("common:profession"),
        Component: SuperSelect<Profession>,
        withSearch: false,
        items: getItemsFromEnum(Profession, (profession) => t(`common:professions.${profession}`)),
        classList,
        validations: {
            required: { value: true, message: t("userForm.validationMessages.profession") }
        }
    })

export const getUserFields = (
    t = noopTFunction,
    { isForTherapist = false, isManager = false }: UserFieldParams = {}
) => {
    const userFields: Field[] = [
        defineField({
            id: "first_name",
            placeholder: t("common:firstname"),
            classList: { wrapper: "col-span-3 h-fit" },
            validations: { required: { value: true, message: t("user:userForm.validationMessages.firstName") } }
        }),
        defineField({
            id: "last_name",
            placeholder: t("common:lastname"),
            classList: { wrapper: "col-span-3 h-fit" },
            validations: { required: { value: true, message: t("user:userForm.validationMessages.lastName") } }
        }),
        defineField({
            id: "profile_image",
            initialValue: null as ProfileImage,
            classList: { wrapper: "col-span-3 row-span-4" },
            Component: ProfilePictureUpload
        }),
        defineField({
            id: "date_of_birth",
            label: t("common:dateofbirth") as string,
            Component: DateField,
            initialValue: null as CalendarDate,
            minValue: dateToCalendarDate(setYear(new Date(), getYear(new Date()) - 130)),
            maxValue: dateToCalendarDate(isForTherapist ? setYear(new Date(), getYear(new Date()) - 18) : new Date()),
            classList: {
                wrapper: "col-span-3 h-fit flex items-center gap-4",
                label: "text-dark-blue text-base font-normal whitespace-nowrap"
            },
            validations: {
                required: {
                    value: !isForTherapist,
                    message: t("user:userForm.validationMessages.dateOfBirthRequired")
                },
                custom: {
                    isValid(value) {
                        const age = differenceInYears(
                            new Date(),
                            value.toDate(Intl.DateTimeFormat().resolvedOptions().timeZone)
                        )
                        return age > 3 && age < 120
                    },
                    message: t("user:userForm.validationMessages.dateOfBirthInvalid")
                }
            }
        }),
        defineField({
            id: "gender",
            placeholder: t("common:gender"),
            Component: GenderSelect<"single">,
            initialValue: "female" as Gender,
            classList: { wrapper: "col-span-3" },
            contentWidth: "w-full",
            validations: {
                required: { value: true, message: t("user:userForm.validationMessages.gender") }
            }
        }),
        defineField({
            id: "email",
            placeholder: t("common:email"),
            classList: { wrapper: "col-span-3 h-fit" },
            validations: {
                required: { value: true, message: t("user:userForm.validationMessages.emailRequired") },
                custom: {
                    isValid: (value: string) => /^\S+@\S+\.\S+$/.test(value),
                    message: t("user:userForm.validationMessages.emailInvalid")
                }
            }
        }),
        defineField({
            id: "phone",
            placeholder: t("user:userForm.phonePlaceholder") as string,
            info: t("user:userForm.phoneInfo") as string,
            Component: PhoneField,
            classList: { wrapper: "col-span-3" },
            validations: {
                required: { value: true, message: t("user:userForm.validationMessages.phoneRequired") },
                custom: {
                    isValid: (value: string) => value.length && value.replace(/\s/gm, "").length > 2,
                    message: t("user:userForm.validationMessages.phoneInvalid")
                }
            }
        }),
        defineField({
            id: "address",
            placeholder: t("common:address"),
            classList: { wrapper: "col-span-6" }
        })
    ]

    if (isManager) userFields.push(getProfessionField(t, { wrapper: "w-64" }))
    return userFields
}

type ProfessionalFieldParams = {
    isEdit?: boolean
    role?: OrgRole
}

export const getProfessionalFields = (t = noopTFunction, params?: ProfessionalFieldParams) =>
    [
        defineField({
            id: "role",
            label: t("common:role") as string,
            placeholder: t("common:role"),
            initialValue: "caregiver",
            readOnly: params?.isEdit && params?.role !== "admin",
            Component: SuperSelect<OrgRole>,
            withSearch: false,
            items: getItemsFromEnum(OrgRole, (role) => t(`common:roles.${role}`)),
            validations: {
                required: { value: true, message: t("userForm.validationMessages.role") }
            }
        }),
        defineField({
            id: "license",
            label: t("common:license") as string,
            placeholder: t("common:license")
        }),
        defineField({
            id: "profession",
            label: t("common:profession") as string,
            placeholder: t("common:profession"),
            Component: SuperSelect<Profession>,
            withSearch: false,
            items: getItemsFromEnum(Profession, (profession) => t(`common:professions.${profession}`)),
            validations: {
                required: { value: true, message: t("userForm.validationMessages.profession") }
            }
        }),
        defineField({
            id: "specialization",
            label: t("common:specialization") as string,
            placeholder: t("common:specialization"),
            Component: SuperSelect<Specialty>,
            withSearch: false,
            items: getItemsFromEnum(Specialty, (s) => t(`common:specializations.${s}`))
        }),
        defineField({
            id: "case_load",
            initialValue: null as number,
            label: t("common:caseLoad") as string,
            placeholder: t("common:caseLoad"),
            type: "number",
            info: t("common:caseLoadInfo") as string
        })
    ] as const

export type UserFormState = {
    user: UserCreateInput<string | File, DateValue> & { profession?: Profession }
    team_member: TeamMemberCreateInput
}
export const initialState = (): UserFormState => ({
    user: initialValuesFromFields(getUserFields()) as UserFormState["user"],
    team_member: initialValuesFromFields(getProfessionalFields())
})

export const useStepList = ({ isEdit }: { isEdit: boolean }) => {
    const { t } = useTranslation("user")
    const currentRole = useCurrentRole()

    const stepList = useMemo(
        () =>
            [
                defineStep({
                    id: "user",
                    name: t("userForm.personalDetails"),
                    fields: getUserFields(t, { isForTherapist: true }),
                    fieldGridColumns: 9
                }),
                defineStep({
                    id: "team_member",
                    name: t("userForm.professionalInformation"),
                    fields: getProfessionalFields(t, { isEdit, role: currentRole.role }),
                    fieldGridColumns: 6,
                    classList: { wrapper: "col-span-3" }
                })
            ] as const,
        [t, isEdit]
    )

    return stepList
}
