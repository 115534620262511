import React from "react"
import Modal from "components/common/Modal"
import { ButtonV2, ControlledBinarySelectorV2, ControlledSelectV2, StepsV2, TypographyV2 } from "components/v2"
import { CloseIcon } from "components/icons"
import { usePublishExerciseModal } from "./usePublishExerciseModal"
import { PublishRulesDescription, SelectedExerciseCard } from "./components"
import { classNames } from "utils"

const PublishExerciseModal = () => {
    const {
        t,
        control,
        publishSteps,
        publishOptions,
        selectedExercise,
        replacedExercise,
        isPublishAndReset,
        submitBtnTitle,
        isReviewed,
        mappedExerciseForSelect,
        onClose,
        onBackClick,
        onContinueClick
    } = usePublishExerciseModal()
    return (
        <Modal show onClose={onClose} centered cardClassName="w-2/4  max-w-screen-lg">
            <div className="p-4 flex justify-between border-b border-border-blue">
                <TypographyV2 width="bold" type="base" className="text-med-black">
                    {t("common:publish")} {t("exercise")}
                </TypographyV2>
                <div className="flex items-center">
                    <div className="mr-3.5">
                        <StepsV2 viewType="row" steps={publishSteps} currentStep={isReviewed ? 2 : 1} />
                    </div>
                    <CloseIcon onClick={onClose} className="cursor-pointer text-[#9CA3AF]" height={20} width={20} />
                </div>
            </div>
            <div className="p-6 h-72 overflow-y-auto">
                {!isReviewed && (
                    <>
                        <ControlledBinarySelectorV2
                            label={t("select_publish_method")}
                            placeholder={t("select_publish_method")}
                            control={control}
                            name="status"
                            options={publishOptions}
                        />
                        {isPublishAndReset && (
                            <ControlledSelectV2
                                classList={{ wrapper: "mt-6" }}
                                placeholder={t("select_replace_exercise_placeholder")}
                                label={t("select_replace_exercise_placeholder")}
                                options={mappedExerciseForSelect}
                                control={control}
                                name="replacedExerciseId"
                            />
                        )}
                    </>
                )}
                {isReviewed && (
                    <div className="flex flex-col justify-between h-full">
                        <div>
                            <TypographyV2 width="semibold" className="text-gray-600">
                                {t("common:publish")}
                            </TypographyV2>
                            <div className="mt-2 flex gap gap-x-6">
                                <SelectedExerciseCard {...selectedExercise} />
                                {isPublishAndReset && <SelectedExerciseCard {...replacedExercise} />}
                            </div>
                        </div>
                        <PublishRulesDescription />
                    </div>
                )}
            </div>
            <div
                className={classNames(
                    "p-4 flex  border-t bg-[#F3F4F6] border-border-blue",
                    isReviewed ? "justify-between" : "justify-end"
                )}
            >
                {isReviewed && (
                    <ButtonV2
                        className="self-start"
                        variant="light-gray"
                        onClick={onBackClick}
                        text={t("common:back")}
                    />
                )}
                <div className="flex gap-2.5 justify-end">
                    <ButtonV2 variant="light-gray" onClick={onClose} text={t("common:cancel")} />
                    <ButtonV2 onClick={onContinueClick} text={submitBtnTitle} />
                </div>
            </div>
        </Modal>
    )
}

export default PublishExerciseModal
