import React from "react"
import { CardV2, TypographyV2 } from "components/v2"
import { BoldPlusIcon } from "components/icons"

interface ICreateEventTypeProps {
    eventType: string
    onClick: () => void
}

const CreateEventType: React.FC<ICreateEventTypeProps> = ({ eventType, onClick }) => (
    <CardV2 className="flex items-center cursor-pointer" onClick={onClick}>
        <div className="flex justify-center items-center w-8 h-8 bg-blue-100">
            <BoldPlusIcon />
        </div>
        <div className="ml-4">
            <TypographyV2 type="sm" width="bold">
                Create new {eventType}
            </TypographyV2>
            <TypographyV2 type="sm" width="normal">
                Build a new Educational Content from scratch.
            </TypographyV2>
        </div>
    </CardV2>
)

export default CreateEventType
