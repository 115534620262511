import { Spinner } from "components/common"
import { Profile, ProfileRow } from "components/Profile"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useDispatch } from "store"
import { useCurrentPatient, useCurrentRole, useCurrentUser } from "store/hooks"
import { openFeaturesSettingsModal, openPatientModal } from "store/modal/actions"
import { getCurrentPatient } from "store/patient/actions"
import { goToThePrevSidebar } from "store/sidebars/actions"
import { UserStatusType } from "@aws-sdk/client-cognito-identity-provider"
import { PatientSidebar } from "types/AppSidebar"
import { fetchPatientResetPassword, sendEmailWithDetails } from "../../api"
import { openChatFromUserProfile } from "../../components/Profile/utils"
import { useConversations } from "../../utils/conversations"
import { firstFieldBlock, fourthFieldBlock, secondFieldBlock, thirdFieldBlock } from "./fields"
import RenderContacts from "./RenderContacts"
import { TabsHeaderV2 } from "components/v2"
import { IntakeResponse, RenderProfileBlock } from "./components/index"
import { FeatureFlags } from "utils/FeatureFlags"

const PatientProfile = ({ sidebar }: { sidebar: PatientSidebar }) => {
    const { t } = useTranslation()
    const patient = useCurrentPatient()
    const dispatch = useDispatch()
    const currentRole = useCurrentRole()
    const currentUser = useCurrentUser()
    const conversations = useConversations(patient?.id)

    const intakeEnabled = FeatureFlags.isEnabled("screening-intake")

    const tabItems = useMemo(
        () => [
            { key: "personal", title: t("patient:sidebar.personal") },
            { key: "clinical", title: t("patient:sidebar.clinical") },
            ...(intakeEnabled ? [{ key: "intake", title: t("patient:sidebar.intake") }] : [])
        ],
        [intakeEnabled]
    )

    const { candidate } = sidebar

    const [activeTab, setActiveTab] = useState<"personal" | "clinical" | "intake">("personal")

    useEffect(() => {
        if (!patient) {
            dispatch(getCurrentPatient(sidebar.patientId))
        }
    }, [sidebar])

    const onActiveTabClick = useCallback((key: string) => {
        setActiveTab(key as any)
        return () => {}
    }, [])

    const caseManager = patient?.collaborators.find((c) => c.permissions.some((p) => p.permission === "case_manager"))
    const isCurrentUserCaseManager = caseManager?.user?.id === currentUser.id

    const handleEditProfile = () => {
        dispatch(openPatientModal({ patientFile: patient }))
    }

    const isSentPasswordEnable = patient?.cognitoUserStatus === UserStatusType.FORCE_CHANGE_PASSWORD

    const [isMailSending, setIsMailSending] = useState(false)
    const handleSendMessage = async () => {
        try {
            setIsMailSending(true)
            if (isSentPasswordEnable) {
                await sendEmailWithDetails(patient.id)
            } else {
                await fetchPatientResetPassword(patient.id)
            }
            toast(t(isSentPasswordEnable ? "patient:whatNext.emailIsSent" : "patient:whatNext.passwordWasReset"), {
                type: "success"
            })
        } catch (error) {
            console.error(error)
            toast(t("common:oopsWentWrong"), { type: "error" })
        } finally {
            setIsMailSending(false)
        }
    }

    const handleOpenChat = () => {
        dispatch(openChatFromUserProfile(conversations, patient.user))
    }

    if (!patient)
        return (
            <div className="flex justify-center my-40">
                <Spinner size={25} className="text-med-blue w-9" />
            </div>
        )

    const showInsuranceDetails = patient.healthcare_provider || patient.ssn

    return (
        <Profile
            user={patient.user}
            role="patient"
            onProfileEdit={handleEditProfile}
            isEditable={currentRole.role === "admin" || isCurrentUserCaseManager}
            onSidebarGoBack={() => {
                dispatch(goToThePrevSidebar())
            }}
            onOpenChat={handleOpenChat}
            onOpenSettings={() => {
                dispatch(openFeaturesSettingsModal({ patient_id: patient.id }))
            }}
            onSendMail={handleSendMessage}
            isSendMailEnabled={!isMailSending}
            isPatient={true}
            isChatAvailable={true}
            patientName={patient.user.first_name}
        >
            <div className="-mx-6 flex [&>div]:!border-b-0 items-center justify-center border-b border-border-blue">
                <TabsHeaderV2 tabItems={tabItems} activeTab={activeTab} onActiveTabClick={onActiveTabClick} />
            </div>
            {activeTab === "personal" && (
                <RenderProfileBlock id="patientDetails" noDivider fields={firstFieldBlock} entity={patient.user}>
                    <ProfileRow
                        fieldId="patientForm.familyOr"
                        label={"patient:patientForm.familyOr"}
                        value={patient.contacts}
                        RenderValue={RenderContacts}
                    />
                </RenderProfileBlock>
            )}
            {activeTab === "clinical" && (
                <>
                    <RenderProfileBlock id="treatmentDetails" fields={secondFieldBlock} entity={patient} />
                    <RenderProfileBlock
                        id="clinicalInfo"
                        fields={thirdFieldBlock}
                        entity={patient}
                        noDivider={!showInsuranceDetails}
                    />
                </>
            )}
            {activeTab === "intake" && candidate?.id && <IntakeResponse candidate={candidate} />}
            {(patient.healthcare_provider || patient.ssn) && (
                <RenderProfileBlock id="insuranceDetails" fields={fourthFieldBlock} entity={patient} noDivider />
            )}
        </Profile>
    )
}

export default PatientProfile
