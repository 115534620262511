import React from "react"
import { classNames } from "utils"
import { RenderLabel } from "./RenderLabel"
import { FormControlProps } from "./types"
import { errorClassNames } from "./utils"

const Checkbox = ({ id, value, setValue, classList, label, error, info }: FormControlProps<boolean>) => {
    return (
        <div className={classNames(classList?.wrapper, "relative flex items-center")}>
            <input
                type="checkbox"
                onChange={() => setValue((v) => !v)}
                className={classNames(
                    "focus:ring-0 focus:ring-offset-0 rounded",
                    !value ? "border-gray-300" : "",
                    classList?.input
                )}
                checked={value}
            />
            {label && <RenderLabel id={`checkbox_${id}`} label={label} classList={classList} info={info} />}
            {error && <div className={classNames(errorClassNames)}>{error}</div>}
        </div>
    )
}

export default Checkbox
