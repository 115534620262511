import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { closeModal } from "store/modal/actions"
import { useForm, useWatch } from "react-hook-form"
import { PublishStatuses } from "components/v2/types"
import { useExerciseSelector } from "store/hooks"
import { RootState } from "types/Redux"
import { formatDate } from "utils"
import { yupResolver } from "@hookform/resolvers/yup"
import { getPublishExerciseSchema, getPublishOptions, getPublishSteps } from "./publishExerciseModalConstants"
import { makeExercisePublishedAction } from "store/exercises/actions"
import { useIsHeLanguage } from "../../../../../../hooks"
import { useDispatch } from "store"

export const usePublishExerciseModal = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation("exercise")

    const modal = useSelector((state: RootState) => state.modal)

    const [isReviewed, setIsReviewed] = useState(false)

    const { list } = useExerciseSelector()

    const { isHeLanguage } = useIsHeLanguage()

    const publishSteps = useMemo(() => getPublishSteps(), [isHeLanguage])
    const publishOptions = useMemo(() => getPublishOptions(), [isHeLanguage])

    const selectedExercise = modal.params

    const exerciseId = selectedExercise.id

    const mappedExerciseForSelect = useMemo(
        () =>
            list
                .filter((item) => item.id !== exerciseId)
                .map(({ title, id, created_at }) => ({
                    title,
                    id,
                    subtitle: `${t("common:create")} ${formatDate(created_at)}`
                })),
        [list, exerciseId]
    )

    const { control } = useForm({
        defaultValues: {
            status: PublishStatuses.Publish,
            replacedExerciseId: null
        },
        resolver: yupResolver(getPublishExerciseSchema())
    })

    const formValues = useWatch({
        control
    })

    const activeStatus = formValues.status
    const replacedExerciseId = formValues.replacedExerciseId

    const isPublishAndReset = activeStatus === PublishStatuses.PublishAndReset

    const submitBtnTitle = t(
        isReviewed ? (isPublishAndReset ? t("publish_and_replace_exercise") : "common:publish") : "common:continue"
    )

    const replacedExercise = useMemo(() => list.find(({ id }) => id === replacedExerciseId), [replacedExerciseId, list])

    const onClose = () => {
        dispatch(closeModal())
    }

    const onContinueClick = () => {
        if (!isReviewed) {
            setIsReviewed(true)
            return
        }
        dispatch(makeExercisePublishedAction(exerciseId))
    }

    const onBackClick = () => {
        setIsReviewed(false)
    }

    return {
        t,
        control,
        publishSteps,
        publishOptions,
        selectedExercise,
        replacedExercise,
        isPublishAndReset,
        submitBtnTitle,
        isReviewed,
        mappedExerciseForSelect,
        onClose,
        onBackClick,
        onContinueClick
    }
}
