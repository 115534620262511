import { DateSegment } from "@react-stately/datepicker"

export const useGetDateFieldSegments = (segments: DateSegment[], dateFormat: string): DateSegment[] => {
    const daySegment = segments.find((segment) => segment.type === "day")
    const monthSegment = segments.find((segment) => segment.type === "month")
    const yearSegment = segments.find((segment) => segment.type === "year")
    const separatorSegment = segments.find((segment) => segment.type === "literal")

    if (!daySegment || !monthSegment || !yearSegment || !separatorSegment) {
        throw new Error("Invalid date field segments")
    }

    const formattedDaySegment = {
        ...daySegment,
        text: daySegment.text.padStart(2, "0")
    }
    const formattedMonthSegment = {
        ...monthSegment,
        text: monthSegment.text.padStart(2, "0")
    }
    const formattedSeparatorSegment = { ...separatorSegment }

    if (dateFormat.toLowerCase().startsWith("m")) {
        return [
            formattedMonthSegment,
            formattedSeparatorSegment,
            formattedDaySegment,
            formattedSeparatorSegment,
            yearSegment
        ]
    } else {
        return [
            formattedDaySegment,
            formattedSeparatorSegment,
            formattedMonthSegment,
            formattedSeparatorSegment,
            yearSegment
        ]
    }
}
