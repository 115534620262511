import { SVGProps } from "react"

const PublishAndReplaceIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.02821 14.4479L8.12234 11.0671M8.12234 11.0671L6.4494 12.5507L6.82834 6.23749L10.3131 11.5154L8.12234 11.0671ZM3.11091 10.8891C0.963029 8.7412 0.963029 5.2588 3.11091 3.11091C5.2588 0.963029 8.7412 0.963029 10.8891 3.11091C11.963 4.18483 12.5 5.59236 12.5 6.9999M4.52513 9.47487C3.15829 8.10804 3.15829 5.89196 4.52513 4.52513C5.89196 3.15829 8.10804 3.15829 9.47487 4.52513C10.1583 5.20853 10.5 6.10424 10.5 6.99996"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default PublishAndReplaceIcon
