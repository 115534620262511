import { TreatmentEndReason } from "@prisma/client"
import { FormModal } from "forms/FormWithSteps"
import { ListPatient } from "pages/patients"
import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { setTreatmentEnd } from "store/global/actions"
import { closeModal } from "store/modal/actions"
import { getTreatmentEndSteps } from "."

export type TreatmentEndState = { date: Date; reason: TreatmentEndReason }
export type TreatmentEndModalParams = {
    patient_id: number
    fromPatientScope: boolean
    setPatients?: Dispatch<SetStateAction<ListPatient[]>>
    initialState?: TreatmentEndState
}

const TreatmentEndModal = ({ isOpen, params }: { isOpen: boolean; params: TreatmentEndModalParams }) => {
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()
    const handleClose = () => dispatch(closeModal())

    const onSubmit = async (state: TreatmentEndState) =>
        await dispatch(setTreatmentEnd(params.patient_id, state, params.fromPatientScope, params.setPatients))
    return (
        <FormModal
            show={isOpen}
            setShow={handleClose}
            useStepList={getTreatmentEndSteps}
            initialState={() => ({ treatment_end: params?.initialState ?? { date: new Date(), reason: undefined } })}
            onSubmit={(state) => onSubmit(state.treatment_end as TreatmentEndState)}
            title={t("treatmentEndForm.title")}
            stepClassName="h-80"
        />
    )
}

export default TreatmentEndModal
