import { ContactUser } from "@sequel-care/types/Patient"
import { Button, Dialog, Toggle, TruncatedString } from "components/common"
import { PlusIcon } from "components/icons"
import EnvelopeIcon from "components/icons/Envelope"
import MinusIcon from "components/icons/Minus"
import PhoneIcon from "components/icons/Phone"
import UserAvatar from "components/UserAvatar"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useCurrentPatient } from "store/hooks"
import { toggleFamilyAuth, getCurrentPatient } from "store/patient/actions"
import { classNames, getUserName } from "utils"
import { FeatureFlags } from "utils/FeatureFlags"

const RenderContacts = ({ value }: { value: ContactUser[] }) => {
    return (
        <div className="flex flex-col gap-2">
            {value.map((contact) => (
                <ContactItem key={contact.id} contact={contact} />
            ))}
        </div>
    )
}

export default RenderContacts

const ContactItem = ({ contact }: { contact: ContactUser }) => {
    const patient = useCurrentPatient()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const allowUserToggle = FeatureFlags.isEnabled("contact-auth")
    const { t } = useTranslation("patient")

    const onClick = useCallback(async () => {
        setLoading(true)
        const res = await dispatch(toggleFamilyAuth(patient?.id, contact.id))
        setLoading(false)
        if (res) {
            // refetch the query so no longer need to refresh the page
            await dispatch(getCurrentPatient(patient?.id))
        }
        return res
    }, [patient?.id])

    const { relation_type, phone, email, has_auth, has_multiple } = contact
    return (
        <div className={classNames("flex justify-between gap-1 bg-text-blue bg-opacity-10 rounded p-3 transition-all")}>
            <UserAvatar user={contact} />
            <Toggle>
                <Toggle.Content className="flex flex-col text-text-blue w-40" minChildrenToDisplay={1}>
                    <div className="flex flex-col">
                        <span className="text-dark-blue">{getUserName(contact)}</span>
                        {relation_type}
                    </div>

                    <div className="flex gap-2 mt-2">
                        <PhoneIcon className="w-5 h-5" />
                        <TruncatedString string={phone} maxLen={14} />
                    </div>
                    <div className="flex gap-2 items-center ">
                        <EnvelopeIcon className="w-5 h-5" />
                        <TruncatedString string={email} maxLen={14} />
                    </div>

                    {allowUserToggle && (
                        <Button
                            theme={has_auth ? "warn" : "primary"}
                            className="my-3"
                            loading={loading}
                            onClick={onClick}
                        >
                            {has_auth ? t("deactivateApp") : t("activateApp")}
                        </Button>
                    )}
                </Toggle.Content>
                <Toggle.Button showChevron={false} className="w-5 h-5 cursor-pointer">
                    {({ isOpen }) => (isOpen ? <MinusIcon className="w-5 h-5" /> : <PlusIcon className="w-5 h-5" />)}
                </Toggle.Button>
            </Toggle>
            {has_multiple && (
                <Dialog
                    onClose={() => setOpen(false)}
                    onConfirm={onClick}
                    open={open}
                    title={t("multipleDeactivation")}
                    type="warn"
                />
            )}
        </div>
    )
}
