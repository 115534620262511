import { OrgRole } from "@prisma/client"
import { BasicUser } from "@sequel-care/types"
import { Spinner, Tooltip } from "components/common"
import SuperSelect from "forms/SuperSelect"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { TbUserOff, TbUserPlus } from "react-icons/tb"
import { useDispatch } from "store"
import { toggleTeamAuth } from "store/global/actions"
import { classNames } from "utils"
import { CompleteUserInOrg } from "../../types/SchemaExtensions"
import { AuthStatus, RenderFieldProps, statuses } from "./types"

export const AuthFilter = ({
    status,
    setStatus,
    contentWidth = "w-64"
}: {
    status: AuthStatus
    setStatus: Dispatch<SetStateAction<AuthStatus>>
    contentWidth?: `w-${number}`
}) => {
    const { t } = useTranslation("therapist")
    const options = useMemo(() => statuses.map((status) => ({ id: status, label: t(`authStatus.${status}`) })), [t])

    return (
        <SuperSelect
            id={"statusFilter"}
            items={options}
            value={status}
            withSearch={false}
            placeholder={t("allUsers")}
            usePlaceholderAsAll
            classList={{
                input: classNames("flex border border-border-blue px-4 py-3 h-[3.125rem] !text-base", contentWidth)
            }}
            setValue={setStatus}
        />
    )
}

const roles = ["caregiver", "admin"]

export const RoleFilter = ({
    role,
    setRole,
    contentWidth = "w-64"
}: {
    role: OrgRole
    setRole: Dispatch<SetStateAction<OrgRole>>
    contentWidth?: `w-${number}`
}) => {
    const { t } = useTranslation("common")
    const options = useMemo(() => roles.map((role) => ({ id: role, label: t(`roles.${role}`) })), [t])
    return (
        <SuperSelect
            id={"statusFilter"}
            items={options}
            value={role}
            withSearch={false}
            placeholder={t("therapist:allTeam")}
            usePlaceholderAsAll
            classList={{
                input: classNames("flex border border-border-blue px-4 py-3 h-[3.125rem] !text-base", contentWidth)
            }}
            setValue={setRole}
        />
    )
}

export const RenderAuthStatus = ({ value, classes, onClick }: RenderFieldProps<BasicUser>) => {
    const { t } = useTranslation("therapist")
    return (
        <div
            className={classNames(
                value?.has_auth ? "text-med-green" : "text-secondary-2",
                classes,
                "flex gap-2 items-center"
            )}
            onClick={onClick}
        >
            <div
                className={classNames(
                    value?.has_auth ? "bg-med-green" : "bg-secondary-2",
                    "w-2.5 h-2.5 rounded-full shrink-0"
                )}
            />
            {t(`authStatus.${value?.has_auth ? "enabled" : "disabled"}`)}
        </div>
    )
}

export const ToggleAuthAction = ({ item }: { item: CompleteUserInOrg<string> }) => {
    const { t } = useTranslation("therapist")
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const has_auth = item.user?.has_auth

    const onClick = async () => {
        setLoading(true)
        await dispatch(toggleTeamAuth(item.user_id))
        setLoading(false)
    }

    if (loading) return <Spinner size={5} />

    const Icon = has_auth ? TbUserOff : TbUserPlus
    return (
        <Tooltip content={has_auth ? t("deactivateUser") : t("activateUser")}>
            <Icon className="w-5 h-5 cursor-pointer" onClick={onClick} />
        </Tooltip>
    )
}
