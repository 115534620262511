import { ControlledScaleSliderV2, TypographyV2 } from "components/v2"
import { ScreeningTemplateQuestion } from "@prisma/client"
import { Control, useController } from "react-hook-form"

interface ScaleSliderQuestionCardProps {
    question: ScreeningTemplateQuestion
    control: Control<Record<string, string | (string | number)[]>>
    error: string | undefined
}

const ScaleSliderQuestionCard = ({ question, control, error }: ScaleSliderQuestionCardProps) => {
    const { is_required, question: question_text, definition } = question
    const questionDefinition = definition as Record<any, any>
    const {
        field: { value }
    } = useController({
        name: `screeningAnswers.${question.id}`,
        control,
        rules: { required: true },
        defaultValue: questionDefinition.min
    })
    return (
        <div className="flex flex-col items-start justify-center gap-[40px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <div className="w-full flex flex-col items-center justify-center">
                <span className="text-[28px] font-semibold leading-9">{value || 0}</span>
                <ControlledScaleSliderV2
                    name={`screeningAnswers.${question.id}`}
                    control={control}
                    isRequired={is_required}
                    min={questionDefinition.min}
                    max={questionDefinition.max}
                    step={questionDefinition.step}
                    minLabel={questionDefinition.minLabel}
                    maxLabel={questionDefinition.maxLabel}
                    error={error}
                    classList={{ wrapper: "w-full" }}
                />
            </div>
        </div>
    )
}

export default ScaleSliderQuestionCard
