import { Language } from "@prisma/client"
import { ChevronLeftIcon } from "components/icons"
import {
    addMonths,
    format,
    getDate,
    getDay,
    getDaysInMonth,
    getYear,
    isAfter,
    isBefore,
    isSameDay,
    isSameMonth,
    setDate
} from "date-fns"
import { range } from "lodash"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useWeekStart } from "store/hooks"
import { classNames } from "utils"
import { DateString, getDateAtHour0, locales, weekdays } from "utils/dates"

const initialMonths = (date: Date) => {
    const firstDayOfMonth = setDate(date, 1)
    return {
        prev: addMonths(firstDayOfMonth, -1),
        current: firstDayOfMonth,
        next: addMonths(firstDayOfMonth, 1)
    }
}

type MonthKey = keyof ReturnType<typeof initialMonths>

export type CalendarProps = {
    value: Date
    setValue: (date: Date) => void
    allowedRange?: "all" | "future" | "past"
}
type Direction = 1 | -1
const disabledDirection: { [key in CalendarProps["allowedRange"]]: Direction } = { future: -1, past: 1, all: null }
const today = getDateAtHour0()

export const Calendar: FC<CalendarProps> = ({ value = new Date(), setValue, allowedRange = "all" }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation("dates")

    const weekStart = useWeekStart()
    const [months, setMonths] = useState(initialMonths(value))

    const changeMonths = useCallback((dir: Direction) => {
        setMonths((months) => {
            const newMonths = { ...months }
            Object.keys(newMonths).forEach((key: MonthKey) => {
                newMonths[key] = addMonths(newMonths[key], dir)
            })
            return newMonths
        })
    }, [])

    const internalChange = useRef(false)
    useEffect(() => {
        if (internalChange.current) internalChange.current = false
        else setMonths(initialMonths(value))
    }, [value])

    const changeSelected = useCallback(
        (date: Date) => {
            internalChange.current = true
            if (isSameMonth(months.current, date)) return setValue(date)

            changeMonths(isSameMonth(addMonths(months.current, 1), date) ? 1 : -1)
            setValue(date)
        },
        [months, setValue]
    )

    const monthName = useMemo(() => {
        return format(months.current, "MMMM", { locale: locales[language as Language] })
    }, [t, months])

    const dateList = useMemo(() => {
        // We substract the weekdays index here so that the start day will be relative to weekStart, and not necessarily to sunday
        let startsAt = getDay(months.current) - weekdays.indexOf(weekStart)
        if (startsAt < 0) startsAt = 7 - Math.abs(startsAt)

        const daysInMonth = {
            prev: getDaysInMonth(months.prev),
            current: getDaysInMonth(months.current)
        }

        const daysWithoutNext = startsAt + daysInMonth.current
        const daysToShowFromNext = 7 - (daysWithoutNext - Math.floor(daysWithoutNext / 7) * 7)

        let dateList = {
            prev: range(daysInMonth.prev - (startsAt - 1), daysInMonth.prev + 1),
            current: range(1, daysInMonth.current + 1),
            next: [] as number[]
        }

        // Calendar should always show 5 weeks. We determine how many days to show from next month based on this.
        const lessThan5Weeks = dateList.prev.length + dateList.current.length < 35
        dateList.next = range(1, daysToShowFromNext + 1 + (lessThan5Weeks ? 7 : 0))

        return Object.entries(dateList)
            .map(([key, range]: [MonthKey, number[]]) => range.map((date) => setDate(months[key], date)))
            .flat()
    }, [months, weekStart])

    const sortedWeekdays = useMemo(
        () =>
            weekStart === "mon"
                ? weekdays.slice(1).concat([weekdays[0]]) // Move sunday to end of array
                : weekdays,
        [weekStart]
    )

    const RenderChevron = useCallback(
        ({ dir }: { dir: Direction }) => {
            const isDisabled = isSameMonth(months.current, today) && disabledDirection[allowedRange] === dir
            return (
                <ChevronLeftIcon
                    className={classNames(
                        !isDisabled ? "cursor-pointer hover:text-dark-blue" : "text-gray-300",
                        (language === "he" ? dir < 0 : dir > 0) && "rotate-180"
                    )}
                    onClick={!isDisabled ? () => changeMonths(dir) : undefined}
                />
            )
        },
        [language, months.current]
    )

    return (
        <div className="w-full flex flex-col select-none">
            <div className="flex items-center justify-between text-text-blue pb-2">
                <RenderChevron dir={-1} />
                <div className="text-dark-blue font-semibold">
                    {monthName} {getYear(months.current)}
                </div>
                <RenderChevron dir={1} />
            </div>
            <div className="-mx-2" style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", rowGap: "6px" }}>
                {sortedWeekdays.map((day) => (
                    <div key={day} className="text-dark-green font-medium px-3 py-0.5">
                        {t(`dayLabels.${day}`, { context: "abbr" })}
                    </div>
                ))}
                {dateList.map((date) => {
                    const isDisabled =
                        (allowedRange === "future" && isBefore(date, today)) ||
                        (allowedRange === "past" && isAfter(date, today))

                    return (
                        <div
                            data-testid={`date-picker-${DateString.from(date)}`}
                            key={DateString.from(date)}
                            className={classNames(
                                "w-9 flex justify-center font-medium py-0.5",
                                isSameDay(value, date)
                                    ? classNames(
                                          isSameMonth(value, months.current)
                                              ? "bg-med-green text-white"
                                              : "bg-very-light-green text-text-blue",
                                          "rounded-full"
                                      )
                                    : classNames(
                                          !isDisabled && "cursor-pointer",
                                          isDisabled
                                              ? "text-gray-400"
                                              : isSameMonth(date, months.current)
                                              ? "text-dark-blue"
                                              : "text-secondary"
                                      )
                            )}
                            onClick={!isDisabled ? () => changeSelected(date) : undefined}
                        >
                            {getDate(date)}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
