import { Language, Organization } from "@prisma/client"
import { OrganizationData } from "@sequel-care/types/User"
import { getCodes, getName } from "country-list"
import { defaultInputClassNames, defineField, Field, PhoneField } from "forms"
import { defineStep } from "forms/FormWithSteps"
import SuperSelect from "forms/SuperSelect"
import { omit, pick } from "lodash"
import ProfilePictureUpload from "modals/UserModal/ProfilePictureUpload"
import { classNames, noopTFunction } from "utils"
import timezones from "../timezones.json"
import { getInitialChatPreferencesState } from "../User"
import { getChatPreferencesFields } from "../User/fields"

export const organizationFields: Field<Organization>[] = []
const classList = {
    wrapper: "col-span-8 flex justify-between h-12",
    input: classNames(defaultInputClassNames, "!text-dark-blue"),
    inputWrapper: "w-2/3 ",
    label: "flex items-center gap-2 my-auto"
}

export const getInitialOrganizationSettingsState = (organization: OrganizationData) => ({
    details: omit(organization, "default_preferences"),
    preferences: pick(organization.default_preferences, ["date_format", "week_start", "timezone", "language"]),
    chat_preferences: getInitialChatPreferencesState(organization.default_preferences)
})

export const useOrganizationSettingsSteps = (t = noopTFunction) => [
    defineStep({
        id: "details",
        name: t("organizationForm.orgDetails"),
        fields: getOrganizationDetailsFields(t),
        fieldGridColumns: 12
    }),
    defineStep({
        id: "preferences",
        name: t("organizationForm.preferences"),
        fields: getPreferencesFields(t),
        classList: { wrapper: "w-3/4" }
    }),
    defineStep({
        id: "chat_preferences",
        name: t("user:userSettings.tabChat"),
        fields: getChatPreferencesFields(t)
    })
]

export const getOrganizationDetailsFields = (t = noopTFunction) => [
    defineField({
        id: "name",
        label: t("organizationForm.organizationName") as string,
        classList,
        validations: {
            required: {
                value: true,
                message: t("organizationForm.validationMessages.name")
            }
        }
    }),
    defineField({
        id: "logo_url",
        Component: ProfilePictureUpload,
        classList: { wrapper: "col-span-4 row-span-5" }
    }),
    defineField({
        id: "website",
        label: t("organizationForm.website") as string,
        classList,
        direction: "ltr"
    }),
    defineField({
        id: "address",
        label: t("common:address") as string,
        classList
    }),
    defineField({
        id: "phone",
        label: t("common:phone") as string,
        info: t("user:userForm.phoneInfo") as string,
        Component: PhoneField,
        classList
    }),
    defineField({
        id: "country",
        label: t("common:country") as string,
        Component: SuperSelect<string>,
        style: "classic",
        items: getCodes().map((code) => ({ id: code, label: getName(code) })),
        classList,
        validations: {
            required: {
                value: true,
                message: t("organizationForm.validationMessages.country")
            }
        }
    }),
    defineField({
        id: "available_languages",
        info: t("organizationForm.languagesInfo") as string,
        label: t("common:available_languages") as string,
        Component: SuperSelect<string[]>,
        items: Object.keys(Language).map((key) => ({ id: key, label: t(`user:userSettings.${key}`) })),
        withSearch: false,
        classList,
        validations: {
            required: {
                value: true,
                message: t("organizationForm.validationMessages.available_languages")
            }
        }
    })
]

export const getPreferencesFields = (t = noopTFunction) => [
    defineField({
        id: "timezone",
        info: t("organizationForm.timezoneInfo") as string,
        label: t("user:userSettings.timeZone") as string,
        Component: SuperSelect<string>,
        items: timezones.map(({ utc, text }) => ({ id: utc[0], label: text })),
        classList
    }),
    defineField({
        id: "language",
        info: t("organizationForm.languageInfo") as string,
        label: t("common:language") as string,
        Component: SuperSelect<string>,
        items: Object.keys(Language).map((key) => ({ id: key, label: t(`user:userSettings.${key}`) })),
        withSearch: false,
        classList
    }),
    defineField({
        id: "date_format",
        info: t("organizationForm.formatInfo") as string,
        label: t("user:userSettings.dateFormat") as string,
        Component: SuperSelect<string>,
        items: [
            { id: "d/m/y", label: t(`user:userSettings:dayFirst`) },
            { id: "m/d/y", label: t("user:userSettings:monthFirst") }
        ],
        withSearch: false,
        classList
    }),
    defineField({
        id: "week_start",
        label: t("common:workingDays") as string,
        Component: SuperSelect<string>,
        items: ["sun", "mon"].map((id) => ({ id, label: t(`dates:workingDays.${id}`) })),
        withSearch: false,
        classList
    })
]
