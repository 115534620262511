import reducers from "./reducers"
import { HYDRATE, createWrapper } from "next-redux-wrapper"
import { thunk, ThunkAction } from "redux-thunk"
import {
    applyMiddleware,
    combineReducers,
    Middleware,
    Reducer,
    Store,
    AnyAction,
    ReducersMapObject,
    legacy_createStore
} from "redux"
import { Dictionary } from "lodash"

import { useDispatch as useDefaultDispatch } from "react-redux"
import { RootState } from "types/Redux"
import { ReactElement } from "react"

const bindMiddleware = (middleware: Middleware[]) => {
    if (process.env.APP_ENV === "prod") return applyMiddleware(...middleware)

    const { composeWithDevTools } = require("@redux-devtools/extension")
    return composeWithDevTools(applyMiddleware(...middleware))
}

type HydrationReducer<S = any> = (nextState: any, state: any) => S
type initProps<S> = {
    initialReducers: ReducerMap<S>
    hydrationReducer?: HydrationReducer<S>
    onInit?: (store: Store<S>) => void
    middleware?: Middleware[]
}
type ReducerMap<S> = ReducersMapObject<S, AnyAction>

class SequelStore<S extends Dictionary<any>> {
    instance: Store<S> | null = null

    private combinedReducer: Reducer<ReturnType<typeof combineReducers<S>>, AnyAction> | undefined
    private hydrationReducer: HydrationReducer<S> | undefined
    private reducers = {} as ReducerMap<S>
    private keysToRemove: (keyof S)[] = []

    constructor({ onInit, middleware = [], initialReducers, hydrationReducer }: initProps<S>) {
        this.reducers = initialReducers
        this.combinedReducer = combineReducers(this.reducers) as any
        this.hydrationReducer = hydrationReducer
        this.instance = legacy_createStore(this.reduce.bind(this), bindMiddleware([thunk, ...middleware]))
        onInit?.(this.instance)
    }

    get getState() {
        return this.instance!.getState
    }

    get subscribe() {
        return this.instance!.subscribe
    }

    get dispatch() {
        return this.instance!.dispatch as AppDispatch
    }

    get reducerMap() {
        return this.reducers
    }

    /**
     * The root reducer function to be passed to the store.
     */
    reduce(state: S | undefined, action: AnyAction) {
        if (action.type === HYDRATE) {
            const nextState = { ...state, ...action.payload }
            return this.hydrationReducer?.(nextState, state) ?? nextState
        }

        if (action.type === "store/RESET") return this.combinedReducer!(undefined, action)

        // If any reducers have been removed, clean up their state first
        if (this.keysToRemove.length > 0) {
            state = { ...state! }
            for (let key of this.keysToRemove) delete state[key]

            this.keysToRemove = []
        }

        // Delegate to the combined reducer
        return this.combinedReducer!(state as S[keyof S], action)
    }

    addReducer(key: keyof S, reducer: Reducer<S[keyof S], AnyAction>) {
        if (!key || this.reducers[key]) return

        this.reducers[key] = reducer as any
        this.combinedReducer = combineReducers(this.reducers) as any
        this.reduce(this.getState(), { type: "ADD_REDUCER" })
    }

    removeReducer(key: keyof S) {
        if (!key || !this.reducers[key]) return

        delete this.reducers[key]
        this.keysToRemove.push(key)
        this.combinedReducer = combineReducers(this.reducers) as any
    }

    wrapper() {
        return createWrapper(() => this.instance!)
    }

    /**
     * A hook that adds a reducer when component is mounted.
     */
    withReducer(...params: Parameters<typeof this.addReducer>) {
        this.addReducer(...params)
        return <C extends (props: any) => ReactElement>(Component: C) =>
            (props: Parameters<C>[0]) =>
                <Component {...props} />
    }
}

const store = new SequelStore<RootState>({ initialReducers: reducers })

export const withReducer = store.withReducer.bind(store)
export const useDispatch = () => useDefaultDispatch<AppDispatch>()

export type AppActionGeneric<S = any, RT = void> = ThunkAction<RT, S, unknown, AnyAction>
export type AppDispatch = Parameters<AppActionGeneric>[0]

export default store
