import {
    WelcomeCard,
    PersonalInformationCard,
    EndCard,
    IntakeCardController
} from "components/v2/components/IntakeScreeningCandidate"
import { Control, useForm } from "react-hook-form"
import cardBuilder from "../IntakeScreeningCandidateUtils/buildIntakeSurveyUtil"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useState } from "react"
import intakeScreeningValidationSchemaBuilder from "../IntakeScreeningValidationSchema/IntakeScreeningValidationSchema"
import personalDetailsValidationSchema from "../IntakeScreeningValidationSchema/PersonalDetailsValidationSchemas"
import { submitScreening } from "api/Intake"
import { useTranslation } from "react-i18next"
const IntakeSurvey = ({ intake, candidateId }: any) => {
    const {
        questions_list,
        welcome_title,
        welcome_subtitle,
        final_title,
        final_subtitle,
        organization_id,
        id,
        organization
    } = intake
    const [currentValidationSchema, setCurrentValidationSchema] = useState(yup.object({}))
    const { t } = useTranslation()
    const {
        control,
        formState: { errors },
        getValues,
        trigger
    } = useForm<any>({
        shouldUnregister: false,
        resolver: yupResolver(currentValidationSchema),
        mode: "onChange",
        defaultValues: {
            personal_details: {},
            screeningAnswers: {}
        }
    })

    const screeningQuestionsValidationSchemas = intakeScreeningValidationSchemaBuilder(
        questions_list.screening_questions
    )
    const validationSchemas = [
        yup.object({}),
        ...(questions_list.personal_details.length > 0
            ? [personalDetailsValidationSchema(questions_list.personal_details, t)]
            : []),
        ...screeningQuestionsValidationSchemas,
        yup.object({})
    ]
    
    const validateStep = async (step: number) => {
        if (step === 0) return true
        setCurrentValidationSchema(validationSchemas[step])
        await new Promise((resolve) => setTimeout(resolve, 0))
        const isValid = await trigger()
        return isValid
    }

    const handleSubmitScreening = async () => {
        const formValues = getValues()
        const screeningAnswers = Object.entries(formValues.screeningAnswers).map(([key, value]) => {
            const question = questions_list.screening_questions.find((question: any) => question.id === Number(key))
            return {
                questionId: Number(key),
                value,
                questionType: question.type,
                ...(question.scale_id ? { scaleId: question.scale_id } : {})
            }
        })

        const personalDetail = formValues.personal_details

        if (personalDetail?.date_of_birth) {
            personalDetail.date_of_birth = formValues?.personal_details?.date_of_birth
                ? new Date(formValues.personal_details.date_of_birth).toISOString().split("T")[0]
                : undefined
        }

        try {
            if (candidateId) {
                await submitScreening({
                    submitUrl: `submit-secondary-screening?candidateId=${candidateId}`,
                    screeningAnswers: {
                        organizationId: organization_id,
                        screeningTemplateId: id,
                        screeningAnswers: screeningAnswers
                    }
                })
            } else {
                await submitScreening({
                    submitUrl: "submit-first-screening",
                    screeningAnswers: {
                        organizationId: organization_id,
                        screeningTemplateId: id,
                        screeningAnswers: screeningAnswers,
                        personalDetailsAnswers: Object.entries(personalDetail).map(([key, value]) => ({
                            personalDetailDbFieldName: key,
                            value
                        }))
                    }
                })
            }
        } catch (error) {
            throw error
        }
    }

    const screeningCards = cardBuilder(
        questions_list.screening_questions,
        control as Control<Record<string, string | (string | number)[]>>,
        errors
    )

    const cards = [
        <WelcomeCard key="welcome" title={welcome_title} subtitle={welcome_subtitle} />,
        ...(questions_list.personal_details.length > 0
            ? [
                  <PersonalInformationCard
                      key="personal-info"
                      questions={questions_list.personal_details}
                      control={control as Control<Record<string, string | (string | number)[]>>}
                      errors={errors}
                      organization={organization}
                  />
              ]
            : []),
        ...screeningCards,
        <EndCard key="end" title={final_title} subtitle={final_subtitle} />
    ]
    
    return <IntakeCardController questions={cards} onSubmit={handleSubmitScreening} validateStep={validateStep} />
}

export default IntakeSurvey
