const QuestionaryIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1683_11517)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.16089 13.1898C8.16089 12.8908 8.40326 12.6484 8.70223 12.6484H12.2108C12.5098 12.6484 12.7521 12.8908 12.7521 13.1898C12.7521 13.4888 12.5098 13.7311 12.2108 13.7311H8.70223C8.40326 13.7311 8.16089 13.4888 8.16089 13.1898Z"
                fill="#84CC16"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.16089 3.36361C8.16089 3.06463 8.40326 2.82227 8.70223 2.82227H14.3159C14.6149 2.82227 14.8573 3.06463 14.8573 3.36361C14.8573 3.66258 14.6149 3.90495 14.3159 3.90495H8.70223C8.40326 3.90495 8.16089 3.66258 8.16089 3.36361Z"
                fill="#84CC16"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.16089 6.17025C8.16089 5.87127 8.40326 5.62891 8.70223 5.62891H12.2108C12.5098 5.62891 12.7521 5.87127 12.7521 6.17025C12.7521 6.46922 12.5098 6.71159 12.2108 6.71159H8.70223C8.40326 6.71159 8.16089 6.46922 8.16089 6.17025Z"
                fill="#84CC16"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.16089 10.3812C8.16089 10.0822 8.40326 9.83984 8.70223 9.83984H14.3159C14.6149 9.83984 14.8573 10.0822 14.8573 10.3812C14.8573 10.6802 14.6149 10.9225 14.3159 10.9225H8.70223C8.40326 10.9225 8.16089 10.6802 8.16089 10.3812Z"
                fill="#84CC16"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.38612 3.20378C2.29755 3.20378 2.22575 3.27558 2.22575 3.36415V6.17101C2.22575 6.25958 2.29755 6.33138 2.38612 6.33138H5.19298C5.28155 6.33138 5.35335 6.25958 5.35335 6.17101V3.36415C5.35335 3.27558 5.28155 3.20378 5.19298 3.20378H2.38612ZM1.14307 3.36415C1.14307 2.67763 1.6996 2.12109 2.38612 2.12109H5.19298C5.8795 2.12109 6.43604 2.67763 6.43604 3.36415V6.17101C6.43604 6.85753 5.8795 7.41406 5.19298 7.41406H2.38612C1.6996 7.41406 1.14307 6.85753 1.14307 6.17101V3.36415Z"
                fill="#84CC16"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.38612 10.2194C2.29755 10.2194 2.22575 10.2912 2.22575 10.3798V13.1866C2.22575 13.2752 2.29755 13.347 2.38612 13.347H5.19298C5.28155 13.347 5.35335 13.2752 5.35335 13.1866V10.3798C5.35335 10.2912 5.28155 10.2194 5.19298 10.2194H2.38612ZM1.14307 10.3798C1.14307 9.69325 1.6996 9.13672 2.38612 9.13672H5.19298C5.8795 9.13672 6.43604 9.69325 6.43604 10.3798V13.1866C6.43604 13.8732 5.8795 14.4297 5.19298 14.4297H2.38612C1.6996 14.4297 1.14307 13.8732 1.14307 13.1866V10.3798Z"
                fill="#84CC16"
            />
        </g>
        <defs>
            <clipPath id="clip0_1683_11517">
                <rect width="13.7143" height="13.7143" fill="white" transform="translate(1.14282 1.14258)" />
            </clipPath>
        </defs>
    </svg>
)

export default QuestionaryIcon
