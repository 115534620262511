import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useCurrentPatient, useOverviewNotifications, usePatientLoading } from "store/hooks"
import { pushNotificationToSidebarStack, pushToSidebarStack } from "store/sidebars/actions"
import FeedSidebar from "sidebars/Feed"
import { getOverviewData, readPatientNotifications } from "store/patient/actions"
import { PortalNotificationData } from "@sequel-care/types"
import { getUserName } from "utils"
import FeedList from "./FeedList"
import { BellIcon } from "@heroicons/react/24/outline"
import { useNotificationText } from "components/Notifications/utils"
import { OverviewCard } from "./utils"
import { Gender } from "@prisma/client"

const NotificationsCard = () => {
    const { t } = useTranslation()
    const notifications = useOverviewNotifications()
    const overviewLoading = usePatientLoading("overview")
    const dispatch = useDispatch()

    return (
        <OverviewCard
            title={<span className="text-xl text-dark-blue">{t("overview.notifications")}</span>}
            headerButton={
                <button
                    className="text-med-blue hover:underline cursor-pointer text-xs"
                    onClick={() => dispatch(pushToSidebarStack({ type: "notifications", isOpen: true }))}
                >
                    {t("overview.viewMore")}
                </button>
            }
            wrapperClassNames={"h-80"}
        >
            <NotificationsFeed notifications={notifications.slice(0, 4)} loading={overviewLoading} />
        </OverviewCard>
    )
}

export default NotificationsCard

export const NotificationsSidebar = () => {
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const notifications = useOverviewNotifications()

    useEffect(() => {
        if (notifications.length < 5) {
            const fetchAll = async () => {
                setLoading(true)
                await dispatch(getOverviewData({ notifications: {} }))
                setLoading(false)
            }
            fetchAll()
        }
    }, [])

    return (
        <FeedSidebar action={() => dispatch(readPatientNotifications())} title={t("overview.notifications")}>
            <NotificationsFeed notifications={notifications} loading={loading} />
        </FeedSidebar>
    )
}

const NotificationsFeed = ({
    notifications,
    loading
}: {
    notifications: PortalNotificationData[]
    loading: boolean
}) => {
    const { t } = useTranslation("patient")
    const currentPatient = useCurrentPatient()
    const dispatch = useDispatch()

    const onItemClick = (notification: PortalNotificationData) => () => {
        dispatch(readPatientNotifications(notification.id))
        dispatch(pushNotificationToSidebarStack(notification))
    }

    const useText = (item: PortalNotificationData) =>
        useNotificationText(item, getUserName(currentPatient?.user), currentPatient?.user.gender ?? ("" as Gender))

    return (
        <FeedList
            itemList={notifications}
            useItemOnClick={onItemClick}
            useItemText={useText}
            getIcon={(_) => <BellIcon className="h-5 w-5 text-dark-blue" aria-hidden="true" />}
            emptyState={{
                title: t("overview.emptyNotifications"),
                imageId: "no-notifications"
            }}
            loading={loading}
        />
    )
}
