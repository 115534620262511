import React, { useState, useMemo, useCallback, cloneElement } from "react"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"
import IntakeCardStep from "../IntakeCardStep/IntakeCardStep"
import IntakeCardFooter from "../IntakeCardFooter/IntakeCardFooter"

interface IIntakeCardControllerProps {
    questions: any[]
    onSubmit: () => void
    validateStep: (step: number) => Promise<boolean>
}

const IntakeCardController = ({ questions, onSubmit, validateStep }: IIntakeCardControllerProps) => {
    const [currentCardIndex, setCurrentCardIndex] = useState(0)
    const { t } = useTranslation()

    const nextStepHandler = useCallback(async () => {
        const isStepValid = await validateStep(currentCardIndex)
        if (!isStepValid) return
        if (currentCardIndex !== questions.length - 2) {
            setCurrentCardIndex(currentCardIndex + 1)
        }
    }, [currentCardIndex, questions])

    const nextButton = useMemo(() => {
        return {
            isArrowEnabled: currentCardIndex !== questions.length - 2,
            text:
                currentCardIndex === 0
                    ? t("intake:common.lets_start")
                    : currentCardIndex === questions.length - 2
                    ? t("intake:common.submit")
                    : t("intake:common.next"),
            onClick: async () => {
                const isStepValid = await validateStep(currentCardIndex)
                if (!isStepValid) return
                if (currentCardIndex === questions.length - 2) {
                    onSubmit()
                    setCurrentCardIndex(currentCardIndex + 1)
                } else {
                    setCurrentCardIndex(currentCardIndex + 1)
                }
            },
            hasNext: currentCardIndex < questions.length - 1
        }
    }, [currentCardIndex, questions, t("intake:common.lets_start")])

    const prevButton = useMemo(() => {
        return {
            onClick: () => setCurrentCardIndex(currentCardIndex - 1),
            hasPrev: currentCardIndex > 0 && currentCardIndex !== questions.length - 1
        }
    }, [currentCardIndex])

    return (
        <div className="relative flex justify-center items-center">
            {questions.slice(currentCardIndex, currentCardIndex + 3).map((question, index) => (
                <div
                    key={index}
                    style={{
                        position: "absolute",
                        transform: `scale(${1 - index * 0.05}) translateY(${
                            index === 0 ? 0 : index === 1 ? 27 : 54
                        }px)`,
                        zIndex: 3 - index,
                        opacity: index === 0 ? 1 : 0.7,
                        pointerEvents: index === 0 ? "auto" : "none"
                    }}
                >
                    <div
                        className={classNames(
                            "w-[540px] h-[615px] border border-[#E5E7EB] shadow-[0_1px_2px_0_#0000000D] p-[24px] rounded-xl flex flex-col justify-between bg-white gap-y-[0px] transition-all duration-300 ease-in-out"
                        )}
                    >
                        {/* {nextButton.hasNext && prevButton.hasPrev && (
                            <IntakeCardStep totalSteps={questions.length} currentStep={currentCardIndex} />
                        )} */}
                        <main
                            className={`flex-1 flex flex-col ${
                                !prevButton.hasPrev || !nextButton.hasNext
                                    ? "justify-center items-center"
                                    : "justify-start items-start"
                            }`}
                        >
                            {cloneElement(question, { onChange: nextStepHandler })}
                        </main>
                        {index === 0 && <IntakeCardFooter prevButton={prevButton} nextButton={nextButton} />}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default IntakeCardController
