import { ArrowLeftIcon, InformationCircleIcon } from "@heroicons/react/24/outline"
import { FC, ReactNode } from "react"
import { classNames } from "utils"
import { Button } from "./common"

type TopBarComponent = FC<{
    title?: ReactNode
    leftSection?: { gap?: number; children?: ReactNode }
    className?: string
    titleClassName?: string
    titleOnClick?: () => void
    backButton?: { title: string; onClick: () => void }
    children?: ReactNode
}>

const basicTitleClassName = "text-dark-blue text-2.5xl font-bold"
const TopBar: TopBarComponent = ({
    title,
    leftSection,
    children,
    titleClassName,
    titleOnClick,
    backButton,
    ...props
}) => {
    return (
        <div
            {...props}
            className={classNames("h-20 flex items-center justify-between px-9 flex-shrink-0 z-10", props.className)}
        >
            <div className={`flex items-end gap-${leftSection?.gap ?? 6}`}>
                {backButton ? (
                    <Button theme="secondary" className="gap-3 py-3 rounded-md" onClick={backButton.onClick}>
                        <ArrowLeftIcon className="h-5 w-5" />
                        {backButton.title}
                    </Button>
                ) : null}
                {title && !titleOnClick ? (
                    <h1 data-testid="patient-top-bar" className={titleClassName ?? basicTitleClassName}>
                        {title}
                    </h1>
                ) : (
                    title && (
                        <div className="text-dark-blue cursor-pointer flex items-center gap-2" onClick={titleOnClick}>
                            <h1 className={titleClassName ?? basicTitleClassName}>{title}</h1>
                            <InformationCircleIcon className="h-6 w-6" />
                        </div>
                    )
                )}
                {leftSection?.children}
            </div>
            {children}
        </div>
    )
}

export default TopBar
