import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "store/hooks"
import { localeFormat } from "utils/dates"
import { ResponseComponentProps } from "./types"
import { ReactNode } from "react"

const statusToIcon: { [key: string]: ReactNode } = {
    completed: <CheckIcon className="w-5 h-5 text-med-green" />,
    partial: <CheckIcon className="w-5 h-5 text-warning" />,
    skipped: <XMarkIcon className="w-5 h-5 text-negative" />,
    declined: <XMarkIcon className="w-5 h-5 text-negative" />
}

const TaskLastResponses = ({ event }: ResponseComponentProps) => {
    const { t, i18n } = useTranslation("patient")
    const { preferences } = useCurrentUser()

    if (!event.last_responses?.length) return null

    return (
        <div className="p-12 text-dark-blue font-medium flex flex-col gap-6 border-b border-border-blue">
            {t("sidebar.lastResults")}
            <div className="flex gap-3">
                {event.last_responses.map(({ date, value }) => (
                    <div
                        key={date}
                        className="border border-border-blue flex flex-col items-center rounded h-14 w-14 pt-2 text-sm"
                    >
                        {statusToIcon[value]}
                        {localeFormat(new Date(date), preferences.date_format === "d/m/y" ? "d/M" : "M/d", i18n)}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TaskLastResponses
