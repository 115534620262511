import { PatientModalProps } from "modals/PatientModal/PatientModal"
import { AppAction, ExerciseDefResponse } from "types/Redux"
import { CompleteUserInOrg } from "../../types/SchemaExtensions"
import * as types from "./types"
import { TreatmentEndModalParams } from "modals/TreatmentEndModal/TreatmentEndModal"

export const openModal = (type: string, params: any) => ({
    type: types.OPEN_MODAL,
    payload: { type, params }
})
export const closeModal = () => ({ type: types.CLOSE_MODAL })

export const updateParamsModal = (payload: any) => ({ type: types.UPDATE_PARAMS, payload })

export const openUserModal =
    (params?: Partial<CompleteUserInOrg<string>>): AppAction =>
    async (dispatch) => {
        dispatch(openModal("user", params))
    }

export const openUserPreferencesModal =
    (params: unknown): AppAction =>
    async (dispatch) => {
        dispatch(openModal("preferences", params))
    }

export const openPatientModal =
    (params?: PatientModalProps["params"]): AppAction =>
    async (dispatch) => {
        dispatch(openModal("patient", params))
    }

export const openPatientWhatNextModal =
    (params?: { patientId: number; isAfterPatientCreation?: boolean }): AppAction =>
    async (dispatch) => {
        dispatch(openModal("patient_what_next", params))
    }

export const openCollaboratorsModal =
    (params: unknown): AppAction =>
    async (dispatch) => {
        dispatch(openModal("collaborators", params))
    }

export const openFeaturesSettingsModal =
    (params: { patient_id: number }): AppAction =>
    async (dispatch) => {
        dispatch(openModal("features_settings", params))
    }

export const openTreatmentEndModal = (params: TreatmentEndModalParams) => openModal("treatment_end", params)

export const openCreateExerciseModal =
    (params: unknown): AppAction =>
    async (dispatch) => {
        dispatch(openModal("create_exercise", params))
    }

export const openExerciseBuilderModal =
    (params: ExerciseDefResponse): AppAction =>
    async (dispatch) => {
        dispatch(openModal("exercise_builder", params))
    }
