import { SVGProps } from "react"

const ProfessionalInfo = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M16.25 12.1938V10.0063C16.25 8.45305 14.9908 7.19385 13.4375 7.19385H12.1875C11.6697 7.19385 11.25 6.77411 11.25 6.25635V5.00635C11.25 3.45305 9.9908 2.19385 8.4375 2.19385H6.875M6.875 12.8188H13.125M6.875 15.3188H10M8.75 2.19385H4.6875C4.16973 2.19385 3.75 2.61358 3.75 3.13135V17.5063C3.75 18.0241 4.16973 18.4438 4.6875 18.4438H15.3125C15.8303 18.4438 16.25 18.0241 16.25 17.5063V9.69385C16.25 5.55171 12.8921 2.19385 8.75 2.19385Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default ProfessionalInfo
