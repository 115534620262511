import Image from "next/image"

interface IEndCardProps {
    title: string
    subtitle: string
}

const EndCard = ({ title, subtitle }: IEndCardProps) => {
    return (
        <div className="flex flex-col items-center justify-center gap-[16px]">
            <Image src="/images/logo.png" alt="Intake" width={47} height={70} />
            <h2 className="text-4xl font-normal text-center">{title}</h2>
            <p className="text-sm font-normal text-center">{subtitle}</p>
        </div>
    )
}

export default EndCard
