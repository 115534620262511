import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useQuestionsSelector } from "store/hooks"
import { closeAddVariableModalAction } from "store/questions/actions"
import { useMemo } from "react"
import { getStepsList } from "./questionAddVariableModalConstants"
import { useIsHeLanguage } from "hooks"

export const useQuestionAddVariableModal = () => {
    const { t } = useTranslation("question")
    const dispatch = useDispatch()
    const {
        addVariableModal: { isOpened, selectedExercise }
    } = useQuestionsSelector()

    const { isHeLanguage } = useIsHeLanguage()

    const steps = useMemo(() => getStepsList(), [])

    const onClose = () => {
        dispatch(closeAddVariableModalAction())
    }

    return { t, isHeLanguage, steps, isOpened, selectedExercise, onClose }
}
