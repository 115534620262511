import React from "react"
import { CheckBoxV2, FormInputWrapper } from "components/v2"
import { FormControlPropsV2 } from "components/v2/components/common/form-controls"
import { classNames } from "utils"
import { useController } from "react-hook-form"

interface IControlCheckboxInputV2Props<T> extends FormControlPropsV2<T> {}

const ControlledCheckboxInputV2 = <T,>({
    error,
    label,
    isRequired,
    classList,
    name,
    control,
    ...props
}: IControlCheckboxInputV2Props<T>) => {
    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })
    return (
        <FormInputWrapper {...{ label, error, isRequired, classList: classList?.wrapper }}>
            <CheckBoxV2
                isChecked={value as boolean}
                onChange={() => onChange(!value)}
                className={classNames(label ? "mt-1" : "mt-0", classList?.input)}
                {...props}
            />
        </FormInputWrapper>
    )
}

export default ControlledCheckboxInputV2
