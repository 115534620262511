import {
    ControlledTextInputV2,
    ControlledSelectV2,
    TypographyV2,
    ControlledDateInputV2,
    ControlledPhoneInputV2
} from "components/v2"
import { errorClassNamesV2 } from "components/v2/constants"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"

interface PersonalInformationCardProps {
    questions: any[]
    control: Control<Record<string, string | (string | number)[]>>
    errors: any
    organization: any
}

const PersonalInformationCard = ({ questions, control, errors, organization }: PersonalInformationCardProps) => {
    const { t } = useTranslation()
    
    const getFieldByColumn = (question: any, row: any[]) => {
        switch (question.column_name) {
            case "date_of_birth":
                return (
                    <ControlledDateInputV2
                        label={question.question}
                        name="personal_details.date_of_birth"
                        classList={{ wrapper: "flex-1" }}
                        control={control}
                        placeholder={t("intake:personal_information.date_of_birth")}
                        error={errors?.personal_details?.date_of_birth?.message}
                        dateFormat={organization.default_preferences.date_format}
                    />
                )
            case "gender":
                return (
                    <ControlledSelectV2
                        label={question.question}
                        options={question.definition.options.map((option: any) => ({
                            id: option.value,
                            title: option.label
                        }))}
                        classList={{ wrapper: "flex-1" }}
                        placeholder={t("intake:personal_information.gender")}
                        name="personal_details.gender"
                        control={control}
                        error={errors?.personal_details?.gender?.message}
                    />
                )
            case "phone":
                return (
                    <ControlledPhoneInputV2
                        label={question.question}
                        name="personal_details.phone"
                        classList={{ wrapper: "flex-1" }}
                        control={control}
                        error={errors?.personal_details?.phone?.message}
                        placeholder={t("intake:personal_information.phone_number")}
                    />
                )
            default:
                return (
                    <ControlledTextInputV2
                        key={question.columnName}
                        classList={{ wrapper: row.length === 1 ? "w-full" : "flex-1" }}
                        label={question.question}
                        placeholder={t(`intake:personal_information.${question.column_name}`)}
                        name={`personal_details.${question.column_name}`}
                        control={control}
                        error={errors?.personal_details?.[question.column_name]?.message}
                    />
                )
        }
    }

    const errorMessage =
        Object.values(errors?.personal_details || {}).length > 0
            ? (Object.values(errors?.personal_details || {})[0] as { message: string })?.message
            : ""

    return (
        <div className="flex flex-col items-start justify-center gap-[8px] w-full">
            <TypographyV2 type="base">{t("intake:common.your_personal_information")}</TypographyV2>
            <div className="flex flex-col gap-[8px] w-full">
                {questions
                    .sort((a: any, b: any) => a.position - b.position)
                    .reduce((rows: any[], question, index) => {
                        if (index % 2 === 0) {
                            rows.push([question])
                        } else {
                            rows[rows.length - 1].push(question)
                        }
                        return rows
                    }, [])
                    .map((row: any[], rowIndex: number) => (
                        <div key={rowIndex} className="flex flex-row justify-between gap-[24px] w-full">
                            {row.map((question: any) => getFieldByColumn(question, row))}
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default PersonalInformationCard
