import React, { forwardRef } from "react"
import { classNames } from "utils"
import { TypographyV2 } from "components/v2"
import { CompletedIcon } from "components/icons"

interface IStep {
    label: string
    isCompleted: boolean
}

interface IStepsProps {
    steps: IStep[]
    viewType?: viewType
    currentStep: number
}

type viewType = "row" | "col"

const Steps = forwardRef(({ steps, viewType = "col", currentStep }: IStepsProps) => (
    <div className={classNames("flex gap-10 rounded-lg ", viewType === "row" ? "flex-row" : "flex-col")}>
        {steps.map(({ label }, index) => {
            const isCompleted = index + 1 < currentStep
            return (
                <div key={label} className="relative flex items-center gap-2">
                    {isCompleted ? (
                        <CompletedIcon />
                    ) : (
                        <div
                            className={classNames(
                                "w-3 h-3 rounded-full border-2 flex items-center justify-center",
                                index + 1 <= currentStep ? "bg-med-blue border-blue-100" : " bg-gray-400"
                            )}
                        />
                    )}
                    <TypographyV2
                        width="medium"
                        className={classNames(
                            "text-gray-600",
                            !isCompleted && index + 1 <= currentStep ? "text-blue-500" : ""
                        )}
                    >
                        {label}
                    </TypographyV2>
                </div>
            )
        })}
    </div>
))

export default Steps
