import { defaultFilterClasses } from "components/Exports/FilterInput"
import Search from "components/Search"
import { DiagnosisSelect } from "forms/SuperSelect/wrappers"
import { intersection } from "lodash"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAllQuestionnaires, useCurrentRole } from "store/hooks"
import { createSetter } from "utils"
import { ItemGrid } from "./ItemList"
import {
    EditQuestionnaire,
    fields,
    OwnerFilter,
    PublishToggle,
    QuestionnaireLanguageFilter,
    QuestionnaireStatus,
    QuestionnaireStatusFilter,
    QuestionnaireTypeFilter,
    ViewQuestionnaireDetails
} from "./utils"

const labelKeys = ["name", "status", "owner", "diagnoses", "action"]

const QuestionnairesSettings = () => {
    const questionnaires = useAllQuestionnaires()
    const currentRole = useCurrentRole()
    const { t } = useTranslation("questionnaire")
    const [filters, setFilters] = useState({
        diagnosis: [],
        query: "",
        owner: undefined,
        status: [],
        language: [],
        type: []
    })

    const filteredQuestionnaires = useMemo(
        () =>
            questionnaires
                .map(
                    ({
                        id,
                        title,
                        is_public,
                        organization_id,
                        related_diagnoses,
                        created_by,
                        is_published,
                        is_deleted,
                        friendly_name,
                        target_role,
                        language
                    }) => ({
                        id,
                        title,
                        is_published,
                        status: !is_published
                            ? "unpublished"
                            : is_public
                            ? "published"
                            : ("internal" as QuestionnaireStatus),
                        owner: organization_id === 3 ? t("list.sequelCare") : currentRole.organization.name,
                        related_diagnoses,
                        created_by,
                        is_deleted,
                        friendly_name,
                        organization_id,
                        target_role,
                        language
                    })
                )
                .filter(
                    ({
                        is_deleted,
                        related_diagnoses,
                        title,
                        friendly_name,
                        organization_id,
                        status,
                        language,
                        target_role
                    }) => {
                        let diagnosesMatch = true
                        if (filters.diagnosis.length) {
                            const diagnosesIds = related_diagnoses.map(({ id }) => id)
                            const arrIntersection = intersection(diagnosesIds, filters.diagnosis)
                            diagnosesMatch = Boolean(arrIntersection.length)
                        }

                        const ownerMatch = !filters.owner || filters.owner === organization_id
                        const statusMatch = filters.status.length ? filters.status.includes(status) : true

                        const query = filters.query.toLowerCase()
                        const queryMatch =
                            title.toLowerCase().includes(query) || friendly_name.toLowerCase().includes(query)

                        const languageMatch = filters.language.length ? filters.language.includes(language) : true
                        const typeMatch = filters.type.length ? filters.type.includes(target_role) : true
                        return (
                            !is_deleted &&
                            diagnosesMatch &&
                            queryMatch &&
                            ownerMatch &&
                            statusMatch &&
                            languageMatch &&
                            typeMatch
                        )
                    }
                ),
        [questionnaires, filters]
    )
    const labels = useMemo(() => labelKeys.map((key) => t(`list.${key}`)), [t])

    return (
        <>
            <div className="flex gap-4 w-full">
                <DiagnosisSelect
                    id={"diagnosis_filter"}
                    value={filters.diagnosis}
                    setValue={createSetter(setFilters, "diagnosis")}
                    style="classic"
                    classList={defaultFilterClasses}
                    placeholder={t("list.allDiagnoses")}
                    usePlaceholderAsAll
                />
                {currentRole.organization_id !== 3 && (
                    <OwnerFilter value={filters.owner} setOwner={createSetter(setFilters, "owner")} />
                )}
                <QuestionnaireStatusFilter value={filters.status} setStatus={createSetter(setFilters, "status")} />
                <QuestionnaireLanguageFilter
                    value={filters.language}
                    setLanguage={createSetter(setFilters, "language")}
                />
                <QuestionnaireTypeFilter value={filters.type} setType={createSetter(setFilters, "type")} />
                <Search
                    search={filters.query}
                    setSearch={createSetter(setFilters, "query")}
                    wrapperClassname="!text-dark-blue border border-border-blue"
                />
            </div>

            <ItemGrid
                items={filteredQuestionnaires}
                fields={fields}
                labels={labels}
                labelsBg="bg-white"
                actions={[EditQuestionnaire, PublishToggle, ViewQuestionnaireDetails]}
                gridTemplateColumns="1fr 0.5fr 0.5fr 1fr 0.2fr"
            />
        </>
    )
}

export default QuestionnairesSettings
