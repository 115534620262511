import { RenderLabelV2, TypographyV2 } from "components/v2"
import { errorClassNamesV2 } from "components/v2/constants"
import React, { ReactNode } from "react"
import { classNames } from "utils"

interface IFormInputWrapperProps {
    label?: string
    error?: string
    classList?: string
    isRequired?: boolean
    rightLabelComponent?: ReactNode
    isErrorTextShowing?: boolean
    children: ReactNode
}

const FormInputWrapper: React.FC<IFormInputWrapperProps> = ({
    isRequired,
    label,
    error,
    classList,
    children,
    rightLabelComponent,
    isErrorTextShowing = true
}) => {
    const labelText = label && `${isRequired ? "*" : ""}${label} `

    return (
        <div className={classNames("flex flex-col", classList)}>
            <div className="flex justify-between items-end">
                {!!labelText && <RenderLabelV2 label={labelText} />}
                <>{rightLabelComponent}</>
            </div>
            {children}
            {!!error && isErrorTextShowing && (
                <TypographyV2 type="xs" width="medium" className={errorClassNamesV2}>
                    {error}
                </TypographyV2>
            )}
        </div>
    )
}

export default FormInputWrapper
