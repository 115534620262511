import { Spinner } from "components/common"
import CloudImg from "components/common/CloudImg"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"
import { localeDistanceToNow } from "utils/dates"

type FeedListProps<ItemType> = {
    itemList: ItemType[]
    useItemOnClick: (item: ItemType) => (() => void) | undefined
    useItemText: (item: ItemType) => string
    getIcon: (item: ItemType) => ReactNode
    emptyState: { title: string; imageId: "no-notifications" }
    loading: boolean
    getTextEndNode?: (item: ItemType) => ReactNode
}

function FeedList<ItemType extends { id: number; date: string; read?: boolean; text?: string }>({
    itemList,
    emptyState,
    loading,
    ...rest
}: FeedListProps<ItemType>) {
    return (
        <ul role="list" className="p-4 h-full flex flex-col justify-between">
            {loading ? (
                <div className="flex items-center justify-center h-full">
                    <Spinner size={6} className="m-auto" />
                </div>
            ) : !itemList.length ? (
                <div className="flex flex-col justify-center text-center text-text-blue text-sm h-full">
                    <div className="mx-auto">
                        <CloudImg variant="large" imageId={emptyState.imageId} width={140} height={50} />
                    </div>
                    {emptyState.title}
                </div>
            ) : (
                itemList.map((item, eventIdx) => {
                    const notLast = eventIdx !== itemList.length - 1

                    return (
                        <li key={item.id} className={classNames(notLast && "pb-6", "relative")}>
                            {notLast ? (
                                <span
                                    className={"absolute start-4 top-4 -ms-px h-full w-0.5 bg-gray-200"}
                                    aria-hidden="true"
                                />
                            ) : null}
                            <Item {...{ item, ...rest }} />
                        </li>
                    )
                })
            )}
        </ul>
    )
}

export default FeedList

function Item<ItemType extends { date: string; read?: boolean; text?: string }>({
    item,
    getIcon,
    useItemText,
    getTextEndNode,
    useItemOnClick
}: { item: ItemType } & Pick<
    FeedListProps<ItemType>,
    "useItemText" | "useItemOnClick" | "getIcon" | "getTextEndNode"
>) {
    const { t, i18n } = useTranslation()
    const text = useItemText(item)

    const handleClick = useItemOnClick(item)

    return (
        <div onClick={handleClick} className={classNames("relative flex gap-3", handleClick && "cursor-pointer")}>
            <span
                className={classNames("h-8 w-8 bg-border-blue rounded-full flex items-center justify-center relative")}
            >
                {getIcon(item)}
                {"read" in item && !item.read && (
                    <div className="rounded-full bg-dark-blue absolute top-0 right-0 w-2 h-2 z-10" />
                )}
            </span>
            <div className="flex min-w-0 flex-1 justify-between space-x-4">
                <div className="flex gap-2 items-center">
                    <p className="text-sm text-text-blue" dangerouslySetInnerHTML={{ __html: text }} />
                    {getTextEndNode ? getTextEndNode(item) : null}
                </div>

                <div className="whitespace-nowrap text-right text-sm text-secondary">
                    {t("common:distanceAgo", {
                        distance: localeDistanceToNow(new Date(item.date), i18n)
                    })}
                </div>
            </div>
        </div>
    )
}
