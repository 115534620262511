import { classNames } from "utils"
import { ModalCompatiblePortal } from "../Modal"
import { useMenuContext } from "./context"
import { ButtonProps, PanelProps } from "./types"

export const MenuButton = ({ className, children: Children }: ButtonProps) => {
    const {
        isOpen,
        onToggle,
        floatingProps: { refs },
        id
    } = useMenuContext()

    return (
        <div onClick={onToggle} ref={refs.setReference} className={className} id={id}>
            {typeof Children === "function" ? <Children {...{ isOpen, close: onToggle }} /> : Children}
        </div>
    )
}

export const MenuPanel = ({ className, children: Children }: PanelProps) => {
    const {
        isOpen,
        onToggle,
        floatingProps: { refs, floatingStyles },
        overrideParentId
    } = useMenuContext()

    return isOpen ? (
        <ModalCompatiblePortal overrideId={overrideParentId}>
            <div
                id="menu-panel"
                ref={refs.setFloating}
                className={classNames("z-[15] my-2", className)}
                style={floatingStyles}
            >
                {typeof Children === "function" ? Children({ isOpen, close: onToggle }) : Children}
            </div>
        </ModalCompatiblePortal>
    ) : null
}
