import "react-loading-skeleton/dist/skeleton.css"
import "react-toastify/dist/ReactToastify.css"
import "styles/commentPost.css"
import "styles/gradients.css"
import "styles/dualSlider.css"
import "styles/scaleSlider.css"
import "styles/misc.css"
import "styles/rtl-fix.css"
import "tailwindcss/tailwind.css"
import "styles/toastify.css"

import { I18nextProvider } from "react-i18next"
import { useEffect } from "react"
import store from "store"
import { useCurrentLanguage, useCurrentRole } from "store/hooks"
import { getDirFromLanguage } from "hooks/useDir"
import i18next from "i18next"
import mixpanel from "mixpanel-browser"
import { AppProps } from "next/app"
import { sendMixpanelReport } from "utils/mixpanel"
import { Amplify } from "aws-amplify"
import "@aws-amplify/ui-react/styles.css"
import i18n from "locales"
import getDefaultLayout from "utils/getDefaultLayout"
import { CloseIconToastButton, ToastIcon } from "components/v2/components/toast"
import { ToastContainer } from "react-toastify"

mixpanel.init(process.env.MIXPANEL_TOKEN)
Amplify.configure(JSON.parse(process.env.AMPLIFY_CONFIG))

const App = ({ Component, pageProps }: Omit<AppProps, "Component"> & { Component: any }) => {
    const language = useCurrentLanguage()
    const currentRole = useCurrentRole()
    const getLayout = Component.getLayout || getDefaultLayout

    useEffect(() => {
        if (i18next.language !== language) i18next.changeLanguage(language)
        document.querySelector("body")?.setAttribute("dir", getDirFromLanguage(language))
    }, [language])

    useEffect(() => {
        if (currentRole) sendMixpanelReport("Logged In")
    }, [currentRole])

    return getLayout(
        <I18nextProvider i18n={i18n}>
            <Component {...pageProps} />
            <ToastContainer
                theme="colored"
                position="bottom-center"
                hideProgressBar
                closeButton={CloseIconToastButton}
                icon={ToastIcon}
            />
        </I18nextProvider>,
        language
    )
}

export default store.wrapper().withRedux(App)
