import { EventType } from "@prisma/client"
import { QuestionnaireListItem } from "@sequel-care/types"
import { Button, Toggle } from "components/common"
import { Tag } from "components/common/Tag"
import { PlusIcon } from "components/icons"
import MinusIcon from "components/icons/Minus"
import { useRecurrenceDescriptionShort } from "hooks/useRecurrenceDescription"
import { upperFirst } from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useAllCarePlans, useDiagnoses } from "store/hooks"
import { pushToLibraryStack } from "store/patient/actions"
import { EventTemplate } from "types/Misc"
import { classNames, eventTypeIconMap } from "utils"
import DetailsScreen from "."
import { CarePlanEventTypes, carePlanEventTypes } from "components/Settings/utils"

const PlanDetails = ({
    planId,
    eventTemplates,
    withBack
}: {
    planId: number
    eventTemplates: EventTemplate[]
    withBack: boolean
}) => {
    const carePlans = useAllCarePlans()
    const carePlan = carePlans.find(({ id }) => id === planId)
    const allDiagnoses = useDiagnoses()
    const dispatch = useDispatch()
    const sidebarData: Partial<QuestionnaireListItem> = {
        related_diagnoses: allDiagnoses.filter(({ id }) => carePlan.diagnoses.includes(id))
    }

    const events = useMemo(() => {
        const events = Object.fromEntries(carePlanEventTypes.map<[CarePlanEventTypes, EventTemplate[]]>((type) => [type, []])) as unknown as Record<
            CarePlanEventTypes,
            EventTemplate[]
        >
        eventTemplates.forEach((event) => events[event.type as CarePlanEventTypes].push(event))

        return events
    }, [eventTemplates])

    return (
        <DetailsScreen
            title={carePlan.title}
            description={carePlan.description}
            withBack={withBack}
            {...sidebarData}
            onAdd={() =>
                dispatch(
                    pushToLibraryStack({
                        view: "config",
                        planId,
                        patientId: "preserve",
                        eventTemplates
                    })
                )
            }
        >
            <div className="py-12 px-4">
                {carePlanEventTypes.map((type) => (
                    <EventTypeList key={type} events={events[type]} type={type} />
                ))}
            </div>
        </DetailsScreen>
    )
}

export default PlanDetails

const EventTypeList = ({ events, type }: { events: EventTemplate[]; type: EventType }) => {
    const { t } = useTranslation("patient")
    const eventCount = events.length
    if (!eventCount) return null

    return (
        <>
            <div className="text-lg pb-6 text-dark-blue font-medium">
                {t(`contentLib.includes${upperFirst(type)}s`, { [`${type}Count`]: eventCount })}
            </div>
            <div>
                {events.map((event, index) => (
                    <EventItem key={index} {...{ event, index, type }} />
                ))}
            </div>
        </>
    )
}

const EventItem = ({ event, index, type }: { event: EventTemplate; index: number; type: EventType }) => {
    const { t } = useTranslation("common")
    const recurrenceDescription = useRecurrenceDescriptionShort(event.recurrence?.interval)
    const dispatch = useDispatch()

    const Icon = eventTypeIconMap[`${type}`]

    return (
        <Toggle>
            <Toggle.Button
                className={classNames("py-2", index !== 0 && "border-t border-border-blue")}
                showChevron={false}
            >
                {({ isOpen }) => (
                    <div className="flex justify-between w-full items-center">
                        <div className="flex gap-4 text-dark-blue">
                            <span className="bg-med-blue/10 p-1.5 text-med-blue">{<Icon className="w-5 h-5 " />}</span>

                            <span className="items-center flex">{event.title}</span>
                            <Tag color="blue">{recurrenceDescription}</Tag>
                        </div>
                        {!event.description ? null : isOpen ? (
                            <MinusIcon className="w-5 h-5 text-med-blue" />
                        ) : (
                            <PlusIcon className="w-5 h-5 text-med-blue" />
                        )}
                    </div>
                )}
            </Toggle.Button>
            <Toggle.Content className="text-text-blue ltr:pr-2 rtl:pl-2 py-6">
                <div className="flex flex-col gap-4">
                    {event.description}
                    {type === "questionnaire" && (
                        <div className="flex justify-end">
                            <Button
                                theme="secondary"
                                onClick={() =>
                                    dispatch(
                                        pushToLibraryStack({
                                            view: "learn_more",
                                            eventTemplates: event,
                                            patientId: "preserve"
                                        })
                                    )
                                }
                            >
                                {t("learnMore")}
                            </Button>
                        </div>
                    )}
                </div>
            </Toggle.Content>
        </Toggle>
    )
}
