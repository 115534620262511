import { ScreeningQuestionType } from "@prisma/client"
import * as yup from "yup"

const therapistQuestionnaireValidationSchemaBuilder = (questions: Record<string, any>[]) => {
    const screeningAnswersSchema = questions.reduce((acc, question) => {
        if (question.type === ScreeningQuestionType.guideline) return acc
        if (question.type === ScreeningQuestionType.multiple_choice) {
            acc[question.id] = yup.array().required()
        } else {
            acc[question.id] = yup.string().required()
        }
        return acc
    }, {} as Record<string, any>)

    return yup.object({
        screeningAnswers: yup.object(screeningAnswersSchema)
    })
}

export default therapistQuestionnaireValidationSchemaBuilder
