import { SelectOptionV2, IOptionV2 } from "components/v2/components/common/selects"

export interface ISingleSelectOptionV2Props {
    option?: IOptionV2
    handleSelectOption?: (option: string | number) => void
}

const SingleSelectOptionV2 = ({ option, handleSelectOption }: ISingleSelectOptionV2Props) => {
    return (
        <div
            className="text-black w-full flex flex-row p-3 hover:bg-blue-50 pointer-events-auto items-center gap-x-2.5"
            onClick={() => handleSelectOption(option.id)}
        >
            <SelectOptionV2 option={option} />
        </div>
    )
}

export default SingleSelectOptionV2
