import { ExerciseType, Language } from "@prisma/client"

export const languagesWithCapital = {
    [Language.en]: "en",
    [Language.he]: "he"
}

export const exerciseTypeTitles = {
    [ExerciseType.EXERCISE_JOURNAL]: "journal",
    [ExerciseType.EXERCISE_ONLY]: "exercise"
}
