import { Permission } from "@prisma/client"
import { Metric, PatientStatus, TrendWithUnmeasured, WorkingAlliance } from "@sequel-care/types"
import { PatientWithMetrics } from "@sequel-care/types/Patient"
import { TOptions } from "i18next"
import { camelCase, isEqual, uniqBy } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getDashboardPatients } from "store/global/actions"
import { useCurrentUser } from "store/hooks"
import { LastArrayElement } from "type-fest"
import { RootState } from "types/Redux"
import { idsOf, useEffectWithDepHistory } from "utils"
import { useTherapistDashboardContext } from "./context"
import { DashboardFilters, Interactions, MetricFilter } from "./types"
import { useDispatch } from "store"

export const tableColors = [
    "#DA69CF",
    "#6243D7",
    "#A9ACFE",
    "#F5AAF7",
    "#3523FF",
    "#5497A6",
    "#AC81D8",
    "#3C7FF9",
    "#BE8FED",
    "#23C9EC",
    "#B6BBBD",
    "#A95487",
    "#46B5F4",
    "#93B3E2"
]

export function useCurrentFilterOptions() {
    const { t } = useTranslation("therapist")
    return useMemo(
        () => [
            { id: 1, name: t("dashboard.filters.currentPatients") },
            { id: 2, name: t("dashboard.filters.pastPatients") }
        ],
        [t]
    )
}

export const colorsByTrend: Record<TrendWithUnmeasured, `text-${string}`> = {
    positive: "text-med-green",
    unchanged: "text-warning",
    negative: "text-negative",
    unmeasured: "text-gray-500"
}

export const valuesByMetric = {
    primary_progress: ["positive", "unchanged", "negative", "unmeasured"] as TrendWithUnmeasured[],
    working_alliance: ["high", "medium", "low", "unmeasured"] as WorkingAlliance[]
}

export function useMetricValueToTitle<K extends keyof Metric>(metric: K) {
    const { t } = useTranslation("therapist")
    return useCallback(
        (value: Metric[K]) => {
            const translationFor = (key: string, options?: TOptions) =>
                t(`listSubtitles.${camelCase(metric)}.${key}`, options)
            return translationFor("template", { value: translationFor(value as string) })
        },
        [t, metric]
    )
}

export function filterPatientsByMetric<M extends keyof Metric>(
    patients: PatientWithMetrics<string>[],
    metric: M,
    value: Metric[M],
    mode: "match" | "no_match" = "match"
) {
    return patients.filter(({ metrics }) => {
        let doesMatch: boolean = false

        if (Array.isArray(metrics[metric])) {
            doesMatch = (metrics[metric] as Metric[M][]).includes(value)
        } else {
            doesMatch = Array.isArray(value)
                ? value.includes(metrics[metric] as LastArrayElement<typeof value>)
                : metrics[metric] === value
        }

        return mode === "match" ? doesMatch : !doesMatch
    })
}

export const highBurdenIndicators: Partial<Metric> = {
    severe_score: true,
    primary_progress: "negative",
    activity: "inactive"
}

export const getHighBurdenPatients = (patients: PatientWithMetrics<string>[], mode: "match" | "no_match" = "match") => {
    const patientsByMetric = Object.entries(highBurdenIndicators).map(
        ([metric, value]) =>
            [metric as keyof Metric, filterPatientsByMetric(patients, metric as keyof Metric, value)] as const
    )

    const uniquePatients = uniqBy(
        patientsByMetric.flatMap(([_, patients]) => patients),
        "id"
    )

    const patientsNotIn = (exclude: PatientWithMetrics<string>[]) => {
        const idsToExclude = idsOf(exclude)
        return patients.filter((patient) => !idsToExclude.includes(patient.id))
    }

    return {
        patientsByMetric:
            mode === "match"
                ? patientsByMetric
                : patientsByMetric.map(
                      ([metric, matchingPatients]) => [metric, patientsNotIn(matchingPatients)] as const
                  ),
        uniquePatients: mode === "match" ? uniquePatients : patientsNotIn(uniquePatients)
    }
}

type InteractionIndicators = { isSelected: boolean | null; isHovered: boolean | null }

export function useInteractionProps<ElType extends Element = HTMLDivElement>(data: MetricFilter) {
    const context = useTherapistDashboardContext()
    const [callbacks, setCallbacks] = useState<Interactions<ElType>>(null)

    const [interactionData, setInteractionData] = useState<InteractionIndicators>({
        isSelected: null,
        isHovered: null
    })

    useEffectWithDepHistory(
        (previous) => {
            if (!previous || (context && (data.key !== previous.data.key || !isEqual(data, previous.data)))) {
                setCallbacks({
                    onClick: () =>
                        context.setSelected((selected) =>
                            selected?.key !== data.key || selected?.value !== data.value ? data : null
                        ),
                    onMouseEnter: () => context.setHovered(data),
                    onMouseLeave: () => context.setHovered(null)
                })
            }

            if (!isEqual(context?.filters, previous?.context?.filters)) {
                const getInteractionIndicator = (key: keyof DashboardFilters.Interactions) => {
                    if (!context.filters[key]) return null

                    const isActive =
                        "high_burden" in data
                            ? "high_burden" in context.filters[key]
                            : context.filters[key].key === data.key
                    return isActive ? isEqual(context.filters[key], data) : null
                }

                setInteractionData({
                    isSelected: getInteractionIndicator("selected"),
                    isHovered: getInteractionIndicator("hovered")
                })
            }
        },
        { data, context }
    )

    return { ...callbacks, ...interactionData }
}

export const useDashboardPatients = (status?: PatientStatus, collaboratorPermission?: Permission) => {
    const dashboardPatients = useSelector((state: RootState) => state.global.dashboardPatients)
    const currentUser = useCurrentUser()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchDashboardPatients = async () => {
            setLoading(true)
            await dispatch(getDashboardPatients())
            setLoading(false)
        }
        if (!dashboardPatients) fetchDashboardPatients()
    }, [])

    return {
        loading,
        patients: useMemo(
            () =>
                dashboardPatients?.filter(
                    (patient) =>
                        "metrics" in patient &&
                        (status ? patient.metrics?.status === status : true) &&
                        (collaboratorPermission
                            ? patient.collaborators.some(
                                  ({ permissions, user }) =>
                                      permissions.some(({ permission }) => permission === collaboratorPermission) &&
                                      user.id === currentUser.id
                              )
                            : true)
                ) ?? [],
            [dashboardPatients]
        )
    }
}
