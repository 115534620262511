import { SVGProps } from "react"

const TimeOfDayIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8 2V3.5M12.2426 3.75736L11.182 4.81802M14 8H12.5M12.2426 12.2426L11.182 11.182M8 12.5V14M4.81802 11.182L3.75736 12.2426M3.5 8H2M4.81802 4.81802L3.75736 3.75736M10.5 8C10.5 9.38071 9.38071 10.5 8 10.5C6.61929 10.5 5.5 9.38071 5.5 8C5.5 6.61929 6.61929 5.5 8 5.5C9.38071 5.5 10.5 6.61929 10.5 8Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default TimeOfDayIcon
