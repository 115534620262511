import { Tag } from "@prisma/client"

const tagClassNames = "rounded-full bg-text-blue/10 py-1 px-3 w-fit whitespace-nowrap mr-1 mt-1.5 inline-block truncate"

export const RenderTags = ({ value }: { value: Tag[] }) => {
    return (
        <>
            {value.map(({ name }, index) => (
                <div className={tagClassNames} key={index}>
                    {name}
                </div>
            ))}
        </>
    )
}

export default RenderTags
