import { TypographyV2, ControlledRadioCircleGroupV2 } from "components/v2"
import { Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
interface YesNoQuestionCardProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: any
    onChange?: () => void
}

const YesNoQuestionCard = ({ control, error, question, onChange = () => {} }: YesNoQuestionCardProps) => {
    const { is_required, question: question_text } = question
    const { t } = useTranslation()
    const options = [
        { value: "yes", label: t("intake:common.yes") },
        { value: "no", label: t("intake:common.no") }
    ]

    return (
        <div className="flex flex-col items-start justify-center gap-[24px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <ControlledRadioCircleGroupV2
                name={`screeningAnswers.${question.id}`}
                classList={{ wrapper: "w-full" }}
                isBordered
                isRequired={is_required}
                options={options.map((option: any) => ({
                    value: option.value,
                    labelComponent: <TypographyV2 type="base">{option.label}</TypographyV2>
                }))}
                label=""
                control={control}
                error={error}
                onChange={onChange}
            />
        </div>
    )
}

export default YesNoQuestionCard
