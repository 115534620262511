import { ApiDelete, ApiGet, ApiPost } from "./ApiRequest"

import { QuestionnaireDef } from "@prisma/client"

export interface TherapistEvent {
    id: number
    title: string
    expires_within: number
    patient: {
        user: {
            first_name: string
            last_name: string
        }
    }
}

export const fetchQuestionnairesForRole = () => {
    const instance = new ApiGet<QuestionnaireDef[]>(`therapist-portal/questionnaire`)
    instance.callerName = fetchQuestionnairesForRole.name
    return instance.run()
}

export const fetchEventsForTherapist = () => {
    const instance = new ApiGet<TherapistEvent[]>(`therapist-portal/current-events`)
    instance.callerName = fetchEventsForTherapist.name
    return instance.run()
}

export const getQuestionnairByEventId = (eventId: number) => {
    const instance = new ApiGet<QuestionnaireDef>(`questionnaire/event/${eventId}`)
    instance.callerName = getQuestionnairByEventId.name
    return instance.run()
}

export const submitTherapistQuestionnaire = (data: any) => {
    const instance = new ApiPost<any>(`questionnaire/therapist/respond`, data)
    instance.callerName = submitTherapistQuestionnaire.name
    return instance.run()
}

export const deleteTherapistQuestionnaireEvent = (patientId: number, caseManagerId: number) => {
    const instance = new ApiDelete<Record<string, boolean>>(`therapist-portal/event/${patientId}/${caseManagerId}`)
    instance.callerName = deleteTherapistQuestionnaireEvent.name
    return instance.run()
}
