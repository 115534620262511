import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline"
import {
    DetailedHTMLProps,
    Dispatch,
    forwardRef,
    ForwardRefRenderFunction,
    InputHTMLAttributes,
    SetStateAction
} from "react"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"

export type SetStringState = Dispatch<SetStateAction<string>>
export type SearchProps = { search: string; setSearch: SetStringState; placeholder?: string }

type SearchInputProps = Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "placeholder" | "onChange" | "value"
> & { wrapperClassname?: string; iconClassName?: string }

const Search: ForwardRefRenderFunction<HTMLInputElement, SearchProps & SearchInputProps> = (
    { search, setSearch, wrapperClassname, placeholder, className, iconClassName, ...inputProps },
    ref
) => {
    const { t } = useTranslation("common")
    return (
        <label
            className={classNames(
                "flex items-center gap-2 bg-transperent text-secondary relative px-3",
                wrapperClassname
            )}
        >
            <MagnifyingGlassIcon className={classNames("w-5", iconClassName)} />
            <input
                className={classNames(
                    "w-full text-dark-blue bg-inherit placeholder:text-secondary focus:!ring-0 focus:outline-none !border-none",
                    className
                )}
                placeholder={placeholder ?? t("search")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                ref={ref}
                {...inputProps}
            />
            <XMarkIcon
                className="w-4 cursor-pointer hover:scale-110 hover:text-dark-blue transition-all"
                onMouseDown={(e) => {
                    setSearch("")
                    e.preventDefault()
                }}
            />
        </label>
    )
}

export default forwardRef(Search)
