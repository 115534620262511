import { ById } from "@sequel-care/types/utils"
import i18next from "i18next"
import { toast } from "react-toastify"
import store from "store"
import { pushEventSidebarToStack } from "store/global/actions"
import { GraphState } from "types/Redux"
import { GRAPH_STYLES } from "utils/graphs"
import { Dataset, GraphItem, OnDataPointClick, Options } from "../types"

type GraphOptionsProps = {
    items?: ById<GraphItem>
    onClick?: OnDataPointClick
    activeIds: string[]
    hasData?: boolean
    datasets: Dataset[]
}

export const getGraphOptions = ({ items = {}, onClick, activeIds, hasData, datasets }: GraphOptionsProps): Options => {
    const [activeItem] = activeIds
    const hasSingleDataset = hasData && activeIds.length === 1
    const cutoffs = (hasSingleDataset && items[activeItem]?.cutoffs) || []
    const maxScore = (activeItem && hasSingleDataset && items[activeItem]?.max_score) || null

    return {
        layout: {
            padding: {
                top: 24, // This makes sure the bar labels do not get cut off
                left: 16,
                right: cutoffs.length ? 44 : 48
            }
        },
        animation: {
            duration: 0
        },
        onHover:
            onClick && !onClick.noPointer
                ? (event, [activeElement]) => {
                      const style = (event.native.target as HTMLCanvasElement)?.style

                      if (
                          activeElement &&
                          style?.cursor !== "pointer" &&
                          datasets?.[activeElement?.datasetIndex].itemId.includes("q")
                      ) {
                          style.cursor = "pointer"
                      } else if (!activeElement && style?.cursor === "pointer") {
                          style.cursor = "default"
                      }
                  }
                : undefined,
        onClick,
        scales: {
            y: {
                min: 0,
                suggestedMax: 100,
                max: hasSingleDataset ? maxScore : undefined,
                ticks: {
                    color: hasSingleDataset ? GRAPH_STYLES.COLORS.secondary : "transparent",
                    stepSize: hasSingleDataset ? (maxScore < 10 ? 1 : maxScore % 2 ? 3 : 4) : 5,
                    font: {
                        family: GRAPH_STYLES.FONTS.axes,
                        size: 14
                    }
                },
                border: {
                    dash: [7, 7],
                    display: true
                },
                grid: {
                    color: GRAPH_STYLES.COLORS.border_blue
                }
            },
            x: {
                grid: {
                    lineWidth: 2,
                    color: GRAPH_STYLES.COLORS.border_blue
                },
                ticks: {
                    color: GRAPH_STYLES.COLORS.secondary,
                    font: {
                        family: GRAPH_STYLES.FONTS.axes,
                        size: 14
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                backgroundColor: GRAPH_STYLES.COLORS.tooltip,
                yAlign: "bottom",
                xAlign: "center",
                titleColor: GRAPH_STYLES.COLORS.tooltipText,
                titleMarginBottom: 0,
                titleFont: {
                    family: GRAPH_STYLES.FONTS.axes,
                    size: 12,
                    weight: "bold"
                },
                footerColor: GRAPH_STYLES.COLORS.tooltipText,
                footerFont: {
                    family: GRAPH_STYLES.FONTS.axes,
                    size: 12,
                    weight: "bold"
                },
                callbacks: {
                    title: (tooltipItem) => tooltipItem[0].dataset.label,
                    label: () => "",
                    footer: (tooltipItem) => {
                        const { dataset, dataIndex } = tooltipItem[0]
                        return `Score: ${(dataset as Dataset).rawData[dataIndex].score}`
                    }
                },
                padding: {
                    top: 8,
                    bottom: 8,
                    left: 8,
                    right: 24
                },
                borderColor: GRAPH_STYLES.COLORS.border_blue,
                borderWidth: 1,
                caretPadding: 25,
                footerMarginTop: 0,
                bodySpacing: 0
            },
            annotation: {
                annotations: {
                    ...Object.fromEntries(
                        cutoffs.map((cutoff, index) => {
                            const yMin = index === 0 ? 0 : cutoff.min
                            const yMax = cutoffs[index + 1]?.min ?? items[activeItem].max_score

                            return [
                                `cutoff${cutoff.min}`,
                                {
                                    type: "box",
                                    yMin,
                                    yMax,
                                    borderWidth: 0,
                                    backgroundColor: cutoff.color + "20",
                                    drawTime: "afterDatasetsDraw"
                                }
                            ]
                        })
                    )
                }
            }
        }
    }
}

export const getOnGraphClick = ({
    datasets,
    graphState
}: {
    datasets: Dataset[]
    graphState: GraphState
}): OnDataPointClick => {
    return graphState.resolution === "day"
        ? (_, [activeElement]) => {
              if (!activeElement || !datasets.length) return
              //for some reason datasets is empty sometimes - return to avoid crashing

              const { datasetIndex, index } = activeElement
              const { rawData, itemId } = datasets[datasetIndex]

              if (!rawData[index] || itemId.includes("t")) return

              const { date_on_timeline, event_id } = rawData[index]
              store.dispatch(
                  pushEventSidebarToStack({
                      id: event_id,
                      date_on_timeline,
                      context: { context: "dashboard", time: new Date() }
                  })
              )
          }
        : Object.assign(() => toast(i18next.t("patient:dashboard.resolutionWarning"), { type: "info" }), {
              noPointer: true
          })
}
