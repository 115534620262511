import React, { useMemo } from "react"
import { CardV2, TypographyV2 } from "components/v2"
import UserAvatar from "components/UserAvatar"
import { BasicUser } from "@sequel-care/types"

interface IContentItemProps {
    title: string
    subtitle: string
    author: BasicUser
    categories: string[]
}

const ActivityItemCard: React.FC<IContentItemProps> = ({ title, subtitle, author, categories }) => {
    const visibleCategories = useMemo(() => categories.slice(0, 2), [categories])
    const notVisibleCount = useMemo(() => categories.slice(2).length, [categories])

    const categoriesList = notVisibleCount ? [...visibleCategories, `+${notVisibleCount}`] : visibleCategories
    return (
        <CardV2 className="flex flex-col justify-between border-none">
            <div>
                <TypographyV2 type="sm" width="bold">
                    {title}
                </TypographyV2>
                <TypographyV2 type="sm" className="text-gray-400 mt-1">
                    {subtitle}
                </TypographyV2>
            </div>
            <div>
                <div className="flex gap-0.5 mt-4">
                    {/* TODO: Add TagV2 component after merging DP changes */}
                    {categoriesList.map((item) => (
                        <div
                            className="flex justify-center bg-opacity-20 rounded-sm px-2 py-1 text-xs h-auto bg-med-blue text-text-blue"
                            key={item}
                        >
                            {item}
                        </div>
                    ))}
                </div>
                <div className="mt-4  flex items-center">
                    <UserAvatar size={6} user={author} />
                    <TypographyV2 type="xs" className="ml-1 text-black">
                        {author.first_name + " " + author.last_name}
                    </TypographyV2>
                </div>
            </div>
        </CardV2>
    )
}

export default ActivityItemCard
