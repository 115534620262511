import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

type SearchResultsSectionProps<ItemType> = {
    title: string
    items: ItemType[]
    getItemLabel: (item: ItemType) => string
    onItemClick: (item: ItemType) => void
    t: TFunction<"patient", undefined>
}

const getInitialItemCount = (items: any[]) => {
    const defaultCount = 8 // Given that there are 3 columns of results, this should always fulfill => defaultCount % 3 === 2
    return items.length <= defaultCount + 1 ? items.length : defaultCount
}

function SearchResultsSection<ItemType extends { id: string | number }>({
    title,
    items,
    getItemLabel,
    onItemClick
}: SearchResultsSectionProps<ItemType>) {
    const { t } = useTranslation("patient")
    const [itemCount, setItemCount] = useState(getInitialItemCount(items))

    useEffect(() => {
        const initialCount = getInitialItemCount(items)
        if (itemCount !== initialCount) setItemCount(initialCount)
    }, [items])

    return (
        <>
            <div className="uppercase text-sm font-semibold text-secondary-2 flex gap-5 items-center pt-14">
                {title}
                <div className="h-px bg-border-blue grow" />
            </div>
            {items.length ? (
                <div className="grid grid-cols-3 items-start gap-x-16 gap-y-8 pt-9 text-dark-blue">
                    {items.slice(0, itemCount).map((item) => (
                        <button
                            key={item.id}
                            className="text-start hover:underline underline-offset-4"
                            onClick={() => onItemClick(item)}
                        >
                            {getItemLabel(item)}
                        </button>
                    ))}
                    {items.length > itemCount && (
                        <button
                            className="text-med-blue underline underline-offset-4 text-start"
                            onClick={() => setItemCount(itemCount + getInitialItemCount(items) + 1)}
                        >
                            And {items.length - itemCount} more...
                        </button>
                    )}
                </div>
            ) : (
                <div className="text-gray-400 italic text-sm py-8">{t("contentLib.notFound")}</div>
            )}
        </>
    )
}

export default SearchResultsSection
