import { ReactNode, SVGProps } from "react"

export enum PublishStatuses {
    Publish = "Publish",
    PublishAndReset = "PublishAndReset"
}

export interface IPublishBinaryListItem {
    value: PublishStatuses
    title: string
    subTitle: string
    icon: (props: SVGProps<SVGSVGElement>) => ReactNode
}
