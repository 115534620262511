export type THtmlTag = "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "label" | "span" | "div" | "li"

export const typographyClasses = {
    xm: "text-xm leading-4",
    xs: "text-xs",
    sm: "text-sm",
    base: "text-base",
    xl: "text-xl",
    "2xl": "text-2xl",
    "3xl": "text-3xl",
    "4xl": "text-4xl",
    "5xl": "text-5xl",
    "6xl": "text-6xl",
    "7xl": "text-7xl",
    "8xl": "text-8xl",
    "9xl": "text-9xl"
}

export const widthClasses = {
    normal: "font-normal",
    medium: "font-medium",
    semibold: "font-semibold",
    bold: "font-bold"
}
