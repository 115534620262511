import React from "react"
import { EmptyListPlaceholder, ExerciseFilters, ExerciseItem, TableListV2 } from "components/v2"
import { useExerciseList } from "./useExerciseList"

const ExerciseList = () => {
    const { list, exerciseHeaderCell, visibleList, emptyTextProps } = useExerciseList()

    return (
        <div className="w-full">
            {!!list.length && <ExerciseFilters />}
            <TableListV2
                isEmpty={!visibleList.length}
                headerCells={exerciseHeaderCell}
                emptyListComponent={<EmptyListPlaceholder {...emptyTextProps} />}
                listComponent={visibleList?.map((exercise) => (
                    <ExerciseItem key={exercise.id} {...exercise} />
                ))}
            />
        </div>
    )
}

export default ExerciseList
