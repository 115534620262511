import UserAvatar from "components/UserAvatar"
import { ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { classNames, getUserName } from "utils"
import GroupAvatar from "./GroupAvatar"
import { ConversationParticipant } from "./utils"

type ConversationDetailsProps = {
    RenderDetails: (props: { participantNames: string; role: string }) => ReactNode
    users: ConversationParticipant[]
    avatarSize?: number
    onClick?: () => void
    className?: string
    withoutParticipantNames?: boolean
}

const ConversationDetails = ({
    avatarSize = 8,
    users,
    RenderDetails,
    onClick,
    className,
    withoutParticipantNames
}: ConversationDetailsProps) => {
    const { t } = useTranslation("")
    const participantNames = useMemo(() => {
        if (withoutParticipantNames) return null

        const maxParticipants = 2

        if (users.length === 1) return getUserName(users[0])
        if (users.length <= maxParticipants) {
            const participantNames = users.map(getUserName)
            return `${participantNames.slice(0, -1).join(", ")} and ${participantNames.slice(-1)}`
        }

        return users.map(({ first_name }) => first_name).join(", ")
    }, [users])

    const role =
        users.length === 1
            ? users[0].relation_type
                ? t(`common:relationType.${users[0].relation_type}`)
                : users[0].profession
                ? t(`common:professions.${users[0].profession}`)
                : t(`common:roles.${users[0].role}`)
            : t("patient:chat.groupChat")

    return (
        <div
            className={classNames(onClick && "cursor-pointer", "flex items-center gap-3", className)}
            onClick={onClick}
        >
            <div className={classNames("flex shrink-0", users.length > 1 && "px-2")}>
                {users.length === 1 ? (
                    users.map((user) => <UserAvatar key={user.id} user={user} size={avatarSize} />)
                ) : (
                    <GroupAvatar className="-mx-2" />
                )}
            </div>
            <div className="grow">
                <RenderDetails participantNames={participantNames} role={role} />
            </div>
        </div>
    )
}

export default ConversationDetails
