import { SVGProps } from "react"

const CameraIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.82689 6.49374C6.46581 7.07238 5.86127 7.45282 5.186 7.54879C4.80655 7.60271 4.42853 7.66107 4.05199 7.72382C2.99912 7.89926 2.25 8.82547 2.25 9.89287V18.3188C2.25 19.5615 3.25736 20.5688 4.5 20.5688H19.5C20.7426 20.5688 21.75 19.5615 21.75 18.3188V9.89288C21.75 8.82548 21.0009 7.89927 19.948 7.72383C19.5715 7.66108 19.1934 7.60272 18.814 7.54879C18.1387 7.45283 17.5342 7.07239 17.1731 6.49375L16.3519 5.17774C15.9734 4.57121 15.3294 4.17723 14.6155 4.1389C13.7496 4.0924 12.8775 4.06885 12 4.06885C11.1225 4.06885 10.2504 4.0924 9.3845 4.1389C8.6706 4.17723 8.02658 4.57121 7.64809 5.17774L6.82689 6.49374Z"
            stroke="#3C7FF9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.5 13.0688C16.5 15.5541 14.4853 17.5688 12 17.5688C9.51472 17.5688 7.5 15.5541 7.5 13.0688C7.5 10.5836 9.51472 8.56885 12 8.56885C14.4853 8.56885 16.5 10.5836 16.5 13.0688Z"
            stroke="#3C7FF9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M18.75 10.8188H18.7575V10.8263H18.75V10.8188Z"
            stroke="#3C7FF9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)
export default CameraIcon
