import { AppointmentStatus } from "@prisma/client"
import { Spinner } from "components/common"
import { BanIcon, CheckIcon, XIcon } from "components/icons"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"

const appointmentStatusButtons: {
    id: AppointmentStatus
    classNameUnselected: string
    classNameSelected: string
    icon?: ReactNode
}[] = [
    {
        id: "occured",
        classNameUnselected: "border-med-green text-med-green hover:bg-med-green hover:text-white",
        classNameSelected: "border-med-green text-white bg-med-green",
        icon: <CheckIcon className="text-xl shrink-0" />
    },

    {
        id: "canceled",
        classNameUnselected: "border-warning text-warning hover:bg-warning hover:text-white",
        classNameSelected: "border-warning text-white bg-warning",
        icon: <XIcon className="text-xl shrink-0" />
    },
    {
        id: "not_showed",
        classNameUnselected: "border-negative text-negative hover:bg-negative hover:text-white",
        classNameSelected: "border-negative bg-negative text-white",
        icon: <BanIcon className="text-xl shrink-0" />
    }
]

const AppointmentStatusButtons = ({
    status,
    setStatus,
    loading,
    roundedClass,
    size
}: {
    status: AppointmentStatus
    setStatus: (status: AppointmentStatus) => void
    loading: AppointmentStatus
    roundedClass: `rounded-${"2xl" | "lg"}`
    size: "small" | "large"
}) => {
    const { t } = useTranslation("common")
    return (
        <>
            {appointmentStatusButtons.map(({ id, classNameSelected, classNameUnselected, icon }) => {
                return (
                    <button
                        key={id}
                        onClick={() => setStatus(id)}
                        className={classNames(
                            classNames(
                                roundedClass,
                                size === "large" ? "px-6 py-3 w-40 font-medium" : "p-2 w-24 h-8",
                                "flex justify-center gap-2 border items-center cursor-pointer"
                            ),
                            status === id ? classNameSelected : classNameUnselected
                        )}
                    >
                        {loading === id ? (
                            <Spinner size={size === "large" ? 8 : 5} />
                        ) : (
                            <>
                                {size === "large" && icon}
                                {t(`common:appointmentStatuses.${id}`)}
                            </>
                        )}
                    </button>
                )
            })}
        </>
    )
}

export default AppointmentStatusButtons
