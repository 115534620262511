import { string, number, object, array } from "yup"
import { ExerciseType, Gender, Language, PreviousInstance } from "@prisma/client"
import { RadioGroupMode } from "types/exercises/ExerciseDef"
import i18n from "locales"

const createRangeArray = (n: number, m: number): number[] => Array.from({ length: m - n + 1 }, (_, i) => n + i)

export const minAgeList = createRangeArray(1, 18).map((num) => ({ id: num, title: `${num}` }))
export const maxAgeList = createRangeArray(18, 65).map((num) => ({ id: num, title: `${num}` }))

export const getLanguageList = () => [
    { id: Language.en, title: i18n.t("en", { ns: "exercise" }) },
    { id: Language.he, title: i18n.t("he", { ns: "exercise" }) }
]

export const genders = [
    { id: Gender.male, title: "male" },
    { id: Gender.female, title: "female" },
    { id: Gender.non_binary, title: "non_binary" },
    { id: Gender.transgender, title: "transgender" },
    { id: Gender.intersex, title: "intersex" },
    { id: Gender.other, title: "other" }
]

export const getExerciseSchemaValidation = () =>
    object().shape({
        title: string()
            .required(i18n.t("validation.titleIsRequired", { ns: "exercise" }))
            .max(191, i18n.t("validation.maxLength", { ns: "exercise", max: 191 })),
        diagnose_ids: array()
            .of(number())
            .min(1, i18n.t("validation.atListOneDiagnoseRequired", { ns: "exercise" }))
            .required(),
        gender_mode: string().oneOf(Object.values(RadioGroupMode)).required(),
        min_age: number().min(1).max(18).required(),
        max_age: number().min(18).max(65).required(),
        age_mode: string().oneOf(Object.values(RadioGroupMode)).required(),
        description: string()
            .required(i18n.t("validation.descriptionIsRequired", { ns: "exercise" }))
            .max(255, i18n.t("validation.maxLength", { ns: "exercise", max: 255 })),
        public_name: string()
            .required(i18n.t("validation.publicNameIsRequired", { ns: "exercise" }))
            .max(120, i18n.t("validation.maxLength", { ns: "exercise", max: 120 })),
        brief_summary: string().max(120, i18n.t("validation.maxLength", { ns: "exercise", max: 120 })),
        gender_custom: string().oneOf(Object.values(Gender)).required(),
        language: string().oneOf(Object.values(Language)).required(),
        role_ids: array()
            .of(number())
            .min(1, i18n.t("validation.atListOneRoleIsRequired", { ns: "exercise" }))
            .required(),
        exercise_type: string().oneOf(Object.values(ExerciseType)).required(),
        previous_instance: string().oneOf(Object.values(PreviousInstance)).required()
    })
