import { SVGProps } from "react"

const ExerciseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.85993 2.42829C2.85993 1.16593 3.88328 0.142578 5.14565 0.142578H10.8599C12.1223 0.142578 13.1456 1.16593 13.1456 2.42829V2.73425C13.8284 3.12919 14.2885 3.86733 14.2885 4.71401V5.01997C14.9712 5.4149 15.4314 6.15305 15.4314 6.99972V11.5712C15.4314 12.8335 14.408 13.8569 13.1456 13.8569H2.85993C1.59757 13.8569 0.574219 12.8335 0.574219 11.5712V6.99972C0.574219 6.15305 1.03433 5.4149 1.71708 5.01997V4.71401C1.71708 3.86733 2.17719 3.12919 2.85993 2.73425V2.42829ZM4.00279 2.42829H12.0028C12.0028 1.79711 11.4911 1.28544 10.8599 1.28544H5.14565C4.51446 1.28544 4.00279 1.79711 4.00279 2.42829ZM2.85993 4.71401H13.1456C13.1456 4.21729 12.8284 3.7932 12.3838 3.63603C12.2654 3.59418 12.1374 3.57115 12.0028 3.57115H4.00279C3.86819 3.57115 3.74021 3.59418 3.62179 3.63603C3.17713 3.7932 2.85993 4.21729 2.85993 4.71401ZM2.85993 5.85686C2.72534 5.85686 2.59735 5.87989 2.47893 5.92175C2.03428 6.07891 1.71708 6.50301 1.71708 6.99972V11.5712C1.71708 12.2023 2.22875 12.714 2.85993 12.714H13.1456C13.7768 12.714 14.2885 12.2023 14.2885 11.5712V6.99972C14.2885 6.50301 13.9713 6.07891 13.5266 5.92175C13.4082 5.87989 13.2802 5.85686 13.1456 5.85686H2.85993Z"
            fill="currentColor"
        />
    </svg>
)

export default ExerciseIcon
