import { Gender } from "@prisma/client"
import { PortalNotificationData } from "@sequel-care/types"
import { isSameDay, isSameWeek } from "date-fns"
import DOMPurify from "dompurify"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { useAllQuestionnaires, usePatientList, useUsersInCurrentOrg } from "store/hooks"

export const useNotificationText = (notification: PortalNotificationData, fullName: string, gender: Gender) => {
    const { t } = useTranslation("patient")
    const questionnaires = useAllQuestionnaires()

    return useMemo(() => {
        if (!notification) return null
        if (notification.type === "question_alert") return DOMPurify.sanitize(notification.body)

        const questionnaire =
            notification.ref_data?.questionnaire_id &&
            questionnaires?.find(({ id }) => id === notification.ref_data.questionnaire_id)

        return t(`portalNotifications.${notification.type}`, {
            fullName,
            questionnaire: questionnaire ? `${questionnaire?.friendly_name} (${questionnaire?.title})` : "",
            body: DOMPurify.sanitize(notification?.body),
            context: gender,
            contactName: notification?.ref_data?.contact_name,
            eventTitle: notification?.ref_data?.event_title,
            eventType: notification?.ref_data?.event_type,
            interpolation: { escapeValue: false }
        })
    }, [notification, questionnaires, fullName])
}

export const useUserForAvatar = (notification?: PortalNotificationData) => {
    const patients = usePatientList()
    const team = useUsersInCurrentOrg()
    return useMemo(() => {
        const patient = patients.find((patient) => {
            if (!notification) return null
            else return patient.user_id === notification.from_user_id
        })
        if (patient) return patient.user

        const teamMember = team?.find((user) => {
            if (!notification) return null
            else return user.user_id === notification.from_user_id
        })
        return teamMember?.user
    }, [patients, team, notification])
}

const dateToNotificationRange = (date: Date, t: TFunction) => {
    if (isSameDay(date, new Date())) return t("dates:today")
    if (isSameWeek(date, new Date())) return t("dates:thisWeek")
    else return t("dates:older")
}

export const useNotificationRanges = (notifications: PortalNotificationData[], t: TFunction) => {
    return useMemo(() => {
        let lastRange: string

        return Object.fromEntries(
            notifications.map(({ date }, index) => {
                const currentRange = dateToNotificationRange(new Date(date), t)
                if (!lastRange || lastRange !== currentRange) {
                    lastRange = currentRange
                    return [index, currentRange]
                }

                return [index, undefined]
            })
        )
    }, [notifications])
}
