import { RenderItemProps } from "forms/ListField"
import { getContactFields } from "./fields"
import { PatientFormContact } from "@sequel-care/types/Patient"
import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react"
import { FormContext } from "forms/FormWithSteps"
import { getStateValue } from "utils"
import { FormError } from "forms/types"
import { RenderFields } from "forms"
import { getExistingFamily } from "api"
import { useTranslation } from "react-i18next"

export const RenderContact = ({
    id,
    item,
    errors = {},
    index,
    setValue,
    ItemWrapper,
    useFields,
    classList,
    gridTemplateColumns,
    edit = undefined,
    setItemErrors,
    setEdit,
    removeDisabled
}: RenderItemProps<ReturnType<typeof getContactFields>, PatientFormContact>) => {
    const { setErrors: setAllErrors } = useContext(FormContext)
    const fields = useFields(item)
    const [loading, setLoading] = useState({ email: false })
    const { t } = useTranslation("patient")

    const setItem: Dispatch<SetStateAction<PatientFormContact>> = useCallback((updatedItem) => {
        setValue((value) =>
            value.map((item, itemIndex) => (itemIndex !== index ? item : getStateValue(updatedItem, item)))
        )
    }, [])

    const handleRemove = useCallback(() => {
        setValue((value) => value.filter((_, i) => index !== i))
        setAllErrors((allErrors) => ({
            ...allErrors,
            [id]: (allErrors[id] as Exclude<FormError, string>)?.filter((_, i: number) => index !== i)
        }))
    }, [index])

    const fetchContact = async () => {
        if (!item.email) return
        setLoading({ email: true })
        const existingContact = await getExistingFamily(item.email)
        setLoading({ email: false })
        if (!existingContact) {
            return
        }
        setItemErrors({
            ...errors,
            email: t("patientForm.duplicated_email")
        })
    }

    return (
        <ItemWrapper
            key={index}
            setErrors={setItemErrors}
            {...{
                item,
                setItem,
                index,
                handleRemove,
                fields,
                errors,
                setValue,
                classList,
                setEdit,
                edit,
                removeDisabled,
                gridTemplateColumns
            }}
        >
            <RenderFields
                state={item}
                setState={setItem}
                errors={{}}
                setErrors={setItemErrors}
                isEdit={edit === index}
                onBlur={{ email: fetchContact }}
                {...{ fields, classList, loading }}
            />
        </ItemWrapper>
    )
}
