import React from "react"
import { FormControlPropsV2, FormInputWrapper, IGroupOptionV2, IOptionV2, SelectV2 } from "components/v2/index"
import { useController } from "react-hook-form"

interface IControlledSelectV2Props<T> extends FormControlPropsV2<T> {
    options: (IOptionV2 | IGroupOptionV2)[]
    mode?: "single" | "multiple"
    isAutocompleteEnabled?: boolean
    modalRef?: React.RefObject<HTMLDivElement>
}

const ControlledSelectV2 = <T,>({
    label,
    name,
    control,
    classList,
    isRequired,
    isErrorTextShowing,
    modalRef,
    ...inputProps
}: IControlledSelectV2Props<T>) => {
    const { error, mode = "single" } = inputProps
    const isSingleMode = mode === "single"

    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    const selectedKeys = (isSingleMode ? [value] : value) as string[]

    const setValue = (value: string[]) => {
        onChange(isSingleMode ? value[0] : value)
    }

    return (
        <FormInputWrapper
            {...{ label, isRequired, error, isErrorTextShowing, modalRef, classList: classList?.wrapper }}
        >
            <SelectV2
                selectedKeys={selectedKeys}
                onChange={setValue}
                {...inputProps}
                classList={{ input: classList?.input }}
                error={error}
                modalRef={modalRef}
            />
        </FormInputWrapper>
    )
}

export default ControlledSelectV2
