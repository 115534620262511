import { SVGProps } from "react"

const EditUnitDetailsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.33203 2.66602H2.66536C2.31174 2.66602 1.9726 2.80649 1.72256 3.05654C1.47251 3.30659 1.33203 3.64573 1.33203 3.99935V13.3327C1.33203 13.6863 1.47251 14.0254 1.72256 14.2755C1.9726 14.5255 2.31174 14.666 2.66536 14.666H11.9987C12.3523 14.666 12.6915 14.5255 12.9415 14.2755C13.1916 14.0254 13.332 13.6863 13.332 13.3327V8.66602"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.332 1.66617C12.5972 1.40095 12.957 1.25195 13.332 1.25195C13.7071 1.25195 14.0668 1.40095 14.332 1.66617C14.5972 1.93138 14.7462 2.29109 14.7462 2.66617C14.7462 3.04124 14.5972 3.40095 14.332 3.66617L7.9987 9.9995L5.33203 10.6662L5.9987 7.9995L12.332 1.66617Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)
export default EditUnitDetailsIcon
