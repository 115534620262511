import {
    SingleSelectOptionV2,
    MultiSelectOptionV2,
    ExpandableRowV2,
    IOptionV2,
    IGroupOptionV2
} from "components/v2/components/common/selects"

interface ISelectMenuV2Props {
    options: (IOptionV2 | IGroupOptionV2)[]
    mode: "single" | "multiple"
    selectedKeys: (string | number)[]
    handleSelectOption: (option: string | number) => void
    isSearching: boolean
}

const SelectMenuV2 = ({ options, mode, selectedKeys, handleSelectOption, isSearching }: ISelectMenuV2Props) => {
    return (
        <>
            {options.map((option) =>
                "groupName" in option ? (
                    <ExpandableRowV2 key={option.groupName} header={option.groupName} isSearching={isSearching}>
                        {option.options.map((opt: IOptionV2) =>
                            mode === "multiple" ? (
                                <MultiSelectOptionV2
                                    option={opt}
                                    isSelected={selectedKeys.includes(opt.id)}
                                    handleSelectOption={handleSelectOption}
                                    key={opt.id}
                                />
                            ) : (
                                <SingleSelectOptionV2
                                    option={opt}
                                    handleSelectOption={handleSelectOption}
                                    key={opt.id}
                                />
                            )
                        )}
                    </ExpandableRowV2>
                ) : mode === "multiple" ? (
                    <MultiSelectOptionV2
                        option={option}
                        key={option.id}
                        isSelected={selectedKeys.includes(option.id)}
                        handleSelectOption={handleSelectOption}
                    />
                ) : (
                    <SingleSelectOptionV2 option={option} handleSelectOption={handleSelectOption} key={option.id} />
                )
            )}
        </>
    )
}

export default SelectMenuV2
