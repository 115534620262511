import { SVGProps } from "react"

const CompletedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.5 12.5C9.81371 12.5 12.5 9.81371 12.5 6.5C12.5 3.18629 9.81371 0.5 6.5 0.5C3.18629 0.5 0.5 3.18629 0.5 6.5C0.5 9.81371 3.18629 12.5 6.5 12.5ZM9.39241 5.14335C9.57514 4.8921 9.51959 4.54031 9.26835 4.35759C9.0171 4.17486 8.66531 4.23041 8.48259 4.48165L5.86977 8.07428L4.46025 6.66475C4.24058 6.44508 3.88442 6.44508 3.66475 6.66475C3.44508 6.88442 3.44508 7.24058 3.66475 7.46025L5.53975 9.33525C5.65608 9.45157 5.8175 9.51114 5.9815 9.49828C6.1455 9.48541 6.29566 9.40139 6.39241 9.26835L9.39241 5.14335Z"
            fill="#3C7FF9"
        />
    </svg>
)

export default CompletedIcon
