import { PortalNotificationData } from "@sequel-care/types"
import { last } from "lodash"
import {
    AppSidebar,
    ConversationSidebar,
    EventSidebar,
    isConversationSidebar,
    isEventSidebar,
    isPatientSidebar,
    isTherapistSidebar
} from "types/AppSidebar"
import { AppAction, RootState } from "types/Redux"
import { sendMixpanelReport } from "utils/mixpanel"
import * as types from "./types"
import { IntakeCandidate } from "components/v2/constants/enums/intake"

export type SimplifiedSidebar<Sidebar extends AppSidebar> = Omit<Sidebar, "type" | "isOpen">
const getCurrentSidebar = (getState: () => RootState) => last(getState().sidebars.stack)

export const pushToSidebarStack = (sidebar: AppSidebar) => ({ type: types.PUSH_TO_SIDEBAR_STACK, payload: sidebar })

export function updateCurrentSidebar<Sidebar extends AppSidebar>(sidebar: SimplifiedSidebar<Sidebar>) {
    return { type: types.UPDATE_CURRENT_SIDEBAR, payload: sidebar }
}

export const goToThePrevSidebar = () => {
    return { type: types.GO_TO_THE_PREVIOUS_SIDEBAR }
}

export const deleteLastElementFromSidebar = () => {
    return { type: types.DELETE_THE_LAST_ELEMENT_SIDEBAR }
}

export const toggleCurrentSidebar = (): AppAction => async (dispatch, getState) => {
    const currentSidebar = getCurrentSidebar(getState)
    setTimeout(() => {
        const currentSidebarInTimeout = getCurrentSidebar(getState)
        // Putting this inside a timeout and adding this condition makes sure that if the trigger for toggling
        // was a click to open a different sidebar, we do not dispatch this action needlessly (as the sidebar will be closing anyway)
        if (currentSidebar === currentSidebarInTimeout) dispatch({ type: types.TOGGLE_CURRENT_SIDEBAR })
    })
}

export const pushEventSidebarToStack = (sidebar: SimplifiedSidebar<EventSidebar>): AppAction<any> => {
    return async (dispatch, getState) => {
        const currentSidebar = getCurrentSidebar(getState)
        if (
            isEventSidebar(currentSidebar) &&
            sidebar.id === currentSidebar.id &&
            sidebar.date_on_timeline === currentSidebar.date_on_timeline
        ) {
            if (!currentSidebar.isOpen && sidebar.initialScrollTo !== currentSidebar.initialScrollTo)
                dispatch(updateCurrentSidebar<EventSidebar>(sidebar))
            return dispatch(toggleCurrentSidebar())
        }

        dispatch(pushToSidebarStack({ type: "event", isOpen: true, ...sidebar }))
    }
}

export const pushConversationSidebarToStack = (sidebar: SimplifiedSidebar<ConversationSidebar>): AppAction<any> => {
    return async (dispatch, getState) => {
        const currentSidebar = getCurrentSidebar(getState)
        if (isConversationSidebar(currentSidebar)) {
            if (!currentSidebar.isOpen) dispatch(updateConversationSidebar(sidebar))
            return dispatch(toggleCurrentSidebar())
        }

        dispatch(pushToSidebarStack({ type: "conversation", isOpen: true, ...sidebar }))
    }
}

export const updateConversationSidebar = (sidebar: SimplifiedSidebar<ConversationSidebar>) =>
    updateCurrentSidebar<ConversationSidebar>(sidebar)

export const pushPatientProfileSidebarToStack =
    ({patientId, candidate}: {patientId: number, candidate?: IntakeCandidate}): AppAction<any> =>
    async (dispatch, getState) => {
        const currentSidebar = getCurrentSidebar(getState)
        if (isPatientSidebar(currentSidebar) && currentSidebar.patientId === patientId)
            return dispatch(toggleCurrentSidebar())

        sendMixpanelReport("Patient Sidebar opened")
        dispatch(
            pushToSidebarStack({
                type: "patient",
                isOpen: true,
                candidate,
                patientId
            })
        )
    }

export const toggleTherapistProfile =
    (userId: number | null = null): AppAction<any> =>
    async (dispatch, getState) => {
        const currentSidebar = getCurrentSidebar(getState)
        if (isTherapistSidebar(currentSidebar) && (currentSidebar.userId === userId || !userId)) {
            dispatch(toggleCurrentSidebar())
        } else if (isTherapistSidebar(currentSidebar)) {
            dispatch(updateCurrentSidebar({ type: "therapist", isOpen: true, userId }))
        } else {
            dispatch(pushToSidebarStack({ type: "therapist", isOpen: true, userId }))
        }
    }

export const pushNotificationToSidebarStack = (notification: PortalNotificationData) =>
    pushEventSidebarToStack({
        id: notification.ref_data.event_id as number,
        date_on_timeline: new Date(notification.ref_data.date_on_timeline as string),
        patient_id: notification.patient_id,
        initialScrollTo: ["comment_mention", "comment_followed"].includes(notification?.type) ? "activity" : undefined,
        context: {
            context: "notification",
            time: new Date()
        }
    })
