import { SVGProps } from "react"

const CheckCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 10.8137 4.18629 13.5 7.5 13.5ZM10.3924 6.14335C10.5751 5.8921 10.5196 5.54031 10.2683 5.35759C10.0171 5.17486 9.66531 5.23041 9.48259 5.48165L6.86977 9.07428L5.46025 7.66475C5.24058 7.44508 4.88442 7.44508 4.66475 7.66475C4.44508 7.88442 4.44508 8.24058 4.66475 8.46025L6.53975 10.3352C6.65608 10.4516 6.8175 10.5111 6.9815 10.4983C7.1455 10.4854 7.29566 10.4014 7.39241 10.2683L10.3924 6.14335Z"
            fill="#3C7FF9"
        />
    </svg>
)

export default CheckCircle
