import React from "react"
import { classNames } from "utils"
import { EventTypes } from "types"
import { eventTypeColors, eventTypeIcons } from "./tagWithIconConstants"

interface IEventTypeProps {
    eventType: EventTypes
    className?: string
}

const TagWithIcon: React.FC<IEventTypeProps> = ({ eventType, className }) => {
    const eventTypeColor = eventTypeColors[eventType]
    const EventTypeIcons = eventTypeIcons[eventType]

    return (
        <div
            className={classNames(
                eventTypeColor,
                "w-[30px] h-[30px] pt-1 gap-sm rounded bg-lime-50 flex justify-center items-center",
                className
            )}
        >
            <EventTypeIcons />
        </div>
    )
}

export default TagWithIcon
