import Sidebar from "components/AppSidebar"
import Head from "next/head"
import { ReactNode } from "react"
import { useCurrentRole } from "store/hooks"
import ContentLibrary from "components/ContentLibrary"
import { JumpTo } from "components/Patient/JumpTo"
import SidebarStack from "sidebars"
import { Authenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import AppLoading from "components/AppLoading"
import { AuthProvider } from "utils/auth"
import { Header, authenticatorFormFields } from "utils/auth/authenticatorComponent"
import ModalContainer from "modals"
import { Language } from "components/v2/constants/enums"

interface IAuthorizedLayout {
    children: ReactNode
    language: Language
}

const AuthorizedLayout = ({ children, language }: IAuthorizedLayout) => {
    const currentRole = useCurrentRole()
    return (
        <>
            <Head>
                <title>SequelCare | Virtual Care Provider Portal</title>
                <script
                    id="Cookiebot"
                    src="https://consent.cookiebot.com/uc.js"
                    data-cbid="5bf51162-3c33-41b5-b093-ab130d9bae55"
                    data-blockingmode="auto"
                    data-culture={language}
                    type="text/javascript"
                    async
                />
            </Head>

            <Authenticator hideSignUp components={{ Header }} formFields={authenticatorFormFields}>
                {({ signOut, user }) => (
                    <AuthProvider LoadingState={AppLoading} signOut={signOut} user={user}>
                        {currentRole === null ? null : (
                            <>
                                <div
                                    className={`flex ltr:font-inter ${
                                        language === "he" ? "rtl:font-assistant" : "rtl:font-open-sans"
                                    }`}
                                >
                                    <Sidebar />
                                    <main className="flex flex-col relative flex-grow min-h-screen max-h-screen overflow-y-auto">
                                        {children}
                                        <ModalContainer />
                                    </main>
                                </div>
                                <SidebarStack />
                                <ContentLibrary />
                                <JumpTo />
                            </>
                        )}
                    </AuthProvider>
                )}
            </Authenticator>
        </>
    )
}

export default AuthorizedLayout
