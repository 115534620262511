import { useDispatch } from "store"
import { useExerciseSelector } from "store/hooks"
import { useEffect, useMemo } from "react"
import { getExercisesListAction } from "store/exercises/actions"
import { closeModal, openCreateExerciseModal } from "store/modal/actions"
import { filterExercisesByDiagnoseId, searchExerciseByText } from "utils/exercises"
import { useTranslation } from "react-i18next"
import i18n from "locales"

export const useExerciseList = () => {
    const { t } = useTranslation("exercise")
    const dispatch = useDispatch()
    const { list, searchParams } = useExerciseSelector()

    const exerciseHeaderCell = useMemo(
        () => [
            {
                title: i18n.t("eventType.exercise", { ns: "common" }),
                className: "w-2/5"
            },
            {
                title: i18n.t("diagnoses", { ns: "exercise" }),
                className: "w-1/5"
            },
            {
                title: i18n.t("status", { ns: "common" }),
                className: "w-1/5"
            },
            {
                title: i18n.t("creator", { ns: "exercise" }),
                className: "w-1/5"
            },
            { className: "w-[10px]" }
        ],
        []
    )

    const visibleList = useMemo(() => {
        let newList = [...list]
        const { text, diagnose, status, creator } = searchParams

        if (diagnose) {
            newList = filterExercisesByDiagnoseId(newList, diagnose)
        }

        if (status) {
            newList = newList.filter((exercise) => exercise.status === status)
        }

        if (creator) {
            newList = newList.filter(({ creator: { user } }) => user.id === creator)
        }

        if (text) {
            newList = searchExerciseByText(newList, text)
        }
        return newList
    }, [list, searchParams])

    const emptyTextProps = {
        title: t("emptyList.title"),
        subTitle: t("emptyList.subTitle"),
        btnText: t("createExercise"),
        onClick: () => {
            dispatch(openCreateExerciseModal({}))
        }
    }

    useEffect(() => {
        dispatch(getExercisesListAction())
    }, [])

    useEffect(() => {
        return () => {
            dispatch(closeModal())
        }
    }, [])

    return {
        list,
        visibleList,
        exerciseHeaderCell,
        emptyTextProps
    }
}
