import { Language, UserRole } from "@prisma/client"
import { defaultInputClassNames } from "forms"
import SuperSelect from "forms/SuperSelect"
import { getItemsFromEnum } from "forms/SuperSelect/utils"
import { Dispatch, SetStateAction, useMemo } from "react"
import { TFunction } from "i18next"
import ContentLibHeader from "../ContentLibHeader"
import { CategoryObj, LibrarySort, RenderLibraryProps } from "./types"

type RenderHeaderProps<CategoryType> = Pick<
    RenderLibraryProps<any, CategoryType>,
    "categories" | "type" | "RenderBreadCrumb"
> & {
    role: UserRole
    setRole: Dispatch<SetStateAction<UserRole>>
    language: Language
    setLanguage: Dispatch<SetStateAction<Language>>
    sort: LibrarySort
    setSort: Dispatch<SetStateAction<LibrarySort>>
    t: TFunction<"patient", undefined>
}

function RenderHeader<CategoryType extends CategoryObj>({
    role,
    setRole,
    language,
    setLanguage,
    categories,
    type,
    RenderBreadCrumb,
    sort,
    setSort,
    t
}: RenderHeaderProps<CategoryType>) {
    const languages = useMemo(() => getItemsFromEnum(Language, (item) => t(`common:languages.${item}`)), [t])
    const roles = useMemo(() => getItemsFromEnum(UserRole, (item) => t(`common:roles.${item}`)), [t]).filter(
        (role) => role.id != UserRole.admin && role.id != UserRole.therapist
    )
    const sorts = useMemo(() => getItemsFromEnum(LibrarySort, (item) => t(`common:sort.${item}`)), [t])

    return (
        <div className="flex justify-between items-center">
            <ContentLibHeader
                view={`${type}.cards`}
                title={t(`contentLib.titles.${type}`)}
                subtitle={
                    categories ? (
                        <div className="text-med-blue text-sm font-medium pt-2 flex items-center gap-2">
                            <RenderBreadCrumb categories={categories} category={categories.currentCategory} />
                        </div>
                    ) : undefined
                }
            />
            <div className="flex items-center gap-2">
                {type === "questionnaire" ? (
                    <SuperSelect
                        id="persona-selector"
                        value={role}
                        setValue={setRole}
                        items={roles}
                        classList={{ input: defaultInputClassNames.replace("w-full", "w-36") }}
                        withSearch={false}
                    />
                ) : undefined}
                <SuperSelect
                    id="language-selector"
                    value={language}
                    setValue={setLanguage}
                    items={languages}
                    classList={{ input: defaultInputClassNames.replace("w-full", "w-36") }}
                    withSearch={false}
                />
                {type === "questionnaire" ? (
                    <SuperSelect
                        id="language-selector"
                        value={sort}
                        setValue={setSort}
                        items={sorts}
                        classList={{ input: defaultInputClassNames.replace("w-full", "w-36") }}
                        withSearch={false}
                    />
                ) : undefined}
            </div>
        </div>
    )
}

export default RenderHeader
