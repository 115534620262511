import React, { ReactNode } from "react"
import { classNames } from "utils"

interface ITableCellV2Props {
    children: ReactNode
    className?: string
}

const TableCellV2: React.FC<ITableCellV2Props> = ({ children, className }) => (
    <td className={classNames("p-4", className)}>{children}</td>
)

export default TableCellV2
