import { classNames } from "utils"
import { TagV2, TypographyV2 } from "components/v2"
import React from "react"
import { IPublishBinaryListItem } from "components/v2/types"

interface IBinarySelector2Props {
    isLeftSelected?: boolean
    onChange: (value: string) => void
    options: IPublishBinaryListItem[]
}

const BinarySelector2: React.FC<IBinarySelector2Props> = ({ isLeftSelected = true, options, onChange }) => {
    const onItemClick = (value: string) => () => {
        onChange(value)
    }
    return (
        <div className="flex flex-row gap-[8px] mt-2">
            {options.map(({ title, subTitle, value, icon: Icon }, idx) => {
                const isFocused = idx === 0 ? isLeftSelected : !isLeftSelected
                return (
                    <div
                        key={idx}
                        onClick={onItemClick(value)}
                        className={classNames(
                            "flex flex-col flex-1 border rounded-lg h-[118px] p-[10px] gap-y-4",
                            isFocused ? "border-[#3C7FF9] shadow-[0_0_8px_0_#3C7FF980]" : "border-[#E5E7EB]"
                        )}
                    >
                        <TagV2
                            icon={
                                <Icon
                                    className={isFocused ? "text-med-blue" : "text-gray-400"}
                                    width={16}
                                    height={16}
                                />
                            }
                            color={isFocused ? "blue" : "gray"}
                        />
                        <TypographyV2 width="bold" type="xs">
                            {title}
                            <TypographyV2 type="xs">{subTitle}</TypographyV2>
                        </TypographyV2>
                    </div>
                )
            })}
        </div>
    )
}

export default BinarySelector2
