import { useFloating } from "@floating-ui/react-dom"
import { Transition } from "@headlessui/react"
import { ModalCompatiblePortal } from "components/common"
import { useEffect } from "react"

interface IPopoverV2Props {
    open: boolean
    children: React.ReactNode
    elementRef: React.RefObject<HTMLElement>
}

const ClickablePopoverV2 = ({ children, open, elementRef }: IPopoverV2Props) => {
    const { update, refs, floatingStyles } = useFloating({
        placement: "bottom-start"
    })

    useEffect(() => {
        refs.setReference(elementRef.current)
        update()
    }, [elementRef, open])

    return (
        <ModalCompatiblePortal>
            <div ref={refs.setFloating} style={floatingStyles} className="z-[1000]">
                <Transition
                    show={open}
                    enter="transition duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="w-fit pointer-events-auto text-white px-3 p-2 rounded">{children}</div>
                </Transition>
            </div>
        </ModalCompatiblePortal>
    )
}

export default ClickablePopoverV2
