import { BasicUser, FlatEvent } from "@sequel-care/types"
import { AvatarWithName } from "components/UserAvatar"
import { DetailedHTMLProps, HTMLAttributes, ReactNode, useMemo, MouseEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { usePatientCollaborators } from "store/hooks"
import { classNames } from "utils"
import { getAppointmentEndTime, getDateAtHour0, localeFormat } from "utils/dates"
import { getFile } from "api"
import { Spinner } from "components/common"
import { useRecurrenceDescriptionLong } from "hooks/useRecurrenceDescription"

interface EventEditFieldProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    label: string
    children: ReactNode
    testId: string
}

export const EventDetailsField = ({ label, children, testId, ...props }: EventEditFieldProps) => {
    return (
        <div
            data-testid={testId}
            className={classNames("flex items-center gap-4 py-2 px-3 text-sm rounded", props.className)}
        >
            <div className="text-secondary-2">{label}</div>
            {children}
        </div>
    )
}

export const CollaboratorsField = ({ collaborators }: { collaborators?: number[] }) => {
    const patientCollaborators = usePatientCollaborators()
    const { t } = useTranslation()
    const collaboratorsById = useMemo(() => {
        return Object.fromEntries(patientCollaborators.map((collaborator) => [collaborator.user.id, collaborator]))
    }, [patientCollaborators])

    return (
        <EventDetailsField
            testId="sidebar-collaborators_field"
            label={t("common:collaborators")}
            className="col-span-2"
        >
            {collaborators?.length > 0 ? (
                <div className="flex items-center gap-2.5">
                    {collaborators?.map((collaborator, index) =>
                        collaboratorsById[collaborator] ? (
                            <>
                                <AvatarWithName
                                    key={collaborator}
                                    user={collaboratorsById[collaborator].user}
                                    size={5}
                                />
                                {index < collaborators.length - 1 ? ", " : ""}
                            </>
                        ) : null
                    )}
                </div>
            ) : (
                <div className="text-gray-400 italic">{t("patient:eventManager.noCollaborators")}</div>
            )}
        </EventDetailsField>
    )
}

export const DateField = ({ recurrence, date_on_timeline }: FlatEvent<string>) => {
    const { i18n, t } = useTranslation()
    return (
        <EventDetailsField testId="sidebar-date_field" label={t("common:date")} className="col-span-2">
            {localeFormat(getDateAtHour0(recurrence?.start ?? date_on_timeline), "MMMM do, yyyy", i18n)}
        </EventDetailsField>
    )
}

export const RecurrenceField = ({
    event: { recurrence: rec, parent_recurrence }
}: {
    event: FlatEvent<string, string>
}) => {
    const { t } = useTranslation("patient")
    const recurrenceDescription = useRecurrenceDescriptionLong(rec ?? parent_recurrence)

    return (
        <EventDetailsField
            testId="sidebar-recurrence_field"
            label={t("eventManager.recurrenceUpper")}
            className="col-span-2"
        >
            {recurrenceDescription || <div className="text-gray-400 italic"> {t("recurrence.noRecurrence")}</div>}
        </EventDetailsField>
    )
}

export const CreatorField = ({ creatorId }: { creatorId: number }) => {
    const collaborators = usePatientCollaborators()
    const { t } = useTranslation("common")
    const creator = collaborators.find(({ user }) => user.id === creatorId)
    return (
        <EventDetailsField testId="sidebar-creator_field" label={t("createdBy")}>
            {creator ? <AvatarWithName user={creator.user} size={5} /> : "-"}
        </EventDetailsField>
    )
}

export const CompletedAtField = ({ completed_at }: { completed_at: string }) => {
    const { t, i18n } = useTranslation("common")
    return (
        <EventDetailsField testId="sidebar-completed_at_field" label={t("completedAt")} className="col-span-2">
            {localeFormat(new Date(completed_at), "MMMM do, yyyy, p", i18n)}
        </EventDetailsField>
    )
}

export const StartsAtField = ({ event }: { event: FlatEvent<string> }) => {
    const { t, i18n } = useTranslation("common")
    const timeString = useMemo(() => localeFormat(new Date(event.date_on_timeline), "d/L/yyyy, p", i18n), [event])
    return (
        <EventDetailsField testId="sidebar-start_at_field" label={t("startsAt")}>
            {timeString}
        </EventDetailsField>
    )
}

export const EndsAtField = ({ event }: { event: FlatEvent<string> }) => {
    const { t, i18n } = useTranslation("common")

    const timeString = useMemo(() => localeFormat(getAppointmentEndTime(event), "d/L/yyyy, p", i18n), [event])
    return (
        <EventDetailsField testId="sidebar-ends_at_field" label={t("endsAt")}>
            {timeString}
        </EventDetailsField>
    )
}

export const LocationField = ({ location }: { location: string }) => {
    const { t } = useTranslation("common")
    return (
        <EventDetailsField testId="sidebar-location_field" label={t("location")}>
            {location}
        </EventDetailsField>
    )
}

export const LinkField = ({ link }: { link: string }) => {
    const { t } = useTranslation("common")
    const isLink = link.includes(".")
    return (
        <EventDetailsField testId="sidebar-link_field" label={t("link")}>
            <a
                className={classNames(isLink && "text-med-blue hover:underline")}
                href={isLink ? `https://${link}` : undefined}
            >
                {link}
            </a>
        </EventDetailsField>
    )
}

export const InviteesField = ({ invitees }: { invitees?: BasicUser[] }) => {
    const { t } = useTranslation()
    return (
        <EventDetailsField testId="sidebar-invitees_field" label={t("common:invitees")} className="col-span-2">
            {invitees?.length > 0 ? (
                <div className="flex items-center gap-2.5">
                    {invitees?.map((invitee, index) => (
                        <>
                            <AvatarWithName key={index} user={invitee} size={5} />
                            {index < invitees.length - 1 ? ", " : ""}
                        </>
                    ))}
                </div>
            ) : (
                <div className="text-gray-400 italic">{t("patient:eventManager.noInvitees")}</div>
            )}
        </EventDetailsField>
    )
}

export const FileField = ({ event }: { event: FlatEvent<string> }) => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation("common")

    const onFileClick = async (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        e.preventDefault()
        setLoading(true)
        const fileBlob = await getFile(event.attachment.id)
        const url = window.URL.createObjectURL(new Blob([fileBlob]))
        const link = document.createElement("a")

        link.href = url
        link.download = event.attachment.original_name

        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
        setLoading(false)
    }
    return (
        <EventDetailsField testId="sidebar-start_at_field" label={t("file")}>
            <a className="text-med-blue cursor-pointer hover:text-dark-blue" href="#" onClick={onFileClick}>
                {event.attachment.original_name}
            </a>
            {isLoading && <Spinner size={4} />}
        </EventDetailsField>
    )
}
