const initialTableHeader = [
    { title: "potentialPatient" },
    { title: "reviewStatus" },
    // { title: "screeningScore" },
    { title: "lastUpdate" },
    { className: "w-[10px]" }
]

const intakeTableHeader = [
    { title: "potentialPatient" },
    { title: "2ndScreening" },
    { title: "caseManager" },
    { title: "lastUpdate" },
    { className: "w-[10px]" }
]

const rejectedTableHeader = [
    { title: "potentialPatient" },
    { title: "rejectionStage" },
    { title: "rejectedBy" },
    { title: "lastUpdate" },
    { className: "w-[10px]" }
]

const defaultCaseManagers = { id: "all", title: "all_case_managers" }

const defaultRejectedBy = { id: "all", title: "rejected_by_any" }

const defaultStatuses = { id: "all", title: "any_status" }

export {
    initialTableHeader,
    intakeTableHeader,
    rejectedTableHeader,
    defaultCaseManagers,
    defaultStatuses,
    defaultRejectedBy
}
