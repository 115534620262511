import { offset, shift, useFloating } from "@floating-ui/react-dom"
import { BasicUser } from "@sequel-care/types"
import { charToColor } from "@sequel-care/utils"
import { FadeWrapper, ModalCompatiblePortal } from "components/common"
import useBoolean from "hooks/useBoolean"
import { DetailedHTMLProps, forwardRef, ImgHTMLAttributes, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { classNames, getUserName } from "utils"

type UserAvatarProps = Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "ref"> & {
    user?: BasicUser
    size?: number
    role?: string
    withTooltip?: boolean
    noImageUrl?: string
    rounded?: `rounded${string}`
}

const UserAvatar = ({ withTooltip, ...props }: UserAvatarProps) => {
    return withTooltip ? <AvatarWithTooltip {...props} /> : <RenderAvatarWithRef {...props} />
}

const RenderAvatarWithRef = forwardRef<HTMLImageElement, UserAvatarProps>(function RenderAvatar(
    {
        rounded = "rounded-full",
        size = 8,
        user = {} as BasicUser,
        role,
        className,
        withTooltip = false,
        noImageUrl,
        ...propsRest
    },
    ref
) {
    const [broken, setBroken] = useState(false)

    const image = (!broken && user.profile_image?.replace("[variant]", size < 30 ? "small" : "medium")) || noImageUrl

    useEffect(() => {
        if (broken) setBroken(false)
    }, [user.profile_image])

    const fontRatio = 0.4
    const props = {
        ...propsRest,
        className: classNames(
            "text-white  flex items-center justify-center shrink-0 whitespace-nowrap",
            (!image && charToColor(user.first_name?.[0])) ?? "bg-border-blue",
            rounded,
            className
        ),
        style: { width: `${size / 4}rem`, height: `${size / 4}rem`, fontSize: `${(size / 4) * fontRatio}rem` }
    }

    return image ? (
        <img {...props} ref={ref} src={image} onError={() => setBroken(true)} />
    ) : (
        <div {...props} ref={ref}>
            {(user.first_name?.[0]?.toUpperCase() ?? "") + (user.last_name?.[0]?.toUpperCase() ?? "")}
        </div>
    )
})

export const AvatarWithTooltip = (props: UserAvatarProps) => {
    const [hovered, setHoveredTrue, setHoveredFalse] = useBoolean()
    const { refs, floatingStyles } = useFloating({
        placement: "bottom",
        middleware: [offset(10), shift()]
    })

    const displayTimeout = useRef<number>(null)
    const onMouseEnter = () => {
        displayTimeout.current = setTimeout(setHoveredTrue, 400)
    }
    const onMouseLeave = () => {
        clearTimeout(displayTimeout.current)
        if (hovered) setHoveredFalse()
    }

    return (
        <>
            <RenderAvatarWithRef {...props} ref={refs.setReference} {...{ onMouseEnter, onMouseLeave }} />
            <ModalCompatiblePortal>
                <FadeWrapper show={hovered}>
                    <div
                        ref={refs.setFloating}
                        style={{
                            ...floatingStyles,
                            border: "1px solid rgb(222 229 242)"
                        }}
                        className="z-50 flex items-center bg-white p-3 gap-3 rounded-md"
                    >
                        <AvatarWithName {...props} textClassName="text-dark-blue font-semibold" />
                    </div>
                </FadeWrapper>
            </ModalCompatiblePortal>
        </>
    )
}

export const AvatarWithName = ({
    textClassName,
    className,
    withEmail = false,
    ...props
}: UserAvatarProps & { withEmail?: boolean; textClassName?: string; textSuffix?: string }) => {
    const { t } = useTranslation("common")
    const translation = t(`roles.${props.role}`)

    return (
        <div className={classNames("flex items-center gap-2", className)}>
            <UserAvatar {...props} />
            <div className={classNames("flex flex-col", textClassName)}>
                {getUserName(props.user)}
                {withEmail ? (
                    <div className="text-secondary font-normal">{props.user.email}</div>
                ) : (
                    props.role && (
                        <div className="text-secondary font-normal">
                            {!translation.includes("roles.") ? translation : t("common:roles.therapist")}
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default UserAvatar
