import { ReactNode } from "react"
import { classNames } from "utils"

interface DashboardCardProps {
    headerRight?: ReactNode
    title: ReactNode
    subtitle?: ReactNode
    children: ReactNode
    className?: string
    contentClassName?: string
    headerClassName?: string
}

const DashboardCard = ({
    headerRight,
    title,
    subtitle,
    className,
    children,
    contentClassName,
    headerClassName
}: DashboardCardProps) => {
    return (
        <div className={classNames("bg-white p-6 border border-border-blue", className)}>
            <div className="flex items-start justify-between">
                <div className={classNames("mb-4 flex flex-col gap-2", headerClassName)}>
                    <div className="text-dark-blue text-xl font-medium flex items-center gap-3">{title}</div>
                    {subtitle && <div className="text-text-blue">{subtitle}</div>}
                </div>
                {headerRight}
            </div>
            <div className={classNames(contentClassName)}>{children}</div>
        </div>
    )
}

export default DashboardCard
