import { Button, Modal } from "components/common"
import { useState, useEffect, useCallback, useRef, MutableRefObject } from "react"
import { minutesToMilliseconds } from "./utils"
import { useAuthContext } from "./AuthProvider"
import { useDispatch } from "store"
import { resetApp } from "store/global/actions"

import { useTranslation } from "react-i18next"

const tokenExpirationWindow = minutesToMilliseconds(30)
const startCountdownAt = minutesToMilliseconds(15)
const inactivityTimeThreshold = minutesToMilliseconds(10)

export const InactivityModal = () => {
    const { t } = useTranslation("user")
    const [timestamps, setTimestamps] = useState({ expiresIn: Infinity, minutesToExpiry: Infinity })
    let tokenRefreshCounter = 0
    const { signOut, isLoading } = useAuthContext()
    const dispatch = useDispatch()
    const inactivityTimer: MutableRefObject<ReturnType<typeof setInterval>> = useRef(null)
    const jwtInterval: MutableRefObject<ReturnType<typeof setInterval>> = useRef(null)
    const inactiveTime = useRef(0)
    const expiration = useRef(null)

    const doLogOut = useCallback(() => {
        signOut()
        dispatch(resetApp())
    }, [signOut])

    useEffect(() => {
        if (!isLoading) {
            if (!expiration.current) expiration.current = Date.now() + tokenExpirationWindow

            const resetInactivity = () => {
                if (inactivityTimer.current) clearInterval(inactivityTimer.current)
                if (jwtInterval.current) clearInterval(jwtInterval.current)

                inactivityTimer.current = setInterval(() => (inactiveTime.current += 5000), 5000)
                jwtInterval.current = setInterval(() => {
                    const now = Date.now()

                    if (inactiveTime.current >= inactivityTimeThreshold) {
                        if (now >= expiration.current) {
                            clearInterval(jwtInterval.current)
                            return doLogOut()
                        }

                        if (now > expiration.current - startCountdownAt) {
                            // We start incrementing here to trigger the modal and run the counter inside it
                            const expiresIn = expiration.current - now
                            setTimestamps({ expiresIn, minutesToExpiry: Math.round(expiresIn / 60000) })
                        }
                    } else if (now >= expiration.current) {
                        expiration.current += tokenExpirationWindow
                    }
                }, 5000)
            }

            const handleActivity = () => {
                inactiveTime.current = 0
                resetInactivity()
            }

            window.addEventListener("mousemove", handleActivity)
            window.addEventListener("mousedown", handleActivity)
            window.addEventListener("keydown", handleActivity)

            resetInactivity()

            return () => {
                window.removeEventListener("mousemove", handleActivity)
                window.removeEventListener("mousedown", handleActivity)
                window.removeEventListener("keydown", handleActivity)
                clearInterval(inactivityTimer.current)
                clearInterval(jwtInterval.current)
                doLogOut()
            }
        }
    }, [doLogOut, tokenRefreshCounter, isLoading])

    const show = timestamps.expiresIn < startCountdownAt

    useEffect(() => {
        let timeout: NodeJS.Timeout = null
        if (show) timeout = setTimeout(() => doLogOut(), startCountdownAt / 2)

        return () => clearTimeout(timeout)
    }, [show])

    return (
        <Modal show={show} onClose={() => {}} cardClassName="p-8 gap-8" noScroll>
            <h2 className="text-xl font-semibold text-dark-blue mb-2">{t("inactivityModal.title")}</h2>
            <p>
                {t("inactivityModal.youWillBeLoggedOut", {
                    time:
                        timestamps.minutesToExpiry > 0
                            ? ` ${timestamps.minutesToExpiry} ${t("dates:minutes")}`
                            : ` ${Math.round(timestamps.expiresIn / 1000)} ${t("dates:seconds")}`
                })}
            </p>
            <p>{t("inactivityModal.staySignedIn")}</p>
            <div className="flex justify-center gap-4 mt-8">
                <Button
                    theme="primary"
                    onClick={() => {
                        tokenRefreshCounter++
                        setTimestamps({ expiresIn: Infinity, minutesToExpiry: Infinity })
                    }}
                >
                    {t("inactivityModal.yesStaySigned")}
                </Button>
                <Button theme="secondary" onClick={doLogOut}>
                    {t("inactivityModal.noLogOut")}
                </Button>
            </div>
        </Modal>
    )
}
