import { Toggle } from "components/common"
import { CircleMinusIcon, CirclePlusIcon } from "components/icons"
import { useMemo } from "react"
import { TbCheck } from "react-icons/tb"
import { classNames } from "utils"
import { BasicItem, RenderItemComponent, SelectStyle } from "./types"
import { useTranslation } from "react-i18next"

type ItemProps<ItemType extends BasicItem> = {
    onItemClick: (item: ItemType) => void
    selected: ItemType[]
    withSelectionIcon?: boolean
    canSelectMany?: boolean
    style: SelectStyle
    RenderItem?: RenderItemComponent<ItemType>
    closeMenu?: () => void
}
type RenderListProps<ItemType extends BasicItem> = ItemProps<ItemType> & {
    query?: string
    items: ItemType[]
    divideFirst?: boolean
    onAddNew?: Function
}

function filterItemsRecursively<ItemType extends BasicItem>(
    query: string,
    items: ItemType[],
    hasMatchUpTheTree?: boolean
): { items: ItemType[]; isFiltered: boolean } {
    let isFiltered = false
    const mappedItems = items
        .map((item) => {
            const matchesQuery = !query || item.label.toLowerCase().includes(query.toLowerCase())
            const children = item.children && filterItemsRecursively(query, item.children, matchesQuery)
            if (children?.isFiltered) isFiltered = children.isFiltered

            return { ...item, children: children?.items, matchesQuery }
        })
        .filter(
            ({ matchesQuery, children, isAvailableForSelection }) =>
                (typeof isAvailableForSelection === "undefined" || isAvailableForSelection) &&
                (hasMatchUpTheTree || matchesQuery || children?.length)
        )

    return { items: mappedItems, isFiltered: isFiltered || mappedItems.length !== items.length }
}

function RenderList<ItemType extends BasicItem>({
    items,
    query,
    divideFirst,
    onAddNew,
    ...itemProps
}: RenderListProps<ItemType>) {
    const { t } = useTranslation("user")
    const { filteredItems, isFiltered, hasDividers } = useMemo(() => {
        const filtered = filterItemsRecursively(query ?? "", items)
        return {
            filteredItems: filtered.items,
            isFiltered: filtered.isFiltered,
            hasDividers: filtered.items?.some(({ children }) => children?.length)
        }
    }, [items, query])

    const showNoItems = !onAddNew ? !filteredItems?.length : !items.length && !query

    return (
        <div className={classNames(hasDividers && "divide-y divide-border-blue")}>
            {typeof query !== "undefined" && showNoItems && (
                <div className="italic text-gray-400 py-1.5 p-4">
                    {t(`userForm.tagSearch.${!onAddNew ? "noItems" : "startTyping"}`)}
                </div>
            )}
            {filteredItems?.map((item, index) => {
                return (
                    <ItemComponent
                        key={item.id}
                        className={divideFirst && index === 0 ? "border-b border-border-blue" : undefined}
                        isFirstLevel
                        isFiltered={isFiltered}
                        item={item}
                        {...itemProps}
                    />
                )
            })}
        </div>
    )
}

type ItemComponentProps<ItemType extends BasicItem> = ItemProps<ItemType> & {
    item: ItemType
    className?: string
    isFirstLevel?: boolean
    isFiltered: boolean
}
const iconClassNames = "w-5 h-5 shrink-0 text-med-blue translate-y-0.25"
export function ItemComponent<ItemType extends BasicItem>({
    item,
    isFiltered,
    onItemClick,
    selected,
    style,
    isFirstLevel = false,
    withSelectionIcon = false,
    canSelectMany,
    RenderItem,
    closeMenu
}: ItemComponentProps<ItemType>) {
    const { children, label } = item
    const isSelected = selected.some(({ id }) => id === item.id)
    const wrapperClassName = classNames(
        "ltr:text-left rtl:text-right",
        isFirstLevel ? (!children?.length ? "py-1.5" : "py-3") : "py-1",
        isSelected && (!withSelectionIcon || canSelectMany === false) ? "text-dark-blue font-medium" : "text-text-blue"
    )

    const SelectionIcon = isSelected ? CircleMinusIcon : CirclePlusIcon

    return !children?.length ? (
        <label
            data-testid={`content_lib-list_item-${item.id}`}
            className={classNames(
                wrapperClassName,
                (style === "tags" || canSelectMany === false) && "justify-between",
                !isFirstLevel && "!px-0",
                "flex gap-4 cursor-pointer hover:bg-border-blue/30 px-4"
            )}
        >
            <input
                type="checkbox"
                checked={isSelected}
                className={classNames(
                    "rounded-sm border-med-blue translate-y-0.75",
                    (style !== "classic" || !withSelectionIcon || canSelectMany === false) && "hidden"
                )}
                onChange={() => onItemClick(item)}
            />
            {!RenderItem ? label : <RenderItem {...{ item, closeMenu }} />}
            {canSelectMany === false && (
                <TbCheck
                    className={classNames("text-lg shrink-0", !isSelected ? "text-white" : "text-inherit")}
                    aria-hidden={!isSelected}
                />
            )}
            {style === "tags" && withSelectionIcon && <SelectionIcon className={iconClassNames} />}
        </label>
    ) : (
        <Toggle openByDefault={isFiltered}>
            <div className={classNames(wrapperClassName, "flex flex-col px-4")} data-testid={`render-item`}>
                <Toggle.Button showChevron={true} className="gap-4 font-medium text-dark-blue">
                    {label}
                </Toggle.Button>
                <Toggle.Content className={classNames("ltr:pl-5 rtl:pr-5 py-2")}>
                    {children.map((item) => (
                        <ItemComponent
                            key={item.id}
                            {...{ item, onItemClick, isFiltered, selected, withSelectionIcon, style }}
                        />
                    ))}
                </Toggle.Content>
            </div>
        </Toggle>
    )
}

export default RenderList
