import { Toggle } from "components/common"
import { Dispatch, MutableRefObject, ReactNode, SetStateAction, useRef } from "react"
import { classNames } from "utils"

export type ToggleMenuProps = Pick<SidebarListProps, "openSection" | "setOpen" | "menuHeightById"> & {
    sectionRef: MutableRefObject<HTMLDivElement>
}

export type SidebarListProps = {
    id: string
    title: string
    children: ReactNode
    openSection: string
    setOpen: Dispatch<SetStateAction<string>>
    menuHeightById: Record<string, number>
    className?: string
}

const ToggleSidebarList = ({
    id,
    title,
    children,
    openSection,
    setOpen,
    className,
    menuHeightById
}: SidebarListProps) => {
    id = `sidebar-toggle-${id}`
    const ref = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLDivElement>(null)
    const height = menuHeightById[id]
    const isOpen = openSection === id

    const style = {
        height: height + "px",
        transitionDelay: "0ms",
        transitionDuration: "300ms"
    }

    return (
        <div
            className={classNames(className, "text-secondary pb-2 relative transition-all")}
            ref={ref}
            id={id}
            style={style}
        >
            <Toggle openByDefault={isOpen} handleToggle={() => !isOpen && setOpen(id)}>
                <div
                    className="px-5 flex py-2 justify-between cursor-pointer"
                    ref={buttonRef}
                    onClick={() => !isOpen && setOpen(id)}
                >
                    <h3 className="font-semibold text-white"> {title}</h3>
                    <Toggle.Chevron noToggle />
                </div>

                <Toggle.Content
                    className="flex flex-col space-y-2 overflow-auto pt-1 pb-7 transition-opacity"
                    data-testid={`left-bar-${id}`}
                    maxHeight={buttonRef.current?.clientHeight ? height - buttonRef.current.clientHeight : undefined}
                >
                    {children}
                </Toggle.Content>
            </Toggle>
        </div>
    )
}

export default ToggleSidebarList
