import { ChatConfig } from "@sequel-care/types"
import AlertTriangle from "components/icons/AlertTriangle"
import { isEqual } from "lodash"
import { ChatProps } from "pages/chat"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { VariableSizeList } from "react-window"
import { useCurrentUser } from "store/hooks"
import { pushPatientProfileSidebarToStack } from "store/sidebars/actions"
import { Spinner } from "../../../components/common"
import NotificationBellOff from "../../../components/icons/NotificationBellOff"
import ChatContentWrapper from "../ChatContentWrapper"
import ChatMessageInput from "../ChatMessageInput"
import ConversationDetails from "../ConversationDetails"
import { useParticipantsWithoutCurrentUser } from "../utils"
import RenderList from "./RenderList"
import { getListData } from "./utils"
import { TbSearch, TbVolume, TbVolumeOff } from "react-icons/tb"

export type ConversationDisplayProps = Omit<ChatProps, "mode"> & {
    updateChatProps?: (props: Partial<ChatProps>) => void
}
export type SearchProps = {
    query: string
    isMessageSearch: boolean
    foundIdxs?: number[]
    selected?: number
}

const initialSearch: SearchProps = {
    foundIdxs: [],
    query: undefined,
    selected: 0,
    isMessageSearch: true
}

const ConversationDisplay = ({ inSidebar = false, ...props }: ConversationDisplayProps & { inSidebar?: boolean }) => {
    const { t } = useTranslation("patient")
    const currentUser = useCurrentUser()
    const containerRef = useRef<HTMLDivElement>(null)
    const messagesRef = useRef<VariableSizeList>(null)
    const { conversation, newChatWith, patientId } = props
    const chatConfig = conversation?.configuration
        ? (conversation.configuration as ChatConfig)[`${currentUser.id}`]
        : null
    const [isMuted, setIsMuted] = useState<boolean>(chatConfig?.is_muted ?? false)
    const [search, setSearch] = useState(initialSearch)
    const [{ itemCount, listOffset }, setListData] = useState(getListData(conversation))
    const dispatch = useDispatch()

    useEffect(() => {
        if (conversation) {
            setSearch(initialSearch)
            return conversation.subscribe("messages", () => {
                setListData((listData) => {
                    const newData = getListData(conversation)
                    return isEqual(newData, listData) ? listData : newData
                })
            })
        }
    }, [conversation])

    useEffect(() => {
        const fetchMessages = async () => {
            while (conversation.hasMoreMessages) {
                await conversation.loadMoreMessages()
            }
            const { query } = search
            const foundIdxs = conversation.messageDisplayList
                .map(
                    (message, index) =>
                        !("header" in message) &&
                        message.body &&
                        (message.body.toLowerCase().includes(query.toLowerCase()) ? index : null)
                )
                .filter(Boolean)

            setSearch({ ...search, foundIdxs, selected: foundIdxs.length ? foundIdxs.length - 1 : 0 })
        }
        if (search.query) fetchMessages()
    }, [search.query])

    const users = useParticipantsWithoutCurrentUser(conversation, newChatWith)
    const isPatientInChat = users.some(({ role }) => role === "patient")

    const handleMuteToggle = (shouldBeMuted: boolean) => {
        setIsMuted(shouldBeMuted)
        return conversation.toggleMute(shouldBeMuted)
    }

    return (
        <ChatContentWrapper
            search={search}
            setSearch={setSearch}
            header={
                <ConversationDetails
                    {...{ users }}
                    RenderDetails={(props) => <RenderDetails {...props} isMuted={isMuted} />}
                    onClick={
                        isPatientInChat && users.length === 1
                            ? () =>
                                  dispatch(
                                      pushPatientProfileSidebarToStack({
                                          patientId: conversation?.patientId || patientId
                                      })
                                  )
                            : undefined
                    }
                />
            }
            headerEndNode={
                <ConversationDisplayToolbar
                    isMuted={isMuted}
                    onMuteToggle={handleMuteToggle}
                    query={search.query}
                    setSearch={setSearch}
                />
            }
            footer={<ChatMessageInput {...props} {...{ itemCount, messagesRef }} />}
            banner={
                isPatientInChat && (
                    <div className="bg-warning text-warning bg-opacity-20 rounded-3xl px-3 py-2 text-sm leading-5 h-auto flex items-center justify-center gap-1 w-10/12 m-auto font-medium">
                        <AlertTriangle className="w-6 h-6" />
                        {users.length > 1 ? t("chat.patientInGroup") : t("chat.chatWithPatient")}
                    </div>
                )
            }
            className="py-4 px-6"
            containerRef={containerRef}
            backButton={inSidebar ? "list" : undefined}
            width={inSidebar ? "w-150" : "w-full"}
        >
            {itemCount ? (
                (props) => (
                    <RenderList
                        {...props}
                        {...{ containerRef, messagesRef, itemCount, listOffset, conversation, search }}
                        isGroupChat={users.length > 1}
                    />
                )
            ) : (
                <div></div>
            )}
        </ChatContentWrapper>
    )
}

export default ConversationDisplay

const ConversationDisplayToolbar = ({
    isMuted,
    onMuteToggle,
    query,
    setSearch
}: {
    isMuted: boolean
    onMuteToggle: (isMuted: boolean) => Promise<void>
    query?: string
    setSearch: Dispatch<SetStateAction<SearchProps>>
}) => {
    const [loading, setLoading] = useState(false)

    const VolumeIcon = isMuted ? TbVolumeOff : TbVolume
    const iconClasses = "w-6 h-6 cursor-pointer"
    return (
        <div className="flex items-center gap-9">
            <TbSearch
                className={iconClasses}
                onClick={() =>
                    setSearch((search) => (query === "" || query ? initialSearch : { ...search, query: "" }))
                }
            />
            {loading ? (
                <Spinner size={6} />
            ) : (
                <VolumeIcon
                    className={iconClasses}
                    onClick={async () => {
                        setLoading(true)
                        await onMuteToggle(!isMuted)
                        setLoading(false)
                    }}
                />
            )}
        </div>
    )
}

const RenderDetails = ({
    participantNames,
    role,
    isMuted
}: {
    participantNames: string
    role: string
    isMuted: boolean
}) => {
    return (
        <>
            <div className="flex flex-row gap-2 items-center">
                {participantNames}
                {isMuted && <NotificationBellOff className="text-xl w-5 h-5" />}
            </div>
            <div className="text-sm text-text-blue">{role}</div>
        </>
    )
}
