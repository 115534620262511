import React from "react"
import { TypographyV2 } from "../common"

interface ICircleCountProps {
    count: number
}

const CircleCount: React.FC<ICircleCountProps> = ({ count }) => (
    <div className="bg-gray-300 w-[16px] h-[16px] rounded-lg flex justify-center items-center ">
        <TypographyV2 className="text-gray-600">{count}</TypographyV2>
    </div>
)

export default CircleCount
