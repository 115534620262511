import { SVGProps } from "react"

const ChevronDownBold = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.2679 5.70707L13.7375 6.23741L8.91425 11.0606C8.52373 11.4511 7.89056 11.4511 7.50004 11.0606L2.67681 6.23741L2.14648 5.70707L3.20714 4.64637L3.73747 5.17677L8.20714 9.64642L12.6769 5.17676L13.2072 4.64636L14.2679 5.70707Z"
            fill="black"
        />
    </svg>
)

export default ChevronDownBold
