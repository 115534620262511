import { ExerciseDefResponse } from "types/Redux"

export const searchExerciseByText = (exercises: ExerciseDefResponse[], query: string): ExerciseDefResponse[] => {
    if (!query?.length) return exercises
    const lowerCaseQuery = query.toLowerCase()

    return exercises.filter(
        ({ title, description }) =>
            title.toLowerCase().includes(lowerCaseQuery) || description.toLowerCase().includes(lowerCaseQuery)
    )
}
