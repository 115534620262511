import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2, ScaleSliderV2 } from "components/v2"

const ScaleSliderQuestionResponse = ({
    question,
    screeningAnswer
}: {
    question: any
    screeningAnswer: ScreeningAnswer
}) => {
    const { is_required, question: question_text, definition } = question

    return (
        <div className="p-4">
            <div className="bg-white border border-slate-300 rounded-lg  pt-4 pl-3">
                <TypographyV2 type="base" className="text-[#6B7280]">
                    {question_text}
                </TypographyV2>

                <div className="pt-2 flex flex-col gap-4">
                    <ScaleSliderV2
                        value={Number(screeningAnswer?.value)}
                        onChange={(value) => {}}
                        min={definition.min}
                        max={definition.max}
                        step={definition.step}
                        minLabel={definition.minLabel}
                        maxLabel={definition.maxLabel}
                    />
                </div>
            </div>
        </div>
    )
}

export default ScaleSliderQuestionResponse
