import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useDiagnoses, useRoles } from "store/hooks"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { truncate } from "lodash"
import { userRoleTitles } from "components/v2/constants"
import { ExerciseType, Gender, Language, PreviousInstance, UserRole } from "@prisma/client"
import { genders, getExerciseSchemaValidation, getLanguageList } from "./createNewExerciseModalConst"
import { useForm } from "react-hook-form"
import { CreateExerciseFormSubmitPayload, RadioGroupMode } from "types/exercises/ExerciseDef"
import { yupResolver } from "@hookform/resolvers/yup"
import { AudienceIcon, EyeIcon, ProfessionalInfo } from "components/icons"
import { createNewExerciseAction } from "store/exercises/actions"
import { useIsHeLanguage } from "hooks"

export const useCreateNewExerciseModal = (show: boolean) => {
    const { t } = useTranslation("exercise")
    const dispatch = useDispatch()
    const diagnosesData = useDiagnoses()
    const rolesData = useRoles()

    const { isHeLanguage } = useIsHeLanguage()

    const languagesList = useMemo(() => getLanguageList(), [isHeLanguage])

    const diagnosesList = useMemo(
        () => diagnosesData.map(({ id, name }) => ({ id, title: truncate(name, { length: 25 }) })),
        [diagnosesData]
    )

    const rolesList = useMemo(
        () => rolesData.map(({ id, role }) => ({ id, title: t(`common:roles.${userRoleTitles[role as UserRole]}`) })),
        [rolesData]
    )

    const gendersWithLocale = useMemo(
        () => genders.map(({ title, ...rest }) => ({ ...rest, title: t(`common:genders.${title}`) })),
        [t]
    )

    const [activeStepIndex, setActiveStepIndex] = useState(0)

    const sectionRefs = useRef([])
    const observer = useRef<IntersectionObserver | null>(null)

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            gender_mode: RadioGroupMode.ALL,
            min_age: 18,
            max_age: 65,
            age_mode: RadioGroupMode.ALL,
            title: "",
            description: "",
            public_name: "",
            brief_summary: "",
            gender_custom: Gender.male,
            language: Language.en,
            diagnose_ids: [],
            role_ids: [],
            exercise_type: ExerciseType.EXERCISE_JOURNAL,
            previous_instance: PreviousInstance.CURRENT_ANSWERS_ONLY
        },
        resolver: yupResolver(getExerciseSchemaValidation())
    })

    const isFormError = !!Object.entries(errors).length

    useEffect(() => {
        if (observer.current) {
            return
        }
        const observerOptions = {
            threshold: 0.5
        }

        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = sectionRefs.current.indexOf(entry.target)
                    setActiveStepIndex(index)
                }
            })
        }
        setTimeout(() => {
            observer.current = new IntersectionObserver(observerCallback, observerOptions)
            sectionRefs.current.forEach((section) => observer.current.observe(section))
        })

        return () => {
            observer.current?.disconnect()
            observer.current = null
        }
    }, [sectionRefs, show])

    const handleScreeningScroll = useCallback(
        (sectionIndex: number) => {
            if (sectionRefs.current && sectionRefs.current[sectionIndex]) {
                sectionRefs.current[sectionIndex].scrollIntoView({ behavior: "smooth" })
            }
        },
        [sectionRefs]
    )

    const stepsList = useMemo(() => {
        return [
            {
                title: t("createModal.professionalInfo"),
                icon: ProfessionalInfo,
                onClick: () => {
                    handleScreeningScroll(0)
                }
            },
            {
                title: t("audience"),
                icon: AudienceIcon,
                onClick: () => {
                    handleScreeningScroll(1)
                }
            },
            {
                title: t("createModal.audienceView"),
                icon: EyeIcon,
                onClick: () => {
                    handleScreeningScroll(2)
                }
            }
        ]
    }, [setActiveStepIndex, handleScreeningScroll])

    const submitHandler = ({
        gender_mode,
        age_mode,
        min_age,
        max_age,
        gender_custom,
        ...data
    }: CreateExerciseFormSubmitPayload) => {
        const { public_name, description } = data
        const gender = gender_mode === RadioGroupMode.ALL ? Gender.all : gender_custom
        const newMinAge = age_mode !== RadioGroupMode.ALL ? Number(min_age) : null
        const newMaxAge = age_mode !== RadioGroupMode.ALL ? Number(max_age) : null

        dispatch(
            createNewExerciseAction({
                gender,
                min_age: newMinAge,
                max_age: newMaxAge,
                patient_description: description,
                patient_title: public_name,
                recommended_recurrence: 0,
                ...data
            })
        )
    }

    useEffect(() => {
        if (show) {
            setActiveStepIndex(0)
        } else {
            reset()
        }

        return () => {
            setActiveStepIndex(0)
        }
    }, [show])

    return {
        t,
        errors,
        control,
        isFormError,
        stepsList,
        languagesList,
        rolesList,
        diagnosesList,
        activeStepIndex,
        gendersWithLocale,
        sectionRefs,
        submitHandler,
        handleSubmit
    }
}
