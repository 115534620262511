import { ReactNode, useRef, useState } from "react"
import {
    offset,
    shift,
    flip,
    useFloating,
    autoUpdate,
    useInteractions,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    arrow,
    FloatingArrow,
    useTransitionStyles,
    Placement
} from "@floating-ui/react"

interface ITooltipV2Props {
    content?: ReactNode
    children?: React.ReactElement
    placement?: Placement
}

const TooltipV2 = ({ content, children, placement }: ITooltipV2Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const arrowRef = useRef(null)
    const {
        refs: { setReference, setFloating },
        floatingStyles,
        context
    } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement,
        middleware: [
            offset(12),
            flip(),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate
    })

    const hover = useHover(context, { move: false })
    const focus = useFocus(context)
    const dismiss = useDismiss(context)
    const role = useRole(context, { role: "tooltip" })

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

    const { styles: transitionStyles } = useTransitionStyles(context, {
        initial: {
            opacity: 0,
            transform: "scale(0.8)"
        }
    })

    return (
        <>
            <div ref={setReference} {...getReferenceProps()}>
                {children}
            </div>
            {isOpen && content && (
                <div ref={setFloating} style={{ ...floatingStyles, zIndex: 1 }} {...getFloatingProps()}>
                    <div
                        className="bg-med-black pointer-events-none text-white px-3 p-2 rounded"
                        style={transitionStyles}
                    >
                        <FloatingArrow
                            className="fill-med-black"
                            tipRadius={2}
                            height={8}
                            ref={arrowRef}
                            context={context}
                        />
                        {content}
                    </div>
                </div>
            )}
        </>
    )
}

export default TooltipV2
