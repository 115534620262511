export const UPDATE_GLOBAL = "global/UPDATE_GLOBAL"

export const SET_CURRENT_USER = "global/SET_CURRENT_USER"
export const SET_CHAT_TOKEN = "global/SET_CHAT_TOKEN"
export const SET_CURRENT_ROLE = "global/SET_CURRENT_ROLE"
export const UPDATE_ORGANIZATION = "global/UPDATE_ORGANIZATION"
export const SET_USERS_IN_CURRENT_ORG = "global/SET_USERS_IN_CURRENT_ORG"
export const ADD_USER_IN_CURRENT_ORG = "global/ADD_USER_IN_CURRENT_ORG"
export const UPDATE_USER_IN_CURRENT_ORG = "global/UPDATE_USER_IN_CURRENT_ORG"
export const SET_GLOBAL_LOADING = "global/SET_GLOBAL_LOADING"
export const SET_PATIENT_LIST = "global/SET_PATIENT_LIST"
export const ADD_PATIENT_TO_LIST = "global/ADD_PATIENT_TO_LIST"
export const REMOVE_PATIENT_FROM_LIST = "global/REMOVE_PATIENT_FROM_LIST"
export const UPDATE_PATIENT_IN_LIST = "global/UPDATE_PATIENT_IN_LIST"
export const UPDATE_PATIENT_IN_LIST_PARTIAL = "global/UPDATE_PATIENT_IN_LIST_PARTIAL"

export const UPDATE_USER_LANGUAGE = "global/UPDATE_USER_LANGUAGE"

export const SET_PATIENT_TAGS = "global/SET_PATIENT_TAGS"

export const UPDATE_PASSWORD_IS_TEMP = "global/UPDATE_PASSWORD_IS_TEMP"

export const SET_ALL_QUESTIONNAIRES = "global/SET_ALL_QUESTIONNAIRES"
export const ADD_NEW_QUESTIONNAIRE = "global/ADD_NEW_QUESTIONNAIRE"
export const UPDATE_QUESTIONNAIRE = "global/UPDATE_QUESTIONNAIRE"

export const SET_USERS_APPOINTMENTS = "global/SET_USERS_APPOINTMENTS"
export const SET_PAST_APPOINTMENT_STATUS = "global/SET_PAST_APPOINTMENT_STATUS"
export const SET_UPCOMING_APPOINTMENT = "global/SET_UPCOMING_APPOINTMENT"

export const SET_DASHBOARD_PATIENTS = "global/SET_DASHBOARD_PATIENTS"

export const SET_TEAM_AUTH = "global/SET_TEAM_AUTH"

export const UPDATE_USER_PREFERENCES = "global/UPDATE_USER_PREFERENCES"

export const NOTIFY_GOAL_REMOVED = "global/NOTIFY_GOAL_REMOVED"
export const ADD_GOAL_TO_LIST = "global/ADD_GOAL_TO_LIST"
export const UPDATE_GOAL_IN_LIST = "global/UPDATE_GOAL_IN_LIST"

export const SET_GLOBAL_CHAT = "global/SET_GLOBAL_CHAT"

export const SET_GLOBAL_CAREPLANS = "global/SET_GLOBAL_CAREPLANS"
export const SET_CAREPLAN_DEFAULT = "global/SET_CAREPLAN_DEFAULT"
export const REMOVE_CAREPLAN_DEFAULT = "global/REMOVE_CAREPLAN_DEFAULT"

export const SET_SETTINGS_LOADING = "global/SET_SETTINGS_LOADING"

export const ADD_EVENTS_TO_RECENT = "global/ADD_EVENTS_TO_RECENT"

export const SET_TRACKER = "global/SET_TRACKER"

export const SET_ORGANIZATION_TRACKER_MANAGER = "global/SET_ORGANIZATION_TRACKER_MANAGER"

export const SET_INTAKE_CANDIDATES = "global/SET_INTAKE_CANDIDATES"
export const REJECT_CANDIDATE = "global/REJECT_CANDIDATE"
export const UPDATE_INTAKE_CANDIDATE = "global/UPDATE_INTAKE_CANDIDATE"
