import { SVGProps } from "react"

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.57465 3.65141L1.51632 15.4347C1.37079 15.6868 1.29379 15.9725 1.29298 16.2635C1.29216 16.5545 1.36756 16.8407 1.51167 17.0935C1.65579 17.3463 1.86359 17.557 2.11441 17.7046C2.36523 17.8522 2.65032 17.9315 2.94132 17.9347H17.058C17.349 17.9315 17.6341 17.8522 17.8849 17.7046C18.1357 17.557 18.3435 17.3463 18.4876 17.0935C18.6317 16.8407 18.7071 16.5545 18.7063 16.2635C18.7055 15.9725 18.6285 15.6868 18.483 15.4347L11.4247 3.65141C11.2761 3.4065 11.0669 3.20401 10.8173 3.06348C10.5677 2.92295 10.2861 2.84912 9.99965 2.84912C9.71321 2.84912 9.43159 2.92295 9.18199 3.06348C8.93238 3.20401 8.72321 3.4065 8.57465 3.65141Z"
            fill="#EA580C"
        />
        <path
            d="M10 7.93457V11.2679"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10 14.6011H10.0083"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default WarningIcon
