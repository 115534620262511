import { Metric } from "@sequel-care/types"
import { PatientWithMetrics } from "@sequel-care/types/Patient"
import DashboardCard from "components/DashboardCard"
import { camelCase } from "lodash"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { CircleChart, CircleChartWithInteractions } from "../ChartComponents"
import { colorsByTrend, filterPatientsByMetric, useMetricValueToTitle, valuesByMetric } from "../utils"

export function TrendProgress<K extends keyof typeof valuesByMetric>({
    patients: allPatients,
    metric,
    i18nValuePrefix = "dashboard.trendLabels",
    includeUnmeasured,
    withInteractions,
    loading
}: {
    patients: PatientWithMetrics<string>[]
    metric: K
    i18nValuePrefix?: string
    includeUnmeasured?: boolean
    withInteractions?: boolean
    loading: boolean
}) {
    const { t } = useTranslation("therapist")
    const metricValueToTitle = useMetricValueToTitle(metric)

    const countByTrend = valuesByMetric[metric].map(
        (trend) =>
            [
                trend,
                includeUnmeasured || trend !== "unmeasured"
                    ? filterPatientsByMetric(allPatients, metric, trend as Metric[K])
                    : null
            ] as const
    )

    const propI18nKey = camelCase(metric)
    const CircleComponent = withInteractions ? CircleChartWithInteractions : CircleChart

    return (
        <DashboardCard
            title={loading ? <Skeleton className="w-32" /> : t(`dashboard.${propI18nKey}.title`)}
            subtitle={loading ? <Skeleton className="w-64" /> : t(`dashboard.${propI18nKey}.subtitle`)}
            contentClassName="my-8 flex justify-between"
        >
            {countByTrend.map(([metricValue, patients], index) =>
                patients ? (
                    <CircleComponent
                        key={metricValue}
                        loading={loading}
                        label={t(`${i18nValuePrefix}.${metricValue}`)}
                        size={window.innerWidth > 1370 ? "medium" : "smaller"}
                        value={patients.length}
                        value_out_of={allPatients.length}
                        color={Object.values(colorsByTrend)[index]}
                        filterdata={
                            !withInteractions
                                ? undefined
                                : {
                                      key: metric,
                                      value: metricValue,
                                      title: metricValueToTitle(metricValue as Metric[K])
                                  }
                        }
                    />
                ) : null
            )}
        </DashboardCard>
    )
}
