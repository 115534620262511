import React from "react"
import { classNames } from "utils"

interface ICheckBoxV2Props {
    isChecked: boolean
    className?: string
    onChange: () => void
}

const CheckBoxV2: React.FC<ICheckBoxV2Props> = ({ isChecked, className, onChange }) => (
    <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        className={classNames(
            "focus:ring-0 focus:ring-offset-0 rounded",
            !isChecked ? "border-gray-300" : "",
            className
        )}
    />
)

export default CheckBoxV2
