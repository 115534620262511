import React, { ReactNode } from "react"

interface IDividerV2Props {
    children?: ReactNode
}

const DividerV2: React.FC<IDividerV2Props> = ({ children }) => {
    return (
        <div className="flex flex-row items-center w-full">
            <div className="border-t border-[#DCE2E8] w-full" />
            {children && <div className="flex flex-row p-x-4">{children}</div>}
            <div className="border-t border-[#DCE2E8] w-full" />
        </div>
    )
}

export default DividerV2
