import i18n from "locales"
import { PublishStatuses } from "components/v2/types"
import { PaperAirplaneIcon } from "@heroicons/react/24/outline"
import { PublishAndReplaceIcon } from "components/icons"
import { mixed, number, object } from "yup"

export const getPublishSteps = () => [
    { label: i18n.t("publish_method", { ns: "exercise" }), isCompleted: true },
    { label: i18n.t("review", { ns: "exercise" }), isCompleted: false }
]

export const getPublishOptions = () => [
    {
        value: PublishStatuses.Publish,
        title: i18n.t("publish_only", { ns: "exercise" }),
        subTitle: i18n.t("publish_as_new_item", { ns: "exercise" }),
        icon: PaperAirplaneIcon
    },
    {
        value: PublishStatuses.PublishAndReset,
        title: i18n.t("publish_and_replace", { ns: "exercise" }),
        subTitle: i18n.t("replace_all_current", { ns: "exercise" }),
        icon: PublishAndReplaceIcon
    }
]

export const getPublishExerciseSchema = () =>
    object().shape({
        status: mixed().oneOf(Object.values(PublishStatuses)),
        replacedExerciseId: number().when("status", {
            is: PublishStatuses.PublishAndReset,
            then: (schema) => schema.required("replacedExerciseId is required when status is PublishAndReset"),
            otherwise: (schema) => schema.optional()
        })
    })
