import { TypographyV2 } from "components/v2"
import { EyeIcon } from "components/icons"
import React, { FC, SVGProps } from "react"
import { classNames } from "utils"
import { useIsHeLanguage } from "hooks"

interface IAttentionCardProps {
    text: React.ReactNode
    Icon?: FC<SVGProps<SVGSVGElement>>
}

const AttentionCard: React.FC<IAttentionCardProps> = ({ text, Icon = EyeIcon }) => {
    const { isHeLanguage } = useIsHeLanguage()

    return (
        <div className="bg-blue-50 p-2 w-max rounded flex items-center">
            <Icon className="text-med-blue" />
            <TypographyV2 className={classNames("ml-2 italic", isHeLanguage ? "mr-2" : "ml-2")}>{text}</TypographyV2>
        </div>
    )
}

export default AttentionCard
