import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import { ButtonV2 } from "components/v2"

interface IntakeCardFooterProps {
    prevButton: any
    nextButton: any
}

const IntakeCardFooter = ({ prevButton, nextButton }: IntakeCardFooterProps) => {
    return (
        <footer className="w-full flex justify-between">
            <div className="flex items-center">
                {prevButton.hasPrev && (
                    <ArrowLeftIcon
                        className="w-4 cursor-pointer hover:scale-110 hover:text-dark-blue transition-all rtl:rotate-180 ltr:rotate-0"
                        onMouseDown={(e) => {
                            prevButton.onClick()
                            e.preventDefault()
                        }}
                    />
                )}
            </div>
            <div>
                {nextButton.hasNext && (
                    <ButtonV2
                        className="rounded-[100px] flex-row-reverse"
                        onClick={nextButton.onClick}
                        text={nextButton.text}
                        icon={
                            nextButton.isArrowEnabled && (
                                <ArrowRightIcon className="w-4 cursor-pointer hover:scale-110 hover:text-dark-blue transition-all rtl:rotate-180 ltr:rotate-0" />
                            )
                        }
                    />
                )}
            </div>
        </footer>
    )
}

export default IntakeCardFooter
