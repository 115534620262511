import React, { ReactNode } from "react"

interface ListItemV2 {
    isVerticalView?: boolean
    children: ReactNode
}

const ListItemV2: React.FC<ListItemV2> = ({ children, isVerticalView = false }) => {
    return <div className={isVerticalView ? "w-full" : "sm:w-[calc(33.333%-8px)]"}>{children}</div>
}

export default ListItemV2
