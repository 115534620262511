import { QuestionGridContext } from "components/Questionnaires/QuestionGrid"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useAllExercises } from "store/hooks"
import { pushToLibraryStack } from "store/patient/actions"
import DetailsScreen from "."
import { eventToTemplate } from "../utils"
import { Fragment, useEffect, useState } from "react"
import { getExerciseQuestionsByExerciseId } from "api"
import { responseComponents } from "./QuestionnaireDetails"
import { QuestionType } from "@prisma/client"
import { ExerciseQuestionResponse } from "../../../types/Redux"

const ExerciseDetails = ({ exerciseId, withBack }: { exerciseId: number; withBack: boolean }) => {
    const [questions, setQuestions] = useState<ExerciseQuestionResponse[]>([])
    const exercises = useAllExercises()
    const exercise = exercises.find(({ id }) => exerciseId === id)
    const dispatch = useDispatch()
    const { t } = useTranslation("patient")

    useEffect(() => {
        const fetchQuestions = async () => {
            const response = await getExerciseQuestionsByExerciseId(exerciseId)
            setQuestions(response)
        }

        fetchQuestions()
    }, [exerciseId])

    return (
        <DetailsScreen
            title={exercise.title}
            description={exercise.description}
            withBack={withBack}
            duration={exercise.duration}
            related_diagnoses={[]}
            onAdd={() =>
                dispatch(
                    pushToLibraryStack({
                        view: "config",
                        eventTemplates: eventToTemplate("exercise", exercise),
                        patientId: "preserve"
                    })
                )
            }
        >
            <QuestionGridContext.Provider value={{ exercise }}>
                <div className="text-lg pb-6 text-dark-blue font-medium">{t("contentLib.insideExercise")}</div>
                <div className="grid gap-y-5 gap-x-8 font-normal grid-cols-2">
                    {questions.map((question) => {
                        const RenderResponse = responseComponents[question.type]
                        return (
                            <Fragment key={question.id}>
                                <div
                                    className="text-secondary-2 text-sm break-words"
                                    data-testid={`question-${question.id}`}
                                >
                                    {question.type === QuestionType.free_text ? (
                                        <p dangerouslySetInnerHTML={{ __html: question.question }}></p>
                                    ) : (
                                        question.question
                                    )}
                                </div>
                                <RenderResponse question={question} value={undefined} />
                            </Fragment>
                        )
                    })}
                </div>
            </QuestionGridContext.Provider>
        </DetailsScreen>
    )
}

export default ExerciseDetails
