import { PatientSnapshot } from "@sequel-care/types"
import { TruncatedString } from "components/common"
import { ScoreTag } from "components/common/Tag"
import { ArrowUpRightIcon } from "components/icons"
import MinusIcon from "components/icons/Minus"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useAllQuestionnaires } from "store/hooks"
import { pushEventSidebarToStack } from "store/sidebars/actions"
import { classNames } from "utils"
import { localeFormat } from "utils/dates"

const gridStyle = { gridTemplateColumns: "0.7fr 1fr 1fr 1fr 0.1fr" }

const QuestionnaireSnapshot = ({
    questionnaireData,
    patient_id
}: {
    patient_id: number
    questionnaireData: PatientSnapshot<string>["questionnaireData"]
}) => {
    const { t, i18n } = useTranslation("patient")
    const questionnaires = useAllQuestionnaires()
    const dispatch = useDispatch()

    return (
        <>
            <div className="text-dark-blue">
                <div className="font-medium">{t("sidebar.lastQuestionnaireScores")}</div>
                {questionnaireData.length ? (
                    <div className="flex flex-col mt-6">
                        <div
                            className="grid text-secondary-2 opacity-40"
                            style={{ gridTemplateColumns: "0.7fr 0.9fr 0.95fr 1fr 0.1fr" }}
                        >
                            <span>{t("common:name")}</span>
                            <span>{t("common:lastScore")}</span>
                            <span>{t("common:previousScore")}</span>
                            <span>{t("common:reflection")}</span>
                        </div>
                        {questionnaireData.map(({ questionnaire_id, last_event, prev_event }, index) => {
                            const questionnaire = questionnaires.find(({ id }) => id === questionnaire_id)
                            if (!questionnaire) return null

                            const { questionnaire_scale } = questionnaire
                            return (
                                <div
                                    key={questionnaire_id}
                                    className={classNames(
                                        "grid items-center text-text-blue py-4",
                                        index !== 0 && "border-t border-border-blue"
                                    )}
                                    style={gridStyle}
                                >
                                    <TruncatedString string={questionnaire.title} maxLen={7} />
                                    <span className="flex items-center gap-2">
                                        {questionnaire_scale ? (
                                            <ScoreTag score={last_event.score} scale={questionnaire_scale} />
                                        ) : (
                                            <MinusIcon className="w-5 h-5 mx-5" />
                                        )}
                                        {localeFormat(new Date(last_event.completed_at), "d/M", i18n)}
                                    </span>
                                    {prev_event ? (
                                        <span className="flex items-center gap-2">
                                            {questionnaire_scale ? (
                                                <ScoreTag score={prev_event.score} scale={questionnaire_scale} />
                                            ) : (
                                                <MinusIcon className="w-5 h-5 mx-6" />
                                            )}
                                            {localeFormat(new Date(prev_event.completed_at), "d/M", i18n)}
                                        </span>
                                    ) : (
                                        <MinusIcon className="w-5 h-5 mx-6" />
                                    )}
                                    <span
                                        className={classNames(
                                            last_event.reflection_response ? "text-med-green" : "text-negative"
                                        )}
                                    >
                                        {t(`dashboard.reflection.${last_event.reflection_response}`)}
                                    </span>
                                    <div
                                        onClick={() =>
                                            dispatch(
                                                pushEventSidebarToStack({
                                                    id: last_event.id,
                                                    date_on_timeline: new Date(last_event.date_on_timeline),
                                                    patient_id,
                                                    context: { time: new Date(), context: "snapshot-sidebar" }
                                                })
                                            )
                                        }
                                    >
                                        <ArrowUpRightIcon className="text-xl hover:scale-110 transition-transform cursor-pointer text-secondary-2 rtl:rotate-y-180" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className="text-text-blue mt-6">{t("sidebar.no_questionnaires")}</div>
                )}
            </div>
        </>
    )
}

export default QuestionnaireSnapshot
