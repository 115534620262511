import { useMemo } from "react"
import { truncate } from "lodash"
import { useDispatch } from "store"
import { useExerciseSearchSelector, useExerciseSelector } from "store/hooks"
import { updateExercisesSearchParamsAction } from "store/exercises/actions"
import { ExerciseSearchParamsKey, ExerciseSearchValue } from "types/Redux"
import { useTranslation } from "react-i18next"
import { statusSelectOptions } from "./exerciseFiltersContants"

export const useExerciseFilters = () => {
    const { t } = useTranslation("exercise")
    const dispatch = useDispatch()
    const { diagnoses, creators } = useExerciseSelector()
    const { text, diagnose, status, creator } = useExerciseSearchSelector()

    const mappedStatusSelectWithLocalization = useMemo(
        () =>
            statusSelectOptions.map(({ title, ...rest }) => ({
                ...rest,
                title: t(`exerciseStatuses.${title}`)
            })),
        [t]
    )

    const diagnoseOptions = useMemo(
        () => diagnoses?.map(({ id, name_en }) => ({ id, title: truncate(name_en, { length: 20 }) })),
        [diagnoses]
    )

    const creatorOptions = useMemo(
        () => creators?.map(({ id, first_name, last_name }) => ({ id, title: first_name + " " + last_name })),
        [creators]
    )

    const onChangeSearchParams = (key: ExerciseSearchParamsKey, value: ExerciseSearchValue) => {
        dispatch(updateExercisesSearchParamsAction({ key, value }))
    }

    const onSelectChange =
        (key: ExerciseSearchParamsKey) =>
        ([value]: [string | number]) => {
            onChangeSearchParams(key, value)
        }

    return {
        t,
        creator,
        text,
        status,
        diagnose,
        diagnoseOptions,
        creatorOptions,
        mappedStatusSelectWithLocalization,
        onChangeSearchParams,
        onSelectChange
    }
}
