import { SVGProps } from "react"

const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M1.69556 7.58751C1.63799 7.41479 1.63794 7.22779 1.6954 7.05504C2.85237 3.57695 6.13326 1.06885 9.99994 1.06885C13.8648 1.06885 17.1445 3.57462 18.3029 7.05018C18.3604 7.22291 18.3605 7.4099 18.303 7.58266C17.1461 11.0607 13.8652 13.5688 9.99848 13.5688C6.1336 13.5688 2.85397 11.0631 1.69556 7.58751Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.4993 7.31885C12.4993 8.69956 11.38 9.81885 9.99927 9.81885C8.61856 9.81885 7.49927 8.69956 7.49927 7.31885C7.49927 5.93814 8.61856 4.81885 9.99927 4.81885C11.38 4.81885 12.4993 5.93814 12.4993 7.31885Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default EyeIcon
