import { IntakeCandidate } from "components/v2/constants/enums/intake"
import React from "react"
import { buildIntakeFormResponse } from "../buildIntakeFormResponse"
import { useTranslation } from "react-i18next"
import { Accordion } from "."

const IntakeResponse = ({ candidate }: { candidate: IntakeCandidate }) => {

    const {t} = useTranslation()

    return (
        <>
            <div className="-mx-6">
                <Accordion
                    title={t("intake:screeningTable.1stScreening")}
                    totalQuestions={candidate?.first_screening_instance?.screening_template?.questions_list?.length}
                >
                    {buildIntakeFormResponse(
                        candidate?.first_screening_instance?.screening_template?.questions_list,
                        candidate?.first_screening_instance?.screening_answers
                    )}
                </Accordion>
            </div>
            {candidate?.second_screening_instance && (
                <div className="-mx-6">
                    <Accordion
                        title={t("intake:screeningTable.2ndScreening")}
                        totalQuestions={candidate?.second_screening_instance?.screening_template?.questions_list?.length}
                    >
                        {buildIntakeFormResponse(
                            candidate?.second_screening_instance?.screening_template?.questions_list,
                            candidate?.second_screening_instance?.screening_answers
                        )}
                    </Accordion>
                </div>
            )}
            {candidate?.summary && (
                <div className="-mx-6">
                    <Accordion
                        title={t("intake:common.intake_summary")}
                    >
                        {candidate?.summary}
                    </Accordion>
                </div>
            )}
        </>
    )
}

export default IntakeResponse
