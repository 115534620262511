import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react"
import { classNames } from "utils"
import { colors } from "./badgeV2Constants"
import { TypographyV2 } from "components/v2"
import { useCurrentLanguage } from "store/hooks"

interface IBadgeV2Props extends PropsWithChildren<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> {
    icon?: React.ReactNode
    text: string | number
    color?: string
    onClick?: React.MouseEventHandler<HTMLDivElement>
    classNameLabel?: string
}

const BadgeV2 = ({
    color = "transparent",
    className,
    icon,
    text,
    onClick,
    classNameLabel,
    contentEditable,
    ...props
}: IBadgeV2Props) => {
    const language = useCurrentLanguage()
    return (
        <div
            {...props}
            className={classNames(
                colors[color],
                "flex justify-center items-center rounded-sm px-3 py-2 text-2xs h-auto font-medium gap-x-1 cursor-pointer",
                className
            )}
            onClick={onClick}
            dir={language === "he" ? "rtl" : "ltr"}
        >
            {icon}
            <TypographyV2 type="xm" width="medium" className={classNameLabel}>
                {text}
            </TypographyV2>
        </div>
    )
}

export default BadgeV2
