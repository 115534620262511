import React, { useMemo } from "react"
import { getExerciseVisibleList } from "components/v2/utiles"
import { BadgeV2 } from "components/v2"
import { truncate } from "lodash"
import { Diagnosis } from "@prisma/client"

interface IExerciseDiagnosesListProps {
    diagnoses: { diagnose: Diagnosis }[]
}

const ExerciseDiagnosesList: React.FC<IExerciseDiagnosesListProps> = ({ diagnoses }) => {
    const { visibleDiagnoses, notVisibleCount } = useMemo(() => getExerciseVisibleList(diagnoses), [diagnoses])

    return (
        <>
            {visibleDiagnoses?.map(({ diagnose: { name_en, id } }) => (
                <BadgeV2 key={id} text={truncate(name_en, { length: 10 })} color="darkBlue" className="px-1.5 py-0.5" />
            ))}
            {!!notVisibleCount && <BadgeV2 text={`+ ${notVisibleCount}`} color="darkBlue" className="px-1.5 py-0.5" />}
        </>
    )
}

export default ExerciseDiagnosesList
