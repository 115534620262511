import React, { memo } from "react"
import { useSelectForm } from "./useSelectForm"
import { ISelectFormProps } from "./selectFormTypes"
import { ButtonV2, DeleteIconV2 } from "components/v2"
import { ControlledTextInputV2 } from "components/v2"
import { BoldPlusIcon } from "components/icons"
import { classNames } from "utils"

const SelectForm = memo((props: ISelectFormProps) => {
    const { t, isHeLanguage, newControl, fields, onAddClick, onRemoveClick } = useSelectForm(props)

    return (
        <div className="mt-10">
            {fields.map((field, index) => (
                <div className="flex items-center mb-5" key={field.id}>
                    <ControlledTextInputV2
                        classList={{ wrapper: classNames("w-full", isHeLanguage ? "ml-3" : "mr-3") }}
                        placeholder={`${t("option")} ${index + 1}`}
                        control={newControl}
                        name={`options.${index}.option`}
                    />
                    <DeleteIconV2 onClick={onRemoveClick(index)} />
                </div>
            ))}

            <ButtonV2 icon={<BoldPlusIcon />} text={t("addOption")} variant="transparent" onClick={onAddClick} />
        </div>
    )
})

export default SelectForm
