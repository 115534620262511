import { useEffect, useState } from "react"
import { QuestionGrid, QuestionGridContext, RenderScaleGroupFn } from "components/Questionnaires/QuestionGrid"
import { useQuestionsGroupedByScale } from "components/Questionnaires/QuestionGrid/useQuestionsGroupedByScale"
import { useTranslation } from "react-i18next"
import { useExercise, useQuestionnaire } from "store/hooks"
import SidebarSection from "../SidebarSection"
import { responseComponents } from "./QuestionResponseComponents"
import { QuestionnaireLastScores, QuestionnaireScoreBreakdown } from "./QuestionnaireScoreSections"
import { ResponseComponentProps } from "./types"
import { useResponsesByQuestionId } from "./utils"
import { ExerciseQuestion } from "@prisma/client"
import { getExerciseQuestionsByExerciseId } from "api"

export const QuestionnaireResponses = ({ event }: ResponseComponentProps) => {
    const { t } = useTranslation("patient")
    const questionnaire = useQuestionnaire(event?.questionnaire_id)

    const questionsWithScaleGroups = useQuestionsGroupedByScale(questionnaire, true)
    const responsesByQuestionId = useResponsesByQuestionId(event)
    const hasResponses = questionnaire?.questions.some(({ id }) => typeof responsesByQuestionId[id] !== "undefined")

    return (
        <QuestionGridContext.Provider value={{ questionnaire, responsesByQuestionId }}>
            {questionnaire?.questionnaire_scale && <QuestionnaireLastScores {...{ questionnaire, event }} />}
            {questionnaire && event.group_scores.length > 1 && (
                <QuestionnaireScoreBreakdown {...{ questionnaire, event }} />
            )}
            {hasResponses && (
                <SidebarSection className="py-6" title={t("dashboard.responses")}>
                    <div className="pt-4">
                        <QuestionGrid items={questionsWithScaleGroups} {...{ responseComponents, RenderScaleGroup }} />
                    </div>
                </SidebarSection>
            )}
        </QuestionGridContext.Provider>
    )
}

const RenderScaleGroup: RenderScaleGroupFn = ({ items }) => {
    return <QuestionGrid items={items} insideScaleGroup responseComponents={responseComponents} />
}

export const ExerciseResponses = ({ event }: ResponseComponentProps) => {
    const { t } = useTranslation("patient")
    const [questions, setQuestions] = useState<ExerciseQuestion[]>([])
    const exercise = useExercise(event.exercise_id)
    const responsesByQuestionId = useResponsesByQuestionId(event)

    useEffect(() => {
        const fetchQuestions = async () => {
            const response = await getExerciseQuestionsByExerciseId(event.exercise_id)
            setQuestions(response)
        }

        fetchQuestions()
    }, [event.exercise_id])

    if (!event.responses.length) return null

    return (
        <QuestionGridContext.Provider value={{ exercise, responsesByQuestionId }}>
            <SidebarSection className="py-6" title={t("dashboard.responses")}>
                <div className="flex flex-col divide-y divide-gray-200">
                    <QuestionGrid items={questions} responseComponents={responseComponents} />
                </div>
            </SidebarSection>
        </QuestionGridContext.Provider>
    )
}
