import { FC } from "react"
import { ScaleForm, SelectForm } from "../question-type-forms"
import { ExerciseQuestionType } from "@prisma/client"
import { IQuestionsFromProps } from "types"
import { object, string } from "yup"
import i18n from "locales"

export const questionTypeForms: { [keyof in ExerciseQuestionType]: FC<IQuestionsFromProps> } = {
    [ExerciseQuestionType.scale]: ScaleForm,
    [ExerciseQuestionType.select]: SelectForm,
    [ExerciseQuestionType.free_text]: () => null,
    [ExerciseQuestionType.text]: () => null,
    [ExerciseQuestionType.image]: () => null,
    [ExerciseQuestionType.time_of_day]: () => null,
    [ExerciseQuestionType.minutes]: () => null,
    [ExerciseQuestionType.yes_no]: () => null,
    [ExerciseQuestionType.multiple_choice]: () => null,
    [ExerciseQuestionType.number]: () => null,
    [ExerciseQuestionType.percent]: () => null
}

export const getQuestionSchemaValidation = () =>
    object().shape({
        question: string()
            .trim()
            .required(i18n.t("validation.questionIsRequired", { ns: "question" }))
            .min(1, i18n.t("validation.questionIsRequired", { ns: "question" }))
            .max(300, i18n.t("validation.maxLength", { ns: "common", max: 300 })),
        post_question: string()
            .trim()
            .optional()
            .max(300, i18n.t("validation.maxLength", { ns: "common", max: 300 })),
        type: string().oneOf(Object.values(ExerciseQuestionType)).required(),
        definition: object()
    })
