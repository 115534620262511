import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react"
import { FC, Fragment, ReactNode } from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"

const themes = {
    white: { bg: "bg-white", card: "border border-border shadow-sm" },
    gray: { bg: "bg-gray-500", card: "shadow-xl" }
}

type DialogProps = Parameters<typeof Dialog>[0]
export type ModalProps = DialogProps & {
    show: boolean
    scrollAuto?: boolean
    noScroll?: boolean
    cardClassName?: string
    theme?: keyof typeof themes
    centered?: boolean
    children?: ReactNode
}

const contentWrapperId = "modal-content-wrapper"

const Modal: FC<ModalProps> = function Modal({
    show,
    onClose,
    scrollAuto = false,
    noScroll = false,
    theme = "gray",
    children,
    cardClassName,
    centered = false,
    ...props
}) {
    const { i18n } = useTranslation()
    return (
        <Transition show={show} as={Fragment}>
            <Dialog onClose={onClose} {...props} className="fixed z-50 inset-0">
                <div
                    id="modal-shader"
                    className="fixed inset-0 bg-opacity-75 bg-white transition-opacity flex items-end justify-center text-center sm:block sm:p-0 w-screen"
                >
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <DialogPanel
                            id={contentWrapperId}
                            className={classNames(
                                `relative inline-block align-bottom max-h-9/10screen bg-white rounded-lg transform transition-all sm:align-middle`,
                                !noScroll && (scrollAuto ? "overflow-y-auto" : "overflow-y-scroll"),
                                cardClassName,
                                themes[theme].card,
                                !centered && "mt-[6vh]",
                                centered && "!absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]",
                                i18n.language === "he" ? "text-right" : "text-left"
                            )}
                        >
                            <TransitionChild
                                as="div"
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {children}
                            </TransitionChild>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </Transition>
    )
}

export const ModalCompatiblePortal: FC<{ overrideId?: string; children: ReactNode }> = ({ overrideId, children }) =>
    createPortal(
        children,
        document.querySelector(`#${overrideId ?? contentWrapperId}`) ?? document.querySelector("body")!
    )

export default Modal
