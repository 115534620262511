import React from "react"
import { NavigationItem } from "components/v2"
import type { IStepsListProps } from "./navigationListType"

const NavigationList: React.FC<IStepsListProps> = ({ activeStepIndex, stepsList }) => (
    <div className="bg-gray-100 p-4 rounded-lg border-slate-200 border">
        {stepsList.map((stepItem, index) => (
            <NavigationItem key={index} isActive={activeStepIndex === index} {...{ ...stepItem, index }} />
        ))}
    </div>
)

export default NavigationList
