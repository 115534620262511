import { forwardRef } from "react"
import { classNames } from "utils"
import { transformNameToLetter } from "./userAvatarConstantsV2"
import { TypographyV2 } from "../common"

interface UserAvatarProps {
    image?: string
    firstName?: string
    lastName?: string
    size?: number
    className?: string
    //TODO: Add tooltipText
    tooltipText?: string
}

const UserAvatarV2 = forwardRef<HTMLImageElement, UserAvatarProps>(
    ({ image, firstName, lastName, className, size = 32 }, ref) => {
        const style = { width: size, height: size }

        return image ? (
            <img ref={ref} src={image} className={classNames("rounded-full", className)} style={style} />
        ) : (
            <div
                ref={ref}
                className={classNames(
                    "rounded-full flex justify-center items-center bg-dark-green text-white",
                    className
                )}
                style={style}
            >
                <TypographyV2 width="medium" type="xs">
                    {transformNameToLetter(firstName) + transformNameToLetter(lastName)}
                </TypographyV2>
            </div>
        )
    }
)

export default UserAvatarV2
