interface IntakeHeaderProps {
    title: string
    logoUrl: string
}

const IntakeHeader = ({ title, logoUrl }: IntakeHeaderProps) => {
    return (
        <header className="p-4 flex flex-row gap-[24px] items-center">
            {logoUrl && <img src={logoUrl} alt={title} width={50} height={50} />}
            <h1>{title}</h1>
        </header>
    )
}

export default IntakeHeader
