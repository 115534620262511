import { FC, ReactElement, ReactNode, SVGProps } from "react"
import Link from "next/link"
import { classNames } from "utils"
import { useTranslation } from "react-i18next"
import ToggleSidebarList, { ToggleMenuProps } from "./ToggleSidebarList"

export type MenuItem = {
    name: string
    href: string
    Icon: (props: SVGProps<SVGSVGElement>) => ReactNode
    testId?: string
}

export const MenuTitle: FC<{ className?: string; children?: ReactNode }> = ({ children, className }) => {
    return <h3 className={classNames("px-6 py-2 text-secondary font-semibold text-sm", className)}>{children}</h3>
}

type RenderMenuItemsProps = { items: MenuItem[]; beforeItems?: ReactNode; title?: string; toggle?: ToggleMenuProps }
export const RenderMenuItems = ({ items, beforeItems, title, toggle }: RenderMenuItemsProps) => {
    const { t } = useTranslation("common")

    const children = items.map(({ name, href, Icon, testId }) => (
        <Link key={href} href={href} passHref legacyBehavior>
            <a
                className="flex items-center gap-x-3 cursor-pointer hover:bg-white/10 py-2 px-6 text-white"
                data-testid={testId}
            >
                <Icon className="text-xl" />
                <div>{name}</div>
            </a>
        </Link>
    ))

    const titleText = t(`sidebar.titles.${title}`)
    return toggle ? (
        <ToggleSidebarList id={title} title={titleText} {...toggle}>
            {children}
        </ToggleSidebarList>
    ) : (
        <div className="flex flex-col py-4">
            {title && <MenuTitle>{titleText}</MenuTitle>}
            {beforeItems}
            {children}
        </div>
    )
}
