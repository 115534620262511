import { SVGProps } from "react"

const DefaultIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.99935 18.7682C14.6017 18.7682 18.3327 15.0373 18.3327 10.4349C18.3327 5.83252 14.6017 2.10156 9.99935 2.10156C5.39698 2.10156 1.66602 5.83252 1.66602 10.4349C1.66602 15.0373 5.39698 18.7682 9.99935 18.7682Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10 13.7679V10.4346"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10 7.10156H10.0083"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default DefaultIcon
