import React, { useCallback, useState } from "react"
import { ScheduleItemCard, ListV2, SearchInputV2 } from "components/v2"
import { EventTypes } from "types"

interface IContentItem {
    title: string
    time: string
    when: string
    eventType: string
}

const list = [
    { title: "Daily Stress Check-In", time: "Every Friday", when: "From Start", eventType: EventTypes.Unit },
    {
        title: "Mood and Thoughts Tracker",
        time: "Every Friday",
        when: "From Start",
        eventType: EventTypes.Questionnaire
    },
    {
        title: "Sleep Quality and Anxiety Connection",
        time: "Every Friday",
        when: "After 5 weeks",
        eventType: EventTypes.Exercise
    },
    { title: "Grounding Techniques", time: "Every Monday", when: "From Start", eventType: EventTypes.Exercise },
    {
        title: "Mindful Breathing Exercise",
        time: "Every Monday",
        when: "From Start",
        eventType: EventTypes.Unit
    }
]

interface IContentListProps {
    isVerticalView?: boolean
    isDraggable?: boolean
    isFiltering?: boolean
}

const ScheduleItemList: React.FC<IContentListProps> = ({
    isVerticalView = false,
    isFiltering = false,
    isDraggable = false
}) => {
    const [filterQuery, setFilterQuery] = useState("")

    const filterFunction = useCallback(
        (item: IContentItem) => {
            const regex = new RegExp(filterQuery, "i")
            return regex.test(item.title)
        },
        [filterQuery]
    )

    return (
        <>
            {isFiltering && (
                <SearchInputV2
                    placeholder="Search"
                    searchText={filterQuery}
                    onChange={(value) => setFilterQuery(value)}
                />
            )}
            <ListV2 renderItem={ScheduleItemCard} {...{ list, isDraggable, isVerticalView, filterFunction }} />
        </>
    )
}

export default ScheduleItemList
