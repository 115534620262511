import { ExerciseDefResponse } from "types/Redux"
import { CardV2, TagV2, TypographyV2 } from "components/v2"
import React from "react"
import { ActivityIcon } from "components/icons"
import { useIsHeLanguage } from "hooks"
import { classNames } from "utils"

const SelectedExerciseCard = ({ title }: ExerciseDefResponse) => {
    const { isHeLanguage } = useIsHeLanguage()
    return (
        <CardV2 className="flex w-3/4 items-center pr-2 p-[8px]">
            <TagV2 icon={<ActivityIcon className="text-gray-400" />} className="bg-gray-100 w-[30px] h-[30px]" />
            <TypographyV2
                width="semibold"
                className={classNames("ml-2 w-4/5 overflow-hidden text-ellipsis", isHeLanguage ? "mr-2" : "ml-2")}
            >
                {title}
            </TypographyV2>
        </CardV2>
    )
}

export default SelectedExerciseCard
