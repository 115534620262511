import { ScreeningQuestionType } from "@prisma/client"
import * as yup from "yup"

const intakeScreeningValidationSchemaBuilder = (questions: any[]) => {
    const validationSchema: any[] = []
    questions.forEach((question) => {
        const questionValidation = question.is_required ? yup.string().required() : yup.string().optional()
        if (question.type === ScreeningQuestionType.text_only) {
            validationSchema.push(yup.object({}))
        } else {
            validationSchema.push(
                yup.object({
                    screeningAnswers: yup.object({
                        [question.id]: questionValidation
                    })
                })
            )
        }
    })

    return [...validationSchema]
}

export default intakeScreeningValidationSchemaBuilder
