import { SelectOptionV2, IOptionV2 } from "components/v2/components/common/selects"
import Checkbox from "forms/Checkbox"

interface IMultiSelectOptionV2Props {
    isSelected: boolean
    option?: IOptionV2
    handleSelectOption?: (option: string | number) => void
}

const MultiSelectOptionV2 = ({ option, handleSelectOption, isSelected }: IMultiSelectOptionV2Props) => {
    return (
        <div
            className="text-black w-full flex flex-row p-3 hover:bg-blue-50 pointer-events-auto items-center gap-x-2.5"
            onClick={() => handleSelectOption(option.id)}
        >
            <div className="flex gap-x-2.5 items-center">
                <Checkbox
                    classList={{ input: "h-[18px] w-[18px]" }}
                    id={option.id + "-check"}
                    value={isSelected}
                    setValue={() => handleSelectOption(option.id)}
                />
                <SelectOptionV2 option={option} />
            </div>
        </div>
    )
}

export default MultiSelectOptionV2
