import { ReactElement } from "react"
import { classNames } from "utils"
import { RadioCircleV2 } from "components/v2"
interface IOptionType {
    value: string
    labelComponent: ReactElement | string
}

export interface IRadioCircleGroupV2Props {
    defaultChecked?: string
    disabled?: boolean
    name: string
    onChange: (value: string) => void
    options: IOptionType[]
    radioCircleWrapperStyle?: string
    radioCircleGroupWrapperStyle?: string
    isBordered?: boolean
    value: string
    readOnly?: boolean
}

const RadioCircleGroupV2 = (props: IRadioCircleGroupV2Props) => {
    const {
        disabled,
        name,
        onChange,
        options,
        radioCircleWrapperStyle,
        radioCircleGroupWrapperStyle,
        isBordered = false,
        value: selectedValue,
        readOnly = false
    } = props

    const handleChange = (value: string) => onChange(value)

    const borderStyle = isBordered ? "border border-border-blue rounded-lg py-4 w-full" : ""
    const wrapperStyle = isBordered ? "gap-4" : ""

    return (
        <div className={classNames("flex flex-col", radioCircleGroupWrapperStyle, wrapperStyle)}>
            {options.map(({ labelComponent, value }) => (
                <div key={value} className="flex gap-1 items-center w-full">
                    <RadioCircleV2
                        {...{ name, disabled, labelComponent, isBordered, readOnly }}
                        isChecked={selectedValue === value}
                        radioCircleWrapperStyle={classNames(
                            radioCircleWrapperStyle,
                            borderStyle,
                            selectedValue === value && isBordered
                                ? "border-med-blue bg-blue-50 shadow-[0_0_4px_0_#3C7FF91A]"
                                : ""
                        )}
                        onChange={handleChange}
                        value={value}
                    />
                </div>
            ))}
        </div>
    )
}

export default RadioCircleGroupV2
