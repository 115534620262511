import React, { memo } from "react"
import { ButtonV2, CardV2, DraggableItemV2, QuestionForm, TypographyV2 } from "components/v2/index"
import { DragIndicator } from "components/icons"
import { DeleteIconV2 } from "components/v2/components/index"
import { useExerciseQuestionItem } from "./useExerciseQuestionItem"
import { questionTypeIcons } from "../questionConstants"
import { ExerciseQuestionResponse } from "types/Redux"
import { classNames } from "utils"

const ExerciseQuestionItem: React.FC<ExerciseQuestionResponse> = memo((questionProps) => {
    const { type, id } = questionProps
    const { t, questionTitle, isHeLanguage, isExpanded, onRowClick, onDeleteClick } =
        useExerciseQuestionItem(questionProps)

    const ExerciseTypeIcon = questionTypeIcons[type]

    return (
        <DraggableItemV2 id={id}>
            <CardV2 onClick={onRowClick}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center flex-1 overflow-hidden">
                        <DragIndicator className={isHeLanguage ? "ml-3" : "mr-3"} />
                        <div className="flex items-center w-9/12">
                            <div className={classNames("p-2 rounded", isExpanded ? "bg-blue-50" : "bg-gray-50")}>
                                <ExerciseTypeIcon className={isExpanded ? "text-med-blue" : "text-gray-400"} />
                            </div>
                            <div
                                className={classNames(
                                    "flex-1 overflow-hidden text-ellipsis whitespace-nowrap",
                                    isHeLanguage ? "mr-3" : "ml-3"
                                )}
                            >
                                <TypographyV2
                                    className={classNames(
                                        "font-bold overflow-hidden text-ellipsis",
                                        isHeLanguage ? "text-right" : "text-left"
                                    )}
                                >
                                    {questionTitle}
                                </TypographyV2>
                            </div>
                        </div>
                    </div>
                    {!isExpanded && (
                        <div className="flex items-center justify-end">
                            <DeleteIconV2 onClick={onDeleteClick} />
                        </div>
                    )}
                </div>
                <div
                    className={classNames(
                        "transition-max-height duration-500 ease-in-out overflow-hidden",
                        isExpanded ? "max-h-max" : "max-h-0"
                    )}
                >
                    <QuestionForm {...questionProps} />
                </div>
                {isExpanded && (
                    <div className="flex items-center justify-end">
                        <ButtonV2
                            variant="light-gray"
                            text={t("deleteItem")}
                            icon={<DeleteIconV2 width={16} height={16} />}
                            onClick={onDeleteClick}
                        />
                    </div>
                )}
            </CardV2>
        </DraggableItemV2>
    )
})

export default ExerciseQuestionItem
