import { CollaboratorWithPermissions } from '@sequel-care/types'
import makeRenderUserList from 'components/Profile/utils'
import { useDispatch } from 'store'
import { toggleTherapistProfile } from 'store/sidebars/actions'


export const RenderCollaborators = makeRenderUserList<CollaboratorWithPermissions>({
    WrapperComponent: ({ item: { user, permissions }, ...props }) => {
        const dispatch = useDispatch()

        if (!permissions.length) return null

        return <button type="button" onClick={() => dispatch(toggleTherapistProfile(user.id))} {...props} />
    },
    getData: ({ user, permissions }) => ({
        user,
        role: permissions[0]?.permission === "case_manager" ? "case_manager" : "therapist"
    })
})


export default RenderCollaborators