import { OrganizationUser, QuestionnaireListItem } from "@sequel-care/types"
import { Spinner, Tooltip } from "components/common"
import { Tag } from "components/common/Tag"
import { defaultFilterClasses } from "components/Exports/FilterInput"
import EditIcon from "components/icons/Edit"
import { TagsField } from "components/PatientList/RowComponents"
import SuperSelect from "forms/SuperSelect"
import { useRouter } from "next/router"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { TbEye, TbTrash, TbTrashOff } from "react-icons/tb"
import { useDispatch } from "store"
import { togglePublish } from "store/global/actions"
import { useAllQuestionnaires, useCurrentRole } from "store/hooks"
import { SkeletonPlaceholder } from "types/Misc"
import { classNames, noopTFunction } from "utils"
import { ListField, RenderFieldProps } from "./types"
import { EventSelector } from "components/ContentLibrary/EventConfig/InputComponents/EventSelector"
import { Field, defineField } from "forms"
import { CarePlanBindingData, CarePlanPayload } from "@sequel-care/types/Event"
import { AvatarField } from "./ItemList"
import { RenderAuthStatus } from "./TeamListComponents"
import { CompleteUserInOrg } from "types/SchemaExtensions"
import {
    getAvailableForField,
    getDateOnTimelineField,
    getRepeatField
} from "components/ContentLibrary/EventConfig/EventFields"
import { pushToLibraryStack } from "store/patient/actions"
import { eventToTemplate } from "components/ContentLibrary/utils"

const RenderTextValue = ({ value, classes }: RenderFieldProps<string>) => (
    <div className={classNames(classes, "py-5 flex items-center text-dark-blue")}>{value}</div>
)

const questionnaireStatuses = ["unpublished", "published", "internal"] as const
export type QuestionnaireStatus = (typeof questionnaireStatuses)[number]

export type ListItem = Pick<
    QuestionnaireListItem,
    "id" | "title" | "related_diagnoses" | "created_by" | "is_published" | "organization_id"
> & {
    owner: string
    status: QuestionnaireStatus
}

export const roleField: ListField<OrganizationUser | CompleteUserInOrg<string>> = {
    key: "role",
    RenderField: ({ value, classes }) => {
        const { t } = useTranslation("common")
        return (
            <div className={classNames(classes, "flex h-full items-center")}>
                <span>{t(`roles.${value}`)}</span>
            </div>
        )
    }
}

export const teamFields: ListField<CompleteUserInOrg<string>>[] = [
    {
        key: "user",
        RenderField: AvatarField
    },
    {
        key: "profession",
        RenderField: ({ value, classes }) => {
            const { t } = useTranslation("common")
            return (
                <div className={classNames(classes, "flex h-full items-center")}>
                    <span>{t(`professions.${value}`)}</span>
                </div>
            )
        }
    },
    roleField,
    {
        key: "user",
        RenderField: RenderAuthStatus
    }
]

export const fields: ListField<ListItem>[] = [
    {
        key: "title",
        RenderField: RenderTextValue
    },
    {
        key: "status",
        RenderField: ({ value, classes }) => {
            const { t } = useTranslation("questionnaire")
            return (
                <div className={classNames(classes, "py-5")}>
                    <Tag color={value === "unpublished" ? "gray" : "blue"} className="w-24">
                        {t(`list.statuses.${value}`)}
                    </Tag>
                </div>
            )
        }
    },
    {
        key: "owner",
        RenderField: RenderTextValue
    },
    {
        key: "related_diagnoses",
        RenderField: TagsField
    }
]

export const OwnerFilter = ({ value, setOwner }: { value: number; setOwner: Dispatch<SetStateAction<number>> }) => {
    const role = useCurrentRole()
    const { t } = useTranslation("questionnaire")

    const items = useMemo(
        () => [
            { id: 3, label: t("list.sequelCare") },
            { id: role.organization_id, label: role.organization.name }
        ],
        [role, t]
    )
    return (
        <SuperSelect
            id="owner_ids"
            items={items}
            value={value}
            setValue={setOwner}
            classList={defaultFilterClasses}
            withSearch={false}
            placeholder={t("list.allOwners")}
            usePlaceholderAsAll
        />
    )
}

export const QuestionnaireStatusFilter = ({
    value,
    setStatus
}: {
    value: QuestionnaireStatus[]
    setStatus: Dispatch<SetStateAction<QuestionnaireStatus[]>>
}) => {
    const { t } = useTranslation("questionnaire")

    const items = useMemo(
        () =>
            questionnaireStatuses.map((id) => ({
                id,
                label: t(`list.statuses.${id}`)
            })),
        [t, questionnaireStatuses]
    )
    return (
        <SuperSelect
            id="questionnaire_status"
            value={value}
            items={items}
            setValue={(value) => setStatus(value as QuestionnaireStatus[])}
            classList={defaultFilterClasses}
            placeholder={t("list.allQuestionnaires")}
            usePlaceholderAsAll
            withSearch={false}
        />
    )
}

export const QuestionnaireLanguageFilter = ({
    value,
    setLanguage
}: {
    value: string[]
    setLanguage: Dispatch<SetStateAction<string[]>>
}) => {
    const { t } = useTranslation("questionnaire")
    const items = useMemo(
        () => [
            { id: "en", label: t("list.english") },
            { id: "he", label: t("list.hebrew") }
        ],
        [t]
    )
    return (
        <SuperSelect
            id="questionnaire_language"
            value={value}
            items={items}
            setValue={setLanguage}
            classList={defaultFilterClasses}
            placeholder={t("list.allLanguages")}
            usePlaceholderAsAll
            withSearch={false}
        />
    )
}

export const QuestionnaireTypeFilter = ({
    value,
    setType
}: {
    value: string[]
    setType: Dispatch<SetStateAction<string[]>>
}) => {
    const { t } = useTranslation("questionnaire")
    const items = useMemo(
        () => [
            { id: "patient", label: t("list.patient_questionnaire") },
            { id: "therapist", label: t("list.therapist_questionnaire") },
            { id: "significant_other", label: t("list.significant_other_questionnaire") }
        ],
        [t]
    )
    return (
        <SuperSelect
            id="questionnaire_type"
            value={value}
            items={items}
            setValue={setType}
            classList={defaultFilterClasses}
            placeholder={t("list.allTypes")}
            usePlaceholderAsAll
            withSearch={false}
        />
    )
}

export const EditQuestionnaire = ({ item }: any | SkeletonPlaceholder) => {
    const role = useCurrentRole()
    const router = useRouter()
    const { t } = useTranslation("patient")

    const isSkeleton = "skeleton" in item
    if (!isSkeleton && (role.role === "caregiver" || role.organization_id !== item.organization_id)) return null

    return (
        <Tooltip content={t("dashboard.editQuestionnaire")}>
            <EditIcon
                className={classNames("w-5 h-5", !isSkeleton && "cursor-pointer")}
                onClick={() => router.push(`/questionnaire/update/${item.id}`)}
            />
        </Tooltip>
    )
}

export const PublishToggle = ({ item }: { item: ListItem | SkeletonPlaceholder }) => {
    const dispatch = useDispatch()
    const role = useCurrentRole()
    const { t, i18n } = useTranslation("questionnaire")
    const [loading, setLoading] = useState(false)
    const isSkeleton = "skeleton" in item

    const onClick = async () => {
        if (!isSkeleton) {
            setLoading(true)

            await dispatch(togglePublish(item.id, !item.is_published))
            setLoading(false)
        }
    }

    if (loading) return <Spinner size={5} />
    if (!isSkeleton && (role.role === "caregiver" || role.organization_id !== item.organization_id)) return null

    const Icon = isSkeleton ? TbTrash : item.is_published ? TbTrashOff : TbTrash
    return (
        <Tooltip content={t("list.publishQuestionnaire")} placement={i18n.language === "he" ? "right" : "left"}>
            <Icon className={classNames("w-5 h-5", !isSkeleton && "cursor-pointer")} onClick={onClick} />
        </Tooltip>
    )
}

export const ViewQuestionnaireDetails = ({ item }: { item: ListItem | SkeletonPlaceholder }) => {
    const { t, i18n } = useTranslation("questionnaire")
    const dispatch = useDispatch()
    const isSkeleton = "skeleton" in item
    const questionnaries = useAllQuestionnaires()

    const onClick = () => {
        if (isSkeleton) return
        const questionnaire = questionnaries.find(({ id }) => id === item.id)
        dispatch(
            pushToLibraryStack({
                patientId: "preserve",
                eventTemplates: eventToTemplate("questionnaire", questionnaire),
                view: "learn_more"
            })
        )
    }

    return (
        <Tooltip content={t("list.viewDetails")} placement={i18n.language === "he" ? "right" : "left"}>
            <TbEye className={classNames("w-5 h-5", !isSkeleton && "cursor-pointer")} onClick={onClick} />
        </Tooltip>
    )
}

export type CarePlanEventConfig = ReturnType<typeof getCarePlanEventFields>

export const getCarePlanEventFields = (t = noopTFunction, withStart = false, customWeekDay = true) => {
    const fields: Field[] = [
        defineField({
            id: "type",
            placeholder: t("patient:eventManager.questionnairePlaceholder"),
            classList: {
                wrapper: "text-dark-blue",
                input: "!border-none !text-base w-full !py-0 placeholder:text-secondary-2 bg-transparent"
            },
            validations: { required: { value: true, message: t("patient:eventManager.validationMessages.event") } },
            types: carePlanEventTypes,
            settingState: true,
            Component: EventSelector
        }),
        getRepeatField({
            t,
            customWeekDay,
            classList: {
                wrapper: "col-span-1",
                input: "w-32 text-dark-blue !text-base !placeholder:text-dark-blue",
                label: "text-text-blue"
            }
        }),
        getAvailableForField({
            t,
            initialValue: 1,
            classList: { label: "text-text-blue", wrapper: "w-20" }
        })
    ]

    if (withStart)
        fields.splice(
            1,
            0,
            getDateOnTimelineField({
                t,
                withIcons: false,
                classList: {
                    wrapper: "!gap-2",
                    input: "text-dark-blue",
                    label: "text-text-blue"
                }
            }) as Field
        )

    return fields
}

export const carePlanEventsTypes = ["questionnaires" as const, "exercises" as const, "tasks" as const]
export const carePlanEventTypes = ["questionnaire" as const, "exercise" as const, "task" as const]
export type CarePlanEventTypes = (typeof carePlanEventTypes)[number]
export const mapCareplanEventsToUpdate = (careplan: CarePlanPayload) => {
    return carePlanEventsTypes.flatMap((type) =>
        careplan[type].map((event) => mapCareplanEventToUpdates(event, type.slice(0, -1)))
    )
}

export const mapCareplanEventToUpdates = (event: CarePlanBindingData, type: string) => {
    return {
        [`${type}_id`]: event.id,
        type,
        title: event.title,
        available_for: event.available_for,
        recurrence: {
            interval:
                event.recommended_recurrence >= 7 ? event.recommended_recurrence / 7 : event.recommended_recurrence,
            weekDay: event.week_day,
            enabled: true,
            unit: event.recommended_recurrence >= 7 ? "weeks" : "days"
        }
    }
}
