import { ChatDataPayload } from "@sequel-care/types"
import { Client, ConnectionState, Conversation, Message } from "@twilio/conversations"
import { fetchConversationData, getChatToken } from "api"
import { groupBy } from "lodash"
import { ArrayElement } from "type-fest/source/exact"
import { getPromiseWithResolve } from "utils"
import SequelConversation from "./SequelConversation"
import { ConversationInstanceSubscriptions, MessageNotification, Unsubscribe } from "./types"

type SetDataFn = (data: ChatDataPayload) => void
type GlobalConversationSubscriptions = Pick<ConversationInstanceSubscriptions, "messageCount"> & {
    conversations: ((conversations: SequelConversation[]) => void)[]
    messageNotifications: ((value: MessageNotification[]) => void)[]
}

const conversationSubscriptionKeys = ["conversations", "messageCount", "messageNotifications"]
class ConversationStore {
    public static conversationsByPatient: Record<number | null, SequelConversation[]> = {}
    private static subscriptions: GlobalConversationSubscriptions = {
        messageCount: [],
        conversations: [],
        messageNotifications: []
    }
    public static subscriptionsByPatient: Record<number, GlobalConversationSubscriptions> = {}
    private static client: Client | undefined
    private static connectionState: ConnectionState = "unknown"

    public static async initializeClient(): Promise<void> {
        this.killAll()

        this.client = await new Client(await getChatToken())
        const updateToken = async () => this.client.updateToken(await getChatToken())
        this.client.on("tokenExpired", updateToken)
        this.client.on("tokenAboutToExpire", updateToken)
        this.client.on("connectionStateChanged", (connectionState) => {
            this.connectionState = connectionState
        })
        this.client.on("messageAdded", (message) => this.addMessages([message]))
        this.client.on("conversationJoined", (conversation) => {
            new SequelConversation(conversation)
        })
    }

    public static killAll() {
        this.client?.removeAllListeners()
        this.conversationsByPatient = {}
        this.subscriptionsByPatient = {}

        conversationSubscriptionKeys.map<GlobalConversationSubscriptions[keyof GlobalConversationSubscriptions]>((key: keyof GlobalConversationSubscriptions) => (this.subscriptions[key] = []))
        this.client = null
    }

    public static async registerConversation(conversation: SequelConversation, setDataFn: SetDataFn) {
        const { patientId } = conversation

        await this.fetchParticipants(conversation, setDataFn)

        if (!this.conversationsByPatient[patientId]) this.conversationsByPatient[patientId] = []
        this.conversationsByPatient[patientId].push(conversation)

        conversation.subscribe("messageCount", () => {
            this.informSubscribers("messageCount", [patientId ?? null])
            this.informSubscribers("messageNotifications")
        })
        this.informSubscribers("conversations", [patientId])
    }

    public static getPatientIdFromConversation(conversation: Conversation) {
        const attributes = conversation.attributes as { patient_id: number }
        return attributes.patient_id
    }

    public static getUserIdFromIdentity(identity: string) {
        return /uid/.test(identity) ? parseInt(identity.slice(3)) : null
    }

    public static subscribe<K extends keyof GlobalConversationSubscriptions>(
        key: K,
        callback: ArrayElement<GlobalConversationSubscriptions[K]>,
        patientId?: number
    ): Unsubscribe {
        // New subscriptions are fired immediately
        callback(this.getSubscriptionData(key, patientId) as any)

        if (patientId && !(patientId in this.subscriptionsByPatient))
            this.subscriptionsByPatient[patientId] = { messageCount: [], conversations: [], messageNotifications: [] }

        const subscriptionObj = patientId ? this.subscriptionsByPatient[patientId] : this.subscriptions
        subscriptionObj[key].push(callback as any)

        return () => this.unsubscribe(key, callback, patientId)
    }

    public static unsubscribe<K extends keyof GlobalConversationSubscriptions>(
        key: K,
        callback: ArrayElement<GlobalConversationSubscriptions[K]>,
        patientId?: number
    ) {
        const subscriptionObj = patientId ? this.subscriptionsByPatient[patientId] : this.subscriptions
        if (subscriptionObj)
            subscriptionObj[key] = (subscriptionObj[key] as any[]).filter((subscription) => subscription !== callback)
    }

    public static getConversations(patientId?: number) {
        return !patientId
            ? Object.values(this.conversationsByPatient).flat()
            : this.conversationsByPatient[patientId] ?? []
    }

    private static participantFetchTimeout: ReturnType<typeof setTimeout> | null = null
    private static participantFetchQueue: {
        conversationSid: string
        setData: SetDataFn
        resolve: () => void
    }[] = []
    private static fetchParticipants(conversation: SequelConversation, setData: SetDataFn) {
        // Using a queue with timeouts might look cumbersome, but this makes sure that when initially loading all
        // conversations, we do not bombard our backend with requests, but rather send one request for all of them
        if (this.participantFetchTimeout) clearTimeout(this.participantFetchTimeout)

        const { promise, resolve } = getPromiseWithResolve<void>()
        this.participantFetchQueue.push({ conversationSid: conversation.sid, setData, resolve })

        this.participantFetchTimeout = setTimeout(async () => {
            const queue = this.participantFetchQueue
            this.participantFetchQueue = []
            this.participantFetchTimeout = null

            const dataByConversation = await fetchConversationData(queue.map(({ conversationSid }) => conversationSid))
            queue.forEach(({ conversationSid, setData, resolve }) => {
                setData(dataByConversation[conversationSid])
                resolve()
            })
        }, 200)

        return promise
    }

    private static getAggregatedMessageCount(patientId?: number) {
        const messageCount = { all: 0, unread: 0 }
        const conversations = this.getConversations(patientId)

        conversations.forEach((conversation) => {
            messageCount.all += conversation.messageCount.all
            messageCount.unread += conversation.messageCount.unread
        })
        return messageCount
    }

    private static getMessagesNotifications() {
        const conversations = this.getConversations()

        const messagesNotifications = conversations.reduce((sum, conversation) => {
            if (conversation.messageCount.unread > 0) {
                sum.push({
                    patientId: conversation.patientId,
                    participants: conversation.participants,
                    date: conversation.lastMessageDate,
                    conversationId: conversation.sid
                })
            }
            return sum
        }, [] as MessageNotification[])
        return messagesNotifications
    }

    private static getSubscriptionData(key: keyof GlobalConversationSubscriptions, patientId?: number) {
        switch (key) {
            case "conversations":
                return this.getConversations(patientId)
            case "messageCount":
                return this.getAggregatedMessageCount(patientId)
            case "messageNotifications":
                return this.getMessagesNotifications()
            default:
                console.warn(`Encountered uncovered subscription key "${key}" in ConversationStore`)
                return null
        }
    }
    private static informSubscribers(key: keyof GlobalConversationSubscriptions, patientIds?: number[]) {
        const iterateOverSubscriptions = (subscriptionsObj: GlobalConversationSubscriptions, data: any) => {
            subscriptionsObj[key].forEach((subscription) => subscription(data))
        }

        iterateOverSubscriptions(this.subscriptions, this.getSubscriptionData(key))
        if (patientIds)
            // We should also inform patient-specific subscriptions
            for (const patientId of patientIds)
                if (patientId in this.subscriptionsByPatient)
                    iterateOverSubscriptions(
                        this.subscriptionsByPatient[patientId],
                        this.getSubscriptionData(key, patientId)
                    )
    }

    private static async addMessages(messages: Message[]) {
        const messagesByConversation = groupBy(messages, (message) => message.conversation.sid)
        Object.values(messagesByConversation).forEach((conversationMessages) => {
            const [{ conversation }] = conversationMessages
            const patientId = this.getPatientIdFromConversation(conversation)
            this.conversationsByPatient?.[patientId]
                .find(({ sid }) => sid === conversation.sid)
                .addMessages(conversationMessages)
        })
    }
}

export default ConversationStore
