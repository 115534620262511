import React, { SVGProps } from "react"

const BulbIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5 0.375C2.72183 0.375 0.875 2.22183 0.875 4.5C0.875 6.02538 1.70316 7.3569 2.93258 8.07023C3.27531 8.26908 3.48993 8.57421 3.49964 8.88128C3.505 9.05066 3.62328 9.19539 3.7882 9.23437C3.96389 9.27589 4.14293 9.30857 4.32479 9.33194C4.4866 9.35273 4.62509 9.22312 4.62509 9.05999V6.72947C4.46545 6.71181 4.30901 6.683 4.15662 6.64377C3.95605 6.59215 3.8353 6.38771 3.88693 6.18714C3.93855 5.98657 4.14299 5.86582 4.34356 5.91745C4.55297 5.97134 4.77288 6.00009 5.00009 6.00009C5.2273 6.00009 5.44721 5.97134 5.65662 5.91745C5.85719 5.86582 6.06163 5.98657 6.11325 6.18714C6.16488 6.38771 6.04413 6.59215 5.84356 6.64377C5.69117 6.683 5.53473 6.71181 5.37509 6.72947V9.05997C5.37509 9.2231 5.51359 9.35271 5.6754 9.33192C5.8572 9.30855 6.03617 9.27588 6.2118 9.23437C6.37672 9.19539 6.495 9.05066 6.50036 8.88128C6.51007 8.57421 6.72469 8.26908 7.06742 8.07023C8.29684 7.3569 9.125 6.02538 9.125 4.5C9.125 2.22183 7.27817 0.375 5 0.375Z"
                fill="#3C7FF9"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.50657 9.94983C3.54517 9.74635 3.74141 9.61269 3.94489 9.65129C4.28637 9.71606 4.63905 9.75001 5 9.75001C5.36095 9.75001 5.71363 9.71606 6.05511 9.65129C6.25859 9.61269 6.45483 9.74635 6.49343 9.94983C6.53203 10.1533 6.39837 10.3495 6.19489 10.3881C5.80756 10.4616 5.40809 10.5 5 10.5C4.59191 10.5 4.19244 10.4616 3.80511 10.3881C3.60163 10.3495 3.46797 10.1533 3.50657 9.94983Z"
                fill="#3C7FF9"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.87704 11.172C3.89858 10.966 4.08303 10.8165 4.28902 10.838C4.52256 10.8625 4.75975 10.875 5 10.875C5.24025 10.875 5.47744 10.8625 5.71098 10.838C5.91697 10.8165 6.10142 10.966 6.12296 11.172C6.14451 11.378 5.995 11.5624 5.78902 11.584C5.5296 11.6111 5.26636 11.625 5 11.625C4.73364 11.625 4.4704 11.6111 4.21098 11.584C4.005 11.5624 3.85549 11.378 3.87704 11.172Z"
                fill="#3C7FF9"
            />
        </svg>
    )
}

export default BulbIcon
