import React from "react"
import { SuccessIcon, ErrorIcon, WarningIcon, InfoIcon, DefaultIcon } from "components/icons"
import { ToastNotificationStatuses } from "components/v2/constants"
import { TypeOptions } from "react-toastify"

interface IToastIconProps {
    type: TypeOptions
}

const trashIconsMap = {
    [ToastNotificationStatuses.Default]: DefaultIcon,
    [ToastNotificationStatuses.Success]: SuccessIcon,
    [ToastNotificationStatuses.Error]: ErrorIcon,
    [ToastNotificationStatuses.Warning]: WarningIcon,
    [ToastNotificationStatuses.Info]: InfoIcon
}

const ToastIcon: React.FC<IToastIconProps> = ({ type }) => {
    const TrashIcon = trashIconsMap[type]
    return <TrashIcon />
}

export default ToastIcon
