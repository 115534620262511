import { TypographyV2, ButtonV2 } from "components/v2"
import React from "react"
import { EmptyListIcon, PlusIcon } from "components/icons"

interface IEmptyListPlaceholderProps {
    title: string
    subTitle: string
    btnText: string
    onClick: () => void
}

const EmptyListPlaceholder: React.FC<IEmptyListPlaceholderProps> = ({ title, subTitle, btnText, onClick }) => (
    <div className="w-full flex flex-col justify-center items-center p-[60px]">
        <EmptyListIcon />
        <TypographyV2 width="bold" type="xl" className="mt-6">
            {title}
        </TypographyV2>
        <TypographyV2 className="text-gray-500 mt-1" type="sm">
            {subTitle}
        </TypographyV2>
        <ButtonV2 icon={<PlusIcon />} className="mt-6" text={btnText} onClick={onClick} />
    </div>
)

export default EmptyListPlaceholder
