import { AnyAction, Reducer } from "redux"
import { QuestionsState } from "types/Redux"

import * as types from "./types"

const initialState: QuestionsState = {
    isLoading: true,
    addVariableModal: {
        isOpened: false,
        selectedQuestion: null,
        selectedExercise: null
    },
    list: []
}

const questionReducer: Reducer<QuestionsState, AnyAction> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.OPEN_ADD_VARIABLE_MODAL: {
            return {
                ...state,
                addVariableModal: {
                    ...state.addVariableModal,
                    isOpened: true,
                    selectedQuestion: payload
                }
            }
        }
        case types.SELECT_EXERCISE_FOR_ADD_VARIABLE_MODAL: {
            return {
                ...state,
                addVariableModal: { ...state.addVariableModal, selectedExercise: payload }
            }
        }
        case types.CLOSE_ADD_VARIABLE_MODAL: {
            return { ...state, addVariableModal: initialState.addVariableModal }
        }
        case types.SET_QUESTIONS_LIST: {
            return { ...state, list: [...payload], isLoading: false }
        }
        case types.CLEAR_QUESTIONS_LIST: {
            return { ...initialState }
        }
        case types.ADD_NEW_QUESTION_TO_LIST: {
            return { ...state, list: [...state.list, payload] }
        }
        default:
            return state
    }
}

export default questionReducer
