import { ExerciseDefResponse, ExerciseUpdateSearchAction } from "types/Redux"
import { Diagnosis, User } from "@prisma/client"

export const SET_EXERCISES_LIST = "exercises/SET_EXERCISES_LIST"
export const SET_EXERCISES_SEARCH_PARAMS = "exercises/SET_EXERCISES_SEARCH_PARAMS"
export const SET_DIAGNOSES_LIST = "exercises/SET_DIAGNOSES_LIST"
export const SET_CREATORS_LIST = "exercises/SET_CREATORS_LIST"
export const SET_NEW_EXERCISE_TO_LIST = "exercises/SET_NEW_EXERCISE_TO_LIST"

interface SetExercisesListAction {
    type: typeof SET_EXERCISES_LIST
    payload: ExerciseDefResponse[]
}

interface SetExercisesSearchParamsAction {
    type: typeof SET_EXERCISES_SEARCH_PARAMS
    payload: ExerciseUpdateSearchAction
}

interface SetDiagnosisParamsAction {
    type: typeof SET_DIAGNOSES_LIST
    payload: Diagnosis[]
}

interface SetCreatorsParamsAction {
    type: typeof SET_CREATORS_LIST
    payload: User[]
}

interface SetNewExerciseToListAction {
    type: typeof SET_NEW_EXERCISE_TO_LIST
    payload: ExerciseDefResponse
}

export type ExercisesActionTypes =
    | SetExercisesListAction
    | SetExercisesSearchParamsAction
    | SetDiagnosisParamsAction
    | SetCreatorsParamsAction
    | SetNewExerciseToListAction
