import { AppointmentStatus, TaskStatus } from "@prisma/client"
import { Tag, TagColor } from "components/common/Tag"
import AppointmentStatusButtons from "components/TherapistHomepage/AppointmentStatusButtons"
import { isAfter } from "date-fns"
import { useRouter } from "next/router"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { toast } from "react-toastify"
import { setAppointmentStatus } from "store/patient/actions"
import { getAppointmentEndTime, localeFormat } from "utils/dates"
import SidebarSection from "../SidebarSection"
import AdditionalSnapshotData from "./AdditionalSnapshotData"
import QuestionnaireSnapshot from "./QuestionnaireSnapshot"
import { ResponseComponentProps } from "./types"

const taskStatusToColor: Record<TaskStatus | "null", TagColor> = {
    completed: "green",
    declined: "red",
    discussion_point: "yellow",
    partial: "green",
    null: "red"
}

const PatientSnapshot = ({ event, updateLocalEvent }: ResponseComponentProps) => {
    const { t, i18n } = useTranslation("patient")
    const { patient_id, snapshot } = event
    const isPast = useMemo(() => isAfter(new Date(), getAppointmentEndTime(event)), [event])
    const [loading, setLoading] = useState<AppointmentStatus>(null)
    const dispatch = useDispatch()
    const genericError = t("common:genericError")
    const router = useRouter()
    const inPatientPage = router.pathname.includes("patient")

    const setStatus = async (status: AppointmentStatus) => {
        setLoading(status)
        const res = await dispatch(
            setAppointmentStatus({ eventId: event.id, status, inPatientPage, date_on_timeline: event.date_on_timeline })
        )
        if (!res) toast(genericError)
        if (res && updateLocalEvent) updateLocalEvent({ appointment_status: status })
        setLoading(null)
    }

    return (
        <>
            <SidebarSection
                className="gap-10 py-10"
                title={
                    <>
                        {t("sidebar.patientSnapshot")}
                        <div className="text-text-blue text-base font-normal">
                            {t("sidebar.patientSnapshotDescription")}
                        </div>
                    </>
                }
                withoutBorder
            >
                <QuestionnaireSnapshot patient_id={patient_id} questionnaireData={snapshot.questionnaireData} />
                <AdditionalSnapshotData additionalInfo={snapshot.additionalInfo} />
                <div>
                    <span className="text-dark-blue font-medium">{t("common:tasks")}</span>
                    <div className="text-text-blue divide-y mt-6">
                        {snapshot.tasks?.length ? (
                            snapshot.tasks.map(
                                ({ task_status, title, event_id, date_on_timeline, completed_at, isSkipped }) => (
                                    <div
                                        key={event_id + date_on_timeline}
                                        className="justify-between flex items-center py-2"
                                    >
                                        {title}
                                        {(task_status || isSkipped) && (
                                            <div className="flex items-center gap-2">
                                                {localeFormat(new Date(completed_at ?? date_on_timeline), "d/M", i18n)}
                                                <Tag className="py-1" color={taskStatusToColor[task_status] as string}>
                                                    {t(`taskStatus.${task_status}`)}
                                                </Tag>
                                            </div>
                                        )}
                                    </div>
                                )
                            )
                        ) : (
                            <span className="text-text-blue">{t("sidebar.no_tasks")}</span>
                        )}
                    </div>
                </div>
            </SidebarSection>
            {isPast && (
                <SidebarSection title={t("sidebar.meetingStatus")} className="py-10 gap-6" withoutBorder>
                    <div className="flex gap-4">
                        <AppointmentStatusButtons
                            {...{ loading, setStatus }}
                            status={event.appointment_status}
                            size="large"
                            roundedClass={"rounded-2xl"}
                        />
                    </div>
                </SidebarSection>
            )}
        </>
    )
}

export default PatientSnapshot
