import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2, RadioCircleGroupV2 } from "components/v2"

const ScreeningQuestionResponse = ({
    question,
    screeningAnswer
}: {
    question: any
    screeningAnswer: ScreeningAnswer
}) => {
    const isSelect = question.definition.type === "select"
    return (
        <div className="p-4">
            <div className="bg-white border border-slate-300 rounded-lg  pt-4 pl-3">
                <TypographyV2 type="base" className="text-[#6B7280]">
                    {question.question}
                </TypographyV2>

                <div className="pt-2 flex flex-col gap-4">
                    {isSelect ? (
                        <TypographyV2 type="base">{screeningAnswer?.value}</TypographyV2>
                    ) : (
                        <RadioCircleGroupV2
                            name="relaxing-pre-sleep"
                            onChange={() => {}}
                            readOnly
                            value={screeningAnswer?.value}
                            options={question.definition.options.map((option: any) => ({
                                value: option?.value,
                                labelComponent: option?.label
                            }))}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ScreeningQuestionResponse
