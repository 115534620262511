import { ScreeningQuestionScale, ScreeningTemplateQuestion } from "@prisma/client"
import { TypographyV2, ControlledRadioCircleGroupV2 } from "components/v2"
import { Control } from "react-hook-form"

interface IScaleQuestionProps {
    control: Control<Record<string, string | (string | number)[]>>
    error?: string
    question: ScreeningTemplateQuestion & { scale: ScreeningQuestionScale }
    onChange?: () => void
}

enum ScaleDirection {
    POSITIVE = "positive",
    NEGATIVE = "negative"
}

const ScaleQuestionCard = ({ control, error, question, onChange }: IScaleQuestionProps) => {
    const { is_required, question: question_text, scale } = question

    const scaleMinValue = scale?.min
    const scaleMaxValue = scale?.max
    const scaleLabels =
        (scale?.labels as any)?.[0].map((label: string, i: number) =>
            label.trim() == "" ? (i + 1).toString() : label
        ) || new Array(scaleMaxValue - scaleMinValue + 1).fill(0).map((_, index) => index.toString())
    const scaleDirection = scale?.direction === ScaleDirection.POSITIVE
    const options = scaleLabels?.map((label: string, index: number) => {
        const step = (scaleMaxValue - scaleMinValue) / (scaleLabels.length - 1)
        const value = scaleDirection ? scaleMaxValue - step * index : scaleMinValue + step * index

        return {
            value: value.toString(),
            labelComponent: <TypographyV2 type="base">{label}</TypographyV2>
        }
    })
    return (
        <div className="flex flex-col items-start justify-center gap-[12px] w-full">
            <div className="w-full items-center justify-center text-center">
                <TypographyV2 type="xs">{question?.description}</TypographyV2>
            </div>
            <TypographyV2 type="base">{question_text}</TypographyV2>
            <ControlledRadioCircleGroupV2
                name={`screeningAnswers.${question.id}`}
                classList={{ wrapper: "w-full" }}
                isBordered
                isRequired={is_required}
                options={options}
                label={""}
                control={control}
                error={error}
                onChange={onChange}
            />
        </div>
    )
}

export default ScaleQuestionCard
