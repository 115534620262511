import { EventStatus } from "@prisma/client"
import { CollaboratorWithPermissions, DashboardCard, PortalNotificationData } from "@sequel-care/types"
import { EventComment, FlatEvent, GetEventsPayload } from "@sequel-care/types/Event"
import { AnyAction, Reducer } from "redux"
import { EventDeleteDialogData, PatientDashboardRanges } from "types/Misc"
import { DashboardGraphType, GraphState, PatientState } from "types/Redux"
import { createRangesObj } from "utils/dates"

import { GoalObject, GoalsDashboardData } from "@sequel-care/types/Goals"
import * as types from "./types"

const initialGraphData = (fromTreatmentStart = false, showHidden?: boolean) => ({
    range: fromTreatmentStart ? PatientDashboardRanges.NO_FILTER : PatientDashboardRanges.LAST_30_DAYS,
    resolution: "day" as const,
    showHidden
})

const initialState: PatientState = {
    currentPatient: null,
    currentCollaborator: null,
    currentPatientFeatures: null,
    eventsByDate: null,
    lastEventFetchData: { date: null, patientId: null },
    loading: {
        overview: true,
        timeline: true,
        questionnaires: true,
        tracker_manager: true,
        tracker: true,
        goals: true,
        overviewQuestionnairesScore: true
    },
    dashboard: {
        questionnaireEvents: null,
        trackerEvents: [],
        cards: {
            overview: createRangesObj([]),
            questionnaire: createRangesObj({})
        },
        lastDashboardFetchData: { date: null, patientId: null }
    },
    graphs: {
        questionnaire: initialGraphData(true, false),
        overview: initialGraphData(),
        overviewScores: { range: PatientDashboardRanges.LAST_3_MONTHS, resolution: "day" }
    },
    comments: {},
    eventsForGantt: null,
    contentLibrary: {
        patientId: null,
        stack: []
    },
    isCollaboratorManagerOpen: false,
    tracker: {
        manager: null,
        questions: undefined,
        measures: undefined,
        groups: [],
        dashboard: { earliestFetch: null, data: {}, colors: {}, completion: {} }
    },
    goals: null,
    overview: {
        notifications: [],
        engagementMontly: {},
        activity: [],
        eventsByWeek: {
            events: {},
            data: null
        },
        goalProgress: [],
        sleep: null,
        tracker: null,
        mood: null,
        questionnairesScores: null
    }
}

const patientReducer: Reducer<PatientState, AnyAction> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.CLEAR_CURRENT_PATIENT: {
            return initialState
        }
        case types.SET_CURRENT_PATIENT: {
            return { ...state, currentPatient: payload }
        }
        case types.SET_CURRENT_PATIENT_FEATURES: {
            return { ...state, currentPatientFeatures: payload }
        }
        case types.SET_CURRENT_COLLABORATOR: {
            return { ...state, currentCollaborator: payload }
        }
        case types.SET_EVENTS_BY_DATE: {
            const { events, comments, fetchData } = payload as GetEventsPayload<string> & {
                fetchData?: PatientState["lastEventFetchData"]
            }
            return {
                ...state,
                comments,
                eventsByDate: events,
                lastEventFetchData: fetchData ?? state.lastEventFetchData
            }
        }
        case types.SET_COMMENTS: {
            return { ...state, comments: payload }
        }
        case types.CLEAR_LAST_FETCH_DATA: {
            return { ...state, lastEventFetchData: initialState.lastEventFetchData }
        }
        case types.DELETE_EVENT: {
            // Find the deleted event and remove it from list
            const { event, policy } = payload as EventDeleteDialogData
            const events = [...state.eventsByDate]
            for (let i = 0; i < events.length; i++) {
                const filtered = events[i].events.filter(
                    ({ id, date_on_timeline }) =>
                        id !== event.id || (policy === "single" && event.date_on_timeline !== date_on_timeline)
                )
                if (filtered.length !== events.length) {
                    events[i] = { ...events[i], events: filtered }
                    if (policy === "single") break
                }
            }

            return { ...state, eventsByDate: events.filter(({ events }) => events.length > 0) }
        }
        // case types.ADD_EVENT: {
        //     const eventsByDate = [...state.eventsByDate];
        //     let dateIndex = state.eventsByDate.findIndex(({ date }) => date === payload.date_on_timeline)
        //     if(dateIndex < 0) dateIndex = eventsByDate.length

        //     eventsByDate[dateIndex] = {
        //         date: payload.date_on_timeline,
        //         events: [...(eventsByDate[dateIndex]?.events ?? []), payload]
        //     }

        //     return { ...state, eventsByDate }
        // }
        case types.SET_PATIENT_LOADING: {
            return { ...state, loading: { ...state.loading, [payload.key]: payload.value } }
        }
        case types.UPDATE_PERMISSION: {
            const collaborators = [...state.currentPatient.collaborators]
            const collaboratorIndex = collaborators.findIndex((c) => c.user.id === payload.user_id)
            const permissions = [...collaborators[collaboratorIndex].permissions]

            permissions[payload.index] = {
                ...permissions[payload.index],
                permission: payload.permission
            }
            collaborators[collaboratorIndex] = { ...collaborators[collaboratorIndex], permissions }

            return { ...state, currentPatient: { ...state.currentPatient, collaborators } }
        }
        case types.ADD_COLLABORATOR: {
            const newCollaborator = payload as CollaboratorWithPermissions

            const newCollaborators = [...state.currentPatient.collaborators]
            const collaboratorIndex = newCollaborators.findIndex(
                (collaborator) => collaborator.user.id === newCollaborator.user.id
            )

            if (collaboratorIndex < 0) newCollaborators.unshift(newCollaborator)
            else newCollaborators[collaboratorIndex] = newCollaborator

            return {
                ...state,
                currentPatient: { ...state.currentPatient, collaborators: newCollaborators }
            }
        }
        case types.REMOVE_PERMISSION: {
            const collaborators = [...state.currentPatient.collaborators]
            const collaboratorIndex = collaborators.findIndex((c) => c.user.id === payload.user_id)
            const permissions = [...collaborators[collaboratorIndex].permissions]

            permissions.splice(payload.index, 1)
            collaborators[collaboratorIndex] = {
                ...collaborators[collaboratorIndex],
                permissions
            }

            return { ...state, currentPatient: { ...state.currentPatient, collaborators } }
        }
        case types.UPDATE_EVENT_STATUS: {
            const { eventId, status } = payload as {
                eventId: number
                status: EventStatus
            }
            const eventsByDate = [...state.eventsByDate]
            for (let i = 0; i < eventsByDate.length; i++) {
                const eventIndex = eventsByDate[i].events.findIndex((event) => event.id === eventId)
                if (eventIndex < 0) continue

                const newEvents = [...eventsByDate[i].events]
                newEvents[eventIndex] = { ...newEvents[eventIndex], status }
                eventsByDate[i] = { ...eventsByDate[i], events: newEvents }
                return { ...state, eventsByDate }
            }
        }
        default:
            return state

        case types.CREATE_COMMENT: {
            const comments = { ...state.comments }
            const eventId = payload.comment.event_id

            comments[eventId] = [...(comments[eventId] ?? []), payload.comment]
            return { ...state, comments }
        }

        case types.UPDATE_COMMENT: {
            const comments = { ...state.comments }
            const eventId = payload.event_id
            if (!comments[eventId]) return state

            comments[eventId] = comments[eventId].map((comment) => (comment.id === payload.id ? payload : comment))
            return { ...state, comments }
        }

        case types.DELETE_COMMENT: {
            const { id, event_id } = payload as EventComment<string>
            const comments = { ...state.comments }
            comments[event_id] = [...comments[event_id]]
            const commentIndex = comments[event_id].findIndex((comment) => comment.id === id)
            comments[event_id].splice(commentIndex, 1)

            return { ...state, comments }
        }

        case types.UPDATE_PATIENT_DASHBOARD: {
            return { ...state, dashboard: { ...state.dashboard, ...payload } }
        }
        case types.UPDATE_GRAPH_STATE: {
            const { key, updates } = payload as {
                key: keyof PatientState["graphs"]
                updates: Partial<GraphState>
            }
            const graphState = { ...state.graphs }
            graphState[key] = { ...graphState[key], ...updates }
            return { ...state, graphs: graphState }
        }
        case types.SET_DASHBOARD_CARDS: {
            const { range, graphKey, cards } = payload as {
                range: PatientDashboardRanges
                graphKey: DashboardGraphType
                cards:
                    | DashboardCard[]
                    | {
                          [key: number]: DashboardCard[]
                      }
            }
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    cards: {
                        ...state.dashboard.cards,
                        [graphKey]: { ...state.dashboard.cards[graphKey], [range]: cards }
                    }
                }
            }
        }
        case types.SET_GOALS_DATA: {
            const data = payload as GoalsDashboardData
            return {
                ...state,
                goals: data
            }
        }
        case types.UPDATE_GOALS_ACHIEVE: {
            const data = payload as {
                id: number
                is_achieved: boolean
            }
            const goalsCache = state.goals
            const offset = data.is_achieved ? 1 : -1
            return {
                ...state,
                goals: {
                    ...goalsCache,
                    custom_goals_achieved: goalsCache.custom_goals_achieved + offset,
                    goals: goalsCache.goals.map((g) => {
                        if (g.id !== payload.id) return g
                        const intercepted: GoalObject = {
                            ...g,
                            is_achieved: data.is_achieved
                        }
                        return intercepted
                    })
                }
            }
        }
        case types.UPDATE_GOAL: {
            const data = payload as GoalObject & {
                title: string
            }
            const goalsCache = state.goals
            return {
                ...state,
                goals: {
                    ...goalsCache,
                    goals: goalsCache.goals.map((g) => {
                        if (g.id !== data.id) return g
                        const intercepted: GoalObject = {
                            ...g,
                            label: data.label ?? data.title ?? g.label,
                            description: data.description,
                            img_id: data.img_id
                        }
                        return intercepted
                    })
                }
            }
        }
        case types.CREATE_GOAL: {
            const data = payload as GoalObject
            const goalsCache = state.goals
            return {
                ...state,
                goals: {
                    ...goalsCache,
                    custom_goals_count: goalsCache.custom_goals_count + 1,
                    goals: [...goalsCache.goals, data]
                }
            }
        }

        case types.SET_EVENTS_FOR_GANTT: {
            return { ...state, eventsForGantt: payload }
        }

        case types.SET_CONTENT_LIBRARY: {
            return {
                ...state,
                contentLibrary: payload
            }
        }

        case types.GO_TO_PREV_CONTENT: {
            let newContentLibrary = { ...state.contentLibrary }

            const sidebarCount = newContentLibrary.stack.length
            if (sidebarCount < 2) newContentLibrary = { patientId: null, stack: [] }
            else newContentLibrary.stack = newContentLibrary.stack.slice(0, -1)
            return {
                ...state,
                contentLibrary: newContentLibrary
            }
        }

        case types.SET_LIBRARY_SEARCH: {
            const contentLibraryStack = [...state.contentLibrary.stack]
            contentLibraryStack[contentLibraryStack.length - 1].search = payload

            return {
                ...state,
                contentLibrary: { patientId: state.contentLibrary.patientId, stack: contentLibraryStack }
            }
        }

        case types.SET_COLLABORATOR_MANAGER: {
            return { ...state, isCollaboratorManagerOpen: payload.open }
        }

        case types.ADD_PATIENT_QUESTIONNAIRE: {
            const questionnaireId = payload
            return {
                ...state,
                currentPatient: {
                    ...state.currentPatient,
                    questionnaires: {
                        ...state.currentPatient.questionnaires,
                        [questionnaireId]: { id: questionnaireId }
                    }
                }
            }
        }

        case types.TOGGLE_AUTH_SUCCESS: {
            const { contactId, has_auth } = payload as {
                contactId: number
                has_auth: boolean
            }
            return {
                ...state,
                currentPatient: {
                    ...state.currentPatient,
                    contacts: state.currentPatient.contacts.map((contact) =>
                        contact.contact_id === contactId ? { ...contact, has_auth } : contact
                    )
                }
            }
        }

        case types.UPDATE_EVENT: {
            const { eventId, updates, date } = payload as {
                eventId: number
                date: string
                updates: Partial<FlatEvent<string>>
            }

            return {
                ...state,
                eventsByDate: state.eventsByDate?.map((byDate) =>
                    byDate.date === date
                        ? {
                              date,
                              events: byDate.events.map((event) =>
                                  event.id === eventId ? ({ ...event, ...updates } as FlatEvent<string>) : event
                              )
                          }
                        : byDate
                )
            }
        }

        case types.SET_TRACKER_MANAGER: {
            return { ...state, tracker: { ...state.tracker, manager: payload } }
        }

        case types.SET_TRACKER_DASHBOARD: {
            return { ...state, tracker: { ...state.tracker, dashboard: payload } }
        }
        case types.SET_TRACKER: {
            return { ...state, tracker: { ...state.tracker, ...payload } }
        }
        case types.ADD_TRACKER_MEASURE: {
            const currentMeasures = state.tracker?.measures || []
            const newMeasures = [...currentMeasures, payload]

            return {
                ...state,
                tracker: { ...state.tracker, measures: newMeasures }
            }
        }
        case types.ADD_TRACKER_GROUP: {
            const newGroups = [...state.tracker.groups, payload]
            return {
                ...state,
                tracker: { ...state.tracker, groups: newGroups }
            }
        }
        case types.UPDATE_OVERVIEW_DATA: {
            return { ...state, overview: { ...state.overview, ...payload } }
        }
        case types.READ_PATIENT_NOTIFICATIONS: {
            let notifications: PortalNotificationData[] = []
            if (!payload)
                notifications = state.overview.notifications.map((notification) => ({ ...notification, read: true }))
            else {
                const notificationsCopy = [...state.overview.notifications]
                const index = notificationsCopy.findIndex(({ id }) => id === payload.id)
                notificationsCopy.splice(index, 1, { ...notificationsCopy[index], read: true })
                notifications = notificationsCopy
            }
            return {
                ...state,
                overview: {
                    ...state.overview,
                    notifications
                }
            }
        }
        case types.UPDATE_CURRENT_PATIENT: {
            return {
                ...state,
                currentPatient: { ...state.currentPatient, ...payload }
            }
        }
    }
}

export default patientReducer
