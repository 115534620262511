import { TFunction } from "i18next"
import * as yup from "yup"

type ErrorKeys = "questionnaireId" | "availability" | "time_unit" | "time_offset" | "repeats" | "collaboratorIds"
export type IError = Record<ErrorKeys, Record<'message', string>>

export const featureSettingsValidationSchema = (t: TFunction) =>
    yup.object().shape({
        // Required fields
        questionnaireId: yup.number().required(t("featuresSettings.questionnaireIsRequired")),

    availability: yup
        .string()
        .oneOf(["day", "until"], t("featuresSettings.invalidAvailabilityOption"))
        .required(t("featuresSettings.availabilityIsRequired")),

    // Conditional fields based on availability
    time_unit: yup
        .string()
        .when("repeats", {
            is: "1", // daily repeats
            then: () => yup
                .string()
                .required(t("featuresSettings.timeUnitIsRequired"))
                .oneOf(["day"], t("featuresSettings.dailyRepeatsDayOnly")),
            otherwise: () => yup
                .string()
                .when(["availability", "repeats"], {
                    is: (availability: string, repeats: string) =>
                        availability === "until" && repeats === "7", // weekly repeats
                    then: () => yup
                        .string()
                        .required(t("featuresSettings.timeUnitIsRequired"))
                        .oneOf(["day", "week"], t("featuresSettings.invalidTimeUnit")),
                    otherwise: () => yup.string().nullable()
                })
        }),

    time_offset: yup
        .number()
        .when(["availability", "repeats", "time_unit"], {
            is: (availability: string, repeats: string, timeUnit: string) => 
                availability === "until" && repeats === "1", // daily repeats
            then: (schema) => schema
                .required(t("featuresSettings.timeOffsetIsRequired"))
                .max(1, t("featuresSettings.dailyRepeatsMaxOffset")),
            otherwise: (schema) => schema
                .when(["availability", "repeats", "time_unit"], {
                    is: (availability: string, repeats: string, timeUnit: string) =>
                        availability === "until" && repeats === "7" && timeUnit === "day", // weekly repeats with day unit
                    then: (schema) => schema
                        .required(t("featuresSettings.timeOffsetIsRequired"))
                        .max(7, t("featuresSettings.weeklyRepeatsDayMaxOffset")),
                    otherwise: (schema) => schema
                        .when(["availability", "repeats", "time_unit"], {
                            is: (availability: string, repeats: string, timeUnit: string) =>
                                availability === "until" && repeats === "7" && timeUnit === "week", // weekly repeats with week unit
                            then: (schema) => schema
                                .required(t("featuresSettings.timeOffsetIsRequired"))
                                .max(1, t("featuresSettings.dailyRepeatsMaxOffset")),
                            otherwise: (schema) => schema.nullable()
                        })
                })
        }),

    repeats: yup
        .string()
        .required(t("featuresSettings.repeatsIsRequired")),

    collaboratorIds: yup.array().of(yup.number()).nullable()
})
