import { Disclosure } from "@headlessui/react"
import { ChevronDownBold } from "components/icons"
import { TypographyV2 } from "components/v2"
import { ReactNode } from "react"

export const Accordion = ({
    children,
    title,
    totalQuestions
}: {
    children: ReactNode
    title: string
    totalQuestions?: number
}) => {
    return (
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="group p-2 flex justify-between w-full items-center">
                        <TypographyV2 type="sm" width="semibold" className="text-med-black">
                            {title}
                        </TypographyV2>
                        <div className="flex items-center gap-1">
                            {/*{typeof totalQuestions === "number" && (*/}
                            {/*    <div className="flex justify-center items-center rounded-sm p-1 cursor-pointer bg-red-50 text-red-500">*/}
                            {/*        <TypographyV2 type="sm" width="semibold">*/}
                            {/*            {totalQuestions}*/}
                            {/*        </TypographyV2>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            <ChevronDownBold className={`transition-all ${open ? "rotate-180" : ""}`} />
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">{children}</Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default Accordion
