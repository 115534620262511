import React, { FC, ReactNode } from "react"
import { DndContext } from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import {
    ExerciseQuestionItem,
    AddBottomLineButton,
    CreateExerciseQuestionPopover,
    CreateFirstExerciseQuestionList
} from "components/v2/index"
import { useExerciseQuestionsList } from "./useExerciseQuestionsList"
import { Loader } from "@aws-amplify/ui-react"
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers"

interface IQuestionsListProps {
    exerciseId: number
}

const ExerciseQuestionsList: React.FC<IQuestionsListProps> = ({ exerciseId }) => {
    const { t, isLoading, sensors, questions, onDragEnd } = useExerciseQuestionsList(exerciseId)

    if (isLoading)
        return (
            <div className="w-full flex justify-center">
                <Loader />
            </div>
        )

    if (!questions.length) return <CreateFirstExerciseQuestionList {...{ exerciseId }} />

    return (
        <div className="flex flex-col w-full">
            <DndContext
                sensors={sensors}
                onDragEnd={onDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <div className="flex flex-col gap-2.5">
                    <SortableContext items={questions} strategy={verticalListSortingStrategy}>
                        {questions?.map((item) => (
                            <ExerciseQuestionItem key={item.id} {...item} />
                        ))}
                    </SortableContext>
                </div>
            </DndContext>
            <AddBottomLineButton
                text={t("addContent")}
                PopoverComponent={({ close }: { close: () => void }) => (
                    <CreateExerciseQuestionPopover onClose={close} exerciseId={exerciseId} />
                )}
            />
        </div>
    )
}

export default ExerciseQuestionsList
