import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"
import { useMemo, useState } from "react"
import { classNames } from "utils"
import { RenderLabel } from "./RenderLabel"
import { FormControlProps } from "./types"
import { defaultInputClassNames } from "./utils"

export const PasswordField = (props: FormControlProps<string>) => {
    const { value, setValue, id, classList } = props
    const [showPassword, setShowPassword] = useState(false)
    const EyeComponent = useMemo(() => (showPassword ? EyeSlashIcon : EyeIcon), [showPassword])

    return (
        <div className={classNames("relative flex items-center", classList?.wrapper)}>
            <RenderLabel {...props} />
            <input
                id={`input-${id}`}
                value={value ?? ""}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setValue(e.target.value)}
                className={classNames(classList.input ?? defaultInputClassNames, "p-2 pr-8")}
            />
            <EyeComponent
                className="absolute right-2 w-5 text-gray-500 cursor-pointer hover:text-black"
                onClick={() => setShowPassword(!showPassword)}
            />
        </div>
    )
}
