import { TypographyV2 } from "components/v2"
import { TemplatePublishingStatus } from "components/v2/constants"
import { classNames } from "utils"
import React from "react"
import { colorClasses } from "./statusConstantsV2"
import { useIsHeLanguage } from "hooks"

interface IStatusV2Props {
    status: TemplatePublishingStatus
    className?: string
    text?: string
}

const StatusV2: React.FC<IStatusV2Props> = ({ text, status, className }) => {
    const { isHeLanguage } = useIsHeLanguage()
    const colorClass = colorClasses[status]
    return (
        <div className={classNames("flex items-center", className)}>
            <div className={classNames("border rounded h-[6px] w-[6px]", colorClass)} />
            <TypographyV2 className={isHeLanguage ? "mr-1" : "ml-1"}>{text}</TypographyV2>
        </div>
    )
}

export default StatusV2
