import { PlusIcon } from "@heroicons/react/24/solid"
import { Tooltip } from "components/common"
import { AlertTriangleIcon, CheckIcon, TrashIcon } from "components/icons"
import EditIcon from "components/icons/Edit"
import { RenderFields, validate } from "forms"
import { FormContext } from "forms/FormWithSteps"
import { ClassList, Field, FieldValues, FormControlProps, FormError } from "forms/types"
import { Dictionary, omit } from "lodash"
import { Dispatch, PropsWithChildren, ReactNode, SetStateAction, useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { classNames, getStateValue } from "utils"

const emptyObject = {}

type ListFieldProps<
    V extends Dictionary<any> = Dictionary<any>,
    Fields extends readonly Field[] = Field[]
> = FormControlProps<V[], FieldValues<Fields>, "layered"> & {
    ItemWrapper: (props: ItemWrapperProps<Fields>) => ReactNode
    ListWrapper: (props: ListWrapperProps<Fields>) => ReactNode
    RenderItem?: (props: RenderItemProps<Fields, FieldValues<Fields>>) => ReactNode
    useFields: (item: V) => Fields
    getInitialItemValue?: () => V
    gridTemplateColumns?: string
    withEdit?: boolean
    removeAll?: boolean
}

export type RenderItemProps<Fields extends readonly Field[], V extends FieldValues<Fields>> = Pick<
    ListFieldProps<V, Fields>,
    "id" | "classList" | "setValue" | "ItemWrapper" | "useFields" | "setErrors"
> & {
    errors?: Dictionary<string>
    item: V
    index: number
    edit?: number
    setEdit?: Dispatch<SetStateAction<number>>
    gridTemplateColumns?: string
    setItemErrors?: (newErrors: Dictionary<string>) => void
    removeDisabled?: boolean
}

export type ListWrapperProps<
    Fields extends readonly Field[],
    V extends FieldValues<Fields> = FieldValues<Fields>
> = PropsWithChildren<
    Required<Pick<FormControlProps<V[]>, "id" | "label" | "setValue" | "classList">> & {
        handleAdd?: () => void
    }
>

export type ItemWrapperProps<
    Fields extends readonly Field[],
    V extends FieldValues<Fields> = FieldValues<Fields>
> = PropsWithChildren<{
    errors: Dictionary<string>
    item: V
    setItem: Dispatch<SetStateAction<V>>
    index: number
    handleRemove: () => void
    fields: Fields
    edit: number
    setEdit: Dispatch<SetStateAction<number>>
    setErrors: Dispatch<SetStateAction<Dictionary<string>>>
    classList?: ClassList
    removeDisabled?: boolean
    gridTemplateColumns?: string
}>

export function ListField<Fields extends readonly Field[], V extends FieldValues<Fields> = FieldValues<Fields>>(
    props: ListFieldProps<V, Fields>
) {
    const { setErrors: setAllErrors } = useContext(FormContext)
    const {
        id,
        label,
        value,
        setValue,
        error,
        ListWrapper,
        getInitialItemValue,
        useFields,
        withEdit = true,
        removeAll = true,
        classList
    } = props

    const [edit, setEdit] = useState<number>(null)
    const item = value?.[edit]
    const fields = useFields(item)
    const isFieldError = !Array.isArray(error)

    const setRowErrors = useCallback(
        (index: number) => (newErrors: Dictionary<string>) => {
            setAllErrors((allErrors) => {
                const listErrors =
                    (allErrors[id] as Exclude<FormError, string>)?.map((itemErrors, itemIndex) =>
                        itemIndex !== index ? itemErrors : (getStateValue(newErrors, itemErrors) as Dictionary<string>)
                    ) ?? new Array(index).fill({}).concat([newErrors])

                return {
                    ...allErrors,
                    [id]: listErrors
                }
            })
        },
        [setAllErrors]
    )

    const handleAdd = useCallback(
        getInitialItemValue
            ? () => {
                  if (withEdit && item) {
                      const errors = validate(item, fields)
                      if (errors) {
                          setRowErrors(edit)(errors as Dictionary<string>)
                          return
                      }
                  }

                  setValue([...value, getInitialItemValue()])
                  setEdit(value.length)
              }
            : undefined,
        [item, edit, value, fields]
    )

    const Component = props.RenderItem ?? RenderItem

    return (
        <>
            <ListWrapper {...{ id, label, setValue, classList }} handleAdd={handleAdd}>
                {value.map((item, index) => (
                    <Component
                        key={index}
                        {...omit(props, "classList")}
                        setItemErrors={setRowErrors(index)}
                        item={item}
                        index={index}
                        errors={error?.[index]}
                        edit={withEdit ? edit : index}
                        setEdit={setEdit}
                        removeDisabled={!removeAll && value.length === 1}
                    />
                ))}
            </ListWrapper>
            {isFieldError ? <div className="text-red-500 text-xs col-span-full">{error}</div> : null}
        </>
    )
}

export function ListWrapper<Fields extends readonly Field[]>({
    children,
    label,
    handleAdd,
    classList
}: ListWrapperProps<Fields>) {
    const { t } = useTranslation()
    return (
        <div className={classNames(classList?.wrapper ?? "my-4 col-span-full")}>
            <p className="w-full text-dark-blue font-medium border-b border-border-blue pb-2">{label}</p>
            <div className="flex flex-col divide-y">
                {children}
                {handleAdd ? (
                    <div className="text-med-blue flex">
                        <span
                            className="items-center flex gap-1.5 ltr:ml-auto rtl:mr-auto mt-2 cursor-pointer"
                            onClick={handleAdd}
                        >
                            <PlusIcon className="w-4 h-4" /> {t("common:add")}
                        </span>
                    </div>
                ) : (
                    <div />
                )}
            </div>
        </div>
    )
}

export function ListRow<Fields extends readonly Field[]>({
    index,
    errors,
    handleRemove,
    children,
    item,
    fields,
    gridTemplateColumns,
    classList,
    edit,
    setEdit,
    setErrors,
    removeDisabled
}: ItemWrapperProps<Fields>) {
    const isEdit = edit === index
    const Icon = isEdit ? CheckIcon : EditIcon

    const toggleEdit = () => {
        if (isEdit) {
            const newErrors = validate(item, fields) as Dictionary<string>
            if (!newErrors) setEdit(null)
            else setErrors(newErrors)
        } else setEdit(index)
    }
    return (
        <div key={index} className={classNames(classList?.wrapper, "flex justify-between py-2")}>
            <div
                className={classNames(
                    "grid gap-x-8 items-center w-full relative ",
                    !isEdit && "opacity-60",
                    classList?.inputWrapper
                )}
                style={{ gridTemplateColumns }}
            >
                {children}
                {errors &&
                    Object.entries(errors).map(([fieldId, error], index) =>
                        error ? (
                            <div
                                key={`${index}:${fieldId}`}
                                className="absolute text-negative ltr:-left-6 rtl:-right-6 items-center translate-y-0.25"
                                style={{ gridArea: `1 / ${fields.findIndex(({ id }) => id === fieldId) + 1}` }}
                            >
                                <Tooltip placement="top" content={error}>
                                    <AlertTriangleIcon className="w-5 h-5" />
                                </Tooltip>
                            </div>
                        ) : null
                    )}
            </div>

            <div className="flex gap-2 items-center">
                <Icon
                    className="h-6 w-6 text-med-blue cursor-pointer hover:scale-110 transition-transform"
                    onClick={toggleEdit}
                />
                <TrashIcon
                    className={classNames(
                        "ltr:ml-auto rtl:mr-auto h-6 w-6 text-med-blue",
                        removeDisabled ? "opacity-60" : "cursor-pointer hover:scale-110 transition-transform"
                    )}
                    onClick={removeDisabled ? undefined : handleRemove}
                />
            </div>
        </div>
    )
}

function RenderItem<Fields extends readonly Field[], V extends FieldValues<Fields>>({
    id,
    item,
    errors = emptyObject,
    index,
    setValue,
    ItemWrapper,
    useFields,
    classList,
    gridTemplateColumns,
    edit = undefined,
    setItemErrors,
    setEdit,
    removeDisabled
}: RenderItemProps<Fields, V>) {
    const { setErrors: setAllErrors } = useContext(FormContext)
    const fields = useFields(item)

    const setItem: Dispatch<SetStateAction<V>> = useCallback((updatedItem) => {
        setValue((value) =>
            value.map((item, itemIndex) => (itemIndex !== index ? item : getStateValue(updatedItem, item)))
        )
    }, [])

    const handleRemove = useCallback(() => {
        setValue((value) => value.filter((_, i) => index !== i))
        setAllErrors((allErrors) => ({
            ...allErrors,
            [id]: (allErrors[id] as Exclude<FormError, string>)?.filter((_, i: number) => index !== i)
        }))
    }, [index])

    return (
        <ItemWrapper
            key={index}
            setErrors={setItemErrors}
            {...{
                item,
                setItem,
                index,
                handleRemove,
                fields,
                errors,
                setValue,
                classList,
                setEdit,
                edit,
                removeDisabled,
                gridTemplateColumns
            }}
        >
            <RenderFields
                state={item}
                setState={setItem}
                errors={emptyObject}
                setErrors={setItemErrors}
                isEdit={edit === index}
                {...{ fields, classList }}
            />
        </ItemWrapper>
    )
}
