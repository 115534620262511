import EmptyListPlaceholder from "components/v2/components/EmptyListPlaceholder"
import React from "react"
import { useTranslation } from "react-i18next"
const UnitList = () => {
    const { t } = useTranslation("unit")

    return (
        <EmptyListPlaceholder
            {...{
                title: t("emptyList.title"),
                subTitle: t("emptyList.subTitle"),
                btnText: t("patient:settingsSection.newUnit"),
                onClick: () => {}
            }}
        />
    )
}

export default UnitList
