import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react"
import { useDispatch } from "store"
import { getInitialAppData } from "store/global/actions"
import { AuthUser } from "aws-amplify/auth"
import { InactivityModal } from "./InactivityModal"

const initialCognitoState = {
    user: null as AuthUser,
    isLoading: true,
    signOut: null as () => void
}
const AuthContext = createContext(initialCognitoState)
export const useAuthContext = () => useContext(AuthContext)

type AuthProviderComponent = FC<{
    LoadingState: (props: { isLoading: boolean }) => ReactNode
    signOut: () => void
    user: AuthUser
    children: ReactNode
}>

export const AuthProvider: AuthProviderComponent = ({ children, LoadingState, signOut, user }) => {
    const dispatch = useDispatch()
    const [cognitoState, setCognitoState] = useState(initialCognitoState)
    const [showLoading, setShowLoading] = useState(false)

    useEffect(() => {
        ;(async () => {
            if (process.env.OFFLINE_USER_ID) {
                await dispatch(getInitialAppData())
                return setCognitoState({ isLoading: false, user: null, signOut: undefined })
            }

            if (user) {
                setShowLoading(true)
                await dispatch(getInitialAppData())
                setCognitoState({ user, signOut, isLoading: false })
            }
        })()
    }, [user])

    return (
        <AuthContext.Provider value={cognitoState}>
            {!cognitoState.isLoading && children}
            {showLoading && <LoadingState isLoading={cognitoState.isLoading} />}
            {typeof window !== "undefined" && <InactivityModal />}
        </AuthContext.Provider>
    )
}
