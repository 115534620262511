import React from "react"
import { CloseIcon } from "components/icons"
import { TypeOptions } from "react-toastify"
import { colors } from "./closeIconToastButtonConstants"

interface ICloseIconButtonProps {
    closeToast: () => void
    type: TypeOptions
}

const CloseIconToastButton: React.FC<ICloseIconButtonProps> = ({ closeToast, type }) => (
    <CloseIcon className={colors[type]} onClick={closeToast} />
)

export default CloseIconToastButton
