import React from 'react'
import { format } from "date-fns"
import { useCurrentUser } from 'store/hooks'

export const RenderDateValue = ({ value: date }: { value: string }) => {
    const { preferences } = useCurrentUser()

    if (!date) return null

    return <>{format(new Date(date), preferences.date_format === "d/m/y" ? "dd/MM/yyyy" : "MM/dd/yyyy")}</>
}


export default RenderDateValue