import { createExercise, getAllExercises, archiveExercise, copyExercise, publishExercise } from "api"
import * as types from "./types"
import { AppAction, ExerciseDefResponse, ExerciseUpdateSearchAction } from "types/Redux"
import { Diagnosis, User } from "@prisma/client"
import { toast } from "react-toastify"
import { CreateExerciseDefPayload } from "types/exercises/ExerciseDef"
import { closeModal, openExerciseBuilderModal, updateParamsModal } from "../modal/actions"
import i18n from "locales"

export const setExercisesListAction = (payload: ExerciseDefResponse[]) => {
    return { type: types.SET_EXERCISES_LIST, payload }
}

export const setExercisesSearchParamsAction = (payload: ExerciseUpdateSearchAction) => {
    return { type: types.SET_EXERCISES_SEARCH_PARAMS, payload }
}

export const setDiagnosisList = (payload: Diagnosis[]) => {
    return { type: types.SET_DIAGNOSES_LIST, payload }
}

export const setCreatorsList = (payload: User[]) => {
    return { type: types.SET_CREATORS_LIST, payload }
}

export const getExercisesListAction = (): AppAction<boolean> => async (dispatch) => {
    const exercises = await getAllExercises()

    const diagnoses = exercises
        .map(({ diagnoses }) => diagnoses.map(({ diagnose }) => diagnose))
        .flat()
        .filter(({ id }, index, self) => index === self.findIndex((d) => d.id === id))

    const creators = exercises
        ?.map(({ creator: { user } }) => user)
        .filter(({ id }, index, self) => index === self.findIndex((d) => d.id === id))

    dispatch(setCreatorsList(creators))
    dispatch(setDiagnosisList(diagnoses))
    dispatch(setExercisesListAction(exercises))
    return true
}

export const updateExercisesSearchParamsAction =
    (searchParams: ExerciseUpdateSearchAction): AppAction<boolean> =>
    async (dispatch) => {
        dispatch(setExercisesSearchParamsAction(searchParams))
        return true
    }

export const createNewExerciseAction =
    (body: CreateExerciseDefPayload): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const newExercise = await createExercise(body)
            toast.success(i18n.t("notifications.successfullyCreated", { ns: "exercise" }))
            dispatch(openExerciseBuilderModal(newExercise))
            dispatch(getExercisesListAction())
            return true
        } catch (err) {}
    }

export const makeExerciseArchivedAction =
    (id: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await archiveExercise(id)
            toast.success(i18n.t("notifications.successfullyArchived", { ns: "exercise" }))
            dispatch(getExercisesListAction())
            return true
        } catch (err) {}
    }

export const makeExercisePublishedAction =
    (id: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const exercise = await publishExercise(id)
            toast.success(i18n.t("notifications.successfullyPublished", { ns: "exercise" }))
            dispatch(updateParamsModal(exercise))
            dispatch(getExercisesListAction())
            dispatch(closeModal())
            return true
        } catch (err) {}
    }

export const makeExerciseCopyAction =
    (id: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await copyExercise(id)
            toast.success(i18n.t("notifications.successfullyCopied", { ns: "exercise" }))
            dispatch(getExercisesListAction())
            return true
        } catch (err) {}
    }
