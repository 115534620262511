import { AnyAction, Reducer } from "redux"
import { ModalState } from "types/Redux"
import * as types from "./types"

const initialState: ModalState = {
    type: null,
    params: {}
}

const modalReducer: Reducer<ModalState, AnyAction> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.OPEN_MODAL: {
            return { ...state, ...payload }
        }
        case types.CLOSE_MODAL: {
            return { ...initialState }
        }
        case types.UPDATE_PARAMS: {
            return { ...state, params: { ...payload } }
        }
        default:
            return state
    }
}

export default modalReducer
