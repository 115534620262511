import { ClockIcon } from "@heroicons/react/24/outline"
import { ExerciseQuestion, ExerciseQuestionType, QuestionScale, QuestionType } from "@prisma/client"
import { QuestionDefinition } from "@sequel-care/types"
import { Tooltip } from "components/common"
import { Tag } from "components/common/Tag"
import { CheckIcon, XIcon } from "components/icons"
import { useQuestionScale } from "components/Questionnaires/QuestionGrid"
import { ReactNode, useMemo } from "react"
import { TbPercentage } from "react-icons/tb"
import { BasicQuestion } from "types/Misc"
import { classNames, getQuestionLabels } from "utils"

const responseTagClassNames = "items-center gap-2 h-fit w-fit -translate-x-1 -translate-y-1.5"

type RenderResponseFn = (props: { question: ExerciseQuestion | BasicQuestion; value: string }) => ReactNode

type ExerciseScale = Pick<QuestionScale, "min" | "max">
const ScaleResponse: RenderResponseFn = ({ question, value: actualValue }) => {
    const scale = useQuestionScale(question)

    const labels = getQuestionLabels(question as BasicQuestion, scale)
    const { min, max } = scale ?? (question.definition as ExerciseScale)

    const possibleValues = useMemo(() => new Array(max - min + 1).fill(true).map((_, index) => index + min), [min, max])

    return (
        <div className="translate-y-1.5">
            <div className="flex justify-between items-center relative mb-6 font-normal text-sm">
                <div className="absolute bg-border-blue h-1 w-full"></div>
                {possibleValues.map((value) => {
                    const circleValue = Number(actualValue) === value ? value : undefined
                    const label = circleValue !== undefined && labels?.[circleValue - min]

                    return value !== min && value !== max ? (
                        <ScaleCircle key={value} value={circleValue} {...{ label, question }} />
                    ) : (
                        <div key={value} className="relative">
                            <ScaleCircle value={circleValue} {...{ label, question }} />
                            <div className="absolute -bottom-6 mx-1">{value}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const ScaleCircle = ({
    value,
    label,
    question
}: {
    value?: number
    label?: string
    question: ExerciseQuestion | BasicQuestion
}) => {
    const circleClassNames = classNames(
        "rounded-full z-[1] text-white text-[.7rem] flex items-center justify-center cursor-default",
        typeof value !== "undefined" ? "bg-dark-blue h-4 w-4" : "bg-border-blue h-2.5 w-2.5"
    )

    return value !== undefined && label ? (
        <Tooltip
            wrapperClassNames={circleClassNames}
            placement="bottom"
            content={label}
            data-testid={`response-for-${question.id}`}
        >
            {value}
        </Tooltip>
    ) : (
        <div className={circleClassNames}>{value}</div>
    )
}

const YesNoResponse: RenderResponseFn = ({ value }) => {
    return (
        <div>
            {parseInt(value) || /* For older versions of exercise - */ value.toLowerCase() === "yes" ? (
                <Tag color="green" className={responseTagClassNames.replace("w-fit", "w-20")}>
                    <CheckIcon className="text-lg" /> Yes
                </Tag>
            ) : (
                <Tag color="red" className={responseTagClassNames.replace("w-fit", "w-20")}>
                    <XIcon className="text-lg" /> No
                </Tag>
            )}
        </div>
    )
}

const MinutesResponse: RenderResponseFn = ({ value }) => {
    const minutes = parseInt(value) % 60
    const hours = Math.floor(parseInt(value) / 60)

    return (
        <Tag color="gray" className={classNames(responseTagClassNames, "!text-dark-blue")}>
            <ClockIcon className="w-5" /> {hours} Hours{minutes ? `, ${minutes} Minutes` : null}
        </Tag>
    )
}

const TextResponse: RenderResponseFn = ({ value }) => {
    return (
        <div className={classNames(!value && "translate-y-0.5 italic text-text-blue/60 text-sm", "min-h-[2.25rem]")}>
            {value || "No response"}
        </div>
    )
}

const MultipleChoiceResponse: RenderResponseFn = ({ value, question }) => {
    const parsedValue: number[] = JSON.parse(value)
    const options = (question.definition as QuestionDefinition)?.options // TODO: Remove "as" after merging questionnaires and exercises

    return (
        <div className="flex flex-wrap gap-2">
            {parsedValue.map((value) => (
                <Tag key={value} color="gray" className={classNames(responseTagClassNames, "!text-dark-blue")}>
                    {options?.[value] ?? value}
                </Tag>
            ))}
        </div>
    )
}

const SelectResponse: RenderResponseFn = ({ value, question }) => {
    const options = (question.definition as QuestionDefinition)?.options // TODO: Remove "as" after merging questionnaires and exercises
    const parsedValue = parseInt(value)

    return (
        <Tag color="gray" className={classNames(responseTagClassNames, "!text-dark-blue")}>
            {options?.[parsedValue] ?? value}
        </Tag>
    )
}

const PercentageResponse: RenderResponseFn = ({ value }) => (
    <div className="flex gap-1 items-center pb-2.5">
        {value}
        <TbPercentage />
    </div>
)

const NumberResponse: RenderResponseFn = ({ value }) => <div className="pb-2.5">{value}</div>

export const responseComponents: Record<QuestionType | ExerciseQuestionType, RenderResponseFn> = {
    scale: ScaleResponse,
    scale_slider: ScaleResponse,
    scale_reverse: ScaleResponse,
    yes_no: YesNoResponse,
    minutes: MinutesResponse,
    free_text: TextResponse,
    multiple_choice: MultipleChoiceResponse,
    select: SelectResponse,
    percent: PercentageResponse,
    number: NumberResponse,
    guideline: () => null as null,
    time_of_day: TextResponse,
    image: () => <>NO RESPOSE HERE</>,
    text: () => <>NO RESPOSE HERE</>
}
