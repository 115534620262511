import { ProfileRow } from 'components/Profile'
import { ProfileDisplayedField } from 'components/Profile/types'
import { Dictionary } from 'lodash'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export function RenderProfileBlock<T extends Dictionary<any>>({
    id,
    fields,
    entity,
    children,
    noDivider = false
}: {
    id: string
    fields: ProfileDisplayedField<string>[]
    entity: T
    children?: ReactNode
    noDivider?: boolean
}) {
    const { t } = useTranslation("patient")

    return (
        <>
            <div className="text-dark-blue font-medium text-base">{t(`patientForm.${id}`)}</div>
            {fields.map((fieldProps) => {
                const { key } = fieldProps
                return <ProfileRow key={key} fieldId={key} value={entity[key]} {...fieldProps} />
            })}
            {children}
            {!noDivider && <div className="border-b border-blue grow -mx-8" />}
        </>
    )
}


export default RenderProfileBlock