import React from "react"
import { FormInputWrapper, ScaleSliderV2 } from "components/v2"
import { FormControlPropsV2 } from "components/v2/components/common/form-controls"
import { useController } from "react-hook-form"

interface IControlScaleSliderV2Prop extends FormControlPropsV2<any> {
    min: number
    max: number
    step?: number
    minLabel?: string
    maxLabel?: string
    isDisabled?: boolean
}

const ControlledScaleSliderV2 = ({
    label,
    name,
    control,
    classList,
    isRequired,
    ...inputProps
}: IControlScaleSliderV2Prop) => {
    const { error } = inputProps
    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    return (
        <FormInputWrapper {...{ isRequired, error, classList: classList?.wrapper }}>
            <ScaleSliderV2 value={Number(value)} onChange={(value) => onChange(value.toString())} {...inputProps} />
        </FormInputWrapper>
    )
}

export default ControlledScaleSliderV2
