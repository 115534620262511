import { useController } from "react-hook-form"
import { IControlledTextInputWithLabelV2Props } from "./types"
import { useEffect, useRef, useState } from "react"

export const useControlledTextInputWithLabelV2 = (props: IControlledTextInputWithLabelV2Props) => {
    const { prevName, nextName, control } = props

    const prevLabelRef = useRef<HTMLDivElement>(null)
    const nextLabelRef = useRef<HTMLDivElement>(null)
    const isFirstRender = useRef(true)

    const [prev, setPrev] = useState("")
    const [after, setAfter] = useState("")

    const {
        field: { value: prevValue, onChange: onPrevChange }
    } = useController({
        name: prevName,
        control,
        rules: { required: true }
    })

    const {
        field: { value: nextValue, onChange: onNextChange }
    } = useController({
        name: nextName,
        control,
        rules: { required: true }
    })

    useEffect(() => {
        if (!isFirstRender.current) return
        setPrev(prevValue)
        setAfter(nextValue)

        isFirstRender.current = false
    }, [prevValue, nextValue])

    const handleOnPrevChange = () => {
        if (prevLabelRef) {
            const text = prevLabelRef.current.innerText
            onPrevChange(text)
        }
    }

    const handleOnNextChange = () => {
        if (nextLabelRef) {
            const text = nextLabelRef.current.innerText
            onNextChange(text)
        }
    }

    return {
        prevValue,
        prevLabelRef,
        nextLabelRef,
        after,
        prev,
        handleOnPrevChange,
        handleOnNextChange
    }
}
