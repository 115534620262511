import { EventStatus } from "@prisma/client"
import { CompleteEvent, FetchEventResponse, RawEventCreateInput } from "@sequel-care/types"
import {
    CommentData,
    CommentUpdateData,
    EventComment,
    EventHandlePolicy,
    EventUpdateData,
    EventValidationPayload,
    ExistingEvent,
    ExistingEventActionPayload,
    FlatEvent,
    GetEventsPayload
} from "@sequel-care/types/Event"
import { EventDeleteDialogData } from "types/Misc"
import { getPatientIdFromPath } from "utils"
import { ApiDelete, ApiGet, ApiPost, ApiPut, SelfAbortingGet } from "./ApiRequest"

export const getEventsInstance = new SelfAbortingGet<GetEventsPayload<string>>("getEvents")
export const getEvents = async (patientId: number, start: Date, end: Date) => {
    return getEventsInstance.run(`/patient/${patientId}/events`, { start, end })
}

export const getEventsForGantt = async (start: Date, patientId: number) => {
    const instance = new ApiGet<GetEventsPayload<string>>(`/patient/${patientId}/events/gantt`, { start })
    instance.callerName = getEventsForGantt.name
    return instance.run()
}

export const deleteEvent = async ({ event, policy }: EventDeleteDialogData) => {
    let params: { policy?: EventHandlePolicy; date?: string }
    if (event.recurrence) {
        params = { policy }
        if (policy !== "all") params.date = event.date_on_timeline
    }
    const instance = new ApiDelete<{ success: true }>(`/patient/${getPatientIdFromPath()}/event/${event.id}`, params)
    instance.callerName = deleteEvent.name
    return instance.run()
}

export const addEvents = async (patientId: number, patient_user_id: number, events: RawEventCreateInput[]) => {
    const instance = new ApiPost<FlatEvent<string>[]>(`/patient/${patientId}/events`, {
        events,
        patient_user_id
    })
    instance.callerName = addEvent.name
    return instance.run()
}

export const addEvent = async (patientId: number, patient_user_id: number, event: RawEventCreateInput) => {
    const instance = new ApiPost<FlatEvent<string>>(`/patient/${patientId}/event`, { event, patient_user_id })
    instance.callerName = addEvent.name
    return instance.run()
}

export const updateEvent = async (data: EventUpdateData) => {
    const instance = new ApiPut<FlatEvent<string>>(`/event/${data.event.id}`, data)
    instance.callerName = updateEvent.name
    return instance.run()
}

export const getEvent = async (patientId: number, eventId: number, date: string) => {
    const instance = new ApiGet<FetchEventResponse>(
        `/patient/${patientId || getPatientIdFromPath()}/event/${eventId}`,
        { date }
    )
    instance.callerName = getEvent.name
    return instance.run()
}

export const updateEventStatus = async (eventId: number, status: EventStatus) => {
    const instance = new ApiPut<{ success: boolean }>(`/event/${eventId}/status`, { status })
    instance.callerName = updateEventStatus.name
    return instance.run()
}

export const createComment = async (eventId: number, data: CommentData) => {
    const instance = new ApiPost<EventComment<string>>(`/event/${eventId}/comment`, data)
    instance.callerName = createComment.name
    return instance.run()
}

export const deleteComment = async (commentId: number) => {
    const instance = new ApiDelete<{ success: true }>(`event/comment/${commentId}`)
    instance.callerName = deleteComment.name
    return instance.run()
}

export const updateComment = async ({ event_id, id }: EventComment<string>, data: CommentUpdateData) => {
    const instance = new ApiPut<EventComment<string>>(`/event/${event_id}/comment/${id}`, data)
    instance.callerName = updateComment.name
    return instance.run()
}

export const validateEvent = async (patient_id: number, data: EventValidationPayload) => {
    const instance = new ApiPost<ExistingEvent>(`patient/${patient_id}/event-validation`, data)
    instance.callerName = validateEvent.name
    return instance.run()
}

export const existingEventAction = (patient_id: number, data: ExistingEventActionPayload) => {
    const instance = new ApiPost<FlatEvent<string>>(`patient/${patient_id}/existing-event-action`, data)
    instance.callerName = existingEventAction.name
    return instance.run()
}
