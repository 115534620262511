import React from "react"
import Modal from "./Modal"
import { CloseIcon } from "components/icons"
import { ButtonV2, TypographyV2 } from "components/v2"
import { useTranslation } from "react-i18next"

type CreateNewEventModalProps = {
    children: React.ReactNode
    eventname: string
    show: boolean
    onClose: () => void
    onConfirm?: () => void
    disabled?: boolean
    onCancel?: () => void
    id?: string
}

const CreateNewEventModal: React.FC<CreateNewEventModalProps> = ({
    children,
    eventname,
    show: isOpen,
    onCancel,
    onClose,
    onConfirm,
    disabled,
    id
}) => {
    const { t } = useTranslation("common")
    return (
        <Modal show={isOpen} onClose={onClose} centered cardClassName="w-5/6 max-w-screen-lg">
            <div className="p-4 flex justify-between border-b border-border-blue">
                <TypographyV2 width="bold" type="base" className="text-med-black">
                    {t("createNew")} {eventname}
                </TypographyV2>
                <CloseIcon onClick={onClose} className="cursor-pointer text-[#9CA3AF]" height={20} width={20} />
            </div>
            <div className="p-6 h-120 overflow-y-auto">{children}</div>
            <div className="p-4 flex justify-end border-t bg-[#F3F4F6] border-border-blue gap-2.5">
                <ButtonV2 variant="light-gray" onClick={onCancel || onClose} text={t("cancel")} />
                <ButtonV2
                    form={id}
                    isDisabled={disabled}
                    type="submit"
                    onClick={onConfirm}
                    text={`${t("create")} ${eventname}`}
                />
            </div>
        </Modal>
    )
}

export default CreateNewEventModal
