import { ApiGet, ApiPost, ApiPut } from "./ApiRequest"
import { RejectCandidateData } from "@sequel-care/types/Intake"
import { IntakeCandidate } from "components/v2/constants/enums/intake"

export const fetchIntakeTemplate = ({
    clinicUrl,
    screeningTemplate
}: {
    clinicUrl: string
    screeningTemplate: string
}) => {
    const instance = new ApiGet<any>(`intake/templates/${encodeURIComponent(clinicUrl)}?screening_template_type=${screeningTemplate}`)
    instance.callerName = fetchIntakeTemplate.name
    return instance.run({ withAuth: "none" })
}

export const submitScreening = ({ submitUrl, screeningAnswers }: { submitUrl: string; screeningAnswers: any }) => {
    const instance = new ApiPost<any>(`intake/${submitUrl}`, screeningAnswers)
    instance.callerName = submitScreening.name
    return instance.run({ withAuth: "none" })
}

export const fetchIntakeCandidates = () => {
    const instance = new ApiGet<IntakeCandidate[]>("intake/candidates")
    instance.callerName = fetchIntakeCandidates.name
    return instance.run()
}

export const fetchIntakeCandidateById = (candidateId: number) => {
    const instance = new ApiGet<IntakeCandidate>(`intake/candidates/${candidateId}`)
    instance.callerName = fetchIntakeCandidateById.name
    return instance.run()
}

export const fetchScreeningTemplate = (templateName: string) => {
    const instance = new ApiGet<any>(`intake/templates/${templateName}`)
    instance.callerName = fetchScreeningTemplate.name
    return instance.run()
}

export const createCandidate = (data: any) => {
    const instance = new ApiPost<any>(`intake/candidates`, data)
    instance.callerName = createCandidate.name
    return instance.run()
}

export const rejectCandidate = (candidateId: number, rejectCandidateData: RejectCandidateData) => {
    const instance = new ApiPut<IntakeCandidate>(`intake/candidates/reject/${candidateId}`, rejectCandidateData)
    instance.callerName = rejectCandidate.name
    return instance.run()
}

export const updateCandidate = (candidateId: number, updates: Partial<IntakeCandidate>) => {
    const instance = new ApiPut<IntakeCandidate>(`intake/candidates/${candidateId}`, updates)
    instance.callerName = updateCandidate.name
    return instance.run()
}

export const sendRejectionEmail = (candidateId: number) => {
    const instance = new ApiPost<{ success: boolean }>(`intake/send-rejection-email/${candidateId}`)
    instance.callerName = sendRejectionEmail.name
    return instance.run()
}

export const proceedCandidate = (candidateId: number, proceedData: any) => {
    const instance = new ApiPut<IntakeCandidate>(`intake/candidates/proceed/${candidateId}`, proceedData)
    instance.callerName = proceedCandidate.name
    return instance.run()
}

export const acceptCandidate = (candidateId: number, acceptData: any) => {
    const instance = new ApiPut<IntakeCandidate>(`intake/candidates/accept/${candidateId}`, acceptData)
    instance.callerName = acceptCandidate.name
    return instance.run()
}

export const fetchIntakeCandidateByPatientId = (patientID: number) => {
    const instance = new ApiGet<IntakeCandidate>(`intake/candidates/accepted-patient/${patientID}`)
    instance.callerName = fetchIntakeCandidateByPatientId.name
    return instance.run()
}

export const send2ndScreening = (candidateId: number) => {
    const instance = new ApiPost<any>(`intake/send-screening-template/${candidateId}`)
    instance.callerName = send2ndScreening.name
    return instance.run()
}

export const updateScreeningInstanceComment = (id: number, params: { comments: string }) => {
    const instance = new ApiPut<any>(`intake/screening-instance/comment/${id}`, params)
    instance.callerName = updateScreeningInstanceComment.name
    return instance.run()
}

export const getIntakeHeaderImage = async (): Promise<Blob> => {
    const instance = new ApiGet("intake/header-image")
    instance.callerName = getIntakeHeaderImage.name
    return instance.run({ withAuth: "none", blobResponse: true }) as Promise<Blob>
}

export const getScreeningUrl = async () => {
    const instance = new ApiGet("intake/organizations/templates")
    instance.callerName = getScreeningUrl.name
    return instance.run()
}
