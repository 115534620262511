import React from "react"
import {
    BagesListV2,
    DropdownMenuV2,
    ExerciseDiagnosesList,
    HoverablePopoverV2,
    StatusV2,
    TableCellV2,
    TagWithIcon,
    TypographyV2,
    UserAvatarV2
} from "components/v2"
import { exerciseStatusesWithLocales, TemplatePublishingStatus } from "components/v2/constants"
import { ElipsisIcon } from "components/icons"
import { EventTypes } from "types"
import { ExerciseDefResponse } from "types/Redux"
import { useExerciseItem } from "./useExerciseItem"
import { classNames } from "utils"

const ExerciseItem = (exercise: ExerciseDefResponse) => {
    const {
        status,
        title,
        description,
        creator: {
            user: { first_name: firstName, last_name: lastName }
        }
    } = exercise

    const { t, diagnoses, isHeLanguage, mappedDiagnosesForBagesList, dropDownActionsList, openExerciseBuilder } =
        useExerciseItem(exercise)

    return (
        <tr className="cursor-pointer" onClick={openExerciseBuilder}>
            <TableCellV2>
                <div className="flex items-center">
                    <TagWithIcon eventType={EventTypes.Exercise} className="flex-shrink-0" />
                    <div className={classNames("flex flex-col items-start", isHeLanguage ? "mr-4" : "ml-4")}>
                        <TypographyV2 width="bold" type="sm" className="w-80 overflow-hidden text-ellipsis">
                            {title}
                        </TypographyV2>
                        <TypographyV2 className="text-gray-500 mt-1 w-80 overflow-hidden text-ellipsis">
                            {description}
                        </TypographyV2>
                    </div>
                </div>
            </TableCellV2>
            <TableCellV2>
                <HoverablePopoverV2 content={<BagesListV2 list={mappedDiagnosesForBagesList} />}>
                    <div className="flex items-center gap-1">
                        <ExerciseDiagnosesList diagnoses={diagnoses} />
                    </div>
                </HoverablePopoverV2>
            </TableCellV2>
            <TableCellV2>
                <StatusV2
                    status={status as TemplatePublishingStatus}
                    text={t(`exerciseStatuses.${exerciseStatusesWithLocales[status]}`)}
                />
            </TableCellV2>
            <TableCellV2>
                <div className="flex items-center">
                    <UserAvatarV2 size={19} {...{ firstName, lastName }} className="p-3" />
                    <TypographyV2 width="normal" className={isHeLanguage ? "mr-2" : "ml-2"}>
                        {firstName + " " + lastName}
                    </TypographyV2>
                </div>
            </TableCellV2>
            <TableCellV2>
                <DropdownMenuV2 menuItems={dropDownActionsList} clickComponent={<ElipsisIcon />} />
            </TableCellV2>
        </tr>
    )
}

export default ExerciseItem
