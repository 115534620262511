import { useController, useForm, useWatch } from "react-hook-form"
import { useEffect, useMemo } from "react"
import { IScaleFormProps, IScaleDefinition } from "./scaleFormTypes"
import { useTranslation } from "react-i18next"
import { useIsHeLanguage } from "hooks"

export const useScaleForm = ({ name, control }: IScaleFormProps) => {
    const { t } = useTranslation("question")
    const {
        field: { onChange, value }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    const { isHeLanguage } = useIsHeLanguage()

    const numbers = useMemo(() => Array.from({ length: 100 }, (_, i) => ({ id: i + 1, title: `${i + 1}` })), [])

    const defaultValues = value as IScaleDefinition

    const { control: newControl } = useForm({
        defaultValues: {
            min: defaultValues?.min ?? 1,
            max: defaultValues?.max ?? 10,
            minLabel: defaultValues?.minLabel ?? "",
            maxLabel: defaultValues?.maxLabel ?? ""
        }
    })

    const formValues = useWatch({
        control: newControl
    })

    useEffect(() => {
        onChange(formValues)
    }, [formValues])

    return { t, isHeLanguage, numbers, newControl }
}
