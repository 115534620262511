import { useCallback } from "react"
import { useDiagnoses } from "store/hooks"

const tagClassNames = "rounded-full bg-text-blue/10 py-1 px-3 w-fit whitespace-nowrap mr-1 mt-1.5 inline-block truncate"


export const RenderDiagnoses = ({ value }: { value: number | number[] }) => {
    const diagnoses = useDiagnoses()
    const getDiagnosisName = useCallback(
        (diagnosisId?: number) => diagnosisId && diagnoses.find((diagnosis) => diagnosis.id === diagnosisId)?.name,
        [diagnoses]
    )

    if (!Array.isArray(value)) return <div className={tagClassNames}>{getDiagnosisName(value)}</div>

    return (
        <>
            {value.map((diagnosisId) => (
                <div key={diagnosisId} className={tagClassNames}>
                    {getDiagnosisName(diagnosisId)}
                </div>
            ))}
        </>
    )
}
export default RenderDiagnoses
