import React from "react"
import { Switch } from "@headlessui/react"
import { classNames } from "utils"

interface IToggleV2Props {
    className?: string
    isChecked: boolean
    onChange: () => void
    isLarge?: boolean
    //TODO: Add tooltipText
    tooltipText?: string
}

const SwitchV2: React.FC<IToggleV2Props> = ({ isLarge = false, isChecked = false, onChange, className }) => {
    const circleClasses = isLarge
        ? isChecked
            ? `translate-x-[17.5px]`
            : "translate-x-[1px]"
        : isChecked
        ? `translate-x-[12.6px]`
        : "translate-x-[1px]"
    return (
        <Switch
            checked={isChecked}
            onChange={onChange}
            className={classNames(
                "relative inline-flex items-center rounded-full",
                isLarge ? "w-[40px] h-[24px]" : "w-[30px] h-[18px]",
                isChecked ? "bg-med-blue" : "bg-gray-300",
                className
            )}
        >
            <span
                className={classNames(
                    "inline-block transform rounded-full bg-white transition",
                    isLarge ? "w-[22px] h-[22px]" : "w-[17px] h-[17px]",
                    circleClasses
                )}
            />
        </Switch>
    )
}

export default SwitchV2
