import { ScreeningAnswer } from "@prisma/client"
import { TypographyV2, RadioCircleGroupV2 } from "components/v2"
import { useTranslation } from "react-i18next";

const YesNoQuestionResponse = ({ question, screeningAnswer }: { question: any; screeningAnswer: ScreeningAnswer }) => {
    const { t } = useTranslation()
    const options = [
        { value: "yes", label: t("intake:common.yes") },
        { value: "no", label: t("intake:common.no") }
    ]

    return (
        <div className="p-4">
            <div className="bg-white border border-slate-300 rounded-lg  p-4">
                <TypographyV2 type="base" className="text-[#6B7280]">
                    {question.question}
                </TypographyV2>

                <div className="pt-2 flex flex-col gap-4">
                    <RadioCircleGroupV2
                        name={question.id}
                        onChange={() => {}}
                        readOnly
                        value={screeningAnswer?.value}
                        isBordered
                        options={options.map((option: any) => ({
                            value: option.value,
                            labelComponent: <TypographyV2 type="base">{option.label}</TypographyV2>
                        }))}
                    />
                </div>
            </div>
        </div>
    )
}

export default YesNoQuestionResponse
