import { defaultLabelClassNamesV2 } from "components/v2/constants/styles"
import React from "react"
import { classNames } from "utils"
import { useIsHeLanguage } from "hooks"

interface IRenderLabelV2Props {
    label: string
    classList?: string
    isRequired?: boolean
}

const RenderLabelV2: React.FC<IRenderLabelV2Props> = ({ label, classList, isRequired }) => {
    const { isHeLanguage } = useIsHeLanguage()
    return (
        <label className={classNames(defaultLabelClassNamesV2, classList, isHeLanguage ? "text-right" : "text-left")}>
            {isRequired && "*"}
            {label}
        </label>
    )
}

export default RenderLabelV2
