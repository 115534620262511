import React, { forwardRef } from "react"
import { classNames } from "utils"
import { disabledInputClassNames, errorInputClassNames, inputClassNames } from "components/v2/constants"

interface ITextInputV2Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    isDisabled?: boolean
    error?: string
    onChange: (value: string) => void
}

const TextInputV2 = forwardRef<HTMLInputElement, ITextInputV2Props>((props, ref) => {
    const { isDisabled, error, className, onChange, ...inputProps } = props

    return (
        <div className="relative">
            <input
                ref={ref}
                onChange={(e) => onChange(e.target.value)}
                className={classNames(
                    inputClassNames,
                    isDisabled && disabledInputClassNames,
                    error && !isDisabled && errorInputClassNames,
                    "pr-6",
                    className
                )}
                disabled={isDisabled}
                {...inputProps}
            />
        </div>
    )
})

export default TextInputV2
