import React, { forwardRef, useMemo } from "react"
import { FormInputWrapper, BinarySelector2 } from "components/v2"
import { FormControlPropsV2 } from "components/v2/components/common/form-controls"
import { useController } from "react-hook-form"
import { IPublishBinaryListItem } from "components/v2/types"

interface IControlTextInputV2Prop extends FormControlPropsV2<any> {
    options: IPublishBinaryListItem[]
}

const ControlledBinarySelectorV2 = forwardRef<HTMLInputElement, IControlTextInputV2Prop>((props, ref) => {
    const { isRequired, label, classList, name, control, ...inputProps } = props

    const { error, options } = inputProps

    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        rules: { required: true }
    })

    const isLeftSelected = useMemo(() => {
        const [left] = options
        return left.value === value
    }, [value, options])

    return (
        <FormInputWrapper {...{ label, error, isRequired, classList: classList?.wrapper }}>
            <BinarySelector2 {...{ options, isLeftSelected, onChange }} />
        </FormInputWrapper>
    )
})

export default ControlledBinarySelectorV2
