import React from "react"
import { Modal } from "components/common"
import { BreadcrumbsSlashIcon, ElipsisIcon, PlusIcon, Square3Stack, UsersIcon } from "components/icons"
import {
    BadgeV2,
    BagesListV2,
    ButtonV2,
    DropdownMenuV2,
    TabsHeaderV2,
    TypographyV2
} from "components/v2/components/common"
import { closeModal } from "store/modal/actions"
import { ExerciseDefResponse } from "types/Redux"
import { ExerciseQuestionsList, QuestionAddVariableModal } from "../exercise-questions"
import { useExerciseBuilderComponent } from "./useExerciseBuilderComponent"
import { ExerciseDiagnosesList, StatusV2 } from "components/v2"
import {
    exerciseStatusesWithLocales,
    genderTitles,
    TemplatePublishingStatus,
    userRoleTitles
} from "components/v2/constants"
import { languagesWithCapital, exerciseTypeTitles } from "./exerciseBuilderConstants"
import { UserRole } from "@prisma/client"
import { HoverablePopoverV2 } from "components/v2"
import { classNames } from "utils"

const ExerciseBuilderComponent = ({ exerciseDefDto }: { exerciseDefDto: ExerciseDefResponse }) => {
    const { id, title, language, exercise_type, description, status, min_age, max_age, gender } = exerciseDefDto
    const {
        t,
        diagnoses,
        isPublished,
        isHeLanguage,
        dispatch,
        activeTab,
        visibleRoles,
        dropDownActionsList,
        mappedDiagnosesForBagesList,
        notVisibleRolesCount,
        onActiveTabClick,
        onPublishClick
    } = useExerciseBuilderComponent(exerciseDefDto)

    return (
        <div>
            <QuestionAddVariableModal />
            <Modal cardClassName="w-full !max-h-screen !h-screen !mt-0 rounded-none" show={true} onClose={() => {}}>
                <div className="max-h-screen absolute top-0 bottom-0 left-0 right-0 flex flex-col">
                    <div className="pt-3 px-4 bg-light-background border-b border-base-light flex justify-between">
                        <div className="flex flex-1 pb-3 gap-2 items-center">
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol className="flex items-center">
                                    <li className="items-center">
                                        <button onClick={() => dispatch(closeModal())}>
                                            <TypographyV2
                                                type="base"
                                                width={"normal"}
                                                className={"inline-flex items-center hover:text-blue-600 text-gray-500"}
                                            >
                                                {t("common:eventType.exercise")}
                                            </TypographyV2>
                                        </button>
                                    </li>
                                    <li className="flex items-center">
                                        <div className="mx-4">
                                            <BreadcrumbsSlashIcon />
                                        </div>
                                        <a href="#">
                                            <TypographyV2
                                                type="base"
                                                width="bold"
                                                className={classNames(
                                                    "text-gray-900 text-base  hover:text-blue-600 w-auto max-w-[300px] overflow-hidden text-ellipsis",
                                                    isHeLanguage ? "text-right" : "text-left"
                                                )}
                                            >
                                                {title}
                                            </TypographyV2>
                                        </a>
                                    </li>
                                </ol>
                            </nav>
                            <div className="flex items-center gap-1">
                                <BadgeV2
                                    text={t(languagesWithCapital[language])}
                                    color="darkBlue"
                                    className="px-1.5 py-0.5"
                                />
                                <BadgeV2
                                    text={t(exerciseTypeTitles[exercise_type])}
                                    color="darkBlue"
                                    className="px-1.5 py-0.5"
                                />
                            </div>
                        </div>
                        <div className="self-end">
                            <TabsHeaderV2
                                tabItems={[
                                    { key: "1", title: t("build") },
                                    { key: "2", title: t("preview") }
                                ]}
                                {...{ activeTab, onActiveTabClick }}
                            />
                        </div>
                        <div className="flex flex-1 pb-3 gap-2 items-center justify-end">
                            <StatusV2
                                status={status as TemplatePublishingStatus}
                                className="mr-10"
                                text={t(`exerciseStatuses.${exerciseStatusesWithLocales[status]}`)}
                            />
                            <ButtonV2 isDisabled={isPublished} text={t("publish")} onClick={onPublishClick} />
                            <DropdownMenuV2
                                menuItems={dropDownActionsList}
                                clickComponent={<ElipsisIcon width={24} height={24} />}
                            />
                        </div>
                    </div>
                    <div className="pb-2.5 pt-3 px-6 bg-light-background border-b border-base-light flex gap-4 items-center justify-between">
                        <div>
                            <TypographyV2 className="text-base-dark" type="xs">
                                {t("professionalDescription")}
                            </TypographyV2>
                            <TypographyV2
                                className="text-base-dark mt-1.5 w-80 overflow-hidden text-ellipsis"
                                type="sm"
                            >
                                {description}
                            </TypographyV2>
                        </div>
                        <div>
                            <div className="flex w-44 items-center gap-1 mb-1.5">
                                <Square3Stack />
                                <TypographyV2 className="text-base-dark" type="xs" width="semibold">
                                    {t("diagnoses")}
                                </TypographyV2>
                            </div>
                            <HoverablePopoverV2 content={<BagesListV2 list={mappedDiagnosesForBagesList} />}>
                                <div className="flex gap-1">
                                    <ExerciseDiagnosesList diagnoses={diagnoses} />
                                </div>
                            </HoverablePopoverV2>
                        </div>
                        <div>
                            <div className="flex w-44 items-center gap-1 mb-1.5">
                                <UsersIcon />
                                <TypographyV2 className="text-base-dark" type="xs" width="semibold">
                                    {t("audience")}
                                </TypographyV2>
                            </div>
                            <div className="flex gap-1">
                                <BadgeV2
                                    text={
                                        min_age && max_age
                                            ? `${t("age")} ${min_age} - ${max_age}`
                                            : t("exercise:createModal.all")
                                    }
                                    color="darkBlue"
                                    className="px-1.5 py-0.5"
                                />
                                <BadgeV2
                                    text={t(`common:genders.${genderTitles[gender]}`)}
                                    color="darkBlue"
                                    className="px-1.5 py-0.5"
                                />
                                {visibleRoles.map(({ role, role_id }) => (
                                    <BadgeV2
                                        key={role_id}
                                        text={t(`common:roles.${userRoleTitles[role.role as UserRole]}`)}
                                        color="darkBlue"
                                        className="px-1.5 py-0.5"
                                    />
                                ))}
                                {!!notVisibleRolesCount && (
                                    <BadgeV2
                                        text={`+ ${notVisibleRolesCount}`}
                                        color="darkBlue"
                                        className="px-1.5 py-0.5"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 pt-16 pb-5 overflow-auto">
                        <div className="w-200 mx-auto my-auto">
                            <TypographyV2
                                className="text-med-black w-auto	 overflow-hidden text-ellipsis"
                                type="3xl"
                                width="bold"
                            >
                                {title}
                            </TypographyV2>
                            <button className="bg-base-lighter  mt-4 text-base-dark rounded-md p-1 flex items-center gap-0.5">
                                <PlusIcon />
                                <TypographyV2 type="xs">
                                    {t("addBriefSummary")} ({t("optional")})
                                </TypographyV2>
                            </button>
                            <div className="mt-10">
                                <ExerciseQuestionsList exerciseId={id} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ExerciseBuilderComponent
