export const errorInputClassNames = `border-rose-600 focus:border-rose-600 focus:shadow-none`

export const disabledInputClassNames = "bg-gray-50 text-gray-400 font-normal focus:shadow-none"

export const inputClassNames =
    "border p-3 text-sm font-medium bg-white rounded-lg border-gray-300 w-full placeholder:text-gray-400 focus:border-[1px] focus:border-primary focus:border-primary focus:shadow-primary-focus focus:outline-none"

export const defaultLabelClassNamesV2 = "mb-0.5 text-xs font-medium"

export const errorClassNamesV2 = "flex items-center tracking-wide text-red-800 text-xs  whitespace-nowrap z-10 mt-2"

export const tabsClassNamesV2 = "flex justify-between w-fit border-b border-border-blue gap-x-10"

export const tabItemClassNamesV2 = "cursor-pointer pb-2 border-b-[2px] -mb-px border-transparent"
