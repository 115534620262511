import React, { memo } from "react"
import { ControlledSelectV2, ControlledTextInputV2 } from "components/v2/components"
import { useScaleForm } from "./useScaleForm"
import { IScaleFormProps } from "./scaleFormTypes"
import { classNames } from "utils"

const ScaleForm = memo((props: IScaleFormProps) => {
    const { t, isHeLanguage, numbers, newControl } = useScaleForm(props)

    return (
        <div className="mt-10">
            <div className="flex items-center">
                <ControlledSelectV2
                    classList={{ wrapper: classNames("w-28", isHeLanguage ? "ml-3" : "mr-3") }}
                    control={newControl}
                    name="min"
                    label={t("min")}
                    options={numbers}
                />
                <ControlledTextInputV2
                    classList={{ wrapper: "w-56" }}
                    control={newControl}
                    name="minLabel"
                    placeholder={t("minLabel")}
                    label={t("minLabel")}
                />
            </div>
            <div className="flex items-center mt-3">
                <ControlledSelectV2
                    classList={{ wrapper: classNames("w-28", isHeLanguage ? "ml-3" : "mr-3") }}
                    control={newControl}
                    name="max"
                    label={t("max")}
                    options={numbers}
                />
                <ControlledTextInputV2
                    classList={{ wrapper: "w-56" }}
                    control={newControl}
                    name="maxLabel"
                    placeholder={t("maxLabel")}
                    label={t("maxLabel")}
                />
            </div>
        </div>
    )
})

export default ScaleForm
