import { Button } from "components/common"
import { ChevronLeftIcon } from "components/icons"
import React, { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useCurrentPatient } from "store/hooks"
import { goToThePrevSidebar } from "store/sidebars/actions"
import { getUserName } from "utils"

const FeedSidebar = ({ action, title, children }: { action?: () => void; children: ReactNode; title: string }) => {
    const { t } = useTranslation("patient")
    const [loading, setLoading] = useState(false)
    const currentPatient = useCurrentPatient()
    const dispatch = useDispatch()

    const handleAction = async () => {
        setLoading(true)
        await action()
        setLoading(false)
    }

    return (
        <div className="flex w-full bg-white h-fit flex-col divide-y divide-border-blue">
            <div className="p-4 flex flex-col gap-4">
                <span className="text-dark-blue flex gap-2 items-center font-semibold">
                    <ChevronLeftIcon
                        onClick={() => dispatch(goToThePrevSidebar())}
                        className="cursor-pointer hover:scale-110 text-secondary-2 hover:text-dark-blue w-4 h-4"
                    />
                    {title}
                </span>
                <div className="flex items-center text-secondary-2 text-xs">
                    {t("overview.latestActivity", { patientName: getUserName(currentPatient?.user) })}
                    {action ? (
                        <Button
                            theme="secondary_borderless"
                            onClick={handleAction}
                            loading={loading}
                            className="text-xs px-2 py-0"
                        >
                            {t("overview.markAll")}
                        </Button>
                    ) : null}
                </div>
            </div>

            {children}
        </div>
    )
}

export default FeedSidebar
