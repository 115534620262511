import React, { useCallback, useState } from "react"
import { ListV2, SearchInputV2 } from "components/v2"
import { useExerciseSelector } from "store/hooks"
import { ExerciseVerticalItem } from "../"

interface IContentItem {
    title: string
    subtitle: string
    author: string
    categories: string[]
}

interface IContentListProps {
    isVerticalView?: boolean
    isFiltering?: boolean
}

const ExerciseAddVariableList: React.FC<IContentListProps> = ({ isFiltering = false }) => {
    const { list } = useExerciseSelector()

    const [filterQuery, setFilterQuery] = useState("")

    const filterFunction = useCallback(
        (item: IContentItem) => {
            const regex = new RegExp(filterQuery, "i")
            return regex.test(item.title)
        },
        [filterQuery]
    )

    return (
        <>
            {isFiltering && (
                <SearchInputV2
                    className="text-sm"
                    iconClassName="w-5"
                    searchText={filterQuery}
                    onChange={(value) => setFilterQuery(value)}
                />
            )}
            <div className="mt-2">
                <ListV2 renderItem={ExerciseVerticalItem} {...{ list, isFiltering, filterFunction }} />
            </div>
        </>
    )
}

export default ExerciseAddVariableList
