import { TableCellV2, TypographyV2 } from "components/v2"
import React, { ReactNode } from "react"
import { classNames } from "utils"

interface IHeaderProps {
    title?: string
    className?: string
}

interface ITableV2Props {
    isEmpty?: boolean
    headerCells: IHeaderProps[]
    listComponent?: ReactNode
    emptyListComponent?: ReactNode
}

const TableListV2: React.FC<ITableV2Props> = ({ isEmpty = false, headerCells, emptyListComponent, listComponent }) => {
    if (isEmpty) return <>{emptyListComponent}</>

    return (
        <table className="table-auto bg-white border rounded-lg  border-separate border-spacing-4 w-full">
            <thead>
                <tr>
                    {headerCells.map(({ title, className }, idx) => (
                        <TableCellV2 key={idx} className={classNames("px-4", className)}>
                            <TypographyV2 width="medium" className="text-gray-400">
                                {title}
                            </TypographyV2>
                        </TableCellV2>
                    ))}
                </tr>
            </thead>
            <tbody>{listComponent}</tbody>
        </table>
    )
}

export default TableListV2
