import { SVGProps } from "react"

const PercentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6 9.5L10 5.5M13 3.17132V14.5L10.5 13.5L8 14.5L5.5 13.5L3 14.5V3.17132C3 2.43267 3.53739 1.80009 4.27112 1.71494C5.49437 1.57298 6.73866 1.5 8 1.5C9.26134 1.5 10.5056 1.57298 11.7289 1.71494C12.4626 1.80009 13 2.43267 13 3.17132ZM6.5 6H6.505V6.005H6.5V6ZM6.75 6C6.75 6.13807 6.63807 6.25 6.5 6.25C6.36193 6.25 6.25 6.13807 6.25 6C6.25 5.86193 6.36193 5.75 6.5 5.75C6.63807 5.75 6.75 5.86193 6.75 6ZM9.5 9H9.505V9.005H9.5V9ZM9.75 9C9.75 9.13807 9.63807 9.25 9.5 9.25C9.36193 9.25 9.25 9.13807 9.25 9C9.25 8.86193 9.36193 8.75 9.5 8.75C9.63807 8.75 9.75 8.86193 9.75 9Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default PercentIcon
