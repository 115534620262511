import { SVGProps } from "react"

const SingleSelectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M5 2.5H4C3.17157 2.5 2.5 3.17157 2.5 4V5M11 2.5H12C12.8284 2.5 13.5 3.17157 13.5 4V5M13.5 11V12C13.5 12.8284 12.8284 13.5 12 13.5H11M5 13.5H4C3.17157 13.5 2.5 12.8284 2.5 12V11M10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)

export default SingleSelectIcon
