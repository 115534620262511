import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react"
import { classNames } from "utils"

import { btnVariantStyles } from "./buttonConstantsV2"
import { TypographyV2 } from "components/v2"
import { useIsHeLanguage } from "hooks"

interface IButtonProps
    extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "disabled"> {
    isDisabled?: boolean
    text: string
    icon?: ReactNode
    variant?: keyof typeof btnVariantStyles
    tooltipText?: string
    classNameText?: string
}

const ButtonV2 = ({
    icon,
    text,
    className,
    isDisabled = false,
    variant = "primary",
    onClick,
    tooltipText,
    classNameText,
    ...props
}: IButtonProps) => {
    const { isHeLanguage } = useIsHeLanguage()
    const themeClass = isDisabled
        ? variant === "transparent"
            ? btnVariantStyles.transparentDisabled
            : btnVariantStyles.disabled
        : btnVariantStyles[variant]

    return (
        <button
            className={classNames(
                "flex items-center rounded-md  py-[9.5px] px-[10.5px]",
                themeClass,
                className,
                isHeLanguage && "flex-row-reverse"
            )}
            onClick={onClick}
            disabled={isDisabled}
            {...props}
        >
            {icon && <div className={isHeLanguage ? "ml-2" : "mr-2"}>{icon}</div>}
            <TypographyV2 className={classNames("px-1", classNameText)} type="sm" width="medium">
                {text}
            </TypographyV2>
        </button>
    )
}

export default ButtonV2
